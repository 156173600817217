import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import dayjs from 'dayjs';
import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import interactionPlugin from '@fullcalendar/interaction';
import './calendar.css';
// Redux stuff
import { useSelector, useDispatch } from 'react-redux';
import { getLessonsByHakwon, getLesson, getEnrollProductGroup } from '../../redux/actions/enrollActions';
import { getCurriculumsByProduct } from '../../redux/actions/dataActions';
import { isLogged } from '../../redux/actions/userActions';

// components
import LessonAccordian from '../../components/manager/LessonAccordian';
// MUI stuff
import withStyles from '@material-ui/core/styles/withStyles';
import {
    Container,
    Divider,
    Grid,
    Button,
    Typography,
    FormControl,
    RadioGroup,
    FormControlLabel,
    Radio,
    IconButton,
    Box,
} from '@material-ui/core';
import { NavigateBeforeOutlined, NavigateNextRounded, RefreshRounded } from '@material-ui/icons';

const styles = (theme) => ({
    ...theme.userPages,
    margin: {
        margin: theme.spacing(2),
    },
    button: {
        margin: theme.spacing(1),
    },
    buttonRight: {
        margin: theme.spacing(1),
        paddingLeft: 'auto',
    },
    dateBox: {
        paddingLeft: theme.spacing(1),
    },
});

// 뮤직플레이/뮤직플레이프리미엄 커리큘럼을 하나의 상품코드"DzbNwY2Bz5uPXP5r2sX6"로만 관리하기 위해 치환
const mgIds = [
    'DzbNwY2Bz5uPXP5r2sX6',
    'JnzBWJx8oTwH9KLcWG0B',
    'LWftI74DJAzr3h9ZGERH',
    'LY2bzzk1kL1jOyssZXDI',
    'hYkzmnFPqshqRNgr4gCN',
    'CwuUfd5sL2srGqpSxoHZ',
    'KiflCPN7CCrr7pTAeJQz',
    'WkHIN2b2KZ2316kcjIFs',
];

// groupBy
function groupBy(list, keyGetter) {
    const map = new Map();
    list.forEach((item) => {
        const key = keyGetter(item);
        const collection = map.get(key);
        if (!collection) {
            map.set(key, [item]);
        } else {
            collection.push(item);
        }
    });
    return map;
}

const SelectLesson = (props) => {
    const { classes, setLessonName, history } = props;
    // redux
    const { lessons, productGroup } = useSelector((state) => state.enroll);
    //const { authenticated, credentials } = useSelector(state => state.user);
    const { product, curriculums } = useSelector((state) => state.data);

    const dispatch = useDispatch();

    // 스토리지 이용
    const hakwonId = localStorage.getItem('hId');
    //const pid = localStorage.getItem('productId');
    const productGroupId = localStorage.getItem('productGroupId');
    const hName = localStorage.getItem('hName');
    const categoryName = localStorage.getItem('categoryName');

    // state
    const [finished, setFinished] = useState(false);
    const [radio, setRadio] = useState(0);
    const [sortRadio, setSortRadio] = useState(0);
    const [payRadio, setPayRadio] = useState(0);
    const [searchDate, setSearchDate] = useState(null);
    const [lessonInfo, setLessonInfo] = useState(null);
    const [payInfo, setPayInfo] = useState(false);
    const [payTypes, setPayTypes] = useState([]);
    const [payType, setPayType] = useState(false);
    const [selectedProductId, setSelectedProductId] = useState('');

    // 주문중단 기간
    const today = dayjs().format('YYYYMMDD');
    const sDt = dayjs().set('date', 26).format('YYYYMMDD');
    const eDt = dayjs().add(1, 'month').set('date', 1).format('YYYYMMDD');
    const closeYN = hakwonId === 'SDkUB38VqSpv0QMFXhrR' ? false : today >= sDt && today < eDt;
    //console.log(today, sDt, eDt, closeYN);
    //const closeText = '3월 수강 신청은 마감되었습니다(~2월25일). 4월수강신청은 3월1일부터 시작됩니다.';

    const onSortRadioSelect = (e) => {
        setSortRadio(e.target.value);
        setPayType(false);
        setPayRadio(null);
        setFinished(false);
    };
    const onRadioSelect = (e) => {
        //
        setRadio(e.target.value);
        setFinished(true);
    };
    const onPayRadioSelect = (e) => {
        setPayRadio(e.target.value);
        setPayType(true);
        //setLessonInfo(null);
        setRadio(null);
        //setFinished(false);
    };

    useEffect(() => {
        if (hakwonId && productGroupId) {
            dispatch(getLessonsByHakwon(hakwonId));
            //dispatch(getProduct(pid)); // 삭제필요 FIXME:
            dispatch(getEnrollProductGroup(productGroupId));
        } else {
            window.location.reload();
        }
    }, [hakwonId, productGroupId, dispatch]);

    // 결제타입 상품 매치
    useEffect(() => {
        if (productGroup.products) {
            productGroup.products.map((prod) =>
                prod.range === payRadio ? setSelectedProductId(prod.productId) : null
            );
        }
    }, [payRadio, productGroup]);

    // 커리큘럼가져오기
    // 뮤직플레이, 뮤직플레이프리미엄 상품은 하나의 상품으로만 치환해서 커리큘럼 관리함.
    useEffect(() => {
        if (mgIds.includes(selectedProductId)) {
            dispatch(getCurriculumsByProduct('DzbNwY2Bz5uPXP5r2sX6'));
        } else {
            dispatch(getCurriculumsByProduct(selectedProductId));
        }
    }, [dispatch, selectedProductId]);

    // 학원의 전체 레슨 중에서, 프로덕트그룹과 카테고리에 해당되는 레슨만 가져옴
    //const filteredLessons = lessons.filter(l => l.productId === pid); // 상품선택시..

    const filteredLessons = lessons?.filter(
        (l) =>
            productGroup.productIds?.some((p) => p === l.productId) &&
            l.category === categoryName &&
            (l.lessonType === 'trial'
                ? dayjs(l.startDate * 1000).format('YYYYMMDD') >= dayjs().format('YYYYMMDD')
                : dayjs(l.startDate * 1000).format('YYYYMMDD') >
                  dayjs(new Date(new Date().getFullYear(), new Date().getMonth(), 25)).format('YYYYMMDD')) &&
            (closeYN && l.lessonType !== 'trial'
                ? dayjs(l.startDate * 1000).format('YYYYMMDD') >=
                  dayjs().add(1, 'month').set('date', 25).format('YYYYMMDD')
                : true)
    );

    // 원데이수업은 당일 이후, 뮤직플레이정규/프리미엄 수업은 매월25일 이후 수업만 display, (마감주간) 매월25일이후면, 그다음달 25일 이후 수업만 display

    // 재분류한 레슨그룹

    // 캘린더 날짜 세팅
    // 1회성 레슨
    const datesOne = filteredLessons
        .filter((l) => l.lessonType === 'trial')
        .map((lesson) => ({
            date: dayjs(lesson.startDate * 1000).format('YYYY-MM-DD'),
            display: 'background',
        }));

    // 반복 레슨
    const recurDates = filteredLessons
        .filter((lesson) => lesson.isRepeat === true)
        .map((l) =>
            l.recurrentDates.map((r, i, le) => ({
                date: dayjs(r).format('YYYY-MM-DD'),
                display: 'background',
                duration: le.duration,
            }))
        );
    const flated = recurDates.reduce((a, v) => a.concat(v), []);
    const dates = [...datesOne, ...flated];

    // // 캘린더 날짜세팅
    // const dates = filteredLessons.map(lesson => ({
    //     date: dayjs(lesson.startDate * 1000).format('YYYY-MM-DD'),
    //     display: 'background'
    // }));

    // 캘린더 리턴
    const handleDateClick = (date) => {
        setRadio(null);
        setSortRadio(null);
        setLessonInfo(null);
        localStorage.setItem('lessonName', '');
        localStorage.setItem('lessonId', '');
        setLessonName('');
        const today = dayjs(new Date()).format('YYYY-MM-DD');

        if (date.dateStr < today) {
            alert('종료된 날짜의 강좌는 선택하실 수 없습니다');
        } else if (date.dateStr === today) {
            alert('오늘 진행되는 강좌의 수강여부는 해당 학원에 문의하신 이후에, 선택하시기 바랍니다.');
            setSearchDate(date.dateStr);
        } else {
            setSearchDate(date.dateStr);
        }
        setPayInfo(false);
        setFinished(false);
    };
    const resetSearchDate = () => {
        setSearchDate(null);
        setLessonInfo(null);
        setFinished(false);
    };
    // 결제 타입 보여주기
    const showPayType = (les) => {
        setPayTypes(les);
        setPayInfo(true);
        setFinished(false);
    };

    // 레슨 결과
    const showLessonInfo = (index, event) => {
        const res = dispatch(isLogged(history));
        if (res === 'logged') {
            if (typeof event.target.disabled === 'undefined') {
                return;
            } else {
                setLessonInfo(filteredLessons[index]);
                dispatch(getLesson(filteredLessons[index].lessonId));

                // FIXME: 스토리지 저장 및 스텝퍼에 고정

                localStorage.setItem('lessonName', filteredLessons[index].lessonName);
                localStorage.setItem('lessonId', filteredLessons[index].lessonId);
                setLessonName(filteredLessons[index].lessonName);
            }
        }
    };

    let label;
    let isClosed;
    let cnt = [];

    const repeatedLessons = filteredLessons
        ?.map((lesson, index) =>
            lesson.isRepeat === true &&
            (dayjs(lesson.startDate * 1000).format('YYYY-MM-DD') === searchDate ||
                (lesson.isRepeat && lesson.recurrentDates.some((d) => dayjs(d).format('YYYY-MM-DD') === searchDate)))
                ? lesson
                : null
        )
        .filter(Boolean);

    const lessonsProducts = repeatedLessons.map((le) => {
        const prod = productGroup?.products.filter((pr) => pr.productId === le.productId)[0];

        return {
            ...le,
            range: prod.range,
            label: le.isWithoutTimeTable
                ? dayjs(le.startDate * 1000).format('ddd요일 클래스')
                : dayjs(le.startDate * 1000).format('A hh시 mm분 클래스'),
            productId: prod.productId,
        };
    });

    const displayTimes = Array.from(groupBy(lessonsProducts, (le) => le.label));

    const closedLesson = filteredLessons
        .filter((lesson) => (lesson.status !== 'open' ? true : lesson.enrolled >= lesson.capacity ? true : false))
        .map((lesson) => lesson.lessonId);

    const payLabel = ['1개월', '3개월', '6개월', '12개월'];
    const payLabels = ['1개월(선결제)', '3개월(선결제)', '6개월(매월정기결제)', '12개월(매월정기결제)'];

    return (
        <Container>
            <Divider light style={{ marginTop: 20, marginBottom: 20 }} />
            <Grid container direction="row" spacing={2}>
                <Grid item xs={12} sm={6}>
                    <div style={{ width: 300, height: 300 }}>
                        <FullCalendar
                            defaultView="dayGridMonth"
                            plugins={[dayGridPlugin, interactionPlugin]}
                            headerToolbar={{
                                start: 'prev',
                                center: 'title',
                                end: 'today next',
                            }}
                            buttonText={{ today: '오늘' }}
                            locale="ko-KR"
                            fixedWeekCount={false}
                            events={dates}
                            eventColor="transparent"
                            eventTextColor="#f15624"
                            eventBackgroundColor="#f15624"
                            eventBorderColr="#f15624"
                            dateClick={handleDateClick}
                        />
                    </div>
                </Grid>
                <Grid item xs={12} sm={6}>
                    <Box>
                        <Typography variant="subtitle1" gutterBottom>
                            달력에서 날짜를 선택해주세요
                            <IconButton onClick={resetSearchDate}>
                                <RefreshRounded />
                            </IconButton>
                        </Typography>
                        <FormControl component="fieldset">
                            <RadioGroup value={sortRadio} onChange={onSortRadioSelect}>
                                {displayTimes.map((d, index) => {
                                    const displayLabel = d[0];
                                    const displayPayLessons = d[1].sort((a, b) => (a.range > b.range ? 1 : -1));
                                    return (
                                        <FormControlLabel
                                            key={index}
                                            value={displayLabel}
                                            control={<Radio />}
                                            label={displayLabel}
                                            onClick={() => showPayType(displayPayLessons)}
                                        />
                                    );
                                })}
                            </RadioGroup>
                        </FormControl>
                        {payInfo && (
                            <FormControl component="fieldset">
                                <Divider light style={{ margin: 10 }} />
                                <Typography variant="subtitle1">결제주기 선택</Typography>
                                <Typography color="secondary" variant="body2">
                                    결제주기를 선택하면, 아래에 선택할 수 있는 클래스가 나타납니다.
                                </Typography>
                                <RadioGroup name="paytype" value={payRadio} onChange={onPayRadioSelect} row>
                                    {payLabel.map((p, index) => {
                                        const x = payTypes.filter((typ) => typ.range === p)[0] || '';

                                        return (
                                            <FormControlLabel
                                                value={p}
                                                control={<Radio disabled={x.range !== p} />}
                                                label={payLabels[index]}
                                                onClick={(e) => {
                                                    if (x.range !== p) {
                                                        alert(
                                                            '해당되는 결제 유형이 없습니다. 학원에 문의하시기 바랍니다.'
                                                        );
                                                        setPayRadio(null);
                                                        setFinished(false);
                                                    } else {
                                                        const res = dispatch(isLogged(history));
                                                        if (res === 'logged') {
                                                            setLessonInfo(x);
                                                            setFinished(true);

                                                            dispatch(getLesson(x.lessonId));

                                                            //

                                                            localStorage.setItem('lessonName', x.lessonName);
                                                            localStorage.setItem('lessonId', x.lessonId);
                                                            setLessonName(x.lessonName);
                                                        }
                                                    }
                                                }}
                                            />
                                        );
                                    })}
                                </RadioGroup>
                            </FormControl>
                        )}

                        <FormControl component="fieldset">
                            {filteredLessons.length === 0 ? (
                                <div>
                                    <Divider light style={{ margin: 10 }} />
                                    <Typography color="error" variant="body2">
                                        선택하신 원에서 진행중인 수업이 없습니다.
                                    </Typography>
                                    <Typography color="error" variant="body2">
                                        해당 학원 혹은 본사에(1688-7970){' '}
                                    </Typography>
                                    <Typography color="error" variant="body2">
                                        문의해 주세요.
                                    </Typography>
                                </div>
                            ) : (
                                <div>
                                    <Divider light style={{ margin: 10 }} />
                                    <RadioGroup aria-label="pay" name="pay1" value={radio} onChange={onRadioSelect}>
                                        {filteredLessons
                                            .sort((a, b) =>
                                                a.lessonName > b.lessonName ? 1 : a.lessonName < b.lessonName ? -1 : 0
                                            )
                                            // eslint-disable-next-line array-callback-return
                                            .map((lesson, index) => {
                                                isClosed =
                                                    lesson.status !== 'open'
                                                        ? true
                                                        : lesson.enrolled >= lesson.capacity
                                                        ? true
                                                        : false;
                                                isClosed
                                                    ? (label = (
                                                          <div key={index}>
                                                              {' '}
                                                              <Typography
                                                                  variant="subtitle2"
                                                                  color="primary"
                                                                  display="inline"
                                                              >
                                                                  {lesson.lessonName}
                                                              </Typography>
                                                              <Typography
                                                                  variant="subtitle2"
                                                                  display="inline"
                                                                  color="error"
                                                              >
                                                                  [마감]
                                                              </Typography>
                                                          </div>
                                                      ))
                                                    : (label = (
                                                          <Typography variant="subtitle2" color="primary" key={index}>
                                                              {lesson.lessonName}
                                                          </Typography>
                                                      ));

                                                //console.log(dayjs(lesson.startDate * 1000).format('YYYY-MM-DD'), dayjs(new Date()).format('YYYY-MM-DD'), searchDate);
                                                if (
                                                    (lesson.isRepeat &&
                                                        payType &&
                                                        lesson.productId === selectedProductId &&
                                                        lesson.recurrentDates[0] === sortRadio) ||
                                                    !lesson.isRepeat
                                                ) {
                                                    if (
                                                        searchDate ===
                                                            dayjs(lesson.startDate * 1000).format('YYYY-MM-DD') ||
                                                        (lesson.isRepeat &&
                                                            lesson.recurrentDates.some(
                                                                (d) => dayjs(d).format('YYYY-MM-DD') === searchDate
                                                            ))
                                                    ) {
                                                        cnt.push(selectedProductId);
                                                        return (
                                                            <FormControlLabel
                                                                key={index}
                                                                value={lesson.lessonId}
                                                                control={<Radio color="primary" />}
                                                                label={label}
                                                                onClick={(event) => showLessonInfo(index, event)}
                                                                disabled={isClosed}
                                                            />
                                                        );
                                                    }
                                                }
                                            })}
                                    </RadioGroup>
                                </div>
                            )}
                        </FormControl>
                    </Box>

                    <Box>
                        <Divider light style={{ marginTop: 15, marginBottom: 15 }} />
                        <Typography variant="subtitle1" gutterBottom>
                            {' '}
                            선택하신 수업 정보
                        </Typography>

                        {lessonInfo && lessonInfo.isRepeat ? (
                            <Container>
                                <Typography variant="body2" gutterBottom>
                                    - 수업명 : <b>{lessonInfo.lessonName}</b>
                                </Typography>
                                <Typography variant="body2" gutterBottom>
                                    - 구분 : [{lessonInfo.category}]
                                </Typography>
                                <Typography variant="body2" display="inline" gutterBottom>
                                    - 결제금액 :{' '}
                                </Typography>
                                <Typography color="primary" variant="body2" display="inline" gutterBottom>
                                    {' '}
                                    <b>{numberFormat(lessonInfo.price)} 원 </b>
                                </Typography>
                                {product?.regularPayment ? (
                                    <Typography variant="body2" gutterBottom>
                                        - 결제방법 : 매월 결제 <b>{product.regularPayment} </b>
                                    </Typography>
                                ) : (
                                    <Typography variant="body2" gutterBottom>
                                        - 결제방법 : 선결제
                                    </Typography>
                                )}
                                <Typography variant="body2" gutterBottom>
                                    - 첫수업 :{' '}
                                    <b>
                                        {dayjs(lessonInfo.startDate * 1000).format(
                                            lessonInfo.isWithoutTimeTable
                                                ? 'YYYY년 MM월DD일(ddd)'
                                                : 'YYYY년 MM월DD일(ddd) A hh시mm분'
                                        )}
                                    </b>
                                </Typography>
                                <Typography variant="body2" gutterBottom>
                                    - 각 수업일자 (<b>총 {lessonInfo.recurrentDates.length} 회</b>)
                                </Typography>
                                {/*  {
                                    lessonInfo.recurrentDates.map((d, i) => <Box key={i} className={classes.dateBox}><Typography variant='body2'>[{i + 1}회차] : {dayjs(d).format('YYYY년 MM월DD일(ddd)')}</Typography></Box>)
                                } */}
                                <Box height={10} />
                                <Typography variant="body2" gutterBottom>
                                    - 커리큘럼 미리보기
                                </Typography>
                                <Typography variant="caption" gutterBottom>
                                    * 자세한 커리큘럼은 연간교육계획안을 참고하십시오.
                                </Typography>
                                <LessonAccordian
                                    curriculums={curriculums
                                        .filter((c) => c.category === lessonInfo.category && c.status === 'open')
                                        .filter((c) => {
                                            const isSameYear =
                                                dayjs(lessonInfo.startDate * 1000).year() ===
                                                dayjs(lessonInfo.endDate * 1000).year();

                                            if (isSameYear) {
                                                return (
                                                    c.yyyymm.substr(4, 2) >=
                                                        dayjs(lessonInfo.startDate * 1000).format('MM') &&
                                                    c.yyyymm.substr(4, 2) <=
                                                        dayjs(lessonInfo.endDate * 1000).format('MM')
                                                );
                                            } else {
                                                return (
                                                    c.yyyymm.substr(4, 2) >=
                                                        dayjs(lessonInfo.startDate * 1000).format('MM') ||
                                                    c.yyyymm.substr(4, 2) <=
                                                        dayjs(lessonInfo.endDate * 1000).format('MM')
                                                );
                                            }
                                        })
                                        .map((c) => {
                                            const isSameYear =
                                                dayjs(lessonInfo.startDate * 1000).year() ===
                                                dayjs(lessonInfo.endDate * 1000).year();
                                            const ord = isSameYear
                                                ? '1'
                                                : c.yyyymm.substr(4, 2) >=
                                                  dayjs(lessonInfo.startDate * 1000).format('MM')
                                                ? '1'
                                                : '2';
                                            return {
                                                ...c,
                                                order: ord + c.yyyymm.substr(4, 2) + c.week,
                                            };
                                        })
                                        .sort((a, b) => {
                                            return a.order < b.order ? -1 : a.order > b.order ? 1 : 0;
                                        })}
                                    recurrentDates={lessonInfo?.recurrentDates}
                                    withoutTimeTable={lessonInfo?.isWithoutTimeTable}
                                />
                                <Box height={10} />
                                <Typography variant="body2" gutterBottom>
                                    - 수업시간 :{' '}
                                    <b>
                                        {lessonInfo.isWithoutTimeTable
                                            ? '학원 지정 시간 (조정 가능)'
                                            : dayjs(lessonInfo.startDate * 1000).format('(ddd) A hh시mm분')}
                                    </b>
                                </Typography>
                                <Typography variant="body2" gutterBottom>
                                    - 진행시간 : {lessonInfo.duration}분
                                </Typography>
                                <Typography variant="body2" gutterBottom>
                                    - 정원 : {lessonInfo.isWithoutTimeTable ? '6' : lessonInfo.capacity}명
                                    {lessonInfo.status !== 'open' ? (
                                        <Typography variant="body2" color="error" display="inline">
                                            <b> [마감되었습니다!]</b>
                                        </Typography>
                                    ) : lessonInfo.enrolled >= lessonInfo.capacity ? (
                                        <Typography variant="body2" display="inline" color="error">
                                            <b> [마감되었습니다!]</b>
                                        </Typography>
                                    ) : (
                                        ''
                                    )}
                                </Typography>
                                <Typography variant="body2" gutterBottom>
                                    - 장소 : <b>{hName}</b>{' '}
                                </Typography>
                                <Typography variant="body2" gutterBottom>
                                    - 안내 : {lessonInfo.descForParent.info1}
                                </Typography>
                                <Typography variant="body2" gutterBottom>
                                    - 준비 : {lessonInfo.descForParent.info2}
                                </Typography>
                            </Container>
                        ) : lessonInfo && !lessonInfo.isRepeat ? (
                            <Container>
                                <Typography variant="body2" gutterBottom>
                                    - 수업명 : <b>{lessonInfo.lessonName}</b>
                                </Typography>
                                <Typography variant="body2" gutterBottom>
                                    - 구분 : {lessonInfo.category}
                                </Typography>
                                <Typography variant="body2" display="inline" gutterBottom>
                                    - 결제금액 :{' '}
                                </Typography>
                                <Typography color="primary" variant="body2" display="inline" gutterBottom>
                                    {' '}
                                    <b>{numberFormat(lessonInfo.price)} 원 </b>{' '}
                                </Typography>
                                <Typography variant="body2" gutterBottom>
                                    - 시작시간 :{' '}
                                    <b>
                                        {dayjs(lessonInfo.startDate * 1000).format('YYYY년 MM월DD일(ddd) A hh시mm분')}
                                    </b>
                                </Typography>
                                <Typography variant="body2" gutterBottom>
                                    - 종료시간 :{' '}
                                    <b>{dayjs(lessonInfo.endDate * 1000).format('YYYY년 MM월DD일(ddd) A hh시mm분')}</b>
                                </Typography>

                                <Typography variant="body2" gutterBottom>
                                    - 정원 : {lessonInfo.capacity} 명
                                </Typography>
                                <Typography variant="body2" gutterBottom>
                                    - 장소 : <b>{hName}</b>{' '}
                                </Typography>
                                <Typography variant="body2" gutterBottom>
                                    - 안내 : {lessonInfo.descForParent.info1}
                                </Typography>
                                <Typography variant="body2" gutterBottom>
                                    - 준비 : {lessonInfo.descForParent.info2}
                                </Typography>
                            </Container>
                        ) : null}
                    </Box>
                </Grid>
            </Grid>
            <Box height={50} />

            {finished && !closedLesson.includes(localStorage.lessonId) ? (
                <Grid container justifyContent="center">
                    <Button
                        component={Link}
                        to={`/enrollstep2`}
                        className={classes.button}
                        variant="outlined"
                        startIcon={<NavigateBeforeOutlined />}
                    >
                        <Typography variant="button">학원 선택</Typography>
                    </Button>
                    <Button
                        component={Link}
                        to={`/enrollstep4`}
                        className={classes.buttonRight}
                        color="primary"
                        variant="contained"
                        endIcon={<NavigateNextRounded />}
                    >
                        <Typography variant="button">결제 하기</Typography>
                    </Button>
                </Grid>
            ) : (
                <Grid container justifyContent="center">
                    <Button
                        component={Link}
                        to={`/enrollstep2`}
                        className={classes.button}
                        variant="outlined"
                        startIcon={<NavigateBeforeOutlined />}
                    >
                        <Typography variant="button">학원 선택</Typography>
                    </Button>
                    <Button
                        disabled
                        component={Link}
                        to={`/enrollstep4`}
                        className={classes.buttonRight}
                        color="primary"
                        variant="contained"
                        endIcon={<NavigateNextRounded />}
                    >
                        <Typography variant="button">결제 하기</Typography>
                    </Button>
                </Grid>
            )}
        </Container>
    );
};

function numberFormat(inputNumber) {
    return inputNumber.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
}

export default withStyles(styles)(SelectLesson);

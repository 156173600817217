import React, { useState, useEffect } from 'react';
//import { Link } from 'react-router-dom';
// Redux stuff
import { useSelector, useDispatch } from 'react-redux';
import { logoutUser, getUserData, updateUserInfo, signoutUser, signoutUserForever } from '../redux/actions/userActions';
import { setMainHakwon } from '../redux/actions/enrollActions';
// components
import Navbar from '../components/layout/Navbar';
import MainGrid from '../components/layout/MainGrid';
import MainTabs from '../components/layout/MainTabs';
import DummyDivider from '../components/layout/DummyDivider';
import DialogOK from '../components/layout/DialogOK';
import Dialog1 from '../components/layout/Dialog1';
import PostCode from '../components/util/PostCode';
import SearchHakwon from '../components/enroll/SearchHakwon';
import Children from '../components/myclass/Children';
import CenterLoading from '../components/layout/CenterLoading';
import Footer from '../components/layout/Footer';
// MUI stuff
import { withStyles, useTheme } from '@material-ui/core/styles';

import { Grid, Badge, Button, Avatar, Typography, Divider, Box, TextField, InputAdornment, IconButton, useMediaQuery, Menu, MenuItem, Fade } from '@material-ui/core';
import { PhoneIphoneRounded, SaveRounded, HomeRounded, EmailRounded, InfoRounded, SearchRounded, Sync } from '@material-ui/icons';


const styles = (theme) => ({
    ...theme.userPage,
    large: {
        width: 100,
        height: 100,
    },
    textfield: {
        width: '100%',
        marginRight: theme.spacing(2)
    },
    badge: {
        backgroundColor: '#44b700',
        color: '#44b700',
        boxShadow: `0 0 0 2px ${theme.palette.background.paper}`,
        '&::after': {
            position: 'absolute',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            borderRadius: '50%',
            animation: '$ripple 1.2s infinite ease-in-out',
            border: '1px solid currentColor',
            content: '""',
        },
    },
    kakaoTalk: {
        width: 155,
        height: 35,
    },
    button: {
        margin: theme.spacing(3),
    },
    buttonRight: {
        margin: theme.spacing(3),
        paddingLeft: 'auto'
    },
    resign: {
        color: '#777777',
    }

});
const tabInfo = [
    { id: 'mypage', label: '마이 페이지', link: '/mypage' },
    { id: 'myclass', label: '마이 클래스', link: '/myclass' },
];


export const Mypage = (props) => {
    const { classes } = props;
    const theme = useTheme();
    const matches = useMediaQuery(theme.breakpoints.down('xs'));
    const user = useSelector(state => state.user);
    const { credentials, userLoading } = user;
    const { loading, errors, message } = useSelector(state => state.UI);
    const dispatch = useDispatch();

    const [selectedHakwon, setSelectedHakwon] = useState({ id: '', name: '' });

    const [hName, setHName] = useState('');
    const [child, setChild] = useState([{ studentName: '' }]);
    const [juso1, setJuso1] = useState('');
    const [juso3, setJuso3] = useState('');

    const [open, setOpen] = useState(false); // 주소검색창
    // dropMenu
    const [anchorEl, setAnchorEl] = React.useState(null);
    const dropOpen = Boolean(anchorEl);

    useEffect(() => {
        dispatch(getUserData());
    }, [dispatch]);

    useEffect(() => {
        if (credentials) {
            let j1 = credentials.address ? credentials.address.juso1 : '';
            let j2 = credentials.address ? credentials.address.juso2 : '';
            setHName(credentials.myHakwon ? credentials.myHakwon.hName : '');
            setChild(credentials.children ? credentials.children.filter(ch => !ch.isDeleted) : [{ studentName: '' }]);
            setJuso1(credentials.address?.juso1 ? `${j1} (${j2})` : '');
            setJuso3(credentials.address?.juso3);
        }
    }, [credentials])

    const [, setIsHakwonLists] = useState(false);

    const handleKakaoToAdmin = () => {
        window.open('https://pf.kakao.com/_xktTxoM/chat');
    }

    const handleChangeHakwon = () => {
        // 저장하고, DB에 갱신한다.
        setHName(selectedHakwon.name);
        dispatch(setMainHakwon(selectedHakwon.id))
        setOpen(false);
    }
    const handleSetJuso1 = (fullAddress) => {
        // console.log(fullAddress, juso1);
        setJuso1(fullAddress);
    }
    const handleSaveUserInfo = () => {
        let info;
        if (juso1.indexOf('(') === -1) {
            info = {
                address: {
                    juso1: juso1,
                    juso2: '',
                    juso3: juso3
                }
            }
        } else {
            info = {
                address: {
                    juso1: juso1.split('(')[0],
                    juso2: juso1.split('(')[1].split(')')[0],
                    juso3: juso3
                }
            };
        }

        dispatch(updateUserInfo(info));

    }
    const handleLogout = () => {
        dispatch(logoutUser(props.history));
    }

    const YellowButton = withStyles({
        root: {
            background: '#f2da00',
            padding: '0 25px',
            height: 38,
            '&:hover': {
                background: '#ffeb33',
            },
        }
    })(Button);
    const handleDropMenu = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleDisconnect = () => {
        if (window.confirm('카카오 계정과의 연결이 끊어집니다. \n다시 카카오계정으로 재가입하게 되면, \n기존 정보와의 연결이 가능합니다.') === true) {
            dispatch(signoutUser(props.history));
            setAnchorEl(null);
        } else {   //취소
            setAnchorEl(null);
            return false;
        }
    }
    const handleSignoutForever = () => {
        if (window.confirm('카카오 계정 연결 끊기와 동시에 \n모든 스마트 뮤직플레이 서비스에서 탈퇴합니다.\n \n개인정보는 지체없이 파기되며, 그로 인해 \n구매 이력의 확인 등 사후 서비스 이용에 제한이 따를 수 있습니다. \n재가입하더라도 이전 서비스를 활용할 수 없습니다. \n\n정말 탈퇴하시겠습니까?') === true) {
            setAnchorEl(null);

            dispatch(signoutUserForever(credentials, props.history))
        } else {
            setAnchorEl(null);
            return false;
        }
    }
    const handleClose = () => {
        setAnchorEl(null);
    };
    const refreshThumnailImage = () => {
        
        if (user.kakaoInfo.kakao_account) {
            if (credentials.profileImage !== user.kakaoInfo.kakao_account?.profile?.thumbnail_image_url) {

                dispatch(updateUserInfo({
                    profileImage: user.kakaoInfo.kakao_account.profile.thumbnail_image_url
                }));
            }

        }


    }



    return (
        <>
            <Navbar menu={2} />
            <div className='container'>
                <MainGrid>
                    <MainTabs tabInfo={tabInfo} initValue={0} />
                    <DummyDivider top={20} bottom={20} />
                    <Typography align='center' variant='h3' color='textSecondary'>마이 페이지</Typography>
                    <Box component='div' align='right' >
                        <Button className={classes.resign} onClick={handleDropMenu} disabled={userLoading}>회원탈퇴</Button>
                        <Button className={classes.resign} onClick={handleLogout} disabled={userLoading}>로그아웃</Button>
                        <Menu
                            id="fade-menu"
                            anchorEl={anchorEl}
                            keepMounted
                            open={dropOpen}
                            onClose={handleClose}
                            TransitionComponent={Fade}
                        >
                            <MenuItem onClick={handleDisconnect}><Typography variant='subtitle2' color='textSecondary'>카카오계정 연결끊기</Typography></MenuItem>
                            <MenuItem onClick={handleSignoutForever}><Typography variant='subtitle2' color='textSecondary'>연결끊고 서비스완전탈퇴</Typography></MenuItem>
                        </Menu>
                    </Box>
                    <DummyDivider top={30} />
                    <Box align='center'>

                        <Badge
                            overlap="circular"
                            anchorOrigin={{
                                vertical: 'bottom',
                                horizontal: 'right',
                            }}
                            badgeContent={<IconButton onClick={refreshThumnailImage}><Sync color='secondary' /></IconButton>}
                        >
                            <Avatar alt='profile' src={credentials.profileImage} className={classes.large} />
                        </Badge>



                        <DummyDivider top={10} />
                        <Typography align='center' variant='h4' color='textSecondary' gutterBottom>{credentials.name}</Typography>
                        <DummyDivider top={30} />
                        <YellowButton onClick={handleKakaoToAdmin}>카카오톡 문의하기</YellowButton>

                    </Box>
                    <Divider light style={{ marginTop: 20, marginBottom: 20 }} />
                    {
                        credentials.level < 9 &&
                        <Box m={2}>
                            <InfoRounded color='primary' display='inline' style={{ marginRight: 10 }} /> <Typography color='primary' variant='subtitle2' display='inline'>원장/교사님들은 반드시 '내학원' 설정을 근무 중인 학원으로 설정해주시기 바랍니다.</Typography>
                        </Box>
                    }
                    <Box display='flex' flexDirection='row' justifyContent='center' alignItems='center' style={{ marginBottom: 15 }}>
                        {/* <Hidden only='xs'> */}
                        <Box style={{ width: 70, padding: 5 }}>
                            <Typography variant={matches ? 'subtitle2' : 'h5'} >
                                이메일
                            </Typography>
                        </Box>
                        {/* </Hidden> */}
                        <Box display='flex' justifyContent='flex-start' className={classes.textfield} >
                            <TextField
                                color='secondary'
                                variant='outlined'
                                value={credentials.email || ''}
                                name='email'
                                placeholder='이메일'
                                InputProps={{
                                    startAdornment: (
                                        <InputAdornment position="start">

                                            <DialogOK title="이메일" textContent={credentials.email} mainContent='카카오톡 계정으로 가입되어 있어, 이 이메일은 수정이 불가능합니다.  변경이 필요하시면 카카오톡에서 변경하시기 바랍니다.'>
                                                <IconButton>
                                                    <EmailRounded color='secondary' />
                                                </IconButton>
                                            </DialogOK>

                                        </InputAdornment>
                                    )
                                }}
                                style={{
                                    backgroundColor: '#ffffff',
                                    height: 56,
                                }}
                                fullWidth
                                readOnly
                            />
                        </Box>
                    </Box>

                    <Box display='flex' flexDirection='row' justifyContent='center' alignItems='center' style={{ marginBottom: 15 }}>
                        {/* <Hidden only='xs'> */}
                        <Box style={{ width: 70, padding: 5 }}>
                            <Typography variant={matches ? 'subtitle2' : 'h5'}>
                                내 학원
                    </Typography>
                        </Box>
                        {/* </Hidden> */}
                        <Box display='flex' justifyContent='flex-start' className={classes.textfield} >
                            <TextField
                                color='secondary'
                                variant='outlined'
                                name='hakwon'
                                value={hName || ''}
                                placeholder='학원등록하세요'
                                InputProps={{
                                    startAdornment: (
                                        <InputAdornment position="start">
                                            <Dialog1
                                                open={open}
                                                title='내 학원 정보 등록'
                                                textContent="지역의 학원을 검색하시고, 선택하시기 바랍니다."
                                                mainContent={<SearchHakwon setSelectedHakwon={setSelectedHakwon} setIsHakwonLists={setIsHakwonLists} noHakwon={false} />}
                                                actionButton="적용"
                                                actionHandler={handleChangeHakwon}
                                            >
                                                <IconButton>
                                                    <HomeRounded color='secondary' />
                                                </IconButton>
                                            </Dialog1>
                                        </InputAdornment>
                                    )
                                }}
                                style={{
                                    backgroundColor: '#ffffff',
                                    height: 56,
                                }}
                                fullWidth
                            />
                        </Box>
                    </Box>

                    <Children
                        child={child}
                        setChild={setChild}
                    />


                    <Box display='flex' flexDirection='row' justifyContent='center' alignItems='center' style={{ marginBottom: 15 }}>
                        {/* <Hidden only='xs'> */}
                        <Box style={{ width: 70, padding: 5 }}>
                            <Typography variant={matches ? 'subtitle2' : 'h5'}>
                                휴대폰
                            </Typography>
                        </Box>
                        {/* </Hidden> */}
                        <Box display='flex' justifyContent='flex-start' className={classes.textfield}>
                            <TextField
                                color='secondary'
                                variant='outlined'
                                name='phone'
                                value={typeof credentials.phone === 'undefined' ? "" : phoneFormat(credentials.phone)}      //TODO: 가입시 업데이트 안되게 조치
                                placeholder='휴대전화'
                                InputProps={{
                                    startAdornment: (

                                        <InputAdornment position="start">

                                            <DialogOK
                                                title="휴대전화"
                                                textContent={phoneFormat(credentials.phone)}
                                                mainContent='카카오톡 계정으로 가입되어 있어, 이 휴대전화번호는 수정이 불가능합니다.  변경이 필요하시면 카카오톡에서 변경하시기 바랍니다.'>
                                                <IconButton >
                                                    <PhoneIphoneRounded color='secondary' />
                                                </IconButton>
                                            </DialogOK>


                                        </InputAdornment>

                                    ),
                                }}
                                style={{
                                    backgroundColor: '#ffffff',
                                    height: 56,
                                }}
                                fullWidth

                            />
                        </Box>
                    </Box>
                    <Box display='flex' flexDirection='row' justifyContent='center' alignItems='center' style={{ marginBottom: 15 }}>
                        {/* <Hidden only='xs'> */}
                        <Box style={{ width: 70, padding: 5 }}>
                            <Typography variant={matches ? 'subtitle2' : 'h5'}>
                                주소
                    </Typography>
                        </Box>
                        {/* </Hidden> */}
                        <Box display='flex' justifyContent='flex-start' className={classes.textfield}  >
                            <TextField
                                color='secondary'
                                variant='outlined'
                                name='juso1'
                                value={juso1 || ''}
                                onChange={e => setJuso1(e.target.value)}
                                placeholder='주소를 검색하세요'
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <PostCode title='주소검색' textContent="주소검색" onChange={handleSetJuso1}>
                                                {/* <Button variant='contained' color='secondary' style={{paddingLeft: 0, paddingRight: 0}} >검색</Button> */}
                                                <IconButton variant='contained'><SearchRounded color='secondary' /></IconButton>
                                            </PostCode>
                                        </InputAdornment>
                                    ),
                                }}
                                style={{
                                    backgroundColor: '#ffffff',
                                    height: 56,
                                }}
                                fullWidth

                            />
                        </Box>

                    </Box>
                    <Box display='flex' flexDirection='row' justifyContent='center' alignItems='center' style={{ marginBottom: 15 }}>
                        {/* <Hidden only='xs'> */}
                        <Box style={{ width: 70, padding: 5 }}>
                        </Box>
                        {/*  </Hidden> */}
                        <Box display='flex' justifyContent='flex-start' className={classes.textfield} >
                            <TextField
                                color='secondary'
                                variant='outlined'
                                name='juso3'
                                value={juso3 || ''}      //TODO: 주소 DB 생성
                                onChange={e => setJuso3(e.target.value)}
                                placeholder='상세주소'
                                style={{
                                    backgroundColor: '#ffffff',
                                    height: 56,
                                }}
                                fullWidth

                            />
                        </Box>

                    </Box>
                    <Divider light style={{ marginTop: 20, marginBottom: 20 }} />
                    {
                        loading && <CenterLoading />
                    }
                    {
                        errors && <Typography color='error'>{errors ? errors.error : ''}</Typography>
                    }
                    {
                        message && <Typography color='error'>{message ? message.mesage : ''}</Typography>
                    }
                    <Grid container justifyContent='center'>
                        {/* <Button disabled className={classes.button} variant='contained' color='secondary' startIcon={<EditRounded />} ><Typography variant='button'>수정하기</Typography></Button> */}
                        <Button className={classes.buttonRight} color='primary' variant='contained' endIcon={<SaveRounded />} onClick={handleSaveUserInfo}><Typography variant='button'>저장하기</Typography></Button>
                    </Grid>

                </MainGrid>
                <Footer />
            </div>
        </>
    );
};

export default withStyles(styles)(Mypage);

function phoneFormat(num) {
    if (typeof num !== 'undefined')
        return num.replace(/(^02.{0}|^01.{1}|[0-9]{3})([0-9]+)([0-9]{4})/, "$1-$2-$3");
}


import React from 'react';
import { useParams } from 'react-router-dom';
// Redux stuff

// components
import Navbar from '../components/layout/Navbar'
import MainGrid from '../components/layout/MainGrid';
import MainTabs from '../components/layout/MainTabs';
import GogoAbout from './gogo/GogoAbout';
import GogoCurriculum from './gogo/GogoCurriculum';
import Footer from '../components/layout/Footer';
// MUI stuff
import withStyles from '@material-ui/core/styles/withStyles';

const styles = (theme) => ({
    ...theme.userPage,
});
export const Gogo = (props) => {
    const { topic } = useParams();
    const tabInfo = [
        { id: "about", label: "뮤직플레이 소개", link: '/gogo/about' },
        { id: "curriculum", label: "연간교육계획안", link: '/gogo/curriculum' },
    ];

    return (
        <>
            <Navbar menu={0} />
            <div className='container'>
                <MainGrid>
                    <MainTabs tabInfo={tabInfo} />
                    {topic === tabInfo[0].id && <GogoAbout />}
                    {topic === tabInfo[1].id && <GogoCurriculum />}

                </MainGrid>
                <Footer />
            </div>
        </>
    );
};


export default withStyles(styles)(Gogo);


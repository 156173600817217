import React, { useEffect, useState } from 'react';
// redux
import { useDispatch } from 'react-redux';
import { updateMember, makeRequestRead } from '../../redux/actions/managerActions';
import { sendATA5} from '../../redux/actions/enrollActions';
// MUI stuff
import withStyles from '@material-ui/core/styles/withStyles';
import { Typography, Button, TextField, Dialog, DialogContent, DialogContentText, FormControlLabel, FormLabel, RadioGroup, Radio, FormControl, DialogActions, Switch, DialogTitle } from '@material-ui/core';
import { SaveRounded } from '@material-ui/icons';
import { isLogged } from '../../redux/actions/userActions';
const styles = (theme) => ({
    ...theme.userPage,

});
const DialogStudentRegister = props => {
    const { registerOpen, handleRegisterClose, hakwonId, selectedRequestId, selectedStudentName, unRegisterd, ataData, history } = props;

    // console.log(registerOpen, hakwonId, selectedRequestId, selectedStudentName, unRegisterd, ataData)
    const dispatch = useDispatch();
    const initialValue = {
        age: '',
        sex: '',
        memo: '',
        phone: '',
        siblings: [],
        classInfo: {
            musicClass: '',
            artClass: ''
        },
        isRegistered: false,
        isPaidGogo: false,
        isInvisibleForHakwon: false,
        studentNameForHakwon: ''
    }
    const [value, setValue] = useState(initialValue);
    const rStudent = unRegisterd.filter(mem=> mem.studentName === selectedStudentName)[0];

    // console.log(unRegisterd);
    // console.log(rStudent);
    // console.log(selectedStudentName);

    // console.log(unRegisterd, 'unRegistered')
    useEffect(()=>{        
        setValue({
            studentName: rStudent?.studentName,
            age: rStudent?.age,
            sex: rStudent?.sex,
            memo: rStudent?.memo,
            phone: rStudent?.phone,
            siblings: [],
            classInfo: rStudent?.classInfo,
            isRegistered: rStudent?.isRegistered,
            isPaidGogo: rStudent?.isPaidGogo,
            isInvisibleForHakwon: rStudent?.isInvisibleForHakwon,
            studentNameForHakwon: rStudent?.studentNameForHakwon ? rStudent?.studentNameForHakwon : rStudent?.studentName
        })
    },[rStudent]);

    const onChangeSex = e => {
        setValue({
            ...value,
            sex: e.target.value
        })
    }
    const onChangeValue = e => {
        setValue({
            ...value,
            [e.target.name]: e.target.value
        })
    }
    const onChangeSwitch = e => {
        setValue({
            ...value,
            [e.target.name]: e.target.checked
        });
        if (e.target.checked && e.target.name === 'isInvisibleForHakwon') {
            alert('삭제하시겠습니까? 저장 버튼을 누르면 삭제됩니다.');
        }
    }
    const onChangeClass = e => {
        setValue({
            ...value,
            classInfo: {
                ...value.classInfo,
                [e.target.name]: e.target.value
            }
        })
    }
    const handleRegisterAndSaveInfo = () => {
        const newData = {
            hakwonId: hakwonId,
            isRegistered: value.isRegistered,
            isDeleted: false,
            isPaidGogo: value.isPaidGogo,
            isInvisibleForHakwon: value.isInvisibleForHakwon,
            studentName: value.studentNameForHakwon,
            studentNameForHakwon: value.studentNameForHakwon,
            age: value.age ? value.age : '',
            sex: value?.sex,
            classInfo: value?.classInfo,
            memo: value?.memo,
            phone: value?.phone,
            siblings: value?.siblings
        }

        const res = dispatch(isLogged(history));
        if (res ==='logged'){
            dispatch(updateMember(rStudent.studentId, newData));
            // console.log(selectedRequestId, hakwonId);
            if(value.isRegistered === true){
                dispatch(makeRequestRead(selectedRequestId, hakwonId));
    
                // 알림톡 발송
                const fullData = {
                    ...ataData,
                    name: newData.studentName,
                    age : newData.age,
                }
                console.log(fullData);
                dispatch(sendATA5(fullData));
            }
            handleRegisterClose();
            setValue(initialValue);
        }
        
        

    }
    // console.log(value);

    return (
        <Dialog open={registerOpen} onClose={handleRegisterClose} aria-labelledby="form-dialog-title">
            <DialogTitle id="form-dialog-title">원생 등록</DialogTitle>
            <DialogContent>
                <DialogContentText>
                    <Typography variant='body1' gutterBottom>원생정보를 확인하고 등록합니다. </Typography>
                    <Typography variant='subtitle1'>학생이름: {value.studentName}</Typography>
                </DialogContentText>
                <FormControlLabel
                    control={<Switch checked={value.isRegistered} onChange={onChangeSwitch} name="isRegistered" />}
                    label={<Typography color='secondary'>{value.isRegistered ? "원생 등록 확인" : "원생 미등록"}</Typography>}
                /><br />
                <TextField
                    margin="dense"
                    id="studentNameForHakwon"
                    label="원생 표시 이름"
                    type="text"
                    name='studentNameForHakwon'
                    value={value.studentNameForHakwon}
                    onChange={onChangeValue}
                    fullWidth
                />
                <FormControl component="fieldset">
                    <FormLabel component="legend">성별</FormLabel>
                    <RadioGroup row value={value.sex} onChange={onChangeSex}>
                        <FormControlLabel value='남' control={<Radio color='primary' />} label='남' />
                        <FormControlLabel value='여' control={<Radio color='primary' />} label='여' />
                    </RadioGroup>
                </FormControl>

                
                <TextField

                    margin="dense"
                    id="age"
                    label="생년월일(YYYYMMDD)"
                    type="text"
                    name='age'
                    value={value.age}
                    onChange={onChangeValue}
                    fullWidth
                />
                <TextField

                    margin="dense"
                    id="phone"
                    label="학생전화번호"
                    type="text"
                    name='phone'
                    value={value.phone}
                    onChange={onChangeValue}
                    fullWidth
                />
                <TextField

                    margin="dense"
                    id="memo"
                    label="메모 (메인에 표시)"
                    type="text"
                    name='memo'
                    value={value.memo}
                    onChange={onChangeValue}
                    fullWidth
                    multiline
                />
                <FormControlLabel
                    control={<Switch checked={value.isPaidGogo} onChange={onChangeSwitch} name="isPaidGogo" />}
                    label={value.isPaidGogo ? "뮤직플레이 수강중" : "뮤직플레이 미등록"}
                /><br />
                <TextField

                    margin="dense"
                    id="musicClass"
                    label="어린음악대 클래스이름"
                    type="text"
                    name='musicClass'
                    value={value.classInfo?.musicClass}
                    onChange={onChangeClass}
                    fullWidth
                />
                <TextField

                    margin="dense"
                    id="artClass"
                    label="어린화가들 클래스이름(있을 경우)"
                    type="text"
                    name='artClass'
                    value={value.classInfo?.artClass}
                    onChange={onChangeClass}
                    fullWidth
                />

            </DialogContent>
            <DialogActions>
                <Button onClick={handleRegisterClose} color="primary" variant='contained'>닫기</Button>
                <Button onClick={handleRegisterAndSaveInfo} color="primary" variant='contained' startIcon={<SaveRounded />}>저장</Button>
            </DialogActions>
        </Dialog>
    );
}
/* function phoneFormat(string) {
    if (string.startsWith("+")) {
        string = "0" + string.substring(3);
    } else if (string.startsWith("82")) {
        string = "0" + string.substring(2);
    }
    const str = string.replace(/(^02.{0}|^01.{1}|[0-9]{3})([0-9]+)([0-9]{4})/, "$1-$2-$3");
    return String(str);
}
 */
export default withStyles(styles)(DialogStudentRegister);
import React, { useEffect } from 'react';
import CKEditor from 'ckeditor4-react';

// redux

// MUI stuff
import withStyles from '@material-ui/core/styles/withStyles';
import { Typography, Grid, Box, IconButton, Button, TextField, Dialog, DialogContent, DialogContentText, DialogActions, DialogTitle } from '@material-ui/core';
import { CloseRounded, SaveRounded } from '@material-ui/icons';
import VideoClip from '../../components/home/VideoClip'; // 필요

const styles = (theme) => ({
    ...theme.userPage,
    closeButton: {
        position: 'absolute',
        right: theme.spacing(1),
        top: theme.spacing(1),
        color: theme.palette.grey[500],
    },
    
});
const DialogWrite = props => {
    const { classes, type, handleUploadImage, open, setOpen, name, setName, title, setTitle, postData, managerLoading, url, hName } = props;
    // editor
    const [data, setData] = React.useState(null);
    const [vimeos, setVimeos] = React.useState('');
    // 추가
    const [buttonTitle, setButtonTitle] = React.useState('뮤직플레이 바로가기');
    const [buttonUrl, setButtonUrl] = React.useState('/');

    const onEditorChange = (event) => {
        //setDescription(e.target.value)
        setData(event.editor.getData());
    }
    useEffect(() => {
        setTitle('');
        setName(type==='질문답변' ? hName : '');

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [type, hName])
    const saveData = () => {
        if (name === '') {
            alert("노출될 작성자 이름을 입력해주세요")
        } else if (title === '') {
            alert("제목을 입력해주세요")
        } else if (data === '') {
            alert("내용을 입력해주세요")
        } else {
            let boardData = {
                title: title,
                name: name,
                description: data,
                type: type,
                vimeos: vimeos,
                buttonTitle : buttonTitle,
                buttonUrl : buttonUrl
            }
            postData(boardData);
            setOpen(false);
        }

    }
    const handleAddPicture = () => {
        const fileInput = document.getElementById('imageInput');
        fileInput.click();
    }
    const handleImageChange = (e) => {
        const image = e.target.files[0];
        // send to the server
        const formData = new FormData();    // FormData()를 이용하여 전송
        formData.append('image', image, image.name);

        handleUploadImage(formData);
    }


    return (
        <Dialog
            open={open}
            aria-labelledby="form-dialog-title"
            fullScreen
            disableEnforceFocus
        >
            <IconButton aria-label="close" className={classes.closeButton} onClick={() => setOpen(false)}>
                <CloseRounded />
            </IconButton>
            <DialogTitle id="form-dialog-title">{type} 작성</DialogTitle>
            <DialogContent>
                <DialogContentText>
                    <Typography variant='body1' gutterBottom>{type} 작성</Typography>

                </DialogContentText>
                <TextField
                    margin="dense"
                    label="제목"
                    type="text"
                    value={title}
                    onChange={e => setTitle(e.target.value)}
                    fullWidth
                />
                <TextField
                    margin="dense"
                    label="작성자"
                    type="text"
                    value={name}
                    onChange={e => setName(e.target.value)}
                    fullWidth
                    disabled={type==='질문답변'}
                />
                <CKEditor
                    data={data}
                    onChange={onEditorChange} />
                <Box height={30} />
                {
                    type !== '질문답변' && <Box>
                        <Box>
                            <Button variant='contained' onClick={handleAddPicture}>이미지 URL 생성/등록</Button>
                            <input
                                type='file'
                                id='imageInput'
                                hidden='hidden'
                                onChange={handleImageChange} />
                        </Box>
                        <Box>
                            <Typography id='url'>{url?.message}</Typography>
                            <img src={url?.message} alt='URL' />
                        </Box>
                        <Box height={20} />
                        <Typography variant='subtitle2'>영상 업로드(vimeo 영상id)</Typography>
                        <TextField
                            margin="dense"
                            label="영상목록 (,로 구분)"
                            type="text"
                            value={vimeos}
                            onChange={e => setVimeos(e.target.value)}
                            fullWidth
                        />
                        <Box height={20} />
                        <Typography variant='subtitle2'>버튼 링크 타이틀 및 경로 입력</Typography>
                        <TextField
                            margin="dense"
                            label="버튼 타이틀"
                            type="text"
                            value={buttonTitle}
                            onChange={e => setButtonTitle(e.target.value)}
                            fullWidth
                        />
                        <TextField
                            margin="dense"
                            label="버튼 경로(/)"
                            type="text"
                            value={buttonUrl}
                            onChange={e => setButtonUrl(e.target.value)}
                            fullWidth
                        />

                    </Box>
                }

                <Box p={1} m={1}>
                    <Grid container>
                        <Grid item xs={12} sm={6}>
                            {vimeos && vimeos.replace(/ /g, '').split(',').map((vimeo, index) =>
                                <VideoClip
                                    url={`https://vimeo.com/${vimeo}`}
                                    key={index}
                                />)
                            }
                        </Grid>
                    </Grid>
                </Box>
            </DialogContent>
            <DialogActions>
                <Button onClick={() => setOpen(false)} color="primary" variant='contained'>닫기</Button>
                <Button onClick={saveData} disabled={managerLoading} color="primary" variant='contained' startIcon={<SaveRounded />}>저장</Button>
            </DialogActions>
        </Dialog>
    );
}

export default withStyles(styles)(DialogWrite);
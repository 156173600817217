import React, { useState } from 'react';
import dayjs from 'dayjs';

// Redux Stuff
import { useDispatch, useSelector } from 'react-redux';
import { isLogged } from '../../redux/actions/userActions';
import { getAllSalesFromTo } from '../../redux/actions/managerActions';

// components
import CenterLoading from '../../components/layout/CenterLoading';

// MUI
import { withStyles } from '@material-ui/styles';
import {
    Box,
    Typography,
    Divider,
    TextField,
    Button,
    Dialog,
    DialogTitle,
    DialogActions,
    DialogContent,
} from '@material-ui/core';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import { ArrowDropDown, ArrowDropUp, SearchRounded, SendRounded } from '@material-ui/icons';
import { sendATA6, sendATA7 } from '../../redux/actions/enrollActions';

const messageForParent = `안녕하세요. 스마트 뮤직플레이입니다. 
{hName} {name} 학생이 수강 중인 뮤직플레이 수업이 이번 달에 종료됩니다.
다음달 뮤직플레이 수업을 이용하시려면 지금 수업 신청을 해주시기 바랍니다.
뮤직플레이 홈페이지 musicgogo.co.kr에서 신청하실 수 있습니다.
수업신청은 매월 25일에 마감됩니다.
감사합니다.`;
const messageForManager = `안녕하세요.어린음악대 본사입니다.
이번 달에 수강이 종료되는 {hName} 뮤직플레이 학생들에게 수업신청 알림톡 전송을 완료하였습니다.
[학원관리> 수강생] 메뉴에서 빨간색으로 표시된 학생을 확인하신 후 
원장님께서도 한번 더 안내 부탁드립니다.
감사합니다.`;

export const AdminEnrolled = (props) => {
    const { classes, history } = props;
    // state
    const [thisMonth, setThisMonth] = useState(dayjs().format('YYYYMM'));
    const [thisHakwon, setThisHakwon] = useState('');
    const { sales, managerLoading } = useSelector((state) => state.manager);

    const dispatch = useDispatch();

    const [open, setOpen] = useState(false);
    const [sortType, setSortType] = useState('hakAsc');
    const [message1] = useState(messageForParent);
    const [message2] = useState(messageForManager);

    const onChangeThisMonth = (e) => {
        setThisMonth(e.target.value);
    };
    const onChangeThisHakwon = (e) => {
        setThisHakwon(e.target.value);
    };
    const onClickSearch = () => {
        const dates = {
            from: dayjs(thisMonth).add(-1, 'year').add(-1, 'month').startOf('month') / 1000,
            to: dayjs(thisMonth).add(1, 'month').startOf('month') / 1000,
        };

        const res = dispatch(isLogged(history));
        if (res === 'logged') {
            dispatch(getAllSalesFromTo(dates));
        }
    };

    const lessonDuration = (sa) => {
        const monthLesson =
            dayjs(sa.lessonDate * 1000).get('date') > 20 && sa.lessonType === 'regular'
                ? dayjs(sa.lessonDate * 1000)
                      .add(1, 'month')
                      .format('YYYY-MM')
                : dayjs(sa.lessonDate * 1000).format('YYYY-MM');
        const duration = sa.totalRound ? sa.totalRound : sa.amount === 45000 ? 3 : 1;
        const monthEnd = dayjs(monthLesson)
            .add(duration - 1, 'month')
            .format('YYYY-MM');

        return {
            monthLesson: monthLesson,
            monthEnd: monthEnd,
            duration: duration,
        };
    };

    const filter = sales
        .filter((sa) => (thisHakwon === '' ? true : sa.hName?.startsWith(thisHakwon)))
        .filter((sa) => sa.status === 'paid')
        .filter((sa) => sa.imp_uid.substring(0, 4) !== 'imps')
        .filter((sa) => {
            if (sa.lessonType === 'trial') {
                return dayjs(sa.lessonDate * 1000).format('YYYYMM') === thisMonth;
            } else {
                // 정기결제이면
                return (
                    lessonDuration(sa).monthEnd >= dayjs(thisMonth).format('YYYY-MM') &&
                    lessonDuration(sa).monthLesson <= dayjs(thisMonth).format('YYYY-MM') &&
                    (sa.memo ? sa.memo.substring(0, 6) > dayjs(thisMonth).format('YYYYMM') : true)
                );
            }
        })
        .sort((a, b) =>
            sortType === 'hakAsc'
                ? a.hName > b.hName
                    ? 1
                    : -1
                : sortType === 'hakDesc'
                ? b.hName > a.hName
                    ? 1
                    : -1
                : sortType === 'monthAsc'
                ? lessonDuration(a).monthEnd > lessonDuration(b).monthEnd
                    ? 1
                    : -1
                : sortType === 'monthDesc'
                ? lessonDuration(b).monthEnd > lessonDuration(a).monthEnd
                    ? 1
                    : -1
                : null
        );

    const alreadyNextMonthPaid = sales
        .filter((sa) => (thisHakwon === '' ? true : sa.hName?.startsWith(thisHakwon)))
        .filter((sa) => sa.status === 'paid')
        .filter((sa) => sa.imp_uid.substring(0, 4) !== 'imps')
        .filter((sa) => {
            return lessonDuration(sa).monthLesson === dayjs(thisMonth).add(1, 'month').format('YYYY-MM');
        })
        .map((sa) => sa.buyer_tel);

    //console.log(alreadyNextMonthPaid);

    const toggleHakwon = () => {
        if (sortType === 'hakDesc') {
            setSortType('hakAsc');
        } else {
            setSortType('hakDesc');
        }
    };
    const toggleMonth = () => {
        if (sortType === 'monthDesc') {
            setSortType('monthAsc');
        } else {
            setSortType('monthDesc');
        }
    };
    const lists = filter
        .filter((sa) => lessonDuration(sa).monthEnd === dayjs(thisMonth).format('YYYY-MM'))
        .filter((sa) => alreadyNextMonthPaid.indexOf(sa.buyer_tel) === -1);

    //console.log(lists);

    const toBeSent = lists.map((li) => ({
        name: li.buyer_name,
        tel: li.buyer_tel,
        hName: li.hName,
        manager: li.hakwonPhone,
    }));
    const hNames = toBeSent.map((l) => l.hName);
    const hNameCnt = hNames.filter((e, i) => hNames.indexOf(e) === i).length;
    const managers = lists.map((li) => ({
        hName: li.hName,
        manager: li.hakwonPhone,
    }));
    const managers2 = managers.filter((v, i, a) => a.findIndex((t) => JSON.stringify(t) === JSON.stringify(v)) === i);

    const requestSendForParent = () => {
        const ataData = toBeSent.map((tb) => ({
            phone: tb.tel.replace(/-/gi, ''),
            hName: tb.hName,
            name: tb.name,
        }));

        //console.log(lists);
        /* const testData = [{
            phone: '010-6228-4969'.replace(/-/gi, ''),
            hName: '주은교육테스트원',
            name: '김덕주'
        },
        {
            phone: '010-4968-7488'.replace(/-/gi, ''),
            hName: '점사분이학원',
            name: '김보선'
        }]; */
        if (ataData.length > 0) {
            if (window.confirm(`${ataData.length}건을 발송하시겠습니까?`)) {
                dispatch(sendATA6(ataData));
                setOpen(false);
            } else {
                alert('취소되었습니다.');
            }
        } else {
            alert('조회 이후에 발송하여 주십시오');
        }
    };

    const requestSendForManager = () => {
        const ataData = managers2.map((tb) => ({
            phone: tb.manager.replace(/-/gi, ''),
            hName: tb.hName,
        }));
        /* const testData = [{
            phone: '010-6228-4969'.replace(/-/gi, ''),
            hName: '아주좋은학원'
        },
        {
            phone: '010-4968-7488'.replace(/-/gi, ''),
            hName: '피아노가아주좋은학원'
        }]; */
        if (ataData.length > 0) {
            if (window.confirm(`${ataData.length}건을 발송하시겠습니까?`)) {
                dispatch(sendATA7(ataData));
                setOpen(false);
            }
        } else {
            alert('조회 이후에 발송하여 주십시오');
        }
    };

    return (
        <Box p={1} m={1}>
            <Box height={20} />
            <Typography variant="subtitle1">현재 각 학원별 수강생 현황 조회</Typography>
            <Divider light />
            <Box p={1} m={1}>
                <TextField
                    size="small"
                    margin="dense"
                    variant="outlined"
                    className={classes.month}
                    value={thisMonth}
                    onChange={onChangeThisMonth}
                />
                <TextField
                    size="small"
                    margin="dense"
                    variant="outlined"
                    className={classes.hwon}
                    value={thisHakwon}
                    placeholder="학원명"
                    onChange={onChangeThisHakwon}
                />
                <Button
                    color="primary"
                    variant="contained"
                    className={classes.btn}
                    onClick={onClickSearch}
                    startIcon={<SearchRounded />}
                >
                    수강생 조회
                </Button>
                <Button
                    color="secondary"
                    variant="contained"
                    className={classes.btn}
                    onClick={() => setOpen(true)}
                    startIcon={<SendRounded />}
                >
                    마감대상 알림톡 발송
                </Button>
            </Box>
            <Box>* 학원, 수업종료월 기준 정렬 가능 @ 알림톡 발송 리스트</Box>
            <Box className={classes.minBox}>
                {managerLoading ? (
                    <CenterLoading />
                ) : (
                    <Box m={1}>
                        <TableContainer component={Paper}>
                            <Table className={classes.table} size="small" aria-label="a dense table">
                                <TableHead>
                                    <TableRow>
                                        <TableCell className={classes.padding}>No</TableCell>
                                        <TableCell className={classes.padding}>
                                            <Button
                                                onClick={toggleHakwon}
                                                endIcon={
                                                    sortType === 'hakAsc' ? (
                                                        <ArrowDropDown color="primary" />
                                                    ) : (
                                                        sortType === 'hakDesc' && <ArrowDropUp color="secondary" />
                                                    )
                                                }
                                            >
                                                학원
                                            </Button>
                                        </TableCell>
                                        <TableCell className={classes.padding}>수강생</TableCell>
                                        <TableCell className={classes.padding}>생년월</TableCell>
                                        <TableCell className={classes.padding}>결제형태</TableCell>
                                        <TableCell className={classes.padding}>결제일</TableCell>
                                        <TableCell className={classes.padding}>유형</TableCell>
                                        <TableCell className={classes.padding}>수업시작월</TableCell>
                                        <TableCell className={classes.padding}>
                                            <Button
                                                onClick={toggleMonth}
                                                endIcon={
                                                    sortType === 'monthAsc' ? (
                                                        <ArrowDropDown color="primary" />
                                                    ) : (
                                                        sortType === 'monthDesc' && <ArrowDropUp color="secondary" />
                                                    )
                                                }
                                            >
                                                수업종료월
                                            </Button>
                                        </TableCell>
                                        <TableCell className={classes.padding}>전화번호</TableCell>
                                        <TableCell className={classes.padding}>원장번호</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {filter.map((row, index, arr) => (
                                        <TableRow key={index}>
                                            <TableCell className={classes.padding}>{index + 1}</TableCell>
                                            <TableCell className={classes.padding}>{row.hName}</TableCell>
                                            <TableCell className={classes.padding}>
                                                {row.status === 'cancelled' ? (
                                                    <strike>{row.buyer_name}</strike>
                                                ) : (
                                                    row.buyer_name
                                                )}
                                            </TableCell>
                                            <TableCell className={classes.padding}>{row.age}</TableCell>
                                            <TableCell className={classes.padding}>
                                                {row.lessonType === 'regular'
                                                    ? '뮤직플레이'
                                                    : row.lessonType === 'trial'
                                                    ? '원데이클래스'
                                                    : '프리미엄'}
                                            </TableCell>
                                            <TableCell className={classes.padding}>
                                                {dayjs(row.paid_at * 1000).format('YYYY-MM-DD')}
                                            </TableCell>
                                            <TableCell className={classes.padding}>
                                                {row.status === 'cancelled' ? (
                                                    <strike>{lessonDuration(row).duration}개월</strike>
                                                ) : (
                                                    lessonDuration(row).duration + '개월'
                                                )}{' '}
                                            </TableCell>
                                            <TableCell className={classes.padding}>
                                                {lessonDuration(row).monthLesson}{' '}
                                            </TableCell>
                                            <TableCell className={classes.padding}>
                                                <Typography
                                                    variant="body2"
                                                    color={
                                                        lessonDuration(row).monthEnd <=
                                                        dayjs(thisMonth).format('YYYY-MM')
                                                            ? 'error'
                                                            : 'inherit'
                                                    }
                                                >
                                                    {lessonDuration(row).monthEnd}
                                                </Typography>
                                            </TableCell>

                                            <TableCell className={classes.padding}>{row.buyer_tel}</TableCell>
                                            <TableCell className={classes.padding}>{row.hakwonPhone}</TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </Box>
                )}
            </Box>
            <Box>
                <Dialog open={open} fullWidth onBackdropClick={() => setOpen(false)}>
                    <DialogTitle>알림톡 발송</DialogTitle>
                    <DialogContent>
                        <Box m={1} p={1}>
                            <Typography variant="subtitle2" color="primary">
                                학부모 발송
                            </Typography>
                            <TextField
                                variant="outlined"
                                fullWidth
                                multiline
                                size="small"
                                margin="dense"
                                value={message1}
                            />
                            <Typography variant="body2" color="secondary">
                                발송대상 : {toBeSent.length}, 대상학원수 : {hNameCnt}
                            </Typography>
                            <Box>
                                {toBeSent.map((tb, ind) => (
                                    <Box key={ind}>
                                        <Typography variant="body2">{`${tb.hName} ${tb.name} ${tb.tel}`}</Typography>
                                    </Box>
                                ))}
                            </Box>
                            <Button color="primary" variant="contained" onClick={requestSendForParent}>
                                학부모 발송
                            </Button>
                            <br />
                            <Typography variant="caption" color="error">
                                *발송 전, 이미 발송되었는지 반드시 확인하고 발송바랍니다. 발송 내역은 solapi.com에서
                                확인하실 수 있습니다.
                            </Typography>
                        </Box>
                        <Box m={1} p={1}>
                            <Typography variant="subtitle2" color="primary">
                                학원장 발송
                            </Typography>
                            <TextField
                                variant="outlined"
                                fullWidth
                                multiline
                                size="small"
                                margin="dense"
                                value={message2}
                            />
                            <Typography variant="body2" color="secondary">
                                발송학원수 : {managers2.length}
                            </Typography>
                            <Box>
                                {managers2.map((tb, ind) => (
                                    <Box key={ind}>
                                        <Typography variant="body2">{`${tb.hName} ${tb.manager}`}</Typography>
                                    </Box>
                                ))}
                            </Box>
                            <Button color="primary" variant="contained" onClick={requestSendForManager}>
                                학원장 발송
                            </Button>
                            <br />
                            <Typography variant="caption" color="error">
                                *발송 전, 이미 발송되었는지 반드시 확인하고 발송바랍니다. 발송 내역은 solapi.com에서
                                확인하실 수 있습니다. 전화번호가 없는 항목이 있는지 반드시 확인해주세요.
                            </Typography>
                        </Box>
                    </DialogContent>
                    <DialogActions>
                        <Button variant="contained" onClick={() => setOpen(false)}>
                            닫기
                        </Button>
                    </DialogActions>
                </Dialog>
            </Box>
        </Box>
    );
};
const styles = (theme) => ({
    ...theme.userPage,
    month: {
        width: 90,
        marginLeft: theme.spacing(2),
    },
    btn: {
        marginLeft: theme.spacing(2),
        marginTop: theme.spacing(1),
    },
    minBox: {
        minHeight: 600,
    },
    hwon: {
        width: 120,
        marginLeft: theme.spacing(2),
    },
    table: {
        padding: 5,
    },
    padding: {
        padding: 5,
    },
});
export default withStyles(styles)(AdminEnrolled);

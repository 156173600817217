import React, { useEffect } from 'react';
// // Redux stuff
import { useSelector, useDispatch } from 'react-redux';
import { getPaidVideos } from '../../redux/actions/dataActions';
// components
import SmallImage1 from '../../images/main_small_5.png';
import SmallImage2 from '../../images/main_small_2.png';
import Tobeup from '../../images/tobe_uploaded.png';
import DummyDivider from '../../components/layout/DummyDivider';
import VideoClip from '../../components/home/VideoClip';
// MUI stuff
import withStyles from '@material-ui/core/styles/withStyles';
import CenterLoading from '../layout/CenterLoading';

import { Grid, Box, Typography, Divider } from '@material-ui/core';

const styles = (theme) => ({
    ...theme.userPage,
    back1: {
        backgroundColor: '#f9f9f9',
        height: 500,
        backgroundImage: `url(${SmallImage1})`,
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center bottom',
        backgroundSize: '414px 400px',
        [theme.breakpoints.down('xs')]: {
            height: 400,
            backgroundRepeat: 'no-repeat',
            backgroundPosition: 'center bottom',
            backgroundSize: '414px 400px',

        }
    },
    back2: {
        backgroundColor: '#fff',
        height: 500,
        backgroundImage: `url(${SmallImage2})`,
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center bottom',
        backgroundSize: '414px 400px',
        [theme.breakpoints.down('xs')]: {
            height: 400,
            backgroundRepeat: 'no-repeat',
            backgroundPosition: 'center bottom',
            backgroundSize: '414px 400px',

        }
    },
    tobeup: {
        width: '100%',
        backgroundImage: `url(${Tobeup})`,
        backgroundRepeat: 'no-repeat',

    }
});

export const HomeSchoolLesson = (props) => {
    const { classes, value, category, category2 } = props;
    const dispatch = useDispatch();
    const { videos, dataLoading } = useSelector(state => state.data);
    useEffect(() => {
        dispatch(getPaidVideos());
    }, [dispatch]);
    //const categories = ['4-5세', '6-7세']
    return (
        <Box>
            {
                value === 2 &&
                <Box m={3} p={10} align='center' className={classes.back1}>
                    <Typography variant='subtitle1' color='secondary'>뮤직플레이 수업은</Typography>
                    <Typography variant='subtitle1' color='secondary'>3월 출시 예정입니다.</Typography>
                </Box>
            }

            {
                value === 3 &&
                <Box m={3} p={10} align='center' className={classes.back2}>
                    <Typography variant='subtitle1' color='secondary'>뮤직플레이 프리미엄 수업은</Typography>
                    <Typography variant='subtitle1' color='secondary'>3월 출시 예정입니다.</Typography>
                </Box>

            }

            {
                value === 0 &&
                <Box m={1} p={1}>
                    <Typography color='primary' variant='h5'>뮤직플레이 원데이 클래스 ({category}) 수업영상</Typography>
                    <Box height={20} />
                    <Grid container>
                        {
                            dataLoading && <CenterLoading />
                        }
                        {videos.filter(v => v.category === category && v.status === 'open' && v.productGroupId === 'musicgogoOneDayClass' && v.type === '유료')
                            .sort((a, b) => a.order - b.order)
                            .map(v =>
                                <Grid item xs={12} sm={6}>
                                    <VideoClip
                                        url={v.videoUrl}
                                        category={`[${v.videoName}]`}
                                        videoName={v.desc}
                                        runningTime={v.playtime}
                                    />
                                </Grid>)
                        }
                    </Grid>


                    <DummyDivider bottom={30} />
                    <Typography color='primary' variant='h5' gutterBottom>수업준비 사항</Typography>
                    <Divider light />
                    <DummyDivider top={30} />
                    <Typography variant='body1' gutterBottom>영상을 TV나 큰 모니터에 연결해서 시청하시면, 수업효과가 배가됩니다.</Typography>
                    <Typography variant='body1' gutterBottom>노트북 등을 'HDMI 연결'을 통해 TV나 모니터에 연결하셔도 좋고,</Typography>
                    <Typography variant='body1' gutterBottom>스마트폰과는 'MHL케이블' 연결을 통해 연결하실 수 있습니다. </Typography>
                    <Typography variant='body1' gutterBottom>영상 플레이 방법 및 문의사항이 있으시면 학원이나, 스마트 뮤직플레이 고객센터에 연락주셔도 좋습니다.</Typography>
                    <Typography variant='body1' gutterBottom>가급적 관련 교구 및 패키지 배송된 이후에, 교구와 함께 수업을 진행해주시면 더욱 좋습니다.</Typography>
                    <DummyDivider bottom={30} />

                </Box>
            }


            {/* value ===1 일때 */}
            {
                value === 1 &&
                <Box m={1} p={1}>

                    <Typography color='primary' variant='h5'>스마트피아노 원데이 클래스 ({category2}) 수업영상</Typography>
                    <Box height={20} />
                    <Grid container>
                        {
                            dataLoading && <CenterLoading />
                        }
                        {videos.filter(v => v.category === category2 && v.productGroupId === 'pianogogoOneDayClass' && v.status === 'open' && v.type === '유료')
                            .sort((a, b) => a.order - b.order)
                            .map(v =>
                                <Grid item xs={12} sm={6}>
                                    <VideoClip
                                        url={v.videoUrl}
                                        category={`[${v.videoName}]`}
                                        videoName={v.desc}
                                        runningTime={v.playtime}
                                    />
                                </Grid>)
                        }
                    </Grid>

                    <DummyDivider bottom={30} />
                    <Typography color='primary' variant='h5' gutterBottom>수업준비 사항</Typography>
                    <Divider light />
                    <DummyDivider top={30} />
                    <Typography variant='body1' gutterBottom>영상을 TV나 큰 모니터에 연결해서 시청하시면, 수업효과가 배가됩니다.</Typography>
                    <Typography variant='body1' gutterBottom>노트북 등을 'HDMI 연결'을 통해 TV나 모니터에 연결하셔도 좋고,</Typography>
                    <Typography variant='body1' gutterBottom>스마트폰과는 'MHL케이블' 연결을 통해 연결하실 수 있습니다. </Typography>
                    <Typography variant='body1' gutterBottom>영상 플레이 방법 및 문의사항이 있으시면 학원이나, 스마트 뮤직플레이 고객센터에 연락주셔도 좋습니다.</Typography>
                    <Typography variant='body1' gutterBottom>가급적 관련 교구 및 패키지 배송된 이후에, 교구와 함께 수업을 진행해주시면 더욱 좋습니다.</Typography>
                    <DummyDivider bottom={30} />

                </Box>
            }
        </Box>



    );
};



export default withStyles(styles)(HomeSchoolLesson);


import React, { useState, useEffect, useCallback } from 'react';

// Redux stuff
import { useSelector, useDispatch } from 'react-redux';
import {
    getHakwonInfo,
    getSalesByHakwon,
    getLessonsByHakwon,
    postUpdateLessonTime,
    saveNewCategoryLesson,
    editPaymentInfoByNewLesson,
    getLessonInHakwon,
} from '../../redux/actions/managerActions';
import { getUserData, isLogged } from '../../redux/actions/userActions';

// components
import LevelCheck from './LevelCheck';

import CenterLoading from '../../components/layout/CenterLoading';
// MUI stuff
import withStyles from '@material-ui/core/styles/withStyles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';

import {
    Typography,
    Divider,
    Button,
    Box,
    InputLabel,
    Checkbox,
    Select,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    Hidden,
    FormControl,
    RadioGroup,
    Radio,
    FormControlLabel,
    TextField,
    IconButton,
} from '@material-ui/core';
import { NotAllowed } from './NotAllowed';
import dayjs from 'dayjs';
import {
    CloseRounded,
    CreateRounded,
    ArrowDownwardRounded,
    EditRounded,
    SaveRounded,
    SearchRounded,
    ShuffleRounded,
} from '@material-ui/icons';

const WhiteRadio = withStyles({
    root: { color: '#666' },
})(Radio);
const WhiteTypo = withStyles({
    root: { color: '#fff' },
})(Typography);

export const Enrolled = (props) => {
    const { classes, history } = props;
    const { rnr, credentials } = useSelector((state) => state.user);
    const { hakwon, sales, managerLoading, lessons, newCategoryLesson } = useSelector((state) => state.manager);
    const { loading } = useSelector((state) => state.UI);
    const dispatch = useDispatch();
    const [allowed, setAllowed] = useState(false);
    const [type, setType] = useState('all');
    const initialMonth = dayjs().format('YYYY-MM');
    const [month, setMonth] = useState(initialMonth);
    const [withCancel, setWithCancel] = useState(false);
    const [isChanged, setIsChanged] = useState(false);
    const [newLesson, setNewLesson] = useState(null);

    // lessonTime
    const [lessonTime, setLessonTime] = useState('15:00');
    const [error, setError] = useState(null);

    // dialog
    const [open, setOpen] = useState(false);
    const [sd, setSd] = useState({});
    const openDetail = (detail) => {
        setSd(detail);
        dispatch(getLessonInHakwon(detail.lessonId));
        setOpen(true);
        setLessonTime(dayjs(detail.lessonDate * 1000).format('HH:mm'));
    };
    // sub dialog(category change)
    const [cateDialog, setCateDialog] = useState(false);
    // sameLesson
    const [similarLessonInfo, setSimilarLessonInfo] = useState(null);

    const hId = credentials.myHakwon ? credentials.myHakwon?.hakwonId : localStorage.getItem('hId');

    useEffect(() => {
        dispatch(getHakwonInfo());
        dispatch(getUserData());
        dispatch(getLessonsByHakwon(hId));
        //dispatch(getSalesByHakwon(hId));
    }, [dispatch, hId]);

    const handleHakwonSales = useCallback(() => {
        const res = dispatch(isLogged(history));
        if (res === 'logged') {
            dispatch(getSalesByHakwon(hId, { from: 1577836800, to: 4102444800 }));
        }
    }, [dispatch, history, hId]);

    const handleMonth = useCallback((e) => {
        e.preventDefault();
        setMonth(e.target.value);
    }, []);

    const handleChangeType = useCallback((e) => {
        e.preventDefault();
        setType(e.target.value);
    }, []);

    const handleChangeWithCancel = useCallback(() => {
        if (withCancel) {
            setWithCancel(false);
        } else {
            setWithCancel(true);
        }
    }, [withCancel]);

    // lesson time
    const handleChangeLessonTime = (e) => {
        setLessonTime(e.target.value);
        setError(null);
    };
    const requestChangeLessonTime = useCallback(
        (oDate, str, sId) => {
            const hh = str.substring(0, 2);
            const mm = str.substring(3, 5);
            const date =
                dayjs(oDate * 1000)
                    .set('hour', hh)
                    .set('minute', mm) / 1000;

            if (str.length !== 5) {
                setError('15:00 처럼 시간:분으로 작성바랍니다.');
            } else if (Number(hh) >= 24) {
                setError('시간 표현이 잘못되었습니다.');
            } else if (Number(mm) >= 60) {
                setError('분 표현이 잘못되었습니다.');
            } else {
                setError(null);
                console.log(date, date / 1000);
                dispatch(postUpdateLessonTime(date, sId, hId));
                setOpen(false);
            }
        },
        [dispatch, hId]
    );

    const rSales = sales.map((sa) => {
        sa.info = lessons.filter((le) => le.lessonId === sa.lessonId)[0];
        return sa;
    });

    const diffMonth = dayjs().diff(dayjs('2021-03-01'), 'month');
    const options = Array.from({ length: diffMonth + 11 }, (x, i) =>
        dayjs()
            .add(i - diffMonth, 'month')
            .format('YYYY-MM')
    );

    const rSales2 = rSales.filter((sa) => (type === 'all' ? true : sa.lessonType === type));

    const lessonDuration = (sa) => {
        const monthLesson =
            dayjs(sa.lessonDate * 1000).get('date') > 20 && sa.lessonType === 'regular'
                ? dayjs(sa.lessonDate * 1000)
                      .add(1, 'month')
                      .format('YYYY-MM')
                : dayjs(sa.lessonDate * 1000).format('YYYY-MM');
        const duration = sa.totalRound ? sa.totalRound : sa.amount === 45000 ? 3 : 1;
        const monthEnd = dayjs(monthLesson)
            .add(duration - 1, 'month')
            .format('YYYY-MM');
        /* const carriedOver = dayjs(monthEnd).endOf('month').format('YYYY-MM-DD') < dayjs(sa.totalRound>0 ? sa.info?.recurrentDates[sa.info?.recurrentDates.length-1] :0).format('YYYY-MM-DD');
        console.log(sa.child_name, monthLesson, month, duration, monthEnd, dayjs(monthEnd).endOf('month').format('YYYY-MM-DD'), dayjs(sa.totalRound>0 ? sa.info?.recurrentDates[sa.info?.recurrentDates.length-1] :0).format('YYYY-MM-DD')); */
        return {
            monthLesson: monthLesson,
            monthEnd: monthEnd,
            duration: duration,
        };
    };

    const filteredSales = rSales2
        .filter((sa) => (withCancel ? true : sa.status === 'paid'))
        .filter((sa) => sa.imp_uid.substring(0, 4) !== 'imps')
        .filter((sa) => {
            if (sa.lessonType !== 'trial') {
                const withoutLast = sa.info?.recurrentDates.slice(0, -1);
                return (
                    withoutLast?.some((s) => s.substring(0, 7) === month) &&
                    (sa.memo ? sa.memo.substring(0, 6) > dayjs(month).format('YYYYMM') : true)
                );
                // 중간결제취소인 경우, 메모에 기록된 수업정지월기준으로 노출제어한다. (추가)
            } else {
                return dayjs(sa.lessonDate * 1000).format('YYYY-MM') === month;
            }
        })
        .sort((a, b) => (a.lessonDate > b.lessonDate ? 1 : -1));

    // 레슨이 업데이트 될때마다 가져온다.
    useEffect(() => {
        if (lessons) {
            const getSimilar = lessons.filter(
                (l) =>
                    l.productId === sd.productId &&
                    l.price === sd.price &&
                    l.startDate === sd.lessonDate &&
                    l.hakwonId === sd.hakwonId &&
                    l.category !== sd.category
            )[0];
            if (getSimilar) {
                setSimilarLessonInfo(getSimilar);
                setIsChanged(false);
            }
        }
    }, [isChanged, lessons, sd]);

    // 새로운 레슨이 생기면
    useEffect(() => {
        if (newCategoryLesson) {
            //console.log(newCategoryLesson);
            setNewLesson(newCategoryLesson);
            if (newCategoryLesson?.lessonId) {
                setSimilarLessonInfo(newCategoryLesson);
            }
        }
    }, [newCategoryLesson]);

    // 카테고리 변경 팝업
    const handleChangeCategory = () => {
        setCateDialog(true);
        const getSimilar = lessons.filter(
            (l) =>
                l.productId === sd.productId &&
                l.price === sd.price &&
                l.startDate === sd.lessonDate &&
                l.hakwonId === sd.hakwonId &&
                l.category !== sd.category
        )[0];
        if (getSimilar) {
            setSimilarLessonInfo(getSimilar);
        }
    };
    //console.log(similarLessonInfo);

    // 러너 카테고리의 유사한 레슨Id를 생성함
    const handleCreateNewSimilarLesson = () => {
        const catego = sd.category;
        const oldLesson = lessons.filter((l) => l.lessonId === sd.lessonId)[0];
        const newLessonName =
            catego === '플레이어'
                ? oldLesson.lessonName.replace('플레이어', '러너')
                : catego === '러너'
                ? oldLesson.lessonName.replace('러너', '플레이어')
                : null;
        if (!['플레이어', '러너'].includes(catego)) {
            alert('플레이어, 러너 만 변경가능합니다.');
        } else {
            const newLesson = {
                ...oldLesson,
                category: catego === '플레이어' ? '러너' : '플레이어',
                lessonName: newLessonName,
                startDate: oldLesson.startDate * 1000,
                endDate: oldLesson.startDate * 1000,
            };
            const { lessonId, createdAt, createdBy, ...newLessonInfo } = newLesson;
            if (newLessonInfo) {
                //console.log(newLessonInfo);
                dispatch(saveNewCategoryLesson(newLessonInfo));
                dispatch(getLessonsByHakwon(hId));
            }
            setIsChanged(true);
        }
    };

    // 레슨 카테고리를 변경하고, payments에 저장하고 화면 닫음
    const handleChangeLessonIdAndSave = () => {
        //console.log(similarLessonInfo);
        if (similarLessonInfo) {
            const newLessonInfo = {
                paymentId: sd.paymentId,
                category: similarLessonInfo.category,
                custom_data: similarLessonInfo.lessonId,
                lessonId: similarLessonInfo.lessonId,
                name: similarLessonInfo.lessonName,
            };
            //console.log(newLessonInfo);
            setCateDialog(false);
            dispatch(editPaymentInfoByNewLesson(newLessonInfo));
            dispatch(getLessonsByHakwon(hId));
        }
    };
    const handleCloseDetailDialog = () => {
        setOpen(false);
        setSimilarLessonInfo(null);
        dispatch(getSalesByHakwon(hId, { from: 1577836800, to: 4102444800 }));
        setNewLesson(null);
    };
    const handleCloseChangeLesson = () => {
        setCateDialog(false);
        setSimilarLessonInfo(null);
        //
        setNewLesson(null);
    };

    return (
        <div>
            <LevelCheck
                checkingTeacherLevel={3}
                setAllowed={setAllowed}
                allowed={allowed}
                hakwon={hakwon}
                rnr={rnr}
                credentials={credentials}
            />
            {allowed ? (
                <Box m={1} p={1}>
                    <Box height={20} />
                    <Typography variant="subtitle1" gutterBottom>
                        월별 시간대별 수강생 현황
                    </Typography>
                    <Divider light />
                    <Box align="right">
                        <Typography variant="caption" className={classes.marginRL}>
                            {credentials.myHakwon?.hName}
                        </Typography>
                    </Box>
                    <Box m={1} p={1}>
                        <FormControl className={classes.formControl}>
                            <InputLabel htmlFor="age-native-simple">월선택</InputLabel>
                            <Select native value={month} onChange={handleMonth}>
                                <option aria-label="None" value="선택" />
                                {options.map((op, inde) => (
                                    <option key={inde} value={op}>
                                        {op}
                                    </option>
                                ))}
                            </Select>
                        </FormControl>
                        <Button
                            color="primary"
                            startIcon={<SearchRounded />}
                            variant="contained"
                            onClick={handleHakwonSales}
                            className={classes.btn}
                        >
                            조회
                        </Button>
                    </Box>

                    <FormControl component="fieldset">
                        <RadioGroup aria-label="category" name="category" value={type} onChange={handleChangeType} row>
                            <FormControlLabel
                                value={'all'}
                                control={<WhiteRadio />}
                                label={<Typography>전체</Typography>}
                            />
                            <FormControlLabel
                                value={'regular'}
                                control={<WhiteRadio />}
                                label={<Typography>뮤직플레이</Typography>}
                            />
                            <FormControlLabel
                                value={'premium'}
                                control={<WhiteRadio />}
                                label={<Typography>프리미엄</Typography>}
                            />
                            <FormControlLabel
                                value={'trial'}
                                control={<WhiteRadio />}
                                label={<Typography>원데이클래스</Typography>}
                            />
                        </RadioGroup>
                    </FormControl>
                    <FormControlLabel
                        control={<Checkbox checked={withCancel} onChange={handleChangeWithCancel} name="checked" />}
                        label={withCancel ? '취소포함' : '취소제외'}
                    />

                    {managerLoading ? (
                        <CenterLoading />
                    ) : (
                        <Box>
                            <Hidden only="xs">
                                <TableContainer component={Paper}>
                                    <Table className={classes.table} size="small" aria-label="a dense table">
                                        <TableHead>
                                            <TableRow>
                                                <TableCell className={classes.padding}>구분</TableCell>
                                                <TableCell className={classes.padding}>No</TableCell>
                                                <TableCell className={classes.padding}>수강생</TableCell>
                                                <TableCell className={classes.padding}>생년월</TableCell>
                                                <TableCell className={classes.padding}>결제형태</TableCell>
                                                <TableCell className={classes.padding}>결제일</TableCell>
                                                <TableCell className={classes.padding}>유형</TableCell>
                                                <TableCell className={classes.padding}>수업시작월</TableCell>
                                                <TableCell className={classes.padding}>수업종료월</TableCell>
                                                <TableCell className={classes.padding}>수업횟수</TableCell>
                                                <TableCell className={classes.padding}>카테고리</TableCell>
                                                <TableCell className={classes.padding}>상세</TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {filteredSales.map((row, index, arr) => (
                                                <TableRow key={index}>
                                                    {
                                                        <TableCell className={classes.padding}>
                                                            {index === 0
                                                                ? dayjs(row.lessonDate * 1000).format('(ddd) A hh:mm')
                                                                : arr[index - 1].lessonDate === row.lessonDate
                                                                ? ''
                                                                : dayjs(row.lessonDate * 1000).format('(ddd) A hh:mm')}
                                                        </TableCell>
                                                    }
                                                    <TableCell className={classes.padding}>{index + 1}</TableCell>
                                                    <TableCell className={classes.padding}>
                                                        {row.status === 'cancelled' ? (
                                                            <strike>{row.child_name}</strike>
                                                        ) : (
                                                            row.child_name
                                                        )}
                                                    </TableCell>
                                                    <TableCell className={classes.padding}>{row.age}</TableCell>
                                                    <TableCell className={classes.padding}>
                                                        {row.lessonType === 'regular'
                                                            ? '뮤직플레이'
                                                            : row.lessonType === 'trial'
                                                            ? '원데이클래스'
                                                            : '프리미엄'}
                                                    </TableCell>
                                                    <TableCell className={classes.padding}>
                                                        {dayjs(row.paid_at * 1000).format('YYYY-MM-DD')}
                                                    </TableCell>
                                                    <TableCell className={classes.padding}>
                                                        {row.status === 'cancelled' ? (
                                                            <strike>{lessonDuration(row).duration}개월</strike>
                                                        ) : (
                                                            lessonDuration(row).duration + '개월'
                                                        )}{' '}
                                                    </TableCell>
                                                    <TableCell className={classes.padding}>
                                                        {lessonDuration(row).monthLesson}{' '}
                                                    </TableCell>
                                                    <TableCell className={classes.padding}>
                                                        <Typography
                                                            variant="body2"
                                                            color={
                                                                lessonDuration(row).monthEnd <= month
                                                                    ? 'error'
                                                                    : 'inherit'
                                                            }
                                                        >
                                                            {lessonDuration(row).monthEnd}
                                                        </Typography>
                                                    </TableCell>
                                                    <TableCell className={classes.padding}>
                                                        {row.lessonType === 'trial'
                                                            ? '1회'
                                                            : row.info?.recurrentDates.filter((date) =>
                                                                  dayjs().isAfter(dayjs(date))
                                                              ).length +
                                                              '/' +
                                                              row.info?.recurrentDates.length +
                                                              '회'}
                                                    </TableCell>
                                                    <TableCell className={classes.padding}>{row.category}</TableCell>
                                                    <TableCell className={classes.padding}>
                                                        <Button
                                                            variant="contained"
                                                            size="small"
                                                            onClick={() => openDetail(row)}
                                                        >
                                                            자세히
                                                        </Button>
                                                    </TableCell>
                                                </TableRow>
                                            ))}
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            </Hidden>
                            <Hidden smUp>
                                {filteredSales.map((row, index, arr) => (
                                    <Box key={index}>
                                        {
                                            <Box m={1}>
                                                <WhiteTypo variant="subtitle2">
                                                    {index === 0
                                                        ? '[' +
                                                          dayjs(row.lessonDate * 1000).format('(ddd) A hh:mm') +
                                                          '클래스]'
                                                        : arr[index - 1].lessonDate === row.lessonDate
                                                        ? ''
                                                        : '[' +
                                                          dayjs(row.lessonDate * 1000).format('(ddd) A hh:mm') +
                                                          '클래스]'}{' '}
                                                </WhiteTypo>
                                            </Box>
                                        }
                                        <Paper key={index} style={{ marginBottom: 10, padding: 10 }}>
                                            <Typography variant="subtitle1" color="secondary" gutterBottom>
                                                [{index + 1}]{row.child_name} ({row.age}) [
                                                {row.lessonType === 'regular'
                                                    ? '뮤직플레이'
                                                    : row.lessonType === 'trial'
                                                    ? '원데이클래스'
                                                    : '프리미엄'}
                                                ]{' '}
                                            </Typography>
                                            <Typography>
                                                수업명 :{' '}
                                                <strong>
                                                    {row.status === 'cancelled' ? (
                                                        <strike>{row.lessonName}</strike>
                                                    ) : (
                                                        row.lessonName
                                                    )}
                                                </strong>
                                            </Typography>
                                            <Typography>
                                                결제일 : {dayjs(row.paid_at * 1000).format('YYYY-MM-DD')}
                                            </Typography>

                                            <Typography>
                                                수업횟수 :{' '}
                                                {row.lessonType === 'trial'
                                                    ? '1회'
                                                    : row.info?.recurrentDates.filter((date) =>
                                                          dayjs().isAfter(dayjs(date))
                                                      ).length +
                                                      '/' +
                                                      row.info?.recurrentDates.length +
                                                      '회'}
                                            </Typography>
                                            <Typography>유형 : {lessonDuration(row).duration}개월</Typography>
                                            <Typography>수업시작월 : {lessonDuration(row).monthLesson}</Typography>
                                            <Typography
                                                color={lessonDuration(row).monthEnd <= month ? 'primary' : 'inherit'}
                                            >
                                                수업종료월 : {lessonDuration(row).monthEnd}
                                            </Typography>
                                            <Typography>카테고리: {row.category}</Typography>
                                            <Box align="right">
                                                <Button
                                                    variant="contained"
                                                    size="small"
                                                    color="primary"
                                                    onClick={() => openDetail(row)}
                                                >
                                                    자세히
                                                </Button>
                                            </Box>
                                        </Paper>
                                    </Box>
                                ))}
                            </Hidden>
                        </Box>
                    )}

                    <Box height={100} />
                </Box>
            ) : (
                <NotAllowed allowed={allowed} rnr={rnr} />
            )}

            {/* detail dialog */}
            <Dialog open={open} maxWidth="md">
                <DialogTitle>{sd.paymentId}</DialogTitle>
                <DialogContent>
                    <Typography>결제아이디 : {sd.paymentId}</Typography>
                    <Typography>주문번호(apply_num) : {sd.apply_num}</Typography>
                    <br />
                    <Typography variant="subtitle1" gutterBottom>
                        주문
                    </Typography>
                    <Typography>
                        학원 : {sd.hName} ({sd.hArea?.area1}-{sd.hArea?.area2})
                    </Typography>
                    <Typography>학생이름 : {sd.child_name}</Typography>
                    <Typography>학생생년월일 : {sd.age}</Typography>
                    <br />
                    <Typography variant="subtitle1" gutterBottom>
                        상품/수업
                    </Typography>
                    <Typography>수업명 : {newLesson?.name ? newLesson.name : sd.lessonName} </Typography>
                    {/* 수업시간 변경 */}
                    <Typography>수업시간 : </Typography>
                    <Typography variant="body2"> * 변경하려면, 15:00 과 같이 24시간형으로 입력해주세요. </Typography>
                    <TextField
                        variant="outlined"
                        margin="dense"
                        value={lessonTime}
                        size="small"
                        onChange={handleChangeLessonTime}
                        helperText={error}
                        error={error}
                    />
                    <Button
                        variant="contained"
                        color="primary"
                        style={{ marginLeft: 20, marginTop: 10 }}
                        startIcon={<EditRounded />}
                        onClick={() => requestChangeLessonTime(sd.lessonDate, lessonTime, sd.paymentId)}
                    >
                        변경
                    </Button>

                    {/* 카테고리 변경 */}
                    <Typography>
                        카테고리 : {newLesson?.category ? newLesson.category : sd.category}
                        <Button
                            color="secondary"
                            variant="contained"
                            size="small"
                            startIcon={<ShuffleRounded />}
                            style={{ marginLeft: 10 }}
                            onClick={handleChangeCategory}
                        >
                            수정
                        </Button>{' '}
                    </Typography>
                    <Typography>수업가격: {sd.price} </Typography>
                    <Typography>정산금액: {sd.settlement} </Typography>
                    <Typography>수업일정: </Typography>
                    <Box style={{ marginLeft: 20 }}>
                        {sd.lessonType === 'trial' ? (
                            <Typography>{dayjs(sd.lessonDate * 1000).format('YYYY-MM-DD HH:mm')}</Typography>
                        ) : (
                            sd.info?.recurrentDates.map((date, ind) => (
                                <Box key={ind}>
                                    <Typography display="inline">ㆍ {ind + 1}회차 : </Typography>
                                    <Typography display="inline">{dayjs(date).format('YYYY-MM-DD')}</Typography>
                                </Box>
                            ))
                        )}
                    </Box>

                    <br />
                    <Typography variant="subtitle1" gutterBottom>
                        결제
                    </Typography>
                    <Typography>결제금액 : {sd.amount}</Typography>
                    <Typography variant="caption" color="primary">
                        {sd.amount === 0
                            ? '* 결제금액이 0 이면, 정기결제 등록상황이며, 실제 결제는 매월 1일 자동 결제됩니다.'
                            : ''}
                    </Typography>
                    <Typography>결제시각 : {dayjs(sd.paid_at * 1000).format('YYYY-MM-DD HH:mm:ss')}</Typography>
                    <Typography>
                        결제방법 : {sd.pay_method} ({sd.channel === 'api' ? '자동결제' : sd.channel})
                    </Typography>
                    <Typography color={sd.status === 'cancelled' ? 'error' : 'secondary'}>
                        상태 : {sd.status === 'cancelled' ? '결제취소' : '결제'}
                    </Typography>

                    <Typography>배송주소 : {sd.delivery_addr}</Typography>
                    <br />
                    <Typography variant="subtitle1" gutterBottom>
                        주문자
                    </Typography>
                    <Typography>주문자 : {sd.parentName}</Typography>
                    <Typography>주문자전화 : {sd.buyer_tel}</Typography>

                    <Typography>주문자email : {sd.buyer_email}</Typography>
                </DialogContent>
                <DialogActions>
                    <Button
                        color="primary"
                        variant="contained"
                        startIcon={<SaveRounded />}
                        onClick={handleCloseDetailDialog}
                    >
                        저장 후 닫기
                    </Button>
                    <Button variant="outlined" startIcon={<CloseRounded />} onClick={() => setOpen(false)}>
                        취소
                    </Button>
                </DialogActions>
            </Dialog>

            {/* 카테고리 변경 기능 다이어로그 */}
            <Dialog open={cateDialog} maxWidth="xs">
                <DialogTitle>수업 정보 변경</DialogTitle>
                <DialogContent>
                    <Typography variant="subtitle2" gutterBottom>
                        현재 수업
                    </Typography>
                    <Typography>-수업이름: {sd.lessonName} </Typography>
                    <Typography>
                        -카테고리:<strong> {sd.category}</strong>{' '}
                    </Typography>
                    <Typography>-수업ID: {sd.lessonId} </Typography>
                    <Box height={10} />
                    <Box align="center">
                        <IconButton>
                            <ArrowDownwardRounded color="secondary" />
                        </IconButton>
                    </Box>
                    <Box height={10} />
                    <Typography variant="subtitle2" gutterBottom>
                        변경 가능한 수업
                    </Typography>
                    {similarLessonInfo ? (
                        <Box>
                            <Typography color="primary">-수업이름: {similarLessonInfo.lessonName} </Typography>
                            <Typography color="primary">
                                -카테고리: <strong>{similarLessonInfo.category}</strong>{' '}
                            </Typography>
                            <Typography color="primary">-수업ID: {similarLessonInfo.lessonId} </Typography>
                        </Box>
                    ) : loading ? (
                        <CenterLoading />
                    ) : newLesson?.lessonId ? (
                        <Box>
                            <Typography color="primary">-수업이름: {newLesson.lessonName} </Typography>
                            <Typography color="primary">
                                -카테고리: <strong>{newLesson.category}</strong>{' '}
                            </Typography>
                            <Typography color="primary">-수업ID: {newLesson.lessonId} </Typography>
                        </Box>
                    ) : (
                        <Typography>
                            - 변경 가능한 수업이 없습니다. 새로 생성하여 변경하시겠습니까?{' '}
                            <Button
                                variant="contained"
                                size="small"
                                color="secondary"
                                startIcon={<CreateRounded />}
                                onClick={handleCreateNewSimilarLesson}
                            >
                                생성
                            </Button>
                        </Typography>
                    )}
                </DialogContent>
                <DialogActions>
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={handleChangeLessonIdAndSave}
                        startIcon={<SaveRounded />}
                        disabled={managerLoading}
                    >
                        변경
                    </Button>
                    <Button variant="contained" onClick={handleCloseChangeLesson}>
                        취소
                    </Button>
                </DialogActions>
                <Box m={3} style={{ backgroundColor: '#eee', borderRadius: 5, padding: 10 }}>
                    <Typography color="primary">
                        <b>주의!! [변경] 버튼을 누른 이후, 아래 [저장 후 닫기] 버튼까지 눌러주셔야 적용됩니다.</b>
                    </Typography>
                </Box>
            </Dialog>
        </div>
    );
};

const styles = (theme) => ({
    ...theme.userPage,
    root: {
        width: '100%',
        maxWidth: '36ch',
        backgroundColor: theme.palette.background.paper,
    },
    inline: {
        display: 'inline',
    },
    date: {
        backgroundColor: '#FAF3E7',
        width: 90,
        margin: theme.spacing(1),
    },
    btn: {
        marginLeft: theme.spacing(2),
        marginTop: theme.spacing(1),
    },
    text: {
        verticalAlign: 'center',
    },
    table: {
        padding: 5,
    },
    padding: {
        padding: 5,
    },
    formControl: {
        backgroundColor: '#fff',
        width: 100,
    },
});

export default withStyles(styles)(Enrolled);

import {
    SET_ADMINS,
    SET_MANAGERS,
    LOADING_ADMIN,
    STOP_LOADING_ADMIN,
    SET_ADMIN_ERRORS,
    CLEAR_ADMIN_ERRORS,
    SET_ADMIN_LESSONS,
    SET_ADMIN_HAKWONS,
    SET_ADMIN_KAKAOLINKS,
    SET_ADMIN_VISITLOG,
    SET_ADMIN_HAKWON,
    SET_SEARCH_USERS,
    SET_AFTERSCHOOLS,
} from '../types';

// admin 관련
const initialState = {
    admins: [],
    managers: [],
    adminLoading: false,
    adminErrors: null,
    adminlessons: [],
    adminhakwons: [],
    adminKakaolinks: [],
    adminVisitLog: [],
    adminHakwon: null,
    //
    searchUsers: [],
    afterSchools: [],
};

export default function (state = initialState, action) {
    switch (action.type) {
        case SET_ADMINS:
            return {
                ...state,
                admins: action.payload,
            };

        case SET_MANAGERS:
            return {
                ...state,
                managers: action.payload,
            };
        case LOADING_ADMIN:
            return {
                ...state,
                adminLoading: true,
            };
        case STOP_LOADING_ADMIN:
            return {
                ...state,
                adminLoading: false,
            };
        case SET_ADMIN_ERRORS:
            return {
                ...state,
                adminLoading: false,
                adminErrors: action.payload,
            };
        case CLEAR_ADMIN_ERRORS:
            return {
                ...state,
                adminLoading: false,
                adminErrors: null,
            };
        case SET_ADMIN_LESSONS: {
            return {
                ...state,
                adminlessons: action.payload,
            };
        }
        case SET_ADMIN_HAKWONS: {
            return {
                ...state,
                adminhakwons: action.payload,
            };
        }
        case SET_ADMIN_KAKAOLINKS: {
            return {
                ...state,
                adminKakaolinks: action.payload,
            };
        }
        case SET_ADMIN_VISITLOG: {
            return {
                ...state,
                adminVisitLog: action.payload,
            };
        }
        case SET_ADMIN_HAKWON: {
            return {
                ...state,
                adminHakwon: action.payload,
            };
        }
        // afterschools
        case SET_SEARCH_USERS: {
            return {
                ...state,
                searchUsers: action.payload,
            };
        }
        case SET_AFTERSCHOOLS: {
            return {
                ...state,
                afterSchools: action.payload,
            };
        }

        default:
            return state;
    }
}

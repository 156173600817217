import React, {useEffect} from 'react';
// redux
import { useSelector, useDispatch } from 'react-redux';
import { clearParentUserInfo, getParentUserInfo } from '../../redux/actions/managerActions';
// MUI stuff
import { withStyles, useTheme } from '@material-ui/core/styles';
import {
    Box, Paper, Grid, useMediaQuery, Typography, Button, TextField,
    Dialog, DialogContent, DialogContentText, FormControlLabel, RadioGroup, Radio,
    FormControl, DialogActions, Switch, DialogTitle,
    TableContainer, Table, TableHead, TableBody, TableRow, TableCell
} from '@material-ui/core';
import { AddRounded, SaveRounded } from '@material-ui/icons';
//import NewStudent from './NewStudent';

const DialogStudent = props => {
    const { classes, open, dialogClose, student, value, onChangeValue, onChangeClass, onChangeSex, onChangeSwitch, handleSaveStudentInfo } = props;
    // redux
    const { parent } = useSelector(state => state.manager);
    const dispatch = useDispatch();
    const theme = useTheme();
    const matches = useMediaQuery(theme.breakpoints.down('xs'));

    useEffect(() => {
        if (open && student.parentId) {
            dispatch(getParentUserInfo(student.parentId));
        }
        return () => {
            dispatch(clearParentUserInfo());
        }
    }, [dispatch, open, student.parentId]);


    return (
        <Dialog open={open} onClose={dialogClose} aria-labelledby="form-dialog-title" fullWidth maxWidth='md' fullScreen={matches}>
            <DialogTitle id="form-dialog-title">원생 정보 수정</DialogTitle>
            <DialogContent>
                <DialogContentText>
                    <Typography variant='body1' gutterBottom>원생정보를 수정합니다. 정보변경 후, 저장버튼을 눌러주십시오.</Typography>
                    {
                        student.cnt > 1 && <Box m={1}>
                            <Typography color='error' variant='subtitle2'>중복된 이름의 원생정보가 있습니다.</Typography>
                            <Typography color='error' variant='subtitle2'>중복된 정보일 경우 메모에 기록하여 하나만 남기고 삭제하여 합치시고, 만일 동명이인일 경우 표시 이름을 변경하여 관리하시기 바랍니다.</Typography>
                        </Box>
                    }
                    <LeftTitleTextField
                        title='학생이름*'
                        value={student.studentName}
                    />
                    <LeftTitleTextField
                        id="studentNameForHakwon"
                        title="원생표시*"
                        type="text"
                        name='studentNameForHakwon'
                        value={value.studentNameForHakwon}
                        onChange={onChangeValue}
                        error={student.cnt > 1 && student.studentName === value.studentNameForHakwon}
                        helperText={student.cnt > 1 && student.studentName === value.studentNameForHakwon ? '학생이름과 다르게 원생표시를 변경해주세요 ex)홍길동1, 홍길동A' : ''}
                    />
                    <LeftTitle title='학부모'>
                        <TableContainer component={Paper}>
                            <Table size='small'>
                                <TableHead>
                                    <TableRow style={{ backgroundColor: '#ffecec' }} >
                                        <TableCell className={classes.padding}>이름</TableCell>
                                        <TableCell className={classes.padding}>관계</TableCell>
                                        <TableCell className={classes.padding}>연락처</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>

                                    <TableRow className={classes.padding}>
                                        <TableCell className={classes.padding}>{parent.user ? parent.user?.name : <Button size='small' color='secondary'><AddRounded /></Button>}</TableCell>
                                        <TableCell className={classes.padding}>{parent && student.relation}</TableCell>
                                        <TableCell className={classes.padding}>{parent && parent.user?.phone}</TableCell>
                                    </TableRow>




                                </TableBody>

                            </Table>
                        </TableContainer>
                    </LeftTitle>




                </DialogContentText>
                <LeftTitle title='등록여부*'>
                    <FormControlLabel
                        control={<Switch checked={value.isRegistered} onChange={onChangeSwitch} name="isRegistered" />}
                        label={value.isRegistered ? "원생 등록 확인" : "원생 미등록"}
                    />
                </LeftTitle>

                <LeftTitleRadio title='성별' value={value.sex} onChange={onChangeSex}>
                    <FormControlLabel value='남' control={<Radio color='primary' />} label='남' />
                    <FormControlLabel value='여' control={<Radio color='primary' />} label='여' />
                </LeftTitleRadio>
                <LeftTitleTextField
                    id="age"
                    title="생년월일*(YYYYMMDD)"
                    type="text"
                    name='age'
                    value={value.age}
                    onChange={onChangeValue}
                />
                <LeftTitleTextField

                    id="phone"
                    title="학생휴대폰"
                    type="text"
                    name='phone'
                    value={value.phone}
                    onChange={onChangeValue}

                />
                <LeftTitleTextField
                    id="memo"
                    title="메모"
                    type="text"
                    name='memo'
                    value={value.memo}
                    onChange={onChangeValue}
                    row={3}
                    multiline
                />
                <LeftTitle title='뮤직플레이'>
                    <FormControlLabel
                        control={<Switch checked={value.isPaidGogo} onChange={onChangeSwitch} name="isPaidGogo" />}
                        label={value.isPaidGogo ? "뮤직플레이 수강중" : "뮤직플레이 미등록"}
                    />
                </LeftTitle>

                <LeftTitleTextField

                    id="musicClass"
                    title="어린음악대 클래스이름"
                    type="text"
                    name='musicClass'
                    value={value.classInfo?.musicClass}
                    onChange={onChangeClass}

                />
                <LeftTitleTextField

                    id="artClass"
                    title="어린화가들 클래스이름(있을 경우)"
                    type="text"
                    name='artClass'
                    value={value.classInfo?.artClass}
                    onChange={onChangeClass}

                />
                <LeftTitle title='원생 삭제'>
                    <FormControlLabel
                        control={<Switch checked={value.isInvisibleForHakwon} color='primary' onChange={onChangeSwitch} name="isInvisibleForHakwon" />}
                        label="원생 삭제"
                    />
                </LeftTitle>

               {/*  <NewStudent 
                    value={value}
                    setValue={onChangeValue}   //FIXME: 넘기는 값 체크, 그리고 
                />
                */}
            </DialogContent>
            <DialogActions>
                <Button onClick={dialogClose} color="primary" variant='contained'>닫기</Button>
                <Button onClick={handleSaveStudentInfo} color="primary" variant='contained' startIcon={<SaveRounded />}>저장</Button>
            </DialogActions>
        </Dialog >
    );
}

//
const LeftTitleTextField = ({ title, ...other }) => {
    const theme = useTheme();
    const matches = useMediaQuery(theme.breakpoints.down('xs'));
    return (<Grid container direction='row' alignItems='center'>
        <Grid item xs={matches ? 12 : 2} justifyContent='flex-start'>{title}</Grid>
        <Grid item xs={matches ? 12 : 10}>
            <TextField
                {...other}
                variant='outlined'
                size='small'
                margin='dense'
                fullWidth
                color='secondary'
            />
        </Grid>
    </Grid>);
}
const LeftTitleRadio = ({ title, value, onChange, children }) => {
    const theme = useTheme();
    const matches = useMediaQuery(theme.breakpoints.down('xs'));
    return (
        <Grid container direction='row' alignItems='center'>
            <Grid item xs={matches ? 12 : 2} justifyContent='flex-start' >{title}</Grid>
            <Grid item xs={matches ? 12 : 10}>
                <FormControl component="fieldset">
                    <RadioGroup aria-label="category" name="category" value={value} onChange={onChange} row>
                        {children}
                    </RadioGroup>
                </FormControl>
            </Grid>

        </Grid>

    )
}
const LeftTitle = ({ title, children }) => {
    const theme = useTheme();
    const matches = useMediaQuery(theme.breakpoints.down('xs'));
    return (<Grid container direction='row' alignItems='center'>
        <Grid item xs={matches ? 12 : 2} justifyContent='flex-start' >{title}</Grid>
        <Grid item xs={matches ? 12 : 10}>
            {children}
        </Grid>
    </Grid>);
}
const styles = (theme) => ({
    ...theme.userPage,
    padding: {
        paddingRight: 3,
        paddingLeft: 3,
    },
});

export default withStyles(styles)(DialogStudent);
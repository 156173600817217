import React, { useState, useEffect } from 'react';
import dayjs from 'dayjs';
//import { Link } from 'react-router-dom';

// Redux stuff
import { useSelector, useDispatch } from 'react-redux';
import { getAllLessons, getAllHakwonsContacts } from '../../redux/actions/adminActions';
import {
    getFreeVideos,
    getPaidVideos,
    postVideo,
    updateVideo,
    deleteVideo,
    getProducts,
} from '../../redux/actions/dataActions';
import {
    deleteTeachingPlan,
    getTeachingPlans,
    postTeachingPlan,
    updateTeachingPlan,
    uploadBoardFile,
} from '../../redux/actions/managerActions';
// components
import CenterLoading from '../../components/layout/CenterLoading';
// MUI stuff
import withStyles from '@material-ui/core/styles/withStyles';

import {
    Paper,
    Button,
    TableContainer,
    Table,
    TableHead,
    TableRow,
    TableCell,
    TableBody,
    Box,
    Typography,
    FormControlLabel,
    FormControl,
    RadioGroup,
    Checkbox,
    Radio,
    TextField,
    Switch,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    Divider,
    Grid,
} from '@material-ui/core';
import {
    EditRounded,
    SaveRounded,
    DeleteRounded,
    CloseRounded,
    VideoLibraryRounded,
    MenuBookRounded,
    SearchRounded,
} from '@material-ui/icons';
import { isLogged } from '../../redux/actions/userActions';

//import { InboxOutlined } from '@material-ui/icons';
const styles = (theme) => ({
    ...theme.userPage,
});
export const AdminLesson = (props) => {
    const { history } = props;
    // const user = useSelector(state => state.user);
    const { adminlessons, adminLoading, adminhakwons } = useSelector((state) => state.admin);
    const { videos, freeVideos, dataLoading, dataErrors, products } = useSelector((state) => state.data);
    const { teachingPlans, url } = useSelector((state) => state.manager);
    const dispatch = useDispatch();
    // countdown loading
    const [second, setSecond] = useState(6);
    useEffect(() => {
        const countdown = setInterval(() => {
            if (parseInt(second) > 1) {
                setSecond(parseInt(second) - 1);
            }
        }, 1000);
        return () => clearInterval(countdown);
    }, [second]);

    const [open, setOpen] = useState(false);
    const [videoType, setVideoType] = useState('');
    const [videoTypes, setVideoTypes] = useState([]);
    const [productGroup, setProductGroup] = useState('');
    const [productGroups, setProductGroups] = useState([]);
    const [category, setCategory] = useState('');
    const [hasCategory, setHasCategory] = useState(false);

    const initial = {
        type: '',
        category: '',
        productGroupId: '',
        videoUrl: '',
        videoName: '',
        desc: '',
        paytime: '',
        status: '',
        curriculumId: '',
        isFreeOpen: false,
        isOpenAtMain: false,
        providerType: 'vimeo',
        startDate: '',
        endDate: '',
        order: '0',
        createdAt: null,
        createdBy: '',
    };
    const [video, setVideo] = useState(initial);

    const [lessonOpen, setLessonOpen] = useState(false);
    const initialLessons = [];
    const [lessons, setLessons] = useState(initialLessons);
    const [hakwonName, setHakwonName] = useState('');
    // 토글
    const [isWithPast, setIsWithPast] = useState(false);

    const handleVideoType = (e) => {
        setVideoType(e.target.value);
    };
    const handleProductGroupId = (e) => {
        setProductGroup(e.target.value);
        setHasCategory(true);
    };
    

    const handleSearchLesson = () => {
        const res = dispatch(isLogged(history));
        if (res === 'logged') {
            setSecond(6);
            dispatch(getAllLessons());
        }
    };
    const handlePopUp = (vid) => {
        setOpen(true);
        setVideo(vid);
    };
    const handleLessonPopUp = (name, lessons) => {
        setLessonOpen(true);
        let sorted = lessons.sort((a, b) =>
            Number(a.startDate > b.startDate) ? 1 : Number(a.startDate < b.startDate) ? -1 : 0
        );
        setLessons(sorted);
        setHakwonName(name);
        //console.log(sorted);
    };
    const handleSave = () => {
        const res = dispatch(isLogged(history));
        if (res === 'logged') {
            let newData = video;
            let videoId = video.videoId;
            if (typeof videoId !== 'undefined') {
                dispatch(updateVideo(videoId, newData));
            } else {
                dispatch(postVideo(newData));
            }
            setOpen(false);
        }
    };
    const handleDelete = () => {
        const res = dispatch(isLogged(history));
        if (res === 'logged') {
            let videoId = video.videoId;
            dispatch(deleteVideo(videoId));
            setOpen(false);
        }
    };

    useEffect(() => {
        dispatch(getPaidVideos());
        dispatch(getFreeVideos());
        dispatch(getAllHakwonsContacts());
        dispatch(getProducts());
    }, [dispatch]);

    useEffect(() => {
        let videoArray;
        let groupArray;
        if (videos && freeVideos) {
            videoArray = [...videos.map((v) => v.type), ...freeVideos.map((v) => v.type)];
            videoArray = [...new Set(videoArray)];
            groupArray = [...videos.map((v) => v.productGroupId), ...freeVideos.map((v) => v.productGroupId)];
            groupArray = [...new Set(groupArray)];
            setVideoTypes(videoArray);
            setProductGroups(groupArray);
        }
    }, [videos, freeVideos]);


    // 영상 카테고리
    const temps = [...videos, ...freeVideos].filter(v => v.productGroupId === productGroup).map(v => v.category);
    const categories = temps.filter((e, i) => temps.indexOf(e)=== i);
    console.log(categories);
    const handleCategory = (e) => {
        setCategory(e.target.value);
    };


    function groupBy(list, keyGetter) {
        const map = new Map();
        list.forEach((item) => {
            const key = keyGetter(item);
            const collection = map.get(key);
            if (!collection) {
                map.set(key, [item]);
            } else {
                collection.push(item);
            }
        });
        return map;
    }

    const newlessons = adminlessons
        .filter((l) => !!l.hakwonId)
        .map((le) => {
            let hak = adminhakwons.filter((h) => h.hakwonId === le.hakwonId)[0];

            le.hName = hak ? hak.hName : '가맹해지';
            le.area1 = hak ? hak.area?.area1 : '-';
            le.area2 = hak ? hak.area?.area2 : '-';

            let prod = products.filter((p) => p.productId === le.productId)[0];
            le.productName = prod.productName;
            return le;
        });
    const grouped = newlessons.length > 0 ? groupBy(newlessons, (lesson) => lesson.hName) : [];

    const results = Array.from(grouped).length > 0 ? Array.from(grouped) : [];




    // 교안, 워크시트 (teachingPlans)
    const productNames = ['뮤직플레이 원데이클래스', '스마트피아노 원데이클래스', '뮤직플레이', '뮤직플레이 프리미엄'];

    const [prod, setProd] = useState(null);
    const [cate, setCate] = useState('all');
    const [teachingPlanId, setTeachingPlanId] = useState('');
    const [urlmessage, setUrlmessage] = useState('');
    const [withDone, setWithDone] = useState(false);
    const initialPlan = {
        product: '',
        category: '',
        type: '교안',
        name: '',
        order: 0,
        yyyymm: '',
        startDate: '',
        endDate: '',
        url: '',
    };
    const [plan, setPlan] = useState(initialPlan);
    const [planOpen, setPlanOpen] = useState(false);
    const handleTeachingPlan = (e) => {
        if (!e) {
            setProd(initialPlan);
        } else {
            setProd(e.target.value);
            dispatch(getTeachingPlans());
        }
    };
    const handleTPCategory = (e) => {
        setCate(e.target.value);
    };
    const filteredTeachingPlans = teachingPlans
        .filter((p) => p.product === prod)
        .filter((p) => (cate === 'all' ? true : p.category === cate))
        .filter((p) => (withDone ? true : dayjs().isBefore(p.endDate)));
    const tempCates = teachingPlans
        .filter((p) => p.product === prod)
        .map((p) => p.category)
        .filter((el, ind, arr) => arr.indexOf(el) === ind);
    const cates = ['all', ...tempCates];

    const handleFile = () => {
        const fileInput = document.getElementById('imageInput');
        fileInput.click();
    };
    const handleFileChange = (e) => {
        const image = e.target.files[0];
        // send to the server

        const formData = new FormData(); // FormData()를 이용하여 전송

        formData.append('image', image, image.name);

        handleUploadImage(formData);
    };
    const handleUploadImage = (formData) => {
        if (url) {
            dispatch(uploadBoardFile(formData));
        }
    };
    // 새로등록
    const handleNewUpload = () => {
        setPlanOpen(true);
        setPlan(initialPlan);
        setTeachingPlanId('');
    };
    // 등록하기
    const handleUploadPlanInfo = () => {
        if (teachingPlanId) {
            // 수정 업데이트
            dispatch(updateTeachingPlan(teachingPlanId, plan));
            setPlanOpen(false);
            setPlan(initialPlan);
        } else {
            // 등록
            dispatch(postTeachingPlan(plan));
            setPlanOpen(false);
            setPlan(initialPlan);
        }
    };
    // 수정하기
    const handleEditPlan = (tid, plan) => {
        setPlan(plan);
        setPlanOpen(true);
        setTeachingPlanId(tid);
    };
    // 삭제하기
    const handleDeletePlanInfo = () => {
        if (teachingPlanId) {
            dispatch(deleteTeachingPlan(teachingPlanId));
            setPlanOpen(false);
            setPlan(initialPlan);
        }
    };
    useEffect(() => {
        if (url) {
            setUrlmessage(url.message);
        }
    }, [url]);

    return (
        <Box p={1} m={1}>
            <Typography variant="h3" display="inline">
                수업 영상 관리
            </Typography>
            <Button
                variant="contained"
                size="small"
                color='secondary'
                style={{ marginLeft: 20 }}
                onClick={() => handlePopUp(initial)}
                startIcon={<VideoLibraryRounded />}
            >
                신규등록
            </Button>
            {dataLoading && <CenterLoading />}
            {dataErrors && <Typography>{dataErrors.errors}</Typography>}
            <Box height={10} />
            <FormControl component="fieldset">
                <Typography variant="subtitle2">사용 유형 선택</Typography>
                <RadioGroup aria-label="category" name="category" value={videoType} onChange={handleVideoType} row>
                    {videoTypes.map((type, ind) => (
                        <Box key={ind}>
                            <FormControlLabel
                                value={type}
                                control={<Radio />}
                                label={<Typography variant="subtitle2">{type}</Typography>}
                            />
                        </Box>
                    ))}
                </RadioGroup>
            </FormControl>
            <RadioGroup aria-label="group" name="group" value={productGroup} onChange={handleProductGroupId} row>
                {productGroups.length > 0
                    ? productGroups?.map((group, ind) => (
                          <Box key={ind}>
                              <FormControlLabel
                                  value={group}
                                  control={<Radio />}
                                  label={<Typography variant="subtitle2">{group}</Typography>}
                              />
                          </Box>
                      ))
                    : null}
            </RadioGroup>
            {hasCategory && (
                <RadioGroup aria-label="group" name="group" value={category} onChange={handleCategory} row>
                    {categories.length > 0 &&
                        ['all', ...categories].map((c, ind) => (
                            <Box key={ind}>
                                <FormControlLabel
                                    value={c}
                                    control={<Radio />}
                                    label={<Typography variant="subtitle2">{c}</Typography>}
                                />
                            </Box>
                        ))}
                </RadioGroup>
            )}

            {[...videos, ...freeVideos]
                .filter((v) => v.type === videoType && v.productGroupId === productGroup)
                .filter(v => (category ==='' || category ==='all') ? true : v.category === category)
                .sort((a, b) => a.order - b.order)
                .map((vid, ind) => (
                    <Box p={1} m={1} key={ind}>
                        <Typography variant="body2">순번: {vid.order}</Typography>
                        <Typography variant="body2">종류: {vid.productGroupId}</Typography>
                        <Typography variant="body2">
                            카테고리: <b>{vid.category}</b>
                        </Typography>
                        <Typography variant="body2">명칭: {vid.videoName}</Typography>
                        <Typography variant="body2">상세: {vid.desc}</Typography>
                        <Typography variant="body2">경로: {vid.videoUrl}</Typography>
                        <Typography variant="body2" color="error">
                            상태: {vid.status}
                        </Typography>
                        {vid.startDate ? (
                            <Typography
                                variant="body2"
                                color={
                                    dayjs(vid.startDate).format('YYYYMMDD') > dayjs().format('YYYYMMDD')
                                        ? 'textSecondary'
                                        : dayjs(vid.startDate).format('YYYYMMDD') <= dayjs().format('YYYYMMDD') &&
                                          dayjs().format('YYYYMMDD') <= dayjs(vid.endDate).format('YYYYMMDD')
                                        ? 'secondary'
                                        : 'error'
                                }
                            >
                                시작일: {vid.startDate}
                            </Typography>
                        ) : null}
                        {vid.endDate ? (
                            <Typography
                                variant="body2"
                                color={
                                    dayjs(vid.startDate).format('YYYYMMDD') > dayjs().format('YYYYMMDD')
                                        ? 'textSecondary'
                                        : dayjs(vid.startDate).format('YYYYMMDD') <= dayjs().format('YYYYMMDD') &&
                                          dayjs().format('YYYYMMDD') <= dayjs(vid.endDate).format('YYYYMMDD')
                                        ? 'secondary'
                                        : 'error'
                                }
                            >
                                종료일: {vid.endDate}
                            </Typography>
                        ) : null}

                        <Button
                            variant="contained"
                            size="small"
                            style={{ margin: 5 }}
                            onClick={() => handlePopUp(vid)}
                            startIcon={<EditRounded />}
                        >
                            수정
                        </Button>
                    </Box>
                ))}

            {/* 교안/워크시트 등록 관리 */}

            <Box height={50} />
            <Divider />
            <Box height={20} />
            <Typography variant="h3" display="inline">
                교안/워크시트 등록 관리
            </Typography>
            
            <FormControlLabel
                style={{ marginLeft: 20 }}
                control={
                    <Checkbox size="small" checked={withDone} onChange={() => setWithDone(!withDone)} name="checked" />
                }
                label={
                    withDone ? (
                        <Typography variant="body2">종료 포함</Typography>
                    ) : (
                        <Typography variant="body2">종료 제외</Typography>
                    )
                }
            />
            <Button
                style={{ marginLeft: 20 }}
                variant="contained"
                color='secondary'
                size="small"
                onClick={handleNewUpload}
                startIcon={<MenuBookRounded />}
            >
                신규 등록
            </Button>
            <Typography variant='body2'gutterBottom>* 워크시트는 복사방지를 위해 시스템관리자에게 요청하여 업로드 합니다.</Typography>
            <Box height={10} />
            <FormControl component="fieldset">
                <RadioGroup aria-label="product" name="product" value={prod} onChange={handleTeachingPlan} row>
                    {productNames.map((n, i) => (
                        <FormControlLabel
                            key={i}
                            value={n}
                            control={<Radio />}
                            label={<Typography variant="subtitle2">{n} </Typography>}
                        />
                    ))}
                </RadioGroup>
            </FormControl>
            <br />
            <FormControl component="fieldset">
                <RadioGroup aria-label="category" name="category" value={cate} onChange={handleTPCategory} row>
                    {cates.map((n, i) => (
                        <FormControlLabel
                            key={i}
                            value={n}
                            control={<Radio size="small" />}
                            label={<Typography variant="subtitle2">{n} </Typography>}
                        />
                    ))}
                </RadioGroup>
            </FormControl>
            <Box height={10} />
            <Grid container direction="row">
                <Grid item xs={6}>
                    <Typography variant="subtitle1">[1.교안]</Typography>
                    {filteredTeachingPlans
                        .filter((p) => p.type === '교안')
                        .sort((a, b) => (a.order > b.order ? 1 : -1))
                        .map((p, i) => (
                            <Box key={i}>
                                <Typography variant="body2">ㆍ상품 : {p.product}</Typography>
                                <Typography variant="body2">ㆍ카테고리: {p.category}</Typography>
                                <Typography variant="body2">ㆍ순서 : {p.order}</Typography>
                                <Typography variant="body2">
                                    ㆍ제목 : <strong>{p.name}</strong>
                                </Typography>
                                <Typography variant="body2">ㆍyyyymm: {p.yyyymm}</Typography>
                                <Typography variant="body2">ㆍ파일URL : {p.url}</Typography>

                                {p.startDate ? (
                                    <Typography
                                        variant="body2"
                                        color={
                                            dayjs(p.startDate).format('YYYYMMDD') > dayjs().format('YYYYMMDD')
                                                ? 'textSecondary'
                                                : dayjs(p.startDate).format('YYYYMMDD') <= dayjs().format('YYYYMMDD') &&
                                                  dayjs().format('YYYYMMDD') <= dayjs(p.endDate).format('YYYYMMDD')
                                                ? 'secondary'
                                                : 'error'
                                        }
                                    >
                                        시작일: {p.startDate}
                                    </Typography>
                                ) : null}
                                {p.endDate ? (
                                    <Typography
                                        variant="body2"
                                        color={
                                            dayjs(p.startDate).format('YYYYMMDD') > dayjs().format('YYYYMMDD')
                                                ? 'textSecondary'
                                                : dayjs(p.startDate).format('YYYYMMDD') <= dayjs().format('YYYYMMDD') &&
                                                  dayjs().format('YYYYMMDD') <= dayjs(p.endDate).format('YYYYMMDD')
                                                ? 'secondary'
                                                : 'error'
                                        }
                                    >
                                        종료일: {p.endDate}
                                    </Typography>
                                ) : null}

                                <Button
                                    variant="contained"
                                    size="small"
                                    style={{ margin: 5 }}
                                    onClick={() => handleEditPlan(p.teachingPlanId, p)}
                                    startIcon={<EditRounded />}
                                >
                                    수정
                                </Button>
                                <Box height={10} />
                            </Box>
                        ))}
                </Grid>
                <Grid item xs={6}>
                    <Typography variant="subtitle1">[2.워크시트]</Typography>
                    {filteredTeachingPlans
                        .filter((p) => p.type === '워크시트')
                        .sort((a, b) => (a.order > b.order ? 1 : -1))
                        .map((p, i) => (
                            <Box key={i}>
                                <Typography variant="body2">ㆍ상품 : {p.product}</Typography>
                                <Typography variant="body2">ㆍ카테고리: {p.category}</Typography>
                                <Typography variant="body2">ㆍ순서 : {p.order}</Typography>
                                <Typography variant="body2">
                                    ㆍ제목 : <strong>{p.name}</strong>
                                </Typography>
                                <Typography variant="body2">ㆍyyyymm: {p.yyyymm}</Typography>
                                <Typography variant="body2">ㆍ파일URL : {p.url}</Typography>

                                {p.startDate ? (
                                    <Typography
                                        variant="body2"
                                        color={
                                            dayjs(p.startDate).format('YYYYMMDD') > dayjs().format('YYYYMMDD')
                                                ? 'textSecondary'
                                                : dayjs(p.startDate).format('YYYYMMDD') <= dayjs().format('YYYYMMDD') &&
                                                  dayjs().format('YYYYMMDD') <= dayjs(p.endDate).format('YYYYMMDD')
                                                ? 'secondary'
                                                : 'error'
                                        }
                                    >
                                        시작일: {p.startDate}
                                    </Typography>
                                ) : null}
                                {p.endDate ? (
                                    <Typography
                                        variant="body2"
                                        color={
                                            dayjs(p.startDate).format('YYYYMMDD') > dayjs().format('YYYYMMDD')
                                                ? 'textSecondary'
                                                : dayjs(p.startDate).format('YYYYMMDD') <= dayjs().format('YYYYMMDD') &&
                                                  dayjs().format('YYYYMMDD') <= dayjs(p.endDate).format('YYYYMMDD')
                                                ? 'secondary'
                                                : 'error'
                                        }
                                    >
                                        종료일: {p.endDate}
                                    </Typography>
                                ) : null}

                                <Button
                                    variant="contained"
                                    size="small"
                                    style={{ margin: 5 }}
                                    onClick={() => handleEditPlan(p.teachingPlanId, p)}
                                    startIcon={<EditRounded />}
                                >
                                    수정
                                </Button>
                                <Box height={10} />
                            </Box>
                        ))}
                </Grid>
            </Grid>
            <Dialog open={planOpen} fullWidth maxWidth="sm">
                <DialogTitle>[교안, 워크시트 생성]</DialogTitle>
                <DialogContent>
                    <TextField
                        variant="outlined"
                        label="타입"
                        value={plan.type}
                        size="small"
                        margin="dense"
                        onChange={(e) => setPlan({ ...plan, type: e.target.value })}
                        fullWidth
                    />
                    <Typography variant="body2">교안 || 워크시트</Typography>

                    <TextField
                        variant="outlined"
                        label="상품"
                        value={plan.product}
                        size="small"
                        margin="dense"
                        onChange={(e) => setPlan({ ...plan, product: e.target.value })}
                        fullWidth
                    />
                    <Typography variant="body2">
                        뮤직플레이 원데이클래스 || 스마트피아노 원데이클래스 || 뮤직플레이 || 뮤직플레이 프리미엄
                    </Typography>
                    <TextField
                        variant="outlined"
                        label="카테고리"
                        value={plan.category}
                        size="small"
                        margin="dense"
                        onChange={(e) => setPlan({ ...plan, category: e.target.value })}
                        fullWidth
                    />
                    <Typography variant="body2">
                        4-5세 || 6-7세 || 8-9세 || 플레이어 || 러너 || 스타터 || 러너플러스
                    </Typography>

                    <TextField
                        variant="outlined"
                        label="순서"
                        value={plan.order}
                        size="small"
                        margin="dense"
                        onChange={(e) => setPlan({ ...plan, order: e.target.value })}
                        fullWidth
                    />
                    <TextField
                        variant="outlined"
                        label="제목"
                        value={plan.name}
                        size="small"
                        margin="dense"
                        onChange={(e) => setPlan({ ...plan, name: e.target.value })}
                        fullWidth
                    />
                    <Typography variant="body2">O월 O주차</Typography>
                    <TextField
                        variant="outlined"
                        label="yyyymm"
                        value={plan.yyyymm}
                        size="small"
                        margin="dense"
                        onChange={(e) => setPlan({ ...plan, yyyymm: e.target.value })}
                        fullWidth
                    />
                    <TextField
                        variant="outlined"
                        label="파일url"
                        value={plan.url}
                        size="small"
                        margin="dense"
                        onChange={(e) => setPlan({ ...plan, url: e.target.value })}
                        fullWidth
                    />
                    <Box>
                        <Button size="small" color="primary" variant="contained" onClick={handleFile}>
                            +파일
                        </Button>
                        <input type="file" id="imageInput" hidden="hidden" onChange={handleFileChange} />
                        <Typography vairant="caption" display="inline">
                            {urlmessage}
                        </Typography>

                        {url?.message && (
                            <Button
                                onClick={() => {
                                    setPlan({ ...plan, url: url?.message });
                                    setUrlmessage('');
                                }}
                                color="primary"
                                size="small"
                            >
                                입력
                            </Button>
                        )}
                    </Box>
                    <TextField
                        variant="outlined"
                        label="시작일(YYYYMMDD)"
                        value={plan.startDate}
                        size="small"
                        margin="dense"
                        onChange={(e) => setPlan({ ...plan, startDate: e.target.value })}
                        fullWidth
                    />
                    <TextField
                        variant="outlined"
                        label="종료일(YYYYMMDD)"
                        value={plan.endDate}
                        size="small"
                        margin="dense"
                        onChange={(e) => setPlan({ ...plan, endDate: e.target.value })}
                        fullWidth
                    />
                </DialogContent>
                <DialogActions>
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={handleUploadPlanInfo}
                        startIcon={<SaveRounded />}
                    >
                        {teachingPlanId ? '업데이트' : '등록'}
                    </Button>
                    {teachingPlanId && (
                        <Button
                            variant="contained"
                            color="secondary"
                            onClick={handleDeletePlanInfo}
                            startIcon={<DeleteRounded />}
                        >
                            삭제
                        </Button>
                    )}
                    <Button variant="contained" onClick={() => setPlanOpen(false)} startIcon={<CloseRounded />}>
                        닫기
                    </Button>
                </DialogActions>
            </Dialog>

            <Box height={50} />
            <Divider />
            <Box height={20} />
            <Typography variant="h3">수업 생성 현황</Typography>
            <Button variant="contained" color='secondary' startIcon={<SearchRounded />} onClick={handleSearchLesson} style={{ margin: 10 }}>
                학원별 레슨 조회
            </Button>
            <Typography variant='body2' gutterBottom>* 조회에 시간이 많이 소요됩니다. 기다리시기 바랍니다.</Typography>
            {adminLoading && (
                <Box align="center">
                    <CenterLoading />
                    <Typography color="primary" variant="caption">
                        로딩 중... {second}초 남음
                    </Typography>
                </Box>
            )}
            <TableContainer component={Paper}>
                <Table size="small" aria-label="a dense table">
                    <TableHead>
                        <TableRow>
                            <TableCell>시/도</TableCell>
                            <TableCell>시군구</TableCell>
                            <TableCell>학원명</TableCell>
                            <TableCell>{`뮤직플레이 원데이`}</TableCell>
                            <TableCell>스마트피아노 원데이</TableCell>

                            <TableCell>뮤직플레이/프리미엄</TableCell>
                            <TableCell>총 생성 수업 수</TableCell>
                            <TableCell>현재 가능 수업수</TableCell>
                            <TableCell>상세</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {results.map((item, index) => (
                            <TableRow key={index}>
                                <TableCell>{item[1][0].area1}</TableCell>
                                <TableCell>{item[1][0].area2}</TableCell>
                                <TableCell>
                                    <strong>{item[0]}</strong>
                                </TableCell>

                                <TableCell>
                                    {item[1].filter((v) => v.productName === '뮤직플레이 원데이클래스').length}
                                </TableCell>
                                <TableCell>
                                    {item[1].filter((v) => v.productName === '스마트피아노 원데이클래스').length}
                                </TableCell>
                                <TableCell>
                                    {
                                        item[1].filter(
                                            (v) =>
                                                v.productName !== '스마트피아노 원데이클래스' &&
                                                v.productName !== '뮤직플레이 원데이클래스'
                                        ).length
                                    }
                                </TableCell>

                                <TableCell>{item[1].length}</TableCell>
                                <TableCell>
                                    {item[1].filter((v) => v.startDate > dayjs() / 1000 && v.status === 'open').length}
                                </TableCell>
                                <TableCell>
                                    <Button color="primary" onClick={() => handleLessonPopUp(item[0], item[1])}>
                                        자세히
                                    </Button>
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>

            <Box height={30} />

            {/* 영상 정보(vimeo) 신규 등록 새창F */}
            <Dialog open={open}>
                <DialogTitle>
                    영상 정보 등록/수정
                    <Typography variant="subtitle2" gutterBottom>
                        {video.videoId}
                    </Typography>
                </DialogTitle>
                <DialogContent dividers>
                    <Box p={1} m={1}>
                        <Typography variant="subtitle2" color="secondary" gutterBottom>
                            구분자
                        </Typography>
                        <TextField
                            variant="outlined"
                            label="사용 유형"
                            value={video.type}
                            size="small"
                            margin="dense"
                            helperText="유료 || 메인맛보기 || 또는신규생성입력할수있음"
                            onChange={(e) => setVideo({ ...video, type: e.target.value })}
                            fullWidth
                        />
                        <TextField
                            variant="outlined"
                            label="상품그룹 아이디(productGroupId)"
                            value={video.productGroupId}
                            size="small"
                            helperText="musicgogo || musicgogoPremium || musicgogoOneDayClass || pianogogoOneDayClass "
                            margin="dense"
                            onChange={(e) => setVideo({ ...video, productGroupId: e.target.value })}
                            fullWidth
                        />
                        <TextField
                            variant="outlined"
                            label="카테고리"
                            value={video.category}
                            size="small"
                            margin="dense"
                            helperText="레슨/상품에 표기된 정확한 카테고리를 입력해주세요. 예) 4-5세 || 6-7세 || 8-9세 || 플레이어 || 러너 || 스타터 || 러너플러스"
                            onChange={(e) => setVideo({ ...video, category: e.target.value })}
                            fullWidth
                        />
                        <Box height={10} />
                        <Typography variant="subtitle2" color="secondary" gutterBottom>
                            영상 표기 정보
                        </Typography>
                        <TextField
                            variant="outlined"
                            label="영상 노출 순서"
                            type="number"
                            value={video.order}
                            size="small"
                            margin="dense"
                            helperText="숫자로 입력해주세요. 작은 숫자가 앞으로 노출됩니다."
                            onChange={(e) => setVideo({ ...video, order: e.target.value })}
                            fullWidth
                        />
                        <TextField
                            variant="outlined"
                            label="영상URL"
                            value={video.videoUrl}
                            size="small"
                            margin="dense"
                            helperText="전체 주소를 입력해주세요. 예) https://vimeo.com/665089730  단,영상번호는 하나만,https://vimeo.com/665089730/12342342 이렇게하면 안됨 "
                            onChange={(e) => setVideo({ ...video, videoUrl: e.target.value })}
                            fullWidth
                        />

                        <TextField
                            variant="outlined"
                            label="[구분]"
                            value={video.videoName}
                            size="small"
                            margin="dense"
                            helperText="뮤직플레이 원데이 클래스(4-5세).. []는 입력하지 마세요. 영상아래에 노출되는 영상제목문구 입니다."
                            onChange={(e) => setVideo({ ...video, videoName: e.target.value })}
                            fullWidth
                        />
                        <TextField
                            variant="outlined"
                            label="영상 명칭"
                            value={video.desc}
                            size="small"
                            margin="dense"
                            helperText="예) 어린음악대가 될래요II!(타악기, 수박쉐이커) 영상아래에 노출되는 영상제목문구입니다."
                            onChange={(e) => setVideo({ ...video, desc: e.target.value })}
                            fullWidth
                        />
                        <TextField
                            variant="outlined"
                            label="영상 세부정보"
                            value={video.playtime}
                            size="small"
                            margin="dense"
                            helperText="예) 재생시간: 13분 32초 <- 재생시간도 입력필요. 다른 정보도 표기 가능"
                            onChange={(e) => setVideo({ ...video, playtime: e.target.value })}
                            fullWidth
                        />

                        <Box height={10} />
                        <Typography variant="subtitle2" color="secondary" gutterBottom>
                            영상 관리 정보
                        </Typography>
                        <TextField
                            variant="outlined"
                            label="영상유형"
                            value={video.providerType}
                            size="small"
                            margin="dense"
                            helperText="youtube || vimeo"
                            onChange={(e) => setVideo({ ...video, providerType: e.target.value })}
                            fullWidth
                        />
                        <FormControlLabel
                            control={
                                <Switch
                                    checked={video.status === 'open' ? true : false}
                                    value={video.status === 'open' ? true : false}
                                    onChange={(e) =>
                                        setVideo({ ...video, status: e.target.checked ? 'open' : 'closed' })
                                    }
                                    color="primary"
                                />
                            }
                            label={video.status === 'open' ? '영상공개(서비스)' : '비공개(서비스안됨)'}
                        />
                        <br />
                        <FormControlLabel
                            control={
                                <Switch
                                    checked={video.isFreeOpen}
                                    value={video.isFreeOpen}
                                    onChange={(e) => setVideo({ ...video, isFreeOpen: e.target.checked })}
                                    color="primary"
                                />
                            }
                            label={video.isFreeOpen ? '무료공개(맛보기용)' : '무료공개아님(유상서비스용)'}
                        />
                        <br />
                        <TextField
                            variant="outlined"
                            label="커리큘럼아이디(curriculumId)"
                            value={video.curriculumId}
                            size="small"
                            margin="dense"
                            helperText="명확한 커리큘럼ID를 입력해주세요. 관리자>상품>2.커리큘럼관리에서 커리큘럼ID를 검색"
                            onChange={(e) => setVideo({ ...video, curriculumId: e.target.value })}
                            fullWidth
                        />
                        <TextField
                            variant="outlined"
                            label="시작일시"
                            value={video.startDate}
                            size="small"
                            margin="dense"
                            helperText="YYYYMMDD 문자열 - 시작일포함"
                            onChange={(e) => setVideo({ ...video, startDate: e.target.value })}
                            fullWidth
                        />
                        <TextField
                            variant="outlined"
                            label="종료일시"
                            value={video.endDate}
                            size="small"
                            margin="dense"
                            helperText="YYYYMMDD 문자열 - 종료일포함"
                            onChange={(e) => setVideo({ ...video, endDate: e.target.value })}
                            fullWidth
                        />
                    </Box>
                </DialogContent>
                <DialogActions>
                    <Button variant="contained" onClick={(e) => setOpen(false)}>
                        닫기
                    </Button>
                    <Button
                        variant="contained"
                        style={{ margin: 10 }}
                        onClick={handleDelete}
                        disabled={dataLoading}
                        startIcon={<DeleteRounded />}
                    >
                        삭제
                    </Button>
                    <Button
                        variant="contained"
                        style={{ margin: 10 }}
                        onClick={handleSave}
                        color="secondary"
                        disabled={dataLoading}
                        startIcon={<SaveRounded />}
                    >
                        저장
                    </Button>
                </DialogActions>
            </Dialog>

            {/* lesson Dialog */}
            <Dialog open={lessonOpen}>
                <DialogTitle>
                    {hakwonName} 수업 생성 현황
                    <Typography variant="subtitle2" gutterBottom>
                        총 {lessons.length}개 생성
                    </Typography>
                    <FormControlLabel
                        control={
                            <Checkbox checked={isWithPast} onChange={() => setIsWithPast(!isWithPast)} name="checked" />
                        }
                        label={
                            isWithPast ? (
                                <Typography variant="body2">이전 포함</Typography>
                            ) : (
                                <Typography variant="body2">당월 이전 제외</Typography>
                            )
                        }
                    />
                </DialogTitle>
                <DialogContent dividers>
                    <Box p={1} m={1}>
                        <Typography variant="subtitle1">[뮤직플레이 원데이클래스]</Typography>
                        {lessons
                            .filter((l) =>
                                isWithPast
                                    ? true
                                    : dayjs(l.startDate * 1000).format('YYYYMM') >= dayjs().format('YYYYMM')
                            )
                            .filter((l) => l.productName === '뮤직플레이 원데이클래스')
                            .map((lesson, index) => (
                                <Box key={index}>
                                    <Typography
                                        variant="subtitle2"
                                        color={
                                            lesson.startDate < dayjs() / 1000 || lesson.status !== 'open'
                                                ? 'error'
                                                : 'secondary'
                                        }
                                    >
                                        {lesson.lessonName}
                                    </Typography>
                                    <Typography variant="body2">-카테고리:{lesson.category}</Typography>
                                    <Typography display="inline" variant="body2">
                                        -수업일: {dayjs(lesson.startDate * 1000).format('YYYY-MM-DD(ddd) HH:mm')}{' '}
                                    </Typography>
                                    <Typography display="inline" color="error" variant="body2">
                                        {lesson.startDate < dayjs() / 1000 ? '(종료)' : null}
                                    </Typography>
                                    <br />
                                    <Typography display="inline" variant="body2">
                                        -신청자 : {lesson.enrolled > 0 ? lesson.enrolled : 0} / {lesson.capacity}{' '}
                                    </Typography>
                                    <Typography
                                        display="inline"
                                        variant="body2"
                                        color={lesson.enrolled > 0 ? 'primary' : 'initial'}
                                    >
                                        ({lesson.enrolled > 0 ? '등록' : '미등록'})
                                    </Typography>
                                    <br />
                                    <Typography variant="body2">
                                        -가격(정산):{lesson.price} ({lesson.settlement})
                                    </Typography>
                                    <Typography variant="body2" color={lesson.status === 'open' ? 'initial' : 'error'}>
                                        -상태:{lesson.status}
                                    </Typography>
                                </Box>
                            ))}
                        <Divider light variant="middle" />
                        <Typography variant="subtitle1">[스마트피아노 원데이클래스]</Typography>
                        {lessons
                            .filter((l) =>
                                isWithPast
                                    ? true
                                    : dayjs(l.startDate * 1000).format('YYYYMM') >= dayjs().format('YYYYMM')
                            )
                            .filter((l) => l.productName === '스마트피아노 원데이클래스')
                            .map((lesson, index) => (
                                <Box key={index}>
                                    <Typography
                                        variant="subtitle2"
                                        color={
                                            lesson.startDate < dayjs() / 1000 || lesson.status !== 'open'
                                                ? 'error'
                                                : 'secondary'
                                        }
                                    >
                                        {lesson.lessonName}
                                    </Typography>
                                    <Typography variant="body2">-카테고리:{lesson.category}</Typography>
                                    <Typography display="inline" variant="body2">
                                        -수업일: {dayjs(lesson.startDate * 1000).format('YYYY-MM-DD(ddd) HH:mm')}{' '}
                                    </Typography>
                                    <Typography display="inline" color="error" variant="body2">
                                        {lesson.startDate < dayjs() / 1000 ? '(종료)' : null}
                                    </Typography>
                                    <br />
                                    <Typography display="inline" variant="body2">
                                        -신청자 : {lesson.enrolled > 0 ? lesson.enrolled : 0} / {lesson.capacity}{' '}
                                    </Typography>
                                    <Typography
                                        display="inline"
                                        variant="body2"
                                        color={lesson.enrolled > 0 ? 'primary' : 'initial'}
                                    >
                                        ({lesson.enrolled > 0 ? '등록' : '미등록'})
                                    </Typography>
                                    <br />
                                    <Typography variant="body2">
                                        -가격(정산):{lesson.price} ({lesson.settlement})
                                    </Typography>
                                    <Typography variant="body2" color={lesson.status === 'open' ? 'initial' : 'error'}>
                                        -상태:{lesson.status}
                                    </Typography>
                                </Box>
                            ))}
                        <Divider light variant="middle" />
                        <Typography variant="subtitle1">[뮤직플레이]</Typography>
                        {lessons
                            .filter((l) =>
                                isWithPast
                                    ? true
                                    : dayjs(l.startDate * 1000).format('YYYYMM') >= dayjs().format('YYYYMM')
                            )
                            .filter(
                                (l) =>
                                    l.productName === '뮤직플레이' ||
                                    l.productName === '뮤직플레이 3개월 수강권' ||
                                    l.productName === '뮤직플레이 6개월 정기결제' ||
                                    l.productName === '뮤직플레이 12개월 정기결제'
                            )
                            .map((lesson, index) => (
                                <Box key={index}>
                                    <Typography
                                        variant="subtitle2"
                                        color={
                                            lesson.startDate < dayjs() / 1000 || lesson.status !== 'open'
                                                ? 'error'
                                                : 'secondary'
                                        }
                                    >
                                        {lesson.lessonName}
                                    </Typography>
                                    <Typography variant="body2">-카테고리:{lesson.category}</Typography>
                                    <Typography variant="body2">-레슨ID:{lesson.lessonId}</Typography>
                                    <Typography display="inline" variant="body2">
                                        -수업일: {dayjs(lesson.startDate * 1000).format('YYYY-MM-DD(ddd) HH:mm')}{' '}
                                    </Typography>
                                    <Typography display="inline" color="error" variant="body2">
                                        {lesson.startDate < dayjs() / 1000 ? '(종료)' : null}
                                    </Typography>
                                    <br />
                                    <Typography display="inline" variant="body2">
                                        -신청자 : {lesson.enrolled > 0 ? lesson.enrolled : 0} / {lesson.capacity}{' '}
                                    </Typography>
                                    <Typography
                                        display="inline"
                                        variant="body2"
                                        color={lesson.enrolled > 0 ? 'primary' : 'initial'}
                                    >
                                        ({lesson.enrolled > 0 ? '등록' : '미등록'})
                                    </Typography>
                                    <br />
                                    <Typography variant="body2">
                                        -가격(정산):{lesson.price} ({lesson.settlement})
                                    </Typography>
                                    <Typography variant="body2" color={lesson.status === 'open' ? 'initial' : 'error'}>
                                        -상태:{lesson.status}
                                    </Typography>
                                    <Typography variant="body2">
                                        -수업일자: {JSON.stringify(lesson?.recurrentDates)}
                                    </Typography>
                                </Box>
                            ))}

                        <Divider light variant="middle" />
                        <Typography variant="subtitle1">[뮤직플레이 프리미엄]</Typography>
                        {lessons
                            .filter((l) =>
                                isWithPast
                                    ? true
                                    : dayjs(l.startDate * 1000).format('YYYYMM') >= dayjs().format('YYYYMM')
                            )
                            .filter(
                                (l) =>
                                    l.productName === '뮤직플레이 프리미엄' ||
                                    l.productName === '뮤직플레이 프리미엄 3개월 수강권' ||
                                    l.productName === '뮤직플레이 프리미엄 6개월 정기결제' ||
                                    l.productName === '뮤직플레이 프리미엄 12개월 정기결제'
                            )
                            .map((lesson, index) => (
                                <Box key={index}>
                                    <Typography
                                        variant="subtitle2"
                                        color={
                                            lesson.startDate < dayjs() / 1000 || lesson.status !== 'open'
                                                ? 'error'
                                                : 'secondary'
                                        }
                                    >
                                        {lesson.lessonName}
                                    </Typography>
                                    <Typography variant="body2">-카테고리:{lesson.category}</Typography>
                                    <Typography display="inline" variant="body2">
                                        -수업일: {dayjs(lesson.startDate * 1000).format('YYYY-MM-DD(ddd) HH:mm')}{' '}
                                    </Typography>
                                    <Typography display="inline" color="error" variant="body2">
                                        {lesson.startDate < dayjs() / 1000 ? '(종료)' : null}
                                    </Typography>
                                    <br />
                                    <Typography display="inline" variant="body2">
                                        -신청자 : {lesson.enrolled > 0 ? lesson.enrolled : 0} / {lesson.capacity}{' '}
                                    </Typography>
                                    <Typography
                                        display="inline"
                                        variant="body2"
                                        color={lesson.enrolled > 0 ? 'primary' : 'initial'}
                                    >
                                        ({lesson.enrolled > 0 ? '등록' : '미등록'})
                                    </Typography>
                                    <br />
                                    <Typography variant="body2">
                                        -가격(정산):{lesson.price} ({lesson.settlement})
                                    </Typography>
                                    <Typography variant="body2" color={lesson.status === 'open' ? 'initial' : 'error'}>
                                        -상태:{lesson.status}
                                    </Typography>
                                    <Typography variant="body2">
                                        -수업일자: {JSON.stringify(lesson?.recurrentDates)}
                                    </Typography>
                                </Box>
                            ))}
                    </Box>
                </DialogContent>
                <DialogActions>
                    <Button variant="contained" onClick={(e) => setLessonOpen(false)}>
                        닫기
                    </Button>
                </DialogActions>
            </Dialog>
        </Box>
    );
};

export default withStyles(styles)(AdminLesson);

import React from 'react';
import { Link } from 'react-router-dom';
import dayjs from 'dayjs';
// redux stuff
import { useDispatch } from 'react-redux';
import { getEnrollProductGroup } from '../../redux/actions/enrollActions';
// component
import SmallImage from '../../images/main_small_5.png';
import Oneday1 from '../../images/oneday_1.png';
import Oneday2 from '../../images/oneday_2.png';
import Oneday3 from '../../images/oneday_3.png';
import Oneday4 from '../../images/oneday_4.png';
import Mgo1 from '../../images/mgo1.png';
import Mgo2 from '../../images/mgo2.png';
import Mgo3 from '../../images/mgo3.png';
import Mgo4 from '../../images/mgo4.png';
import LogoRounded from '../../images/logo_rounded.png';
import Slogan from '../../images/slogan.jpg';

import Event from '../../pages/Event';
// Mui stuff
import { Box, Typography, Paper, Grid, useMediaQuery, Divider, Chip, Link as MuiLink } from '@material-ui/core';
import { withStyles, useTheme } from '@material-ui/styles';
import { KeyboardArrowRightRounded } from '@material-ui/icons';
const styles = (theme) => ({
    ...theme.userPages,
    back: {
        backgroundColor: '#f9f9f9',
        height: 500,
        backgroundImage: `url(${SmallImage})`,
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center bottom',
        backgroundSize: '414px 400px',
        [theme.breakpoints.down('xs')]: {
            height: 400,
            backgroundRepeat: 'no-repeat',
            backgroundPosition: 'center bottom',
            backgroundSize: '414px 400px',
        },
    },
    header: {
        backgroundColor: '#fff',
    },
    whiteFixed: {
        display: 'block',
        backgroundColor: '#ffffff',
        paddingBottom: theme.spacing(1),
        paddingRight: theme.spacing(1),
        paddingLeft: theme.spacing(1),
        position: 'fixed',
        zIndex: 1000,

        [theme.breakpoints.up('sm')]: {
            right: 0,
            top: 118,
            width: 150,
            paddingTop: theme.spacing(3),
        },
        [theme.breakpoints.up('xl')]: {
            width: 200,
            right: 50,
            paddingTop: theme.spacing(3),
        },
        [theme.breakpoints.down('xs')]: {
            width: '100%',
            left: 0,
            bottom: 0,
            paddingTop: theme.spacing(1),
        },
    },
    halfBox: {
        backgroundColor: '#009eff',
        padding: theme.spacing(1),
        cursor: 'pointer',
    },
    halfBox2: {
        backgroundColor: '#f37745',
        padding: theme.spacing(1),
        cursor: 'pointer',
    },
    greenRound: {
        backgroundColor: theme.palette.secondary.main,
        borderRadius: 20,
        width: 200,
    },
    gogo4Img: {
        width: '90%',
    },
    peach: {
        backgroundColor: '#fae8dc',
    },
    borderleft: {
        border: 'solid 5px',
        borderColor: theme.palette.primary.main,
        borderRadius: 20,
        backgroundColor: '#fff',
        marginLeft: theme.spacing(5),

        [theme.breakpoints.down('xs')]: {
            marginLeft: theme.spacing(1),
            paddingTop: theme.spacing(3),
            height: 200,
        },
    },
    borderright: {
        border: 'solid 5px',
        borderColor: theme.palette.primary.main,
        borderRadius: 20,
        backgroundColor: '#fff',
        marginRight: theme.spacing(5),

        [theme.breakpoints.down('xs')]: {
            marginRight: theme.spacing(1),
            paddingTop: theme.spacing(3),
            height: 200,
        },
    },
    logorounded: {
        width: 150,
        height: 80,
        position: 'absolute',
        left: '50%',
        top: '50%',
        transform: 'translate(-50%, -60%)',
    },
    grayBack: {
        backgroundColor: '#e7e7e7',
    },
});

const WhiteTypo = withStyles({
    root: { color: '#ffffff' },
})(Typography);

function EnrollRegular(props) {
    const { classes } = props;
    const theme = useTheme();
    const matches = useMediaQuery(theme.breakpoints.down('xs'));
    const dispatch = useDispatch();

    const handleProduct = (name, productId, productGroupId, productGroupName, categoryName) => {
        localStorage.setItem('productName', name);
        localStorage.setItem('productId', productId);
        localStorage.setItem('productGroupId', productGroupId);
        localStorage.setItem('productGroupName', productGroupName);
        localStorage.setItem('categoryName', categoryName);
        dispatch(getEnrollProductGroup(productGroupId));
    };

    // 주문중단 기간
    const today = dayjs().format('YYYY-MM-DD HH:mm:ss');
    const sDt = dayjs('2022-01-01 00:00:00').format('YYYY-MM-DD HH:mm:ss');
    const eDt = dayjs('2022-01-24 00:00:00').format('YYYY-MM-DD HH:mm:ss');
    const closeYN = today >= sDt && today < eDt;
    const closeText = '러너 수업은 3월부터 진행되며, 3월 수강신청은 2월1일부터 시작됩니다.(~ 2월25일까지)';

    /* const tempMarkup = <Box m={3} p={10} align='center' className={classes.back}>
        <Typography variant='subtitle1'>3월 출시 예정입니다.</Typography>
    </Box>; */
    const realMarkup = (
        <Box>
            <Box p={3} m={1} className={classes.header} align="center">
                <Typography display="inline" variant="subtitle1">
                    현재 학원을 다니고 있는{' '}
                </Typography>
                <br />
                <Typography color="primary" display="inline" variant="subtitle1">
                    어린음악대 원생
                </Typography>
                <Typography display="inline" variant="subtitle1">
                    들을 위한
                </Typography>
                <br />
                <Typography color="primary" display="inline" variant="subtitle1">
                    뮤직플레이{' '}
                </Typography>
                <Typography display="inline" variant="subtitle1">
                    클래스입니다
                </Typography>
            </Box>
            <Event />
            {/* float */}
            <Paper className={classes.whiteFixed} align="center">
                {matches ? (
                    <Box>
                        <Typography variant="subtitle2">어린음악대 원생들을 위한</Typography>
                        <Typography color="primary" variant="subtitle2">
                            <b>뮤직플레이 클래스</b>
                        </Typography>
                        <Box height={5} align="center" />
                    </Box>
                ) : (
                    <Box>
                        <Typography color="primary" variant="subtitle2">
                            어린음악대
                        </Typography>
                        <Typography variant="subtitle2" display="inline" color="primary">
                            원생
                        </Typography>
                        <Typography variant="subtitle2" display="inline">
                            들을 위한
                        </Typography>
                        <br />
                        <Typography color="primary" variant="subtitle2">
                            <b>뮤직플레이 클래스</b>
                        </Typography>
                        <Box height={15} align="center" />
                    </Box>
                )}
                <Typography variant="subtitle2" display="inline">
                    {' '}
                    15,000원
                </Typography>
                <Typography variant="subtitle2" display="inline">
                    {' '}
                    (월4회)
                </Typography>
                <Box height={5} />
                {matches ? (
                    <Grid container direction="row">
                        <Grid item xs={6} component={closeYN ? 'div' : Link} to={closeYN ? null : '/enrollstep2'}>
                            <Box
                                p={1}
                                align="center"
                                style={{
                                    backgroundColor: '#f15624',
                                    cursor: 'pointer',
                                    marginLeft: theme.spacing(2),
                                    marginRight: theme.spacing(2),
                                }}
                                onClick={
                                    closeYN
                                        ? () => alert(closeText)
                                        : () =>
                                              handleProduct(
                                                  '뮤직플레이',
                                                  'JnzBWJx8oTwH9KLcWG0B',
                                                  'musicgogo',
                                                  '뮤직플레이',
                                                  '플레이어'
                                              )
                                }
                            >
                                <WhiteTypo variant="subtitle2">플레이어 (기초 단계) {closeYN && '(마감)'}</WhiteTypo>
                            </Box>
                        </Grid>
                        <Grid item xs={6} component={closeYN ? 'div' : Link} to={closeYN ? null : '/enrollstep2'}>
                            <Box
                                p={1}
                                align="center"
                                style={{
                                    backgroundColor: 'rgb(241, 136, 86)',
                                    cursor: 'pointer',
                                    marginLeft: theme.spacing(2),
                                    marginRight: theme.spacing(2),
                                }}
                                onClick={
                                    closeYN
                                        ? () => alert(closeText)
                                        : () =>
                                              handleProduct(
                                                  '뮤직플레이',
                                                  'JnzBWJx8oTwH9KLcWG0B',
                                                  'musicgogo',
                                                  '뮤직플레이',
                                                  '러너'
                                              )
                                }
                            >
                                <WhiteTypo variant="subtitle2">러너 (심화 단계) {closeYN && '(준비중)'}</WhiteTypo>
                            </Box>
                        </Grid>
                    </Grid>
                ) : (
                    <Box>
                        <Box m={1} component={closeYN ? 'div' : Link} to="/enrollstep2">
                            <Box
                                p={1}
                                align="center"
                                style={{ backgroundColor: '#f15624', cursor: 'pointer' }}
                                onClick={
                                    closeYN
                                        ? () => alert(closeText)
                                        : () =>
                                              handleProduct(
                                                  '뮤직플레이',
                                                  'JnzBWJx8oTwH9KLcWG0B',
                                                  'musicgogo',
                                                  '뮤직플레이',
                                                  '플레이어'
                                              )
                                }
                            >
                                <WhiteTypo variant="subtitle2">플레이어 신청</WhiteTypo>
                                <WhiteTypo variant="subtitle2">(기초 단계) {closeYN && '(마감)'}</WhiteTypo>
                            </Box>
                        </Box>
                        <Box m={1} component={closeYN ? 'div' : Link} to="/enrollstep2">
                            <Box
                                p={1}
                                align="center"
                                style={{ backgroundColor: 'rgb(241, 136, 86)', cursor: 'pointer' }}
                                onClick={
                                    closeYN
                                        ? () => alert(closeText)
                                        : () =>
                                              handleProduct(
                                                  '뮤직플레이',
                                                  'JnzBWJx8oTwH9KLcWG0B',
                                                  'musicgogo',
                                                  '뮤직플레이',
                                                  '러너'
                                              )
                                }
                            >
                                <WhiteTypo variant="subtitle2">러너 신청</WhiteTypo>
                                <WhiteTypo variant="subtitle2">(심화 단계) {closeYN && '(준비중)'}</WhiteTypo>
                            </Box>
                        </Box>
                    </Box>
                )}
            </Paper>
            {/* end of Float */}

            <Box p={1} m={1}>
                <Box height={30} />
                <Typography variant="h4">
                    <b>수업 정보</b>
                </Typography>
                <Divider light variant="middle" style={{ marginTop: 10, marginBottom: 10 }} />
                <Box height={10} />
                <Box p={1} m={1}>
                    <Grid container direction="row">
                        <Grid item>
                            <Typography color="primary" variant="subtitle1" gutterBottom>
                                수업 분량
                            </Typography>
                        </Grid>
                        <Grid item style={{ marginLeft: 20 }}>
                            <Grid container>
                                <Grid item>
                                    <Typography variant="subtitle1" style={{ width: 120 }}>
                                        플레이어(기초)
                                    </Typography>
                                </Grid>
                                <Grid item>
                                    <Typography variant="subtitle1"> 48차시 강의(12개월)</Typography>
                                </Grid>
                            </Grid>
                            <Grid container>
                                <Grid item>
                                    <Typography variant="subtitle1" style={{ width: 120 }}>
                                        러너(심화)
                                    </Typography>
                                </Grid>
                                <Grid item>
                                    <Typography variant="subtitle1"> 48차시 강의(12개월)</Typography>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Box>
                <Box p={1} m={1}>
                    <Grid container direction="row">
                        <Grid item>
                            <Typography color="primary" variant="subtitle1" gutterBottom>
                                수업 시간
                            </Typography>
                        </Grid>
                        <Grid item style={{ marginLeft: 20 }}>
                            <Typography variant="subtitle1">30~35분 </Typography>
                        </Grid>
                    </Grid>
                </Box>
                <Box p={1} m={1}>
                    <Grid container direction="row">
                        <Grid item>
                            <Typography color="primary" variant="subtitle1" gutterBottom>
                                수업 가능일
                            </Typography>
                        </Grid>
                        <Grid item style={{ marginLeft: 20 }}>
                            <Typography variant="subtitle1">전월 25일까지는 결제를 완료해주셔야합니다.</Typography>
                        </Grid>
                    </Grid>
                </Box>
                <Box p={1} m={1}>
                    <Grid container direction="row">
                        <Grid item>
                            <Typography color="primary" variant="subtitle1" gutterBottom>
                                학습 연령
                            </Typography>
                        </Grid>
                        <Grid item style={{ marginLeft: 20 }}>
                            <Typography variant="subtitle1">기초 단계부터 가능(유치/초등 가능) </Typography>
                        </Grid>
                    </Grid>
                </Box>

                <Box height={30} />
                <Typography variant="h4">
                    <b>교육 목표</b>
                </Typography>
                <Divider light variant="middle" style={{ marginTop: 10, marginBotton: 10 }} />
                <Box height={10} />
                <Box m={1} p={1}>
                    <Box>
                        <Typography variant="subtitle1" color="primary" gutterBottom>
                            1.소리와 음에 대한 새로운 발견과 경험의 다양한 접근방법을 제시합니다.
                        </Typography>
                        <Typography variant="body2" gutterBottom>
                            {' '}
                            - 다양한 악기를 제시하고, 아이들로 하여금 새로운 연주 방법들을 발견하도록 합니다.
                        </Typography>
                        <Typography variant="body2" gutterBottom>
                            {' '}
                            - 주변에 있는 물건으로 악기를 만들어 보고, 새로운 소리를 경험하도록 합니다.
                        </Typography>
                    </Box>
                    <Box>
                        <Typography variant="subtitle1" color="primary" gutterBottom>
                            2.아이의 독창성을 인정하면서 주변의 목소리에 귀 기울이는 사회 정서를 발달시킵니다.
                        </Typography>
                        <Typography variant="body2" gutterBottom>
                            {' '}
                            - 자기 조절 능력을 발휘하고, 모든 감정을 경험, 탐색하는 능력을 키워줍니다.
                        </Typography>
                        <Typography variant="body2" gutterBottom>
                            {' '}
                            - 아이의 자존감과 공동체 의식을 키워, 공통체와 소통하고 협동하는 긍정적인 또래 관계를
                            경험하게 합니다.
                        </Typography>
                    </Box>
                    <Box>
                        <Typography variant="subtitle1" color="primary" gutterBottom>
                            3.체험하는 놀이수업과 이론수업을 병행함으로써 이해력과 성취도를 높여 창의성을 키웁니다.
                        </Typography>
                        <Typography variant="body2" gutterBottom>
                            {' '}
                            - 음악교구를 활용한 자기주도적 학습으로 두뇌 발달에 도움을 주고 융합적인 사고능력을
                            키웁니다.
                        </Typography>
                        <Typography variant="body2" gutterBottom>
                            {' '}
                            - 오감을 자극하고 반응하며 추상적인 개념을 구체화하는 다감각적 학습을 경험하고, 신체활동을
                            통해 음악 개념과 용어를 배웁니다.
                        </Typography>
                    </Box>
                    <Box>
                        <Typography variant="subtitle1" color="primary" gutterBottom>
                            4.시대별, 문화별, 영역별 감상 활동을 통해 음악적 공감대를 넓힙니다.
                        </Typography>
                        <Typography variant="body2" gutterBottom>
                            {' '}
                            - 시대화 문화, 장르를 모두 아우르는 폭넓은 음악 감상과 경험의 기회를 제공합니다.
                        </Typography>
                    </Box>
                </Box>

                <Box height={30} />
                <Typography variant="h4">
                    <b>플레이어(기초 단계) 수업 </b>
                </Typography>
                <Divider light variant="middle" style={{ marginTop: 10, marginBotton: 10 }} />
                <Box height={10} />
                <Box alignItems="center" display="flex">
                    <KeyboardArrowRightRounded />
                    <Typography variant="subtitle1">수업 특징</Typography>
                </Box>
                <Box p={1} m={1}>
                    <Typography variant="body1">
                        · 음악의 여러 영역을 폭넓고 자유롭게 활동하는 열정적인 음악가를 위한 감성, 인성 성장의 음악 표현
                        수업
                    </Typography>
                    <Typography variant="body1">
                        · 음악 용어와 기초적인 음악적 개념을 소개함으로서 음악 교육의 기초를 형성
                    </Typography>
                </Box>

                <Box alignItems="center" display="flex">
                    <KeyboardArrowRightRounded />
                    <Typography variant="subtitle1">수업 내용</Typography>
                </Box>
                <Box height={10} />
                <Box p={1} m={1}>
                    <blockquote>
                        <Typography variant="subtitle1">
                            상상력이 풍부한 아이들을 위한 4가지 영역(악기연주, 신체활동, 음악이론, 감상&표현)의 세부
                            활동으로 오감 자극
                        </Typography>
                        <br />
                        <Typography variant="subtitle1">
                            유아의 눈높이에 맞추어진 어린음악대 만의 음원과 워크북으로 재미난 수업 진행
                        </Typography>
                        <br />
                        <Typography variant="subtitle1">
                            수업의 활동과 조화롭게 짜여진 1인교구를 아이들이 직접 조작해 보면서 수업 집중도와 흥미를
                            높임
                        </Typography>
                    </blockquote>
                </Box>
                <Box height={30} />

                <Typography variant="h4" display="inline">
                    <b>러너 (심화 단계) 수업</b>
                </Typography>

                <Divider light variant="middle" style={{ marginTop: 10, marginBotton: 10 }} />
                <Box height={10} />
                <Box alignItems="center" display="flex">
                    <KeyboardArrowRightRounded />
                    <Typography variant="subtitle1">수업 특징</Typography>
                </Box>
                <Box p={1} m={1}>
                    <Typography variant="body1">
                        · 플레이어 심화과정으로 통합적 사고 및 이해를 가진 탁월한 음악가를 위한 교과 과정 연계의 음악
                        체험 수업
                    </Typography>
                    <Typography variant="body1">
                        · 음악 기호, 음악 형식, 작곡 놀이, 국악 등의 추가로 더욱 체계적 학습이 가능한 기초 심화 음악
                        프로그램
                    </Typography>
                </Box>

                <Box alignItems="center" display="flex">
                    <KeyboardArrowRightRounded />
                    <Typography variant="subtitle1">수업 내용</Typography>
                </Box>
                <Box height={10} />
                <Box p={1} m={1}>
                    <blockquote>
                        <Typography variant="subtitle1">
                            논리적인 사고력이 커지는 아이들을 위한 4가지 영역(악기연주, 신체활동, 음악이론, 감상&표현)의
                            전문적인 음악교육 프로그램
                        </Typography>
                        <br />
                        <Typography variant="subtitle1">
                            통합 교과서와 연계된 음악, 이론, 활동을 어린음악대 만의 커리큘럼과 워크북으로 음악적 기초를
                            더욱 단단히 다지는 심화 과정
                        </Typography>
                        <br />
                        <Typography variant="subtitle1">
                            수업의 활동과 조화롭게 짜여진 1인교구를 아이들이 직접 조작해 보면서 수업 집중도와 흥미를
                            높임
                        </Typography>
                    </blockquote>
                </Box>
            </Box>
            <Box height={40} />
            <Box align="center">
                <Box align="center" className={classes.greenRound}>
                    <WhiteTypo variant="subtitle1">뮤직플레이 교육 효과</WhiteTypo>
                </Box>
                <Box height={15} />
                <Typography variant="h4" color="secondary" display="inline">
                    창의력
                </Typography>
                <Typography variant="h4" display="inline">
                    을 갖춘 아이로
                </Typography>
                <br />
                <Typography variant="h4" display="inline">
                    말랑말랑한{' '}
                </Typography>
                <Typography variant="h4" display="inline" color="primary">
                    우뇌
                </Typography>
                <Typography variant="h4" display="inline">
                    로,
                </Typography>
                <Typography variant="h4">미래형 인재를 만드는 최고의 교육</Typography>
            </Box>
            <Box>
                <Grid container direction="row">
                    <Grid item xs={6} sm={3}>
                        <Box align="center" p={2} m={1}>
                            <img src={Oneday1} alt="instru" className={classes.gogo4Img} />
                            <Chip color="secondary" label="악기연주" />
                        </Box>
                    </Grid>
                    <Grid item xs={6} sm={3}>
                        <Box align="center" p={2} m={1}>
                            <img src={Oneday2} alt="instru" className={classes.gogo4Img} />
                            <Chip color="primary" label="신체활동" />
                        </Box>
                    </Grid>
                    <Grid item xs={6} sm={3}>
                        <Box align="center" p={2} m={1}>
                            <img src={Oneday3} alt="instru" className={classes.gogo4Img} />
                            <Chip color="secondary" label="음악이론" />
                        </Box>
                    </Grid>
                    <Grid item xs={6} sm={3}>
                        <Box align="center" p={2} m={1}>
                            <img src={Oneday4} alt="instru" className={classes.gogo4Img} />
                            <Chip color="primary" label="감상&표현" />
                        </Box>
                    </Grid>
                </Grid>
            </Box>

            <Box className={classes.peach} align="center">
                <Box height={50} />
                <Typography variant="h4">
                    <b>음악을 특별하게 만나는 시간!</b>
                </Typography>
                <Typography display="inline" color="primary" variant="h4">
                    <b>MUSIC </b>
                </Typography>
                <Typography display="inline" color="secondary" variant="h4">
                    <b> GOGO</b>
                </Typography>
                <Typography display="inline" variant="h4">
                    <b> 포인트</b>
                </Typography>
                <Box height={20} />
                <Grid container direction="row" style={{ position: 'relative' }}>
                    <Grid item xs={6}>
                        <Box align="center" p={matches ? 1 : 2} m={1} className={classes.borderleft}>
                            <Typography color="primary" variant="subtitle1" display="inline">
                                놀이
                            </Typography>
                            <Typography variant="subtitle1" display="inline">
                                로 만나는 음악
                            </Typography>
                            <Box height={10} />
                            {matches ? (
                                <Box>
                                    <Typography variant="body2">재밌고! 신나고!</Typography>
                                    <Typography variant="body2">즐겁고!</Typography>
                                    <Typography variant="body2">특별한</Typography>
                                    <Typography variant="body2">음률체험교실에서</Typography>
                                    <Typography variant="body2">음악을 느껴보는</Typography>
                                    <Typography variant="body2">시간</Typography>
                                </Box>
                            ) : (
                                <Box>
                                    <Typography variant="body2">재밌고! 신나고! 즐겁고!</Typography>
                                    <Typography variant="body2">특별한 음률체험교실에서</Typography>
                                    <Typography variant="body2">음악을 느껴보는 시간</Typography>
                                </Box>
                            )}
                        </Box>
                    </Grid>
                    <Grid item xs={6}>
                        <Box align="center" p={matches ? 1 : 2} m={1} className={classes.borderright}>
                            <Typography color="primary" variant="subtitle1" display="inline">
                                깊게
                            </Typography>
                            <Typography variant="subtitle1" display="inline">
                                {' '}
                                만나는 음악
                            </Typography>
                            <Box height={10} />
                            {matches ? (
                                <Box>
                                    <Typography variant="body2">음악 전 영역</Typography>
                                    <Typography variant="body2">누리과정과</Typography>
                                    <Typography variant="body2">음악교과와 </Typography>
                                    <Typography variant="body2">연계하여</Typography>
                                    <Typography variant="body2">깊게 만나는 시간</Typography>
                                </Box>
                            ) : (
                                <Box>
                                    <Typography variant="body2">음악 전 영역 누리과정과</Typography>
                                    <Typography variant="body2">음악교과와 연계하여</Typography>
                                    <Typography variant="body2">깊게 만나는 시간</Typography>
                                </Box>
                            )}
                        </Box>
                    </Grid>
                    <Grid item xs={6}>
                        <Box align="center" p={matches ? 1 : 2} m={1} className={classes.borderleft}>
                            <Typography color="primary" variant="subtitle1" display="inline">
                                경험
                            </Typography>
                            <Typography variant="subtitle1" display="inline">
                                으로 만나는 음악
                            </Typography>
                            <Box height={10} />

                            <Typography variant="body2">4가지 영역으로</Typography>
                            <Typography variant="body2">음악을 </Typography>
                            <Typography variant="body2">다양하게</Typography>
                            <Typography variant="body2">경험해보는 시간</Typography>
                        </Box>
                    </Grid>
                    <Grid item xs={6}>
                        <Box align="center" p={matches ? 1 : 2} m={1} className={classes.borderright}>
                            <Typography color="primary" variant="subtitle1" display="inline">
                                온+오프라인
                            </Typography>
                            <Typography variant="subtitle1" display="inline">
                                으로{' '}
                            </Typography>
                            <Typography variant="subtitle1">만나는 음악</Typography>
                            <Box height={10} />
                            {matches ? (
                                <Box>
                                    <Typography variant="body2">영상 속의 </Typography>
                                    <Typography variant="body2">소리쌤을 만나며</Typography>
                                    <Typography variant="body2">아이 스스로</Typography>
                                    <Typography variant="body2">성장하는 시간</Typography>
                                </Box>
                            ) : (
                                <Box>
                                    <Typography variant="body2">영상 속의 소리쌤을 만나며</Typography>
                                    <Typography variant="body2">아이 스스로</Typography>
                                    <Typography variant="body2">성장하는 시간</Typography>
                                </Box>
                            )}
                        </Box>
                    </Grid>
                    <img src={LogoRounded} alt="logo" className={classes.logorounded} />
                </Grid>
                <Box height={30} />
            </Box>

            <Box align="center" className={classes.grayBack}>
                <Box height={40} />
                {matches ? (
                    <Box>
                        <Typography variant="h4">어린음악대 회원들만</Typography>
                        <Typography variant="h4">누리는 특별한 수업</Typography>
                        <Typography color="primary" variant="h4" display="inline">
                            "뮤직플레이"{' '}
                        </Typography>
                        <Typography variant="h4" display="inline">
                            {' '}
                            구성
                        </Typography>
                    </Box>
                ) : (
                    <Box>
                        <Typography variant="h4">어린음악대 회원들만 누리는 특별한 수업</Typography>
                        <Typography color="primary" variant="h4" display="inline">
                            "뮤직플레이"{' '}
                        </Typography>
                        <Typography variant="h4" display="inline">
                            {' '}
                            구성
                        </Typography>
                    </Box>
                )}
                <Box height={20} />
                <Grid container direction="row">
                    <Grid item xs={12} sm={4}>
                        <Box p={2} m={1} align="center" style={{ backgroundColor: '#fff' }}>
                            <img src={Mgo1} alt="comp" className={classes.gogo4Img} />

                            <Box height={10} />
                            <Typography color="primary" variant="subtitle1" gutterBottom>
                                워크북(플레이어/러너)
                            </Typography>
                            <Typography variant="body2">단계별로 제공되는</Typography>
                            <Typography variant="body2">워크북</Typography>
                        </Box>
                    </Grid>
                    <Grid item xs={12} sm={4}>
                        <Box p={2} m={1} align="center" style={{ backgroundColor: '#fff' }}>
                            <img src={Mgo2} alt="comp" className={classes.gogo4Img} />

                            <Box height={10} />
                            <Typography color="primary" variant="subtitle1" gutterBottom>
                                온라인 강의
                            </Typography>
                            <Typography variant="body2">온라인 강의 +</Typography>
                            <Typography variant="body2">오프라인 수업 진행</Typography>
                        </Box>
                    </Grid>
                    <Grid item xs={12} sm={4}>
                        <Box p={2} m={1} align="center" style={{ backgroundColor: '#fff' }}>
                            <img src={Mgo3} alt="comp" className={classes.gogo4Img} />

                            <Box height={10} />
                            <Typography color="primary" variant="subtitle1" gutterBottom>
                                1인 교구
                            </Typography>
                            <Typography variant="body2">1인교구로 다양한 음악 </Typography>
                            <Typography variant="body2">영역을 배우는 시간 </Typography>
                        </Box>
                    </Grid>
                </Grid>
                <Box height={30} />
            </Box>
            <Box p={2} style={{ backgroundColor: '#fff' }} align="center">
                <img src={Mgo4} alt="musicgogo" style={{ maxWidth: 500, width: '100%' }} />
            </Box>
            <Box height={30} />

            <Box m={1} p={1}>
                <Typography variant="h4" color="secondary">
                    <b>[플레이어 커리큘럼] 12개월 연간 48차시 </b>
                </Typography>
            </Box>
            <Box p={1} m={1}>
                <Grid container direction="row">
                    <Grid item xs={12} sm={6}>
                        <blockquote>
                            <Typography variant="subtitle1">01</Typography>
                            <Typography variant="body2">①악기연주-악기 연주를 해보자</Typography>
                            <Typography variant="body2">②신체활동-내 몸이 악기 쿵쿵</Typography>
                            <Typography variant="body2">③음악이론-음표쉼표 컵쌍기</Typography>
                            <Typography variant="body2">④감상&표현-감상을 하려면</Typography>
                            <br />
                            <Typography variant="subtitle1">02</Typography>
                            <Typography variant="body2">①악기연주-소리영역 ①여리게 세게</Typography>
                            <Typography variant="body2">②신체활동-표현법 ①리본막대</Typography>
                            <Typography variant="body2">③음악이론-찍찍이교구(계이름,덧줄,덧칸)</Typography>
                            <Typography variant="body2">④감상&표현-동물의 사육제(1)</Typography>
                            <br />
                            <Typography variant="subtitle1">03</Typography>
                            <Typography variant="body2">①악기연주-소리영역②느리게 빠르게</Typography>
                            <Typography variant="body2">②신체활동-표현법 ②스카프</Typography>
                            <Typography variant="body2">③음악이론-포켓교구로 활동해요</Typography>
                            <Typography variant="body2">④감상&표현-동물의 사육제(2)</Typography>
                            <br />
                            <Typography variant="subtitle1">04</Typography>
                            <Typography variant="body2">①악기연주-소리영역③높은/낮은 소리</Typography>
                            <Typography variant="body2">②신체활동-발바닥 스티커(음표,쉼표)</Typography>
                            <Typography variant="body2">③음악이론-큰보표와 음과 건반 위치</Typography>
                            <Typography variant="body2">④감상&표현-해님과 바람</Typography>
                            <br />
                            <Typography variant="subtitle1">05</Typography>
                            <Typography variant="body2">①악기연주-소리영역④짧은 소리 긴 소리</Typography>
                            <Typography variant="body2">②신체활동-발건반 위로 폴짝</Typography>
                            <Typography variant="body2">③음악이론-나타냄말,빠르기말</Typography>
                            <Typography variant="body2">④감상&표현-호두까기인형</Typography>
                            <br />
                            <Typography variant="subtitle1">06</Typography>
                            <Typography variant="body2">①악기연주-리듬악기를 연주해요</Typography>
                            <Typography variant="body2">②신체활동-커다란 발오선</Typography>
                            <Typography variant="body2">③음악이론-계이름 음이름 낚시</Typography>
                            <Typography variant="body2">④감상&표현-어떻게 표현할까요?</Typography>
                        </blockquote>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <blockquote>
                            <Typography variant="subtitle1">07</Typography>
                            <Typography variant="body2">①악기연주-악기를 만들자</Typography>
                            <Typography variant="body2">②신체활동-발바닥스티커(계이름,음이름,셈여림)</Typography>
                            <Typography variant="body2">③음악이론-음표와 쉼표는 친구</Typography>
                            <Typography variant="body2">④감상&표현-나무와 새(1)</Typography>
                            <br />
                            <Typography variant="subtitle1">08</Typography>
                            <Typography variant="body2">①악기연주-박자이해①4박자</Typography>
                            <Typography variant="body2">②신체활동-동물농장</Typography>
                            <Typography variant="body2">③음악이론-찍찍이교구(조표)</Typography>
                            <Typography variant="body2">④감상&표현-나무와 새(2)</Typography>
                            <br />
                            <Typography variant="subtitle1">09</Typography>
                            <Typography variant="body2">①악기연주-박자이해②3박자</Typography>
                            <Typography variant="body2">②신체활동-전래동요&전래놀이</Typography>
                            <Typography variant="body2">③음악이론-플래시카드 모여라 </Typography>
                            <Typography variant="body2">④감상&표현-시대별 작곡가(바흐)</Typography>
                            <br />
                            <Typography variant="subtitle1">10</Typography>
                            <Typography variant="body2">①악기연주-박자이해③2박자</Typography>
                            <Typography variant="body2">②신체활동-발건반에서 함께 연주해요</Typography>
                            <Typography variant="body2">③음악이론-음악을 표현해보기</Typography>
                            <Typography variant="body2">④감상&표현-시대별 작곡가(베토벤)</Typography>
                            <br />
                            <Typography variant="subtitle1">11</Typography>
                            <Typography variant="body2">①악기연주-우리나라의 국아기</Typography>
                            <Typography variant="body2">②신체활동-발오선과 음높이</Typography>
                            <Typography variant="body2">③음악이론-찍찍이교구(코드)</Typography>
                            <Typography variant="body2">④감상&표현-시대별 작곡가(모차르트)</Typography>
                            <br />
                            <Typography variant="subtitle1">12</Typography>
                            <Typography variant="body2">①악기연주-피아노를 연주해요</Typography>
                            <Typography variant="body2">②신체활동-음악 퀴즈시간!</Typography>
                            <Typography variant="body2">③음악이론-게임으로 배우는 음표쉼표</Typography>
                            <Typography variant="body2">④감상&표현-시대별 작곡가(슈베르트)</Typography>
                        </blockquote>
                    </Grid>
                </Grid>
            </Box>
            <Box height={20} />
            <Box m={1} p={1}>
                <Typography variant="h4" color="secondary">
                    <b>[러너 커리큘럼] 12개월 연간 48차시 </b>
                </Typography>
            </Box>
            <Box p={1} m={1}>
                <Grid container direction="row">
                <Grid item xs={12} sm={6}>
                        <blockquote>
                            
                            <Typography variant="subtitle1">03월 (워크북2단계 1-1)</Typography>
                            <Typography variant="body2">①악기연주-악기 연주를 해보자</Typography>
                            <Typography variant="body2">②신체활동-내 몸이 악기 쿵쿵</Typography>
                            <Typography variant="body2">③음악이론-음표쉼표 컵쌓기</Typography>
                            <Typography variant="body2">④감상&표현-감상을 하려면</Typography>
                            <br />
                            <Typography variant="subtitle1">04월 (워크북2단계 1-2)</Typography>
                            <Typography variant="body2">①악기연주-소리영역①여리게 세게</Typography>
                            <Typography variant="body2">②신체활동-표현법①리본막대</Typography>
                            <Typography variant="body2">③음악이론-찍찍이교구(계이름,덧줄,덧칸) </Typography>
                            <Typography variant="body2">④감상&표현-동물의 사육제(1)</Typography>
                            <br />
                            <Typography variant="subtitle1">05월 (워크북2단계 1-3)</Typography>
                            <Typography variant="body2">①악기연주-소리영역②느리게 빠르게</Typography>
                            <Typography variant="body2">②신체활동-표현법②스카프</Typography>
                            <Typography variant="body2">③음악이론-포켓교구로 활동해요</Typography>
                            <Typography variant="body2">④감상&표현-동물의 사육제(2)</Typography>
                            <br />
                            <Typography variant="subtitle1">06월 (워크북2단계 2-1)</Typography>
                            <Typography variant="body2">①악기연주-소리영역③높은/낮은소리</Typography>
                            <Typography variant="body2">②신체활동-발바닥 스티커(음표,쉼표)</Typography>
                            <Typography variant="body2">③음악이론-큰보표와 음과 건반위치</Typography>
                            <Typography variant="body2">④감상&표현-해님과 바람</Typography>
                            <br />
                            <Typography variant="subtitle1">07월 (워크북2단계 2-2)</Typography>
                            <Typography variant="body2">①악기연주-소리영역④짧은소리 긴소리</Typography>
                            <Typography variant="body2">②신체활동-발건반 위로 폴짝</Typography>
                            <Typography variant="body2">③음악이론-나타냄말, 빠르기말</Typography>
                            <Typography variant="body2">④감상&표현-호두까기 인형</Typography>
                            <br />
                            <Typography variant="subtitle1">※여름(08월), 겨울(01월) 방학에는 특별 주제로 진행됩니다.</Typography>
                        </blockquote>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <blockquote>
                            <Typography variant="subtitle1">09월 (워크북2단계 2-3)</Typography>
                            <Typography variant="body2">①악기연주-리듬악기를 연주해요</Typography>
                            <Typography variant="body2">②신체활동-커다란 발오선</Typography>
                            <Typography variant="body2">③음악이론-계이름 음이름 낚시</Typography>
                            <Typography variant="body2">④감상&표현-어떻게 표현할까요?</Typography>
                            <br />
                            <Typography variant="subtitle1">10월 (워크북2단계 3-1)</Typography>
                            <Typography variant="body2">①악기연주-악기를 만들자</Typography>
                            <Typography variant="body2">②신체활동-발바닥스티커(계이름,음이름,셈여림)</Typography>
                            <Typography variant="body2">③음악이론-음표와 쉼표는 친구</Typography>
                            <Typography variant="body2">④감상&표현-나무와 새</Typography>
                            <br />
                            <Typography variant="subtitle1">11월 (워크북2단계 3-3)</Typography>
                            <Typography variant="body2">①악기연주-박자이해②3박자</Typography>
                            <Typography variant="body2">②신체활동-전래동요&전래놀이</Typography>
                            <Typography variant="body2">③음악이론-플래시카드 모여라</Typography>
                            <Typography variant="body2">④감상&표현-시대별 작곡가(바흐)</Typography>
                            <br />
                            <Typography variant="subtitle1">12월 (워크북2단계 4-1)</Typography>
                            <Typography variant="body2">①악기연주-박자이해③2박자</Typography>
                            <Typography variant="body2">②신체활동-발건반에서 함께 연주해요</Typography>
                            <Typography variant="body2">③음악이론-음악을 표현해보기</Typography>
                            <Typography variant="body2">④감상&표현-시대별 작곡가(베토벤)</Typography>
                            <br />
                            <Typography variant="subtitle1">02월 (워크북2단계 4-2)</Typography>
                            <Typography variant="body2">①악기연주-우리나라의 국악기</Typography>
                            <Typography variant="body2">②신체활동-발오선과 음높이</Typography>
                            <Typography variant="body2">③음악이론-찍찍이교구(코드)</Typography>
                            <Typography variant="body2">④감상&표현-시대별 작곡가(모차르트)</Typography>
                        </blockquote>
                    </Grid>
                </Grid>
                <Box style={{ marginLeft: 30 }}>
                    <Typography variant="body2">
                        ※ 자세한 내용은 <MuiLink href="/gogo/curriculum">뮤직플레이-연간교육계획안</MuiLink> 메뉴에서
                        참고하시기바랍니다.
                    </Typography>
                </Box>
                <Box height={30} />
                <Box>
                    <img src={Slogan} alt="slogan" style={{ width: '100%' }} />
                </Box>
            </Box>
        </Box>
    );

    return <Box>{realMarkup}</Box>;
}
export default withStyles(styles)(EnrollRegular);

import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import dayjs from 'dayjs';
// Redux stuff
import { useDispatch } from 'react-redux';
import { getAllEnrollProductGroupAll, getEnrollProductGroup } from '../../redux/actions/enrollActions';
// components
import MusicgogoLogo from '../../logo.svg';
//import LittleBandLogo from '../../images/littleband_logo.png';
import PianogogoLogo from '../../images/pianogogo_logo.png';
import Prod2 from '../../images/product_2.png';
import Prod3 from '../../images/product_3.png';
import Prod1 from '../../images/product_1.png';
import Prod4 from '../../images/product_4.png';

// MUI stuff
import withStyles from '@material-ui/core/styles/withStyles';
//import useTheme from '@material-ui/core/styles/useTheme';
import { Grid, Box, Typography, Button } from '@material-ui/core';

const styles = (theme) => ({
    ...theme.userPage,
    root: {
        paddingLeft: theme.spacing(1),
        paddingRight: theme.spacing(1),
    },
    imageBox1: {
        width: '100%',
        height: 300,
        backgroundImage: `url(${Prod2})`,
        backgroundSize: 'cover',
        backgroundPosition: 'center center',
        [theme.breakpoints.down('xs')]: {
            height: 200,
        },
    },
    imageBox2: {
        width: '100%',
        height: 300,
        backgroundImage: `url(${Prod3})`,
        backgroundPosition: 'center center',
        backgroundSize: 'cover',
        [theme.breakpoints.down('xs')]: {
            height: 200,
        },
    },
    imageBox3: {
        width: '100%',
        height: 300,
        backgroundImage: `url(${Prod1})`,
        backgroundPosition: 'center center',
        backgroundSize: 'cover',
        [theme.breakpoints.down('xs')]: {
            height: 200,
        },
    },
    imageBox4: {
        width: '100%',
        height: 300,
        backgroundImage: `url(${Prod4})`,
        backgroundPosition: 'center top',
        backgroundSize: 'cover',
        [theme.breakpoints.down('xs')]: {
            height: 200,
        },
    },
    littleBandLogo: {
        position: 'absolute',
        left: '50%',
        top: '48%',
        width: 100,
        height: 48.5,
        transform: 'translate(-50%, -50%)',
    },
    grad1: {
        width: '100%',
        height: 300,
        paddingTop: 75,
        backgroundImage:
            'linear-gradient(to bottom, rgba(76,76,76,0.8), rgba(18,18,18,0.9)), url("https://picsum.photos/400/300")',
        [theme.breakpoints.down('xs')]: {
            height: 200,
            paddingTop: 25,
        },
    },
    grad2: {
        width: '100%',
        height: 300,
        paddingTop: 75,
        backgroundImage:
            'linear-gradient(to bottom, rgba(172,66,180,0.9), rgba(172,66,180,0.9)), url("https://picsum.photos/400/300")',
        [theme.breakpoints.down('xs')]: {
            height: 200,
            paddingTop: 25,
        },
    },
    grad3: {
        width: '100%',
        height: 300,
        paddingTop: 75,
        backgroundColor: 'rgba(241, 86, 36,0.8)',
        [theme.breakpoints.down('xs')]: {
            height: 200,
            paddingTop: 25,
        },
    },
    grad4: {
        width: '100%',
        height: 300,
        paddingTop: 75,
        backgroundImage:
            'linear-gradient(to bottom, rgba(54, 175, 220, 0.9), rgba(54, 175, 220, 0.9)), url("https://picsum.photos/400/300")',
        [theme.breakpoints.down('xs')]: {
            height: 200,
            paddingTop: 25,
        },
    },

    gradPurple1: {
        backgroundColor: '#ac42b4',
    },
    paddings: {
        padding: theme.spacing(3),
        backgroundColor: '#ffffff',
        marginTop: theme.spacing(2),
        [theme.breakpoints.down('xs')]: {
            padding: theme.spacing(1),
            marginTop: theme.spacing(1),
        },
    },
    boxes: {
        height: 90,
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        cursor: 'pointer',
    },
    textBox: {
        height: 210,
        paddingLeft: 5,
        [theme.breakpoints.down('xs')]: {
            height: 250,
        },
    },
    button: {
        marginTop: 27,
        padding: '5px 25px',
    },
    buttonTopShort: {
        marginTop: 10,
        padding: '5px 25px',
    },
    priceTag: {
        position: 'absolute',
        top: 170,
        left: 5,
        [theme.breakpoints.down('xs')]: {
            top: 210,
        },
    },
    event: {
        position: 'absolute',
        top: -10,
    },
});
const YellowTypo = withStyles({
    root: { color: '#ffcc00' },
})(Typography);
const WhiteTypo = withStyles({
    root: { color: '#ffffff' },
})(Typography);
const PurpleTypo = withStyles({
    root: { color: '#ac42b4' },
})(Typography);
const SkyTypo = withStyles({
    root: { color: 'rgb(54, 175, 220)' },
})(Typography);

export const ProductGroup = (props) => {
    const { classes } = props;
    //const { productGroupAll } = useSelector(state => state.enroll);
    const dispatch = useDispatch();
    //const theme = useTheme();
    //const matches = useMediaQuery(theme.breakpoints.down('xs'));

    useState(() => {
        dispatch(getAllEnrollProductGroupAll());
    }, [dispatch]);

    const handleProduct = (name, productId, productGroupId, productGroupName, categoryName) => {
        localStorage.setItem('productName', name);
        localStorage.setItem('productId', productId);
        localStorage.setItem('productGroupId', productGroupId);
        localStorage.setItem('productGroupName', productGroupName);
        localStorage.setItem('categoryName', categoryName);
        dispatch(getEnrollProductGroup(productGroupId));
    };

    /// 주문중단 기간
    const today = dayjs().format('YYYY-MM-DD HH:mm:ss');
    const sDt = dayjs('2022-01-01 00:00:00').format('YYYY-MM-DD HH:mm:ss');
    const eDt = dayjs('2022-01-24 00:00:00').format('YYYY-MM-DD HH:mm:ss');
    const closeYN = today >= sDt && today < eDt;
    const closeText = '러너 수업은 3월부터 진행되며, 3월 수강신청은 2월1일부터 시작됩니다.(~ 2월25일까지)';

    return (
        <Box className={classes.root} m={1}>
            {/* 뮤직플레이 정규 */}
            <Grid container spacing={2} className={classes.paddings}>
                <Grid container item direction="row" xs={12} sm={6} justifyContent="center" className={classes.paddings}>
                    <Grid item xs={6}>
                        <Box className={classes.imageBox3} style={{ position: 'relative' }}>
                            {/* <img src={LittleBandLogo} alt="logo" className={classes.littleBandLogo} /> */}
                        </Box>
                    </Grid>
                    <Grid item xs={6}>
                        <Box className={classes.grad3} align="center">
                            <WhiteTypo variant="subtitle1">뮤직플레이</WhiteTypo>
                            <Box height={20} />
                            <img src={MusicgogoLogo} alt="logo" width="80px" height="39px" />
                            <br />
                            <Button
                                color="secondary"
                                size="small"
                                variant="contained"
                                className={classes.button}
                                component={Link}
                                to="/enroll/regular"
                            >
                                자세히보기
                            </Button>
                        </Box>
                    </Grid>
                </Grid>
                <Grid container item xs={12} sm={6} className={classes.paddings}>
                    <Grid item xs={12} style={{ position: 'relative' }}>
                        <Box className={classes.textBox}>
                            <Typography color="primary" variant="h5" gutterBottom style={{ fontWeight: 'bold' }}>
                                뮤직플레이
                            </Typography>
                            <Typography variant="subtitle2">회원 대상</Typography>
                            <Typography variant="body2">
                                - 어린음악대에서 피아노 레슨을 받는 학생만 신청가능합니다.
                            </Typography>
                            <Typography variant="body2">- 어린음악대 음악학원에서 진행되는 수업입니다.</Typography>
                            <Typography variant="body2" color="primary">
                                - <b>워크북, 1인교구가 포함</b>되어 있습니다.
                            </Typography>
                            <Typography variant="body2" color="primary">
                                - 6개월, 12개월 정기결제가 가능합니다.
                            </Typography>
                        </Box>
                        <Box className={classes.priceTag}>
                            <Typography variant="h5" display="inline">
                                월 15,000원
                            </Typography>
                            <Typography variant="body1" display="inline">
                                {' '}
                                (월 4회)
                            </Typography>
                        </Box>
                    </Grid>
                    <Grid container item direction="row" xs={12} spacing={2}>
                        <Grid item xs={6} component={closeYN ? 'div' : Link} to="/enrollstep2">
                            <Box
                                className={classes.boxes}
                                style={{ backgroundColor: 'rgb(241, 86, 36)' }}
                                onClick={
                                    closeYN
                                        ? () => alert(closeText)
                                        : () =>
                                              handleProduct(
                                                  '뮤직플레이 (플레이어)',
                                                  'DzbNwY2Bz5uPXP5r2sX6',
                                                  'musicgogo',
                                                  '뮤직플레이',
                                                  '플레이어'
                                              )
                                }
                            >
                                <WhiteTypo variant="subtitle1">플레이어 수업신청 {closeYN && '(마감)'}</WhiteTypo>
                                <WhiteTypo variant="body2">(기초 단계)</WhiteTypo>
                            </Box>
                        </Grid>
                        <Grid item xs={6} component={closeYN ? 'div' : Link} to="/enrollstep2">
                            <Box
                                className={classes.boxes}
                                style={{ backgroundColor: 'rgb(241, 136, 86)' }}
                                onClick={
                                    closeYN
                                        ? () => alert(closeText)
                                        : () =>
                                              handleProduct(
                                                  '뮤직플레이 (러너)',
                                                  'DzbNwY2Bz5uPXP5r2sX6',
                                                  'musicgogo',
                                                  '뮤직플레이',
                                                  '러너'
                                              )
                                }
                            >
                                <WhiteTypo variant="subtitle1">러너 수업신청 {closeYN && '(준비중)'}</WhiteTypo>
                                <WhiteTypo variant="body2">(심화 단계)</WhiteTypo>
                            </Box>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
            <Box height={30} />

            {/* 뮤직플레이 프리미엄 */}
            <Grid container spacing={2} className={classes.paddings}>
                <Grid container item direction="row" xs={12} sm={6} justifyContent="center" className={classes.paddings}>
                    <Grid item xs={6}>
                        <Box className={classes.imageBox4}></Box>
                    </Grid>
                    <Grid item xs={6}>
                        <Box className={classes.grad1} align="center">
                            <YellowTypo variant="subtitle1">뮤직플레이</YellowTypo>
                            <YellowTypo variant="subtitle1">프리미엄</YellowTypo>
                            <img src={MusicgogoLogo} alt="logo" width="73.5px" height="35px" />
                            <br />
                            <Button
                                color="secondary"
                                size="small"
                                variant="contained"
                                className={classes.buttonTopShort}
                                component={Link}
                                to="/enroll/premium"
                            >
                                자세히보기
                            </Button>
                        </Box>
                    </Grid>
                </Grid>
                <Grid container item xs={12} sm={6} className={classes.paddings}>
                    <Grid item xs={12} style={{ position: 'relative' }}>
                        <Box className={classes.textBox}>
                            <YellowTypo variant="h5" gutterBottom style={{ fontWeight: 'bold' }}>
                                뮤직플레이 프리미엄
                            </YellowTypo>
                            <Typography variant="subtitle2">4-6명 정원의 소그룹 레슨</Typography>
                            <Typography variant="body2">- 어린음악대 회원/비회원 모두 신청 가능합니다.</Typography>
                            <Typography variant="body2">- 어린음악대 음악 학원에서 진행되는 수업입니다.</Typography>
                            <YellowTypo variant="body2">
                                - <b>프리미엄 패키지(워크북, 1인 교구, 리듬악기)</b> 포함되어 있습니다.
                            </YellowTypo>
                            <Typography variant="body2">- 6개월, 12개월 정기결제가 가능합니다.</Typography>
                        </Box>
                        <Box className={classes.priceTag}>
                            <Typography variant="h5" display="inline">
                                월 70,000원
                            </Typography>
                            <Typography variant="body1" display="inline">
                                {' '}
                                (월 4회)
                            </Typography>
                        </Box>
                    </Grid>
                    <Grid container item direction="row" xs={12} spacing={2}>
                        <Grid item xs={6} component={closeYN ? 'div' : Link} to="/enrollstep2">
                            <Box
                                className={classes.boxes}
                                style={{ backgroundImage: 'linear-gradient(to bottom, #4d4d4d, #0f0f0f)' }}
                                onClick={
                                    closeYN
                                        ? () => alert(closeText)
                                        : () =>
                                              handleProduct(
                                                  '뮤직플레이프리미엄',
                                                  'hYkzmnFPqshqRNgr4gCN',
                                                  'musicgogoPremium',
                                                  '뮤직플레이 프리미엄',
                                                  '플레이어'
                                              )
                                }
                            >
                                <YellowTypo variant="subtitle1">플레이어 수업신청 {closeYN && '(마감)'}</YellowTypo>
                                <YellowTypo variant="body2">(기초 단계)</YellowTypo>
                            </Box>
                        </Grid>
                        <Grid item xs={6} component={closeYN ? 'div': Link} to="/enrollstep2">
                            <Box
                                className={classes.boxes}
                                style={{ backgroundImage: 'linear-gradient(to bottom, #7C7B7B, #323232)' }}
                                onClick={closeYN
                                    ? () => alert(closeText)
                                    : () =>
                                          handleProduct(
                                              '뮤직플레이프리미엄',
                                              'hYkzmnFPqshqRNgr4gCN',
                                              'musicgogoPremium',
                                              '뮤직플레이 프리미엄',
                                              '러너'
                                          )}
                            >
                                <YellowTypo variant="subtitle1">러너 수업신청</YellowTypo>
                                <YellowTypo variant="body2">(심화 단계)</YellowTypo>
                            </Box>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
            <Box height={30} />

            {/* 뮤직플레이 원데이 클래스 */}
            <Grid container spacing={2} className={classes.paddings}>
                <Grid container item direction="row" xs={12} sm={6} justifyContent="center" className={classes.paddings}>
                    <Grid item xs={6}>
                        <Box className={classes.imageBox1}></Box>
                    </Grid>
                    <Grid item xs={6}>
                        <Box className={classes.grad2} align="center">
                            <WhiteTypo variant="subtitle1">뮤직플레이</WhiteTypo>
                            <WhiteTypo variant="subtitle1">원데이 클래스</WhiteTypo>
                            <img src={MusicgogoLogo} alt="logo" width="73.5px" height="35px" />
                            <br />
                            <Button
                                color="secondary"
                                size="small"
                                variant="contained"
                                className={classes.buttonTopShort}
                                component={Link}
                                to="/enroll/oneday"
                            >
                                자세히보기
                            </Button>
                        </Box>
                    </Grid>
                </Grid>
                <Grid container item xs={12} sm={6} className={classes.paddings}>
                    <Grid item xs={12} style={{ position: 'relative' }}>
                        <Box className={classes.textBox}>
                            <PurpleTypo variant="h5" gutterBottom style={{ fontWeight: 'bold' }}>
                                뮤직플레이 원데이 클래스
                            </PurpleTypo>
                            <Typography variant="subtitle2">체험 수업</Typography>
                            <Typography variant="body2">- 신규회원만 신청 가능합니다.</Typography>
                            <Typography variant="body2">
                                - 어린음악대 음악학원 혹은 가정에서(홈스쿨링) 진행되는 수업입니다.
                            </Typography>
                            <Typography variant="body2">- 희망 일자에 수업 시간을 예약할 수 있습니다.</Typography>
                            <PurpleTypo display="inline" variant="body2">
                                - <b>체험 패키지가 포함</b>되어 있습니다.
                            </PurpleTypo>
                        </Box>
                        <Box className={classes.priceTag}>
                            <Typography variant="h5" display="inline">
                                20,000원
                            </Typography>
                            <Typography variant="body1" display="inline">
                                {' '}
                                (1회 수업)
                            </Typography>
                        </Box>
                    </Grid>
                    <Grid container item direction="row" xs={12} spacing={2}>
                        <Grid item xs={6} component={Link} to="/enrollstep2">
                            <Box
                                className={classes.boxes}
                                style={{ backgroundColor: '#ac42b4' }}
                                onClick={() =>
                                    handleProduct(
                                        '뮤직플레이 원데이 4-5세',
                                        'sOjJwaCrMxIMqst181l7',
                                        'musicgogoOneDayClass',
                                        '뮤직플레이 원데이클래스',
                                        '4-5세'
                                    )
                                }
                            >
                                <WhiteTypo variant="subtitle1">4-5세</WhiteTypo>
                                <WhiteTypo variant="subtitle1">수업 신청</WhiteTypo>
                            </Box>
                        </Grid>
                        <Grid item xs={6} component={Link} to="/enrollstep2">
                            <Box
                                className={classes.boxes}
                                style={{ backgroundColor: 'rgba(172, 66, 180,0.6)' }}
                                onClick={() =>
                                    handleProduct(
                                        '뮤직플레이 원데이 6-7세',
                                        'sOjJwaCrMxIMqst181l7',
                                        'musicgogoOneDayClass',
                                        '뮤직플레이 원데이클래스',
                                        '6-7세'
                                    )
                                }
                            >
                                <Typography variant="subtitle1">6-7세</Typography>
                                <Typography variant="subtitle1">수업 신청</Typography>
                            </Box>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
            <Box height={30} />

            {/* 스마트피아노 원데이 클래스 */}
            <Grid container spacing={2} className={classes.paddings}>
                <Grid container item direction="row" xs={12} sm={6} justifyContent="center" className={classes.paddings}>
                    <Grid item xs={6}>
                        <Box className={classes.imageBox2}></Box>
                    </Grid>
                    <Grid item xs={6}>
                        <Box className={classes.grad4} align="center">
                            <WhiteTypo variant="subtitle1">스마트피아노</WhiteTypo>
                            <WhiteTypo variant="subtitle1">원데이 클래스</WhiteTypo>
                            <img src={PianogogoLogo} alt="logo" width="54px" height="31.5px" />
                            <br />
                            <Button
                                color="secondary"
                                size="small"
                                variant="contained"
                                className={classes.button}
                                component={Link}
                                to="/enroll/pianogogo"
                            >
                                자세히보기
                            </Button>
                        </Box>
                    </Grid>
                </Grid>
                <Grid container item xs={12} sm={6} className={classes.paddings}>
                    <Grid item xs={12} style={{ position: 'relative' }}>
                        <Box className={classes.textBox}>
                            <SkyTypo variant="h5" gutterBottom style={{ fontWeight: 'bold' }}>
                                스마트피아노 원데이 클래스
                            </SkyTypo>
                            <Typography variant="subtitle2">체험 수업</Typography>
                            <Typography variant="body2">- 신규회원만 신청 가능합니다.</Typography>
                            <Typography variant="body2">
                                - 어린음악대 음악학원 혹은 가정에서(홈스쿨링) 진행되는 수업입니다.
                            </Typography>
                            <Typography variant="body2">- 희망일자에 수업시간을 예약할 수 있습니다.</Typography>
                            <SkyTypo variant="body2">
                                - <b>체험 패키지가 포함</b>되어 있습니다.
                            </SkyTypo>
                        </Box>
                        <Box className={classes.priceTag}>
                            <Typography variant="h5" display="inline">
                                20,000원
                            </Typography>
                            <Typography variant="body1" display="inline">
                                {' '}
                                (1회 수업)
                            </Typography>
                        </Box>
                    </Grid>
                    <Grid container item direction="row" xs={12} spacing={2}>
                        <Grid item xs={6} component={Link} to="/enrollstep2">
                            <Box
                                className={classes.boxes}
                                style={{ backgroundColor: 'rgb(54, 175, 220)' }}
                                onClick={() =>
                                    handleProduct(
                                        '스마트피아노 원데이',
                                        'C3i47lsVsh90QFUBuSDL',
                                        'pianogogoOneDayClass',
                                        '스마트피아노 원데이클래스',
                                        '6-7세'
                                    )
                                }
                            >
                                <WhiteTypo variant="subtitle1">6-7세</WhiteTypo>
                                <WhiteTypo variant="subtitle1">수업 신청</WhiteTypo>
                            </Box>
                        </Grid>
                        <Grid item xs={6} component={Link} to="/enrollstep2">
                            <Box
                                className={classes.boxes}
                                style={{ backgroundColor: 'rgba(54,175,220,0.6)' }}
                                onClick={() =>
                                    handleProduct(
                                        '스마트피아노 원데이',
                                        'C3i47lsVsh90QFUBuSDL',
                                        'pianogogoOneDayClass',
                                        '스마트피아노 원데이클래스',
                                        '8-9세'
                                    )
                                }
                            >
                                <Typography variant="subtitle1">8-9세</Typography>
                                <Typography variant="subtitle1">수업 신청</Typography>
                            </Box>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
            <Box height={30} />
        </Box>
    );
};

export default withStyles(styles)(ProductGroup);

import {
  LOADING_ADMIN,
  LOADING_UI,
  SET_ADMINS,
  SET_ADMIN_ERRORS,
  SET_MANAGERS,
  STOP_LOADING_ADMIN,
  STOP_LOADING_UI,
  SET_ERRORS,
  SET_ADMIN_LESSONS,
  SET_ADMIN_HAKWONS,
  SET_ADMIN_KAKAOLINKS,
  SET_ADMIN_VISITLOG,
  SET_ADMIN_HAKWON,
  SET_SEARCH_USERS,
  SET_AFTERSCHOOLS,
} from "../types";
import axios from "axios";
// import { getSalesForSettlements, getSettlements } from './managerActions';

// 어드민 등급 일괄 부여하기
export const giveAdminRoles = () => (dispatch) => {
  dispatch({ type: LOADING_ADMIN });
  axios
    .post("/adminlevelbatch")
    .then((res) => {
      dispatch({
        type: SET_ADMINS,
        payload: res.data,
      });
      dispatch({ type: STOP_LOADING_ADMIN });
    })
    .catch((err) => {
      dispatch({
        type: SET_ADMIN_ERRORS,
        payload: err.response.data,
      });
      dispatch({ type: STOP_LOADING_ADMIN });
    });
};

// 학원장 등급 일괄 부여하기
export const giveManagerRoles = () => (dispatch) => {
  dispatch({ type: LOADING_UI });
  axios
    .post("/managerlevelbatch")
    .then((res) => {
      dispatch({
        type: SET_MANAGERS,
        payload: res.data,
      });
      dispatch({ type: STOP_LOADING_UI });
    })
    .catch((err) => {
      dispatch({
        type: SET_ERRORS,
        payload: err.response.data,
      });
      dispatch({ type: STOP_LOADING_UI });
    });
};

// 레슨 전체
export const getAllLessons = () => (dispatch) => {
  dispatch({ type: LOADING_ADMIN });
  axios
    .get("/getlessons")
    .then((res) => {
      dispatch({
        type: SET_ADMIN_LESSONS,
        payload: res.data,
      });
    })
    .catch((err) => {
      console.log(err);
      dispatch({
        type: SET_ADMIN_ERRORS,
        payload: err.response.data,
      });
    })
    .finally(() => {
      dispatch({ type: STOP_LOADING_ADMIN });
    });
};
// 학원전체
export const getAllHakwons = () => (dispatch) => {
  dispatch({ type: LOADING_ADMIN });
  axios
    .get("/gethakwons")
    .then((res) => {
      dispatch({
        type: SET_ADMIN_HAKWONS,
        payload: res.data,
      });
      dispatch({ type: STOP_LOADING_ADMIN });
    })
    .catch((err) => {
      dispatch({ type: STOP_LOADING_ADMIN });
      dispatch({
        type: SET_ADMIN_ERRORS,
        payload: err.response,
      });
    });
};
export const getAllHakwonsContacts = () => (dispatch) => {
  dispatch({
    type: SET_ADMIN_HAKWONS,
    payload: [],
  });
  axios
    .get("/gethakwonscontacts")
    .then((res) => {
      // console.log(res.data);
      dispatch({
        type: SET_ADMIN_HAKWONS,
        payload: res.data,
      });
    })
    .catch((err) => {
      dispatch({
        type: SET_ADMIN_ERRORS,
        payload: err.response,
      });
    });
};
export const getOneHakwon = (hName) => (dispatch) => {
  dispatch({ type: LOADING_ADMIN });
  axios
    .post("/gethakwoninfo", hName)
    .then((res) => {
      dispatch({
        type: SET_ADMIN_HAKWONS,
        payload: res.data,
      });
      dispatch({ type: STOP_LOADING_ADMIN });
    })
    .catch((err) => {
      dispatch({ type: STOP_LOADING_ADMIN });
      dispatch({
        type: SET_ADMIN_ERRORS,
        payload: err.response,
      });
    });
};
export const updateHakwonInfo = (hakwonId, data) => (dispatch) => {
  dispatch({ type: LOADING_ADMIN });
  axios
    .post(`/hakwon/${hakwonId}`, data)
    .then((res) => {
      dispatch({ type: STOP_LOADING_ADMIN });
      dispatch(getOneHakwon({ hName: data.hName }));
    })
    .catch((err) => {
      dispatch({ type: STOP_LOADING_ADMIN });
      dispatch({
        type: SET_ADMIN_ERRORS,
        payload: err.response,
      });
    });
};
export const deleteOwnerPhone = (hakwonId, phone, hName) => (dispatch) => {
  dispatch({ type: LOADING_ADMIN });
  axios
    .post(`/ownerphonedelete/${hakwonId}`, { phone: phone })
    .then((res) => {
      dispatch({ type: STOP_LOADING_ADMIN });
      dispatch(getOneHakwon({ hName: hName }));
    })
    .catch((err) => {
      dispatch({ type: STOP_LOADING_ADMIN });
      dispatch({
        type: SET_ADMIN_ERRORS,
        payload: err.response,
      });
    });
};
export const addOwnerPhone = (hakwonId, phone, hName) => (dispatch) => {
  dispatch({ type: LOADING_ADMIN });
  axios
    .post(`/ownerphone/${hakwonId}`, { phone: phone })
    .then((res) => {
      dispatch({ type: STOP_LOADING_ADMIN });
      dispatch(getOneHakwon({ hName: hName }));
    })
    .catch((err) => {
      dispatch({ type: STOP_LOADING_ADMIN });
      dispatch({
        type: SET_ADMIN_ERRORS,
        payload: err.response,
      });
    });
};
export const insertHakwon = (data) => (dispatch) => {
  dispatch({ type: LOADING_ADMIN });
  axios
    .post(`/posthakwon`, data)
    .then((res) => {
      console.log(res.data);
      dispatch({
        type: SET_ADMIN_HAKWON,
        payload: res.data,
      });
      dispatch({ type: STOP_LOADING_ADMIN });
    })
    .catch((err) => {
      dispatch({ type: STOP_LOADING_ADMIN });
      dispatch({
        type: SET_ADMIN_ERRORS,
        payload: err.response,
      });
    });
};

export const postKakaoLink = (data) => (dispatch) => {
  dispatch({ type: LOADING_ADMIN });
  axios
    .post(`/kakaolink`, data)
    .then((res) => {
      dispatch({ type: STOP_LOADING_ADMIN });
      dispatch(getKakaoLinks());
    })
    .catch((err) => {
      dispatch({ type: STOP_LOADING_ADMIN });
      dispatch({
        type: SET_ADMIN_ERRORS,
        payload: err.response,
      });
    });
};
export const getKakaoLinks = () => (dispatch) => {
  dispatch({ type: LOADING_ADMIN });
  axios
    .get(`/kakaolinks`)
    .then((res) => {
      dispatch({ type: STOP_LOADING_ADMIN });
      dispatch({
        type: SET_ADMIN_KAKAOLINKS,
        payload: res.data,
      });
    })
    .catch((err) => {
      dispatch({ type: STOP_LOADING_ADMIN });
      dispatch({
        type: SET_ADMIN_ERRORS,
        payload: err.response,
      });
    });
};
export const deleteKakaoLink = (kakaolinkId) => (dispatch) => {
  dispatch({ type: LOADING_ADMIN });
  axios
    .delete(`/kakaolink/${kakaolinkId}`)
    .then((res) => {
      dispatch({ type: STOP_LOADING_ADMIN });
      alert(res.data.data);
      dispatch(getKakaoLinks());
    })
    .catch((err) => {
      dispatch({ type: STOP_LOADING_ADMIN });
      dispatch({
        type: SET_ADMIN_ERRORS,
        payload: err.response,
      });
    });
};
export const updateKakaoLink = (kakaolinkId, data) => (dispatch) => {
  dispatch({ type: LOADING_ADMIN });
  axios
    .post(`/kakaolink/${kakaolinkId}`, data)
    .then((res) => {
      dispatch({ type: STOP_LOADING_ADMIN });
      alert(res.data.data);
      dispatch(getKakaoLinks());
    })
    .catch((err) => {
      dispatch({ type: STOP_LOADING_ADMIN });
      dispatch({
        type: SET_ADMIN_ERRORS,
        payload: err.response.data,
      });
    });
};

// visit log
export const getVisitLog = (dates) => (dispatch) => {
  dispatch({ type: LOADING_ADMIN });
  axios
    .post(`/getvisitlog`, dates)
    .then((res) => {
      dispatch({ type: STOP_LOADING_ADMIN });
      dispatch({
        type: SET_ADMIN_VISITLOG,
        payload: res.data,
      });
    })
    .catch((err) => {
      dispatch({ type: STOP_LOADING_ADMIN });
      dispatch({
        type: SET_ADMIN_ERRORS,
        payload: err.response,
      });
    });
};

// 정산 금액 변경
export const updatePaymentData = (ids) => (dispatch) => {
  dispatch({ type: LOADING_ADMIN });
  axios
    .post(`/updatepaymentdata`, ids)
    .then((res) => {
      dispatch({ type: STOP_LOADING_ADMIN });
    })
    .catch((err) => {
      dispatch({ type: STOP_LOADING_ADMIN });
      dispatch({
        type: SET_ADMIN_ERRORS,
        payload: err.response,
      });
    });
};

// afetSchools
export const getUsersInfoByPhoneNumber = (phone) => (dispatch) => {
  dispatch({ type: LOADING_ADMIN });
  axios
    .post(`/getusersbyphone`, phone)
    .then((res) => {
      dispatch({ type: STOP_LOADING_ADMIN });
      dispatch({
        type: SET_SEARCH_USERS,
        payload: res.data,
      });
    })
    .catch((err) => {
      dispatch({ type: STOP_LOADING_ADMIN });
      dispatch({
        type: SET_ADMIN_ERRORS,
        payload: err.response,
      });
    });
};

export const getAfterSchools = () => (dispatch) => {
  dispatch({ type: LOADING_ADMIN });
  axios
    .get(`/afterschools`)
    .then((res) => {
      dispatch({ type: STOP_LOADING_ADMIN });
      dispatch({
        type: SET_AFTERSCHOOLS,
        payload: res.data,
      });
    })
    .catch((err) => {
      dispatch({ type: STOP_LOADING_ADMIN });
      dispatch({
        type: SET_ADMIN_ERRORS,
        payload: err.response,
      });
    });
};
export const postAfterSchool = (data) => (dispatch) => {
  dispatch({ type: LOADING_ADMIN });
  axios
    .post(`/postafterschool`, data)
    .then((res) => {
      dispatch({ type: STOP_LOADING_ADMIN });
      dispatch({
        type: SET_AFTERSCHOOLS,
        payload: res.data,
      });
      dispatch(getAfterSchools());
    })
    .catch((err) => {
      dispatch({ type: STOP_LOADING_ADMIN });
      dispatch({
        type: SET_ADMIN_ERRORS,
        payload: err.response,
      });
      alert(err.response.data.error);
    });
};
export const updateAfterSchool = (args) => (dispatch) => {
  dispatch({ type: LOADING_ADMIN });
  axios
    .post(`/editafterschool/${args.afterSchoolId}`, args)
    .then((res) => {
      dispatch({ type: STOP_LOADING_ADMIN });
      dispatch(getAfterSchools());
    })
    .catch((err) => {
      dispatch({ type: STOP_LOADING_ADMIN });
      dispatch({
        type: SET_ADMIN_ERRORS,
        payload: err.response,
      });
    });
};

export const deleteAfterSchool = (id) => (dispatch) => {
  dispatch({ type: LOADING_ADMIN });
  axios
    .post(`/deleteafterschool/${id}`)
    .then((res) => {
      dispatch({ type: STOP_LOADING_ADMIN });
      dispatch(getAfterSchools());
    })
    .catch((err) => {
      dispatch({ type: STOP_LOADING_ADMIN });
      dispatch({
        type: SET_ADMIN_ERRORS,
        payload: err.response,
      });
    });
};

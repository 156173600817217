import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import dayjs from 'dayjs';
// Redux stuff
import { useSelector, useDispatch } from 'react-redux';
import { getHakwon, getLesson } from '../../redux/actions/enrollActions';
import { getProduct, getCurriculumsByProduct, postRefund, getRefunds } from '../../redux/actions/dataActions';
// components
import LessonAccordian from '../../components/manager/LessonAccordian';
// MUI stuff
import withStyles from '@material-ui/core/styles/withStyles';

import {
    Box,
    Typography,
    Grid,
    Divider,
    Button,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    TextField,
    Link as MuiLink,
} from '@material-ui/core';
import {
    CloseTwoTone,
    CreditCardTwoTone,
    LocalShippingTwoTone,
    OndemandVideoRounded,
    ReceiptTwoTone,
} from '@material-ui/icons';

const styles = (theme) => ({
    ...theme.userPage,

    header: {
        marginBottom: 20,
    },
    topHeader: {
        [theme.breakpoints.down('xs')]: {
            marginTop: 30,
            marginBottom: 20,
        },
        marginBottom: 20,
    },
    menu: {
        minWidth: 80,
        marginBottom: 15,
    },
    item: {
        marginBottom: 15,
    },
    typo: {
        lineHeight: 1.75,
    },
    dividerBottom: {
        marginBottom: 20,
    },
    button: {
        marginLeft: 15,
        marginRight: 15,
    },
    inline: {
        lineHeight: 1.75,
        [theme.breakpoints.down('xs')]: {
            display: 'block',
        },
        display: 'inline',
    },
});
export const MyClassInfo = (props) => {
    const { classes, myclass, monthlyPayments } = props;
    const { lesson, hakwon } = useSelector((state) => state.enroll);
    const { product, curriculums, refunds } = useSelector((state) => state.data);
    const { credentials } = useSelector((state) => state.user);
    const dispatch = useDispatch();
    // state

    useEffect(() => {
        const str = myclass.lessonId.replace(/"/g, ''); // FIXME: data "" 에러
        dispatch(getLesson(str));
    }, [dispatch, myclass]);

    useEffect(() => {
        if (lesson.hakwonId) {
            dispatch(getHakwon(lesson.hakwonId));
        }
    }, [dispatch, lesson]);

    useEffect(() => {
        if (lesson.productId) {
            dispatch(getProduct(lesson.productId));
            dispatch(getCurriculumsByProduct(lesson.productId));
            dispatch(getRefunds());
        }
    }, [dispatch, lesson]);

    const handlePopUp = () => {
        const num = myclass.deliNumber;
        if (num === '' || typeof num === 'undefined') {
            window.alert('준비 중입니다. 단, 뮤직플레이, 뮤직플레이프리미엄 수업은 매월26일 일괄 배송됩니다.');
        } else {
            window.open(
                `http://nplus.doortodoor.co.kr/web/detail.jsp?slipno=+${num}`,
                '_blank',
                'toolbar=yes,scrollbars=yes,resizable=yes,top=500,left=500,width=800,height=800'
            );
        }
    };

    const pIdsMonthlyPaid = [
        'LWftI74DJAzr3h9ZGERH',
        'LY2bzzk1kL1jOyssZXDI',
        'KiflCPN7CCrr7pTAeJQz',
        'WkHIN2b2KZ2316kcjIFs',
    ];

    const monthlyYN = pIdsMonthlyPaid.indexOf(lesson.productId) > -1;

    const monthlyPaidHistory = monthlyPayments
        ?.filter((sa) => sa.merchant_uid.substring(0, 15) === myclass.merchant_uid)
        .sort((a, b) => a.createdAt - b.createdAt);

    const [open, setOpen] = useState(false);
    const initialInfo = {
        paymentId: '',
        lessonName: '',
        phone: '',
        child_name: '',
        memo: '',
    };
    const [info, setInfo] = useState(initialInfo);
    const handleOpen = () => {
        setOpen(true);
        setInfo({
            paymentId: myclass.merchant_uid,
            lessonName: myclass.payName,
            child_name: myclass.child_name,
            phone: credentials.phone,
            memo: '-',
        });
    };
    const handleClose = () => {
        setOpen(false);
        setInfo(initialInfo);
    };
    const handleRefund = () => {
        const upInfo = {
            ...info,
            createdAt: new Date() / 1000,
            status: '요청',
        };
        if (window.confirm('신청하시겠습니까?')) {
            dispatch(postRefund(upInfo));
            setOpen(false);
        }
    };
    const myRefund = refunds?.filter((r) => r.paymentId === myclass.merchant_uid);
    const refundStatus = myRefund.length > 0 ? myRefund[0].status : 'yet';

    return (
        <div>
            <Typography className={classes.topHeader} variant="h4">
                나의 수강 정보
            </Typography>
            <Grid container>
                <Grid item container direction="row">
                    <Grid item className={classes.menu}>
                        <Typography className={classes.typo} variant="body1">
                            나의 수업
                        </Typography>
                    </Grid>
                    <Grid item className={classes.item}>
                        <Typography className={classes.typo} variant="subtitle1" display="inline">
                            {lesson.lessonName}
                        </Typography>
                        {credentials.level < 2 && (
                            <Button
                                className={classes.button}
                                color="primary"
                                variant="contained"
                                startIcon={<OndemandVideoRounded />}
                                component={Link}
                                to={`/homeschool/${myclass.merchant_uid}`}
                            >
                                영상보기
                            </Button>
                        )}
                    </Grid>
                </Grid>
                <Grid item container direction="row">
                    <Grid item className={classes.menu}>
                        <Typography className={classes.typo} variant="body1">
                            수강 기간
                        </Typography>
                    </Grid>
                    <Grid item className={classes.item}>
                        <Typography className={classes.typo} variant="subtitle1">
                            {dayjs(lesson.startDate * 1000).format(
                                lesson.isWithoutTimeTable ? 'YYYY-MM-DD(ddd)' : 'YYYY-MM-DD(ddd) A hh:mm'
                            )}{' '}
                            ~{' '}
                            {dayjs(lesson.endDate * 1000).format(
                                lesson.isWithoutTimeTable ? 'YYYY-MM-DD(ddd)' : 'YYYY-MM-DD(ddd) A hh:mm'
                            )}
                        </Typography>
                    </Grid>
                </Grid>

                <Grid item container direction="row">
                    <Grid item className={classes.menu}>
                        <Typography className={classes.typo} variant="body1">
                            수업 장소
                        </Typography>
                    </Grid>
                    <Grid item className={classes.item}>
                        <Typography className={classes.typo} variant="subtitle1">
                            {hakwon.hName}
                        </Typography>
                    </Grid>
                </Grid>
            </Grid>
            <Divider light className={classes.dividerBottom} />
            <Typography variant="body1" gutterBottom>
                커리큘럼 미리보기
            </Typography>
            <Typography variant="body2" gutterBottom>
                * 자세한 커리큘럼은 연간교육계획안을 참고하십시오.
            </Typography>

            <LessonAccordian
                curriculums={curriculums
                    .filter((c) => c.category === lesson.category && c.status === 'open')
                    .filter((c) => {
                        const isSameYear =
                            dayjs(lesson.startDate * 1000).year() === dayjs(lesson.endDate * 1000).year();

                        if (isSameYear) {
                            return (
                                c.yyyymm.substr(4, 2) >= dayjs(lesson.startDate * 1000).format('MM') &&
                                c.yyyymm.substr(4, 2) <= dayjs(lesson.endDate * 1000).format('MM')
                            );
                        } else {
                            return (
                                c.yyyymm.substr(4, 2) >= dayjs(lesson.startDate * 1000).format('MM') ||
                                c.yyyymm.substr(4, 2) <= dayjs(lesson.endDate * 1000).format('MM')
                            );
                        }
                    })
                    .map(c => {
                        const isSameYear =
                            dayjs(lesson.startDate * 1000).year() === dayjs(lesson.endDate * 1000).year();
                        const ord = isSameYear ? '1' : c.yyyymm.substr(4, 2) >= dayjs(lesson.startDate * 1000).format('MM') ? '1' : '2'
                        return {
                            ...c,
                            order: ord + c.yyyymm.substr(4,2) + c.week
                        }
                    })
                    .sort((a, b) => {
                        //console.log(a.order)
                        return a.order < b.order ? -1 : a.order > b.order ? 1: 0;
                    })}
                recurrentDates={lesson?.recurrentDates}
                withoutTimeTable={lesson?.isWithoutTimeTable}
            />
            <Box height={40} />
            <Divider light className={classes.dividerBottom} />

            <Typography className={classes.header} variant="h4">
                결제 정보
            </Typography>
            <Grid container>
                <Grid item container direction="row">
                    <Grid item className={classes.menu}>
                        <Typography className={classes.typo} variant="body1">
                            결제 금액
                        </Typography>
                    </Grid>
                    <Grid item className={classes.item}>
                        <Typography className={classes.typo} variant="subtitle1">
                            {numberFormat(myclass.amount)} 원
                        </Typography>
                    </Grid>
                </Grid>
                {myclass.amount === 0 ? (
                    <Typography color="error" variant="body2" style={{ marginTop: 0, marginBottom: 20 }}>
                        * 정기결제 예약된 상태이며, 예정된 날짜에 실제 결제가 발생하면, 결제금액이 표기됩니다.
                    </Typography>
                ) : null}
                <Grid item container direction="row">
                    <Grid item className={classes.menu}>
                        <Typography className={classes.typo} variant="body1">
                            결제 일시
                        </Typography>
                    </Grid>
                    <Grid item className={classes.item}>
                        <Typography className={classes.typo} variant="subtitle1">
                            {dayjs(myclass.paidAt * 1000).format('YYYY-MM-DD(ddd) HH:mm')}
                        </Typography>
                    </Grid>
                </Grid>
                <Grid item container direction="row">
                    <Grid item className={classes.menu}>
                        <Typography className={classes.typo} variant="body1">
                            주문 번호
                        </Typography>
                    </Grid>
                    <Grid item className={classes.item}>
                        <Typography className={classes.typo} variant="subtitle1">
                            {myclass.imp_uid}
                        </Typography>
                    </Grid>
                </Grid>
                <Grid item container direction="row">
                    <Grid item className={classes.menu}>
                        <Typography className={classes.typo} variant="body1">
                            승인 번호
                        </Typography>
                    </Grid>
                    <Grid item className={classes.item}>
                        <Typography className={classes.typo} variant="subtitle1">
                            {myclass.apply_num}
                        </Typography>
                    </Grid>
                </Grid>
                <Grid item container direction="row">
                    <Grid item className={classes.menu}>
                        <Typography className={classes.typo} variant="body1">
                            결제 번호
                        </Typography>
                    </Grid>
                    <Grid item className={classes.item}>
                        <Typography className={classes.typo} variant="subtitle1">
                            {myclass.merchant_uid}
                        </Typography>
                    </Grid>
                </Grid>
                <Grid item container direction="row">
                    <Grid item className={classes.menu}>
                        <Typography className={classes.typo} variant="body1">
                            결제 상태
                        </Typography>
                    </Grid>
                    <Grid item className={classes.item}>
                        <Typography className={classes.typo} variant="subtitle1" display="inline" color="primary">
                            {monthlyYN && myclass.status === 'paid'
                                ? '결제예정(매월정기결제)'
                                : monthlyYN && myclass.status === 'cancelled'
                                ? '결제예약취소'
                                : myclass.status === 'cancelled'
                                ? '결제취소 완료'
                                : myclass.status === 'paid'
                                ? '결제완료'
                                : '결제예정'}
                        </Typography>
                        {!monthlyYN && (
                            <Button
                                className={classes.button}
                                color="secondary"
                                variant="contained"
                                onClick={() => window.open(myclass.receipt, '구매영수증', 'width=400px, height=600px')}
                                startIcon={<ReceiptTwoTone />}
                            >
                                영수증보기
                            </Button>
                        )}
                        <Typography className={classes.inline}>(구매자명: {myclass.child_name})</Typography>
                    </Grid>
                </Grid>
                {myclass.status === 'paid' && (
                    <Grid item container direction="row">
                        <Grid item className={classes.menu}>
                            <Typography className={classes.typo} variant="body1">
                                환불 요청
                            </Typography>
                        </Grid>
                        <Grid item className={classes.item}>
                            <Button
                                className={classes.button}
                                color="secondary"
                                variant="contained"
                                onClick={handleOpen}
                                startIcon={<CreditCardTwoTone />}
                                disabled={refundStatus !== 'yet'}
                            >
                                환불/취소 신청
                            </Button>
                            <Typography className={classes.inline} variant="body1">
                                {refundStatus === '요청' || refundStatus === '보류'
                                    ? '[확인 중] 1~3일 이내 처리됩니다.'
                                    : refundStatus === '완료'
                                    ? '[환불/취소 완료]'
                                    : ''}
                            </Typography>
                        </Grid>
                    </Grid>
                )}
                {monthlyYN && myclass.status === 'paid' && (
                    <Grid item container direction="row">
                        <Grid item className={classes.menu}>
                            <Typography className={classes.typo} variant="body1">
                                정기 결제 예정일
                            </Typography>
                            <Grid item className={classes.item}>
                                {myclass.billingDates?.map((b, i) => (
                                    <Grid container direction="row" key={i}>
                                        <Grid item>
                                            <Typography className={classes.typo} variant="body2">
                                                ㆍ{i + 1}회차 : {dayjs(b).format('YYYY년 MM월 DD일(dd)')}{' '}
                                                {monthlyPaidHistory[i] &&
                                                    numberFormat(monthlyPaidHistory[i].amount) + ' 원'}
                                            </Typography>
                                        </Grid>
                                        {monthlyPaidHistory[i] && (
                                            <Button
                                                display="inline"
                                                style={{ align: 'left', marginLeft: 15 }}
                                                color="primary"
                                                variant="contained"
                                                size="small"
                                                onClick={() =>
                                                    window.open(
                                                        monthlyPaidHistory[i].receipt,
                                                        '구매영수증',
                                                        'width=400px, height=600px'
                                                    )
                                                }
                                            >
                                                결제 영수증
                                            </Button>
                                        )}
                                    </Grid>
                                ))}
                            </Grid>
                        </Grid>
                    </Grid>
                )}
            </Grid>
            <Divider light className={classes.dividerBottom} />

            <Typography className={classes.header} variant="h4">
                배송 정보
            </Typography>
            <Grid container>
                <Grid item container direction="row">
                    <Grid item className={classes.menu}>
                        <Typography className={classes.typo} variant="body1">
                            배송 제품
                        </Typography>
                    </Grid>
                    <Grid item className={classes.item}>
                        <Typography className={classes.typo} variant="subtitle1">
                            {product.packages}
                        </Typography>
                    </Grid>
                </Grid>
                <Grid item container direction="row">
                    <Grid item className={classes.menu}>
                        <Typography className={classes.typo} variant="body1">
                            배송 주소
                        </Typography>
                    </Grid>
                    <Grid item className={classes.item}>
                        <Typography className={classes.typo} variant="subtitle1">
                            {myclass.juso}
                        </Typography>
                    </Grid>
                </Grid>
                <Grid item container direction="row">
                    <Grid item className={classes.menu}>
                        <Typography className={classes.typo} variant="body1">
                            {myclass.deliNumber ? '운송장번호' : '배송 상태'}
                        </Typography>
                    </Grid>
                    <Grid item className={classes.item}>
                        <Typography className={classes.typo} display="inline" variant="subtitle1">
                            {myclass.deliNumber ? myclass.deliNumber : '준비 중'}
                        </Typography>
                        <Button
                            className={classes.button}
                            color="secondary"
                            variant="contained"
                            onClick={handlePopUp}
                            startIcon={<LocalShippingTwoTone />}
                        >
                            배송조회
                        </Button>
                        {/* <Button className={classes.button} variant='contained' onClick={() => alert('상담 준비중입니다.')}>카카오톡 문의하기</Button> */}
                    </Grid>
                </Grid>
            </Grid>

            {/* 환불신청 dialog */}
            <Dialog open={open} fullWidth>
                <DialogTitle>
                    <Typography variant="h5">환불 취소 신청</Typography>
                </DialogTitle>
                <DialogContent>
                    <Typography gutterBottom>
                        ㆍ 고객센터(1688-7970)에서 확인 후 처리해드리고, 아래에 남기신 연락처로 문자메시지나 전화로
                        연락드립니다.
                    </Typography>
                    <Typography gutterBottom>ㆍ 영업일 기준 1~3일이내에 처리해드리도록 하겠습니다.</Typography>
                    <Typography gutterBottom>
                        ㆍ 수업이 경과한 수업일수에 따라서 환불금액에 차이가 있을 수 있습니다. 환불규정은{' '}
                        <MuiLink href="/help/infocancel">이용 안내- 수업변경취소 메뉴</MuiLink>를 참고하시기 바랍니다.
                    </Typography>
                    <Box height={20} />
                    <Typography gutterBottom>
                        ㆍ 취소할 수업명 : <strong>{info.lessonName}</strong>
                    </Typography>
                    <Typography gutterBottom>
                        ㆍ 자녀명 : <strong>{info.child_name}</strong>
                    </Typography>
                    <Box height={20} />
                    <TextField
                        label="연락받으실 전화번호"
                        variant="outlined"
                        fullWidth
                        required
                        value={info.phone}
                        onChange={(e) => setInfo({ ...info, phone: e.target.value })}
                    />
                    <Box height={20} />
                    <TextField
                        label="남기실 말씀"
                        variant="outlined"
                        fullWidth
                        value={info.memo}
                        onChange={(e) => setInfo({ ...info, memo: e.target.value })}
                    />
                </DialogContent>
                <DialogActions>
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={handleRefund}
                        startIcon={<CreditCardTwoTone />}
                    >
                        환불 취소 신청
                    </Button>
                    <Button variant="contained" onClick={handleClose} startIcon={<CloseTwoTone />}>
                        닫기
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
};

function numberFormat(inputNumber) {
    return String(inputNumber).replace(/\B(?=(\d{3})+(?!\d))/g, ',');
}

export default withStyles(styles)(MyClassInfo);

import React, { useState, useEffect } from 'react';
import { Link, useHistory } from 'react-router-dom';

// components
import Snackbar1 from '../../components/util/Snackbar1';
import SearchHakwon from './SearchHakwon';

// redux
import { useDispatch, useSelector } from 'react-redux';
import { checkStudentByName, saveStudentBeforRequest, saveRequestToHakwon, setMyStudent, sendATA4 } from '../../redux/actions/enrollActions';

// Mui
import { withStyles } from '@material-ui/core/styles';


import { Box, Container, Divider, Paper, Grid, Typography, Button, TextField, FormControl, Radio, FormLabel, RadioGroup, FormControlLabel, IconButton } from '@material-ui/core';
import { NavigateBeforeOutlined, NavigateNextRounded, OpenInBrowserRounded } from '@material-ui/icons';



const styles = (theme) => ({
	...theme.userPages,
	formControl: {
		margin: theme.spacing(1),
		minWidth: 120,
	},
	selectEmpty: {
		marginTop: theme.spacing(2),
	},
	container: {
		padding: 10,
		display: 'flex',
		alignItems: 'center',
		maxWidth: 600,
		marginTop: theme.spacing(2),
		margin: `20px auto`,
	},
	root: {
		width: '100%',
		backgroundColor: theme.palette.background.paper
	},
	fluid: {
		padding: '2px 4px',
		minWidth: 330,
		maxWidth: 430
	},
	form: {
		padding: '2px 4px',
		display: 'flex',
		alignItems: 'center',
		width: '100%',
		maxWidth: 500,
		marginTop: theme.spacing(2),
		margin: `20px auto`,
	},
	input: {
		marginLeft: theme.spacing(1),
		flex: 1,
	},
	iconButton: {
		padding: 10,
	},
	divider: {
		height: 28,
		margin: 4,
	},
	pos: {
		marginBottom: 12,
	},
	button: {
		margin: theme.spacing(1),
	},
	buttonRight: {
		margin: theme.spacing(1),
		paddingLeft: 'auto'
	},
	search: {
		width: 250
	},
	whiteBackground: {
		backgroundColor: '#ffffff'
	}
});

const SearchButton = withStyles({
	root: { padding: 15, marginLeft: 15 }
})(Button);

const SelectHakwon = (props) => {
	// redux
	const { loading } = useSelector(state => state.UI); // 학생은 UI로딩 으로 분리
	const { students, student, enrollErrors } = useSelector(state => state.enroll);    //학원은 데이터로딩
	const { authenticated, credentials } = useSelector(state => state.user);
	const dispatch = useDispatch();
	const history = useHistory();

	const { classes, setHakwonName, productGroupName } = props;

	const [myhakwonPanel, setMyhakwonPanel] = useState(false);


	const [isHakwonLists, setIsHakwonLists] = useState(false); // 학원 라디오 리스트
	const [selectedHakwon, setSelectedHakwon] = useState({ id: '', name: '', phone: '' });

	const [searchResultPanel, setSearchResultPanel] = useState(false); // 자녀 검색 결과 패널
	const [studentName, setStudentName] = useState('');
	// 생년월추가
	const [age, setAge] = useState('');

	const [myChild, setMyChild] = useState('');

	const [finished, setFinished] = useState(false);
	const [requestPhoneNumber, setRequestPhoneNumber] = useState('');
	const [userPhoneNumber, setUserPhoneNumber] = useState('');
	const [sent, setSent] = useState(false);

	useEffect(() => {
		setUserPhoneNumber(credentials.phone);
		setStudentName(localStorage.getItem('childName'));
		setAge(localStorage.getItem('age'));
	}, [credentials]);
	// 스낵바
	const [snackbarOpen, setSnackbarOpen] = useState(false);
	const [reqSnackbarOpen, setReqSnackbarOpen] = useState(false);
	const [confirmSnackbarOpen, setConfirmSnackbarOpen] = useState(false);
	const [phoneNumberError, setPhoneNumberError] = useState(false);
	// 잠시변경 FIXME:
	// const hName = hakwon ? hakwon.hName : localStorage.getItem('hName');
	const hName = localStorage.getItem('hName');


	// 자녀이름 검색창 //
	const handleSearch = (e, studentName) => {
		e.preventDefault();
		if (studentName.trim() === '' || studentName === null || studentName === ' ') {
			setSnackbarOpen(true);
		} else {
			if (checkNameAndAge(studentName, age)) {
				if (productGroupName === '뮤직플레이') {
					setFinished(false);
					setSearchResultPanel(true);
	
					dispatch(checkStudentByName(selectedHakwon.id, { studentName: studentName.trim() }));
					e.target.value = '';
					localStorage.setItem('childName', studentName.trim());
				} else {  // 원데이클래스일 경우에는 이름만 기록
					localStorage.setItem('childName', studentName.trim());
					window.alert('기록되었습니다.');
				}
			}
			
		}
	}

	const handleStudentName = (e) => {
		setStudentName(e.target.value);
	}
	const handleAge = (e) => {
		setAge(e.target.value);
		localStorage.setItem('age', e.target.value);

	}
	const checkNameAndAge = (checkName, checkAge) => {
		// checkLogic
		if (isBirthday(checkAge) && checkName !== '') {
			localStorage.setItem('age', checkAge);
			localStorage.setItem('childName', studentName);
			return true;
		} else {
			if (!isBirthday(checkAge) || checkAge === null) alert('생년월일 8자리를 확인해주세요.');
			if (checkName === '') alert('이름을 입력해주세요');
			return false;
		}
	}

	const selectMyChild = (e) => {

		setMyChild(e.target.value);
		// 리덕스에 저장

		// FIXME: 스토리지 저장
		localStorage.setItem('childId', e.target.value);
		localStorage.setItem('childName', e.target.name);
		localStorage.setItem('childStatus', '확인');
	}

	// 학원에 요청하기
	const sendForRequestToHakwon = (e) => {
		const checkData = {
			hakwonId: selectedHakwon.id,
			studentName: studentName.trim(),
			studentNameForHakwon: studentName.trim(),
			phone: requestPhoneNumber ? phoneFormat(requestPhoneNumber) : '',
			parentId: credentials.uid,
			isRegistered: false,
			isDeleted: false,
			isPaidGogo: false,
			isInvisibleForHakwon: false,
			age: age
		}
		const requestData = {
			studentName: studentName.trim(),
			hakwonId: selectedHakwon.id,
			phone: requestPhoneNumber ? phoneFormat(requestPhoneNumber) : '',
			parentPhone: userPhoneNumber,
			isRead: false,
			sender: credentials.uid,
			type: 'requestForHakwon'
		}
		const ataData = {
			name: studentName.trim(),
			age: age,
			parentName: credentials.name,
			parentNum: userPhoneNumber,
			childNum: requestPhoneNumber ? phoneFormat(requestPhoneNumber) : '-',
			hName: hName,
			managerNum: selectedHakwon.phone === '010-1111-1111' ? '01062284969' : selectedHakwon.phone.replace(/-/gi, '')
		}
		//console.log(ataData);


		if (checkPhone(requestPhoneNumber)) {
			e.preventDefault();

			// request를 학원으로 보내기, 학원관련자 모두 Noti받기
			dispatch(saveStudentBeforRequest(checkData));
			dispatch(saveRequestToHakwon(selectedHakwon.id, requestData));
			// 학원관리>원생관리에 노출

			setReqSnackbarOpen(true);
			setSent(true);
			localStorage.setItem('childStatus', '확인 중');

			// 알림톡 발송
			dispatch(sendATA4(ataData));

		} else {
			setPhoneNumberError(true);
		}

	}
	// 확인하고
	const confirmHandler = (e) => {
		e.preventDefault();
		setConfirmSnackbarOpen(true);
		setFinished(true); // DB에 있어서 바로 확인되었을 때,
		setSearchResultPanel(false);
		// 여기로 이동
		if (myChild) {
			dispatch(setMyStudent(myChild));
		}
	}

	// 요청이후 패널 닫기
	const closeAfterRequest = () => {
		// 알림톡 전송확인이후에 오픈한다.
		// setFinished(true); 

		setSearchResultPanel(false);
	}

	const saveChildDataToStorage = () => {
		localStorage.setItem('childName', studentName.trim());
		localStorage.setItem('age', age);
	}

	// 결과 헤더 텍스트 : 등록확인된 학생들만 보여준다.
	const filteredStudents = students.filter(s => s.isInvisibleForHakwon === false && s.isDeleted === false && s.isRegistered === true);

	let headerText;

	if (filteredStudents.length === 1) {
		headerText = (`'${selectedHakwon.name}'에 원생 등록이 확인되었습니다.`)
	} else {
		headerText = (`'${selectedHakwon.name}'에 중복된 이름이 있습니다. 전화번호를 뒷자리로 확인하여 주시기 바랍니다.`);
	}


	const pushNextStep = () => {
		saveChildDataToStorage();
		if (checkNameAndAge(studentName.trim(), age)) {
			history.push('/enrollstep3')
		}
	}


	// 핸드폰번호 자동완성
	useEffect(() => {
		if (requestPhoneNumber !=='' && typeof requestPhoneNumber !== 'undefined') {
			if (requestPhoneNumber.length === 10) {
				setRequestPhoneNumber(requestPhoneNumber.replace(/(\d{3})(\d{3})(\d{4})/, '$1-$2-$3'));
			}
			if (requestPhoneNumber.length === 13) {
				setRequestPhoneNumber(requestPhoneNumber.replace(/-/g, '').replace(/(\d{3})(\d{4})(\d{4})/, '$1-$2-$3'));
			}

		}
	}, [requestPhoneNumber]);

	useEffect(() => {
		if (userPhoneNumber !== '' && typeof userPhoneNumber !== 'undefined') {
			if (userPhoneNumber.length === 10) {
				setUserPhoneNumber(userPhoneNumber.replace(/(\d{3})(\d{3})(\d{4})/, '$1-$2-$3'));
			}
			if (userPhoneNumber.length === 13) {
				setUserPhoneNumber(userPhoneNumber.replace(/-/g, '').replace(/(\d{3})(\d{4})(\d{4})/, '$1-$2-$3'));
			}
		}

	}, [userPhoneNumber]);

	return (
		<div>
			{
				myhakwonPanel ? <div>
					<Typography display='inline'>지정 학원 : </Typography>
					<Typography display='inline' color='primary'>{hName}</Typography>
					<br />
					<Typography display='inline'>자녀 이름 : </Typography>
					<Typography display='inline' color='primary'>{student.studentName}</Typography>
					<br />
					<IconButton onClick={() => setMyhakwonPanel(false)}><OpenInBrowserRounded /></IconButton>
				</div>
					:
					<div>{/* start of panel */}
						<SearchHakwon
							setIsHakwonLists={setIsHakwonLists}
							setSelectedHakwon={setSelectedHakwon}
							online={false} //noHakwon 시 처리.. true 시 온라인 안내 페이지 노출함 !
							setHakwonName={setHakwonName}
						/>
						<Box height={10} />

						{
							isHakwonLists &&
							<Container>
								<Typography variant='body1' color='primary' gutterBottom> 선택한 학원 : {selectedHakwon.name}</Typography>
								<Divider light style={{ marginBottom: 15, marginTop: 15 }} />

								{
									!authenticated ?
										<Paper style={{ maxWidth: 500, margin: `20px auto`, padding: 25 }}>
											<Grid container spacing={2}>
												<Grid item style={{ width: 100 }} >
													<Typography component='div' align='center' variant='h5' style={{ marginTop: 20 }}>안내</Typography>
												</Grid>
												<Grid item xs>
													<Typography color='error' variant='body2'>로그인이 필요한 서비스입니다. </Typography>
													<Button variant='contained' color='primary' style={{ margin: 15 }} component={Link} to='/login?/enrollstep2'>로그인</Button>
												</Grid>
											</Grid>
										</Paper>
										:
										<Box>
											<Typography variant='subtitle1'>{(productGroupName === '뮤직플레이') ? '자녀 학원등록 확인' : '자녀 이름 입력'}</Typography>
											<Typography color='secondary' variant='body1' gutterBottom>{
												finished ? '완료되었습니다. 수업날짜 선택 버튼을 눌러주세요.' :
													productGroupName === '뮤직플레이' ? '! 해당학원에 등록되어 있어야 가능한 수업입니다. 원생 등록 여부의 확인이 필요합니다.'
														: '수업을 받을 자녀 이름을 입력해주세요.'}</Typography>
											<Grid container direction='row'>
												<Grid item xs={8}>
													<TextField
														variant='outlined'
														color='primary'
														value={studentName}
														label='자녀 이름'
														onChange={handleStudentName}
														fullWidth
														className={classes.whiteBackground}
													/>
												</Grid>
												<Grid item xs={4}>
													<SearchButton variant='outlined' color='primary' onClick={(e) => handleSearch(e, studentName.trim())} >
														{(productGroupName === '뮤직플레이') ? '검색' : '입력'}
													</SearchButton>
												</Grid>
											</Grid>
											<Box height={10} />


											<Grid container direction='row'>
												<Grid item xs={8}>
													<TextField
														variant='outlined'
														color='primary'
														value={age}
														label='생년월일(YYYYMMDD) 8자리'
														onChange={handleAge}
														fullWidth
														className={classes.whiteBackground}
													/>
												</Grid>
											</Grid>
										</Box>
								}

							</Container>
						}

						{
							isHakwonLists && authenticated &&
							<Container>
								<Typography variant='body1' color='primary'>자녀 이름: {studentName} </Typography>
							</Container>
						}

						{
							authenticated && isHakwonLists && studentName && searchResultPanel &&
							<div>
								<Paper style={{ maxWidth: 500, margin: `20px auto`, padding: 25 }}>
									<Grid container spacing={2}>
										<Grid item style={{ width: 100 }} >
											<Typography component='div' align='center' variant='h5' style={{ marginTop: 20 }}>검색결과</Typography>
										</Grid>
										{
											loading ?
												<Typography color='error'>'확인 중...' </Typography>
												:
												<Grid item xs>
													{
														(filteredStudents.length > 0 && !finished) ?
															<div>
																<FormControl component="fieldset">
																	<FormLabel component="legend">{headerText}</FormLabel>
																	<RadioGroup aria-label="mychild" name="mychild" value={myChild} onChange={selectMyChild}>
																		{filteredStudents
																			.map(stu => (
																				<FormControlLabel key={stu.studentId} value={stu.studentId} name={stu.studentName} control={<Radio />} label={`${stu.studentName}(${stu.phone ? stu.phone.slice(0, 3) + "-****-" + stu.phone.slice(-4) : '-'})`} />
																			))}
																	</RadioGroup>
																</FormControl>
																{
																	myChild ?
																		<Button style={{ marginTop: 15 }} variant='contained' fullWidth onClick={confirmHandler}>완료하기</Button>
																		: <Button style={{ marginTop: 15 }} color='primary' variant='contained' fullWidth onClick={confirmHandler}>확인</Button>
																}
															</div>
															: enrollErrors ?
																<div>
																	<Typography color='error' variant='body2' gutterBottom>{enrollErrors ? enrollErrors?.data.error : ''}</Typography>
																	<Typography color='textSecondary' variant='body2' gutterBottom>{`아직 '${selectedHakwon.name}'에 등록된 이름이 없습니다. 학원에서 원생 등록 확인을 위해, 연락받으실 학부모님 전화번호를 남겨주시기 바랍니다. 자녀전화번호(있을 시)도 남겨주시면 동일이름 구분에 용이합니다.`}</Typography>
																	<div type='form'>
																		<TextField
																			style={{ marginTop: 20 }}
																			label="자녀 휴대폰"
																			type="text"
																			fullWidth
																			variant="outlined"
																			value={requestPhoneNumber}
																			onChange={(e) => setRequestPhoneNumber(e.target.value)}
																		/>
																		<TextField
																			style={{ marginTop: 20 }}
																			label="연락받으실 학부모 휴대폰"
																			required
																			type="text"
																			fullWidth
																			variant="outlined"
																			value={userPhoneNumber}
																			onChange={(e) => setUserPhoneNumber(e.target.value)}
																		/>
																		{
																			sent ? <Button style={{ marginTop: 15 }} variant='contained' fullWidth onClick={closeAfterRequest}>요청 완료하기</Button>
																				: <Button style={{ marginTop: 15 }} color='primary' variant='contained' fullWidth onClick={sendForRequestToHakwon}>학원장에게 확인요청</Button>
																		}
																	</div>
																</div>
																: <div><Typography>학원에서 확인중입니다. </Typography>
																	<Typography> 잠시만 기다려주십시오.</Typography>
																	<Typography color='secondary'> 상태 : {localStorage.getItem('childStatus')}</Typography>
																</div>
													}
												</Grid>
										}
									</Grid>
								</Paper>
							</div>
						}
					</div> /* end of panel */
			}
			<Box height={50} />

			{/* finished여부는 나중에 최종체크하는 것으로 변경 : finshed ? 에서 hName ? 으로 변경 FIXME: */}
			{
				(hName && studentName && (productGroupName === '뮤직플레이' ? finished : true)) ?
					<Grid container justifyContent='center'>
						<Button component={Link} to='/lesson' className={classes.button} variant='outlined' startIcon={<NavigateBeforeOutlined />}><Typography variant='button'>이전으로</Typography></Button>
						<Button onClick={pushNextStep} className={classes.buttonRight} color='primary' variant='contained' endIcon={<NavigateNextRounded />}><Typography variant='button'>수업 날짜</Typography></Button>
					</Grid>
					:
					<Grid container justifyContent='center'>
						<Button component={Link} to='/lesson' className={classes.button} variant='outlined' startIcon={<NavigateBeforeOutlined />}><Typography variant='button'>이전으로</Typography></Button>
						<Button disabled component={Link} to={`/enrollstep3`} className={classes.buttonRight} color='primary' variant='contained' endIcon={<NavigateNextRounded />}><Typography variant='button'>수업 날짜</Typography></Button>
					</Grid>
			}


			<Snackbar1 open={snackbarOpen} close={setSnackbarOpen} second={3} severity='warning' message="이름을 입력해주세요." />
			<Snackbar1 open={reqSnackbarOpen} close={setReqSnackbarOpen} second={6} severity='success' message="원장님에게 등록 확인 요청을 보냈습니다. 원장님 인증 완료 후 연락받으실 번호로 메시지를 보내드리겠습니다." />
			<Snackbar1 open={confirmSnackbarOpen} close={setConfirmSnackbarOpen} second={6} severity='success' message="학원생 등록이 확인되었습니다." />
			<Snackbar1 open={phoneNumberError} close={setPhoneNumberError} second={2} severity='error' message="휴대 전화 번호가 올바르지 않습니다." />
		</div>
	);
}
function phoneFormat(string) {
	if (string.startsWith("+")) {
		string = "0" + string.substring(3);
	} else if (string.startsWith("82")) {
		string = "0" + string.substring(2);
	}
	const str = string.replace(/(^02.{0}|^01.{1}|[0-9]{3})([0-9]+)([0-9]{4})/, "$1-$2-$3");
	return String(str);
}
function checkPhone(string) {
	if (string === '') {

		return true;
	} else {
		const str = phoneFormat(string);
		const rule = /^\d{3}-\d{3,4}-\d{4}$/;
		if (rule.test(str)) {
			return true;
		} else {
			return false;
		}
	}

}
function isBirthday(dateStr) {
	var year = Number(dateStr.substr(0, 4)); // 입력한 값의 0~4자리까지 (연)
	var month = Number(dateStr.substr(4, 2)); // 입력한 값의 4번째 자리부터 2자리 숫자 (월)
	var day = Number(dateStr.substr(6, 2)); // 입력한 값 6번째 자리부터 2자리 숫자 (일)
	var today = new Date(); // 날짜 변수 선언
	var yearNow = today.getFullYear(); // 올해 연도 가져옴

	if (dateStr.length <= 8) {
		// 연도의 경우 1900 보다 작거나 yearNow 보다 크다면 false를 반환합니다.
		if (1900 > year || year > yearNow) {
			return false;
		} else if (month < 1 || month > 12) {
			return false;
		} else if (day < 1 || day > 31) {
			return false;
		} else if ((month === 4 || month === 6 || month === 9 || month === 11) && day === 31) {
			return false;
		} else if (month === 2) {

			var isleap = (year % 4 === 0 && (year % 100 !== 0 || year % 400 === 0));
			if (day > 29 || (day === 29 && !isleap)) {
				return false;
			} else {
				return true;
			} //end of if (day>29 || (day===29 && !isleap))
		} else {
			return true;
		}//end of if
	}
	else {
		//1.입력된 생년월일이 8자 초과할때 :  auth:false
		return false;
	}
}

export default withStyles(styles)(SelectHakwon);
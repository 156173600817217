import React, { useEffect, useState } from "react";
import dayjs from "dayjs";
// Redux stuff
import { useSelector, useDispatch } from "react-redux";
import { getAfterSchools } from "../../redux/actions/adminActions";
// components
import AdminAddAfterSchool from "./dialog/AdminAddAfterSchool";
import AdminEditAfterSchool from "./dialog/AdminEditAfterSchool";
// MUI stuff
import withStyles from "@material-ui/core/styles/withStyles";

import {
  Box,
  Typography,
  Button,
  Table,
  Paper,
  TableHead,
  TableBody,
  TableContainer,
  TableRow,
  TableCell,
  Divider,
} from "@material-ui/core";

import { AddBoxRounded, EditRounded } from "@material-ui/icons";

const styles = (theme) => ({
  ...theme.userPage,
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
  padding: {
    paddingRight: 3,
    paddingLeft: 3,
  },
});

export const AdminAfterSchool = (props) => {
  const { classes } = props;
  const { afterSchools } = useSelector((state) => state.admin);
  const dispatch = useDispatch();

  const initial = {
    afterSchoolId: "",
    title: "",
    name: "",
    userId: "",
    userLevel: 9,
    phone: "",
    startDay: "",
    endDay: "",
    memo: "",
  };
  const [open, setOpen] = useState(false);
  const [editOpen, setEditOpen] = useState(false);
  const [info, setInfo] = useState(initial);

  useEffect(() => {
    dispatch(getAfterSchools());
  }, [dispatch]);

  const handleSearch = () => {
    setOpen(true);
  };
  const handleEditOpen = (item) => {
    setEditOpen(true);
    setInfo(item);
  };

  return (
    <Box p={1} m={1}>
      <Box
        display="flex"
        flexDirection="row"
        justifyContent="space-between"
        alignItems="center"
        style={{ width: "100%", marginBottom: 5 }}
      >
        <Typography variant="h5">방과후교사 정보 관리(방세아)</Typography>
        <Button
          variant="contained"
          color="secondary"
          size="small"
          startIcon={<AddBoxRounded />}
          onClick={handleSearch}
        >
          추가
        </Button>
      </Box>
      <Divider light style={{ marginTop: 5, marginBottom: 5 }} />
      <Box style={{ minHeight: "600px" }}>
        <TableContainer component={Paper}>
          <Table size="small" aria-label="dense">
            <TableHead style={{ backgroundColor: "#eefafa" }}>
              <TableRow>
                <TableCell style={{ width: "5%" }}>no.</TableCell>
                <TableCell style={{ width: "20%" }}>교실(class)</TableCell>
                <TableCell style={{ width: "15%" }}>교사명</TableCell>
                <TableCell style={{ width: "15%" }}>휴대전화</TableCell>
                <TableCell style={{ width: "10%" }}>시작일</TableCell>
                <TableCell style={{ width: "10%" }}>종료일</TableCell>
                <TableCell style={{ width: "25%", textAlign: "center" }}>
                  상세
                </TableCell>
              </TableRow>
            </TableHead>
          </Table>
          <TableBody>
            {afterSchools.length &&
              afterSchools.map((item, index) => (
                <TableRow key={item.afterSchoolId}>
                  <TableCell style={{ width: "5%" }}>{index + 1}</TableCell>
                  <TableCell style={{ width: "20%" }}>{item.title}</TableCell>
                  <TableCell style={{ width: "15%" }}>{item.name}</TableCell>
                  <TableCell style={{ width: "15%" }}>{item.phone}</TableCell>
                  <TableCell style={{ width: "10%" }}>
                    {item.startDay}
                  </TableCell>
                  <TableCell style={{ width: "10%" }}>{item.endDay}</TableCell>
                  <TableCell style={{ width: "25%", textAlign: "center" }}>
                    <Button
                      variant="contained"
                      size="small"
                      onClick={() => handleEditOpen(item)}
                      startIcon={<EditRounded />}
                    >
                      수정
                    </Button>
                  </TableCell>
                </TableRow>
              ))}
          </TableBody>
        </TableContainer>
      </Box>
      {/* dialog */}
      <AdminAddAfterSchool {...{ open, setOpen }} />
      <AdminEditAfterSchool
        {...{ editOpen, setEditOpen, info, setInfo, initial }}
      />
    </Box>
  );
};

export default withStyles(styles)(AdminAfterSchool);

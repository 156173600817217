import React, { useState, useEffect, useRef, useCallback } from "react";
import { Link } from "react-router-dom";
import smoothscroll from "smoothscroll-polyfill";
import jwtDecode from "jwt-decode";
import axios from "axios";
// Redux stuff
import { useSelector, useDispatch } from "react-redux";
import { getUserData, logoutUser } from "../../redux/actions/userActions";
import { SET_AUTHENTICATED } from "../../redux/types";
// components
import logo from "../../logo.svg";
// MUI stuff
import withStyles from "@material-ui/core/styles/withStyles";
import Appbar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Tooltip from "@material-ui/core/Tooltip";
import IconButton from "@material-ui/core/IconButton";
import MenuRoundedIcon from "@material-ui/icons/MenuRounded";
import {
  Grid,
  Hidden,
  SwipeableDrawer,
  Link as MuiLink,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Button,
  Avatar,
  Typography,
  Divider,
  Tabs,
  Tab,
  Fab,
  Box,
  ListSubheader,
  Collapse,
  Badge,
} from "@material-ui/core";
import {
  ArrowBackRounded,
  Notifications,
  ExpandLess,
  ExpandMore,
  KeyboardArrowUpRounded,
  Security,
  CardGiftcardRounded,
  AssignmentTurnedInRounded,
  AssignmentRounded,
  AssignmentIndRounded,
  OndemandVideoRounded,
  SettingsApplicationsRounded,
  AccountBalanceRounded,
} from "@material-ui/icons";
import useScrollTrigger from "@material-ui/core/useScrollTrigger";
import Zoom from "@material-ui/core/Zoom";

const styles = (theme) => ({
  ...theme.userPages,
  DrawerWidth: {
    backgroundColor: "#dedede",
    width: 270, // 드로워 좌우 넓이
    height: "100%",
    marginTop: 0,
  },
  cont: {
    color: theme.palette.primary.main,
    flexGrow: 1,
  },
  // responsive hidden
  mobileHidden: {
    visibility: "visible",
    [theme.breakpoints.down("xs")]: {
      visibility: "hidden",
    },
  },
  webHidden: {
    visibility: "visible",
    [theme.breakpoints.up("sm")]: {
      visibility: "hidden",
    },
  },
  // toolbar

  topAppbar: {
    backgroundColor: "#eeeeee",
    color: "#ffffff",
    position: "absolute",
    boxShadow: "none",
    [theme.breakpoints.down("xs")]: {
      position: "fixed",
      backgroundColor: "white",
    },
  },
  toolbarCustomized: {
    //
    alignItems: "center", //
    padding: 0,
  },
  secondAppbar: {
    boxShadow: "none",
  },

  toolbarMargin: {
    [theme.breakpoints.down("xs")]: {
      paddingLeft: 12,
      paddingRight: 12,
    },
    height: 70, //이게 작동
  },
  fab: {
    position: "fixed",
    bottom: theme.spacing(10),
    right: theme.spacing(4),
    zIndex: 1250,
  },
  button: {
    margin: theme.spacing(3),
  },
  search: {
    backgroundColor: "#949494",
    borderRadius: 19,
    width: 261,
    height: 37,
    opacity: 0.58,
    color: "#ffffff",
  },
});

const Navbar = (props) => {
  const { classes, menu, window } = props;

  const [isOpen, setIsOpen] = useState(false);
  const [value, setValue] = useState(menu ? menu : 0);
  //const [menuexpand, setMenuexpand] = useState(false);
  const [menuexpand1, setMenuexpand1] = useState(false);

  const { authenticated, credentials, badge } = useSelector(
    (state) => state.user
  );
  const dispatch = useDispatch();

  // 자동 로그아웃
  const token = localStorage.FBIdToken;

  useEffect(() => {
    if (token) {
      const decodedToken = jwtDecode(token);
      if (decodedToken.exp * 1000 < Date.now()) {
        //authenticated = false;
        dispatch(logoutUser(props.history));
        window.location.href = "/";
      } else {
        //authenticated = true;
        dispatch({ type: SET_AUTHENTICATED });
        axios.defaults.headers.common["Authorization"] = token;
        dispatch(getUserData());
      }
    }
  }, [token, dispatch, window, props.history]);

  const handleExpand1 = useCallback(
    (e) => {
      setMenuexpand1(!menuexpand1);
    },
    [menuexpand1]
  );

  const toggleDrawer = useCallback(
    (event) => {
      if (
        event &&
        event.type === "keydown" &&
        (event.key === "Tab" || event.key === "Shift")
      ) {
        return;
      }
      setIsOpen(!isOpen);
    },
    [isOpen]
  );

  const handleChange = useCallback((event, newValue) => {
    setValue(newValue);
  }, []);

  const trigger = useScrollTrigger({
    disableHysteresis: true,
    threshold: 100,
  });
  const toRef = useRef();
  const toTop = useCallback(() => {
    // window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
    const anc = toRef.current;
    if (window) {
      window.scrollTo({ behavior: "smooth", top: 0 });
    } else {
      anc.scrollIntoView({ behavior: "smooth", block: "center" });
    }
  }, [window]);

  smoothscroll.polyfill();

  let isAdmin = false;
  let isHakwon = false;
  let isUser = false;
  let isAfterSchool = false;

  if (authenticated && credentials.level === 8) {
    isAfterSchool = true;
  } else if (authenticated && credentials.level > 5) {
    isUser = true;
  } else if (credentials.level > 1) {
    isHakwon = true;
  } else if (credentials.level >= 0) {
    isAdmin = true;
  }
  const handleLogout = useCallback(() => {
    dispatch(logoutUser(props.history));
  }, [dispatch, props.history]);

  return (
    <div ref={toRef}>
      <Appbar className={classes.topAppbar}>
        <Toolbar className={classes.toolbarCustomized}>
          <Grid
            container
            direction="column"
            justifyContent="flex-start"
            alignItems="center"
          >
            <Grid
              item
              container
              direction="row"
              justifyContent="space-between"
              alignItems="center"
              className={classes.toolbarMargin}
            >
              <Grid item xs={4} sm={5}>
                <Tooltip
                  title="메뉴"
                  placement="bottom"
                  className={classes.webHidden}
                >
                  <IconButton
                    edge="start"
                    color="primary"
                    arial-label="menu"
                    onKeyDown={toggleDrawer}
                    onClick={toggleDrawer}
                  >
                    <MenuRoundedIcon />
                  </IconButton>
                </Tooltip>
              </Grid>
              <Grid item xs={4} sm={2} style={{ textAlign: "center" }}>
                <Tooltip title="홈" placement="bottom">
                  <MuiLink component={Link} to="/">
                    <img
                      id="logo"
                      src={logo}
                      width="98px"
                      height="55px"
                      alt="logo"
                    />{" "}
                    {/* width='73.5px' height='35px' */}
                  </MuiLink>
                </Tooltip>
              </Grid>
              <Grid item xs={4} sm={4} style={{ textAlign: "right" }}>
                {authenticated ? (
                  <div>
                    <IconButton>
                      <Badge
                        badgeContent={badge}
                        color="primary"
                        overlap="rectangular"
                      >
                        <Notifications color="disabled" />
                      </Badge>
                    </IconButton>

                    <IconButton edge="end" component={Link} to="/mypage">
                      <Avatar
                        style={{ width: 33, height: 33 }}
                        color="secondary"
                        src={credentials.profileImage}
                      />
                    </IconButton>
                  </div>
                ) : (
                  <div>
                    <Button color="primary" component={Link} to="/login">
                      <Typography variant="button">로그인</Typography>
                    </Button>
                    <Hidden only="xs">
                      <Button color="primary" component={Link} to="/signup">
                        <Typography variant="button">회원가입</Typography>
                      </Button>
                    </Hidden>
                  </div>
                )}
              </Grid>
            </Grid>
            <Grid item sm={1}></Grid>
            <Hidden only="xs">
              <Grid item xs={12} container>
                <div className={classes.cont}>
                  <Appbar
                    color="inherit"
                    position="relative"
                    className={classes.secondAppbar}
                  >
                    <Tabs
                      value={value}
                      onChange={handleChange}
                      //variant= 'fullWidth'
                      indicatorColor="primary"
                      //textColor="#fff" //
                      centered
                      //variant="scrollable" //
                      //scrollButtons="on" //
                      aria-label="메뉴"
                      color="primary" // added
                    >
                      <Tab
                        label="뮤직플레이"
                        component={Link}
                        to="/gogo/about"
                      />
                      <Tab label="수업 신청" component={Link} to="/lesson" />
                      <Tab label="이용 안내" component={Link} to="/help/qna" />
                      {(isUser || isHakwon || isAdmin || isAfterSchool) && (
                        <Tab
                          label="마이 클래스"
                          component={Link}
                          to="/myclass"
                        />
                      )}

                      {(isHakwon || isAdmin || isAfterSchool) && (
                        <Tab label="수업 영상" component={Link} to="/video" />
                      )}
                      {(isHakwon || isAdmin) && (
                        <Tab
                          label="수업 관리"
                          component={Link}
                          to="/lessonmanager"
                        />
                      )}
                      {(isHakwon || isAdmin) && (
                        <Tab
                          label="학원 관리"
                          component={Link}
                          to="/manager/main"
                        />
                      )}

                      {isAdmin && (
                        <Tab
                          label="관리자메뉴"
                          component={Link}
                          to="/admin/role"
                        />
                      )}
                    </Tabs>
                  </Appbar>
                </div>
              </Grid>
            </Hidden>
          </Grid>
        </Toolbar>
      </Appbar>
      {/* 스크롤TOP*/}

      <Box>
        <SwipeableDrawer
          anchor="left"
          open={isOpen}
          onClose={toggleDrawer}
          onOpen={toggleDrawer}
          className={classes.drawerPaper}
        >
          <List
            component="nav"
            aria-labelledby="nested-list-subheader"
            subheader={
              <ListSubheader
                component="div"
                id="nested-list-subheader"
                style={{ backgroundColor: "#d6d6d6", height: 160 }}
              >
                <div>
                  <IconButton
                    edge="start"
                    arial-label="close"
                    onKeyDown={toggleDrawer}
                    onClick={toggleDrawer}
                  >
                    <ArrowBackRounded />
                  </IconButton>
                </div>
                <Box className={classes.avatar} align="center">
                  {authenticated ? (
                    <Box>
                      <IconButton
                        component={Link}
                        to="/mypage"
                        onClick={toggleDrawer}
                      >
                        <Avatar
                          src={credentials.profileImage}
                          style={{ width: 55, height: 55 }}
                        />
                      </IconButton>
                      <Typography variant="body2" style={{ marginBottom: 15 }}>
                        {credentials.name}
                      </Typography>
                    </Box>
                  ) : (
                    <Box>
                      <IconButton
                        component={Link}
                        to="/login"
                        onClick={toggleDrawer}
                      >
                        <Avatar
                          src="/broken-image.jpg"
                          style={{ width: 55, height: 55, color: "#787878" }}
                        />
                      </IconButton>
                      <Typography variant="body2" style={{ marginBottom: 15 }}>
                        로그인
                      </Typography>
                    </Box>
                  )}
                </Box>
              </ListSubheader>
            }
            className={classes.DrawerWidth}
          >
            {/* <Box align='center'> // FIXME: 가맹원검색
                            <TextField variant='outlined' placeholder='가맹원 검색' className={classes.search} />
                        </Box> */}

            <Divider light variant="middle" />
            <ListItem button onClick={handleExpand1} style={{ marginTop: 5 }}>
              <ListItemIcon>
                <CardGiftcardRounded />
              </ListItemIcon>
              <ListItemText>뮤직플레이</ListItemText>
              {menuexpand1 ? <ExpandLess /> : <ExpandMore />}
            </ListItem>
            <Collapse in={menuexpand1} timeout="auto" unmountOnExit>
              <List component="div" disablePadding>
                <ListItem
                  button
                  style={{ paddingLeft: 30 }}
                  component={Link}
                  to="/gogo/about"
                  onClick={toggleDrawer}
                >
                  <ListItemIcon></ListItemIcon>
                  <ListItemText>- 뮤직플레이 소개</ListItemText>
                </ListItem>
                <List component="div" disablePadding>
                  <ListItem
                    button
                    style={{ paddingLeft: 30 }}
                    component={Link}
                    to="/gogo/curriculum"
                    onClick={toggleDrawer}
                  >
                    <ListItemIcon></ListItemIcon>
                    <ListItemText>- 연간교육계획안</ListItemText>
                  </ListItem>
                </List>
              </List>
            </Collapse>

            <ListItem
              button
              component={Link}
              to="/lesson"
              onClick={toggleDrawer}
            >
              <ListItemIcon>
                <AssignmentTurnedInRounded />
              </ListItemIcon>
              <ListItemText primary="수업 신청" />
            </ListItem>
            <ListItem
              button
              component={Link}
              to="/help/qna"
              onClick={toggleDrawer}
            >
              <ListItemIcon>
                <AssignmentRounded />
              </ListItemIcon>
              <ListItemText>이용안내</ListItemText>
            </ListItem>

            {(isUser || isHakwon || isAdmin) && (
              <ListItem
                button
                component={Link}
                to="/myclass"
                onClick={toggleDrawer}
              >
                <ListItemIcon>
                  <AssignmentIndRounded />
                </ListItemIcon>
                <ListItemText>마이클래스</ListItemText>
              </ListItem>
            )}
            {(isHakwon || isAdmin) && (
              <div>
                <Divider light variant="middle" />
                <ListItem
                  button
                  component={Link}
                  to="/video"
                  onClick={toggleDrawer}
                >
                  <ListItemIcon>
                    <OndemandVideoRounded />
                  </ListItemIcon>
                  <ListItemText>수업영상</ListItemText>
                </ListItem>

                <ListItem
                  button
                  component={Link}
                  to="/lessonmanager"
                  onClick={toggleDrawer}
                >
                  <ListItemIcon>
                    <SettingsApplicationsRounded />
                  </ListItemIcon>
                  <ListItemText>수업관리</ListItemText>
                </ListItem>
                <ListItem
                  button
                  component={Link}
                  to="/manager/main"
                  onClick={toggleDrawer}
                >
                  <ListItemIcon>
                    <AccountBalanceRounded />
                  </ListItemIcon>
                  <ListItemText>학원관리</ListItemText>
                </ListItem>
              </div>
            )}
            {isAdmin && (
              <div>
                <Divider light variant="middle" />
                <ListItem
                  button
                  component={Link}
                  to="/admin/role"
                  onClick={toggleDrawer}
                >
                  <ListItemIcon>
                    <Security />
                  </ListItemIcon>
                  <ListItemText>관리자메뉴</ListItemText>
                </ListItem>
              </div>
            )}
            <Divider light variant="middle" />
            {authenticated ? (
              <Box align="center">
                <Button
                  color="primary"
                  size="small"
                  variant="contained"
                  className={classes.button}
                  onClick={handleLogout}
                >
                  로그아웃
                </Button>
              </Box>
            ) : (
              <Box align="center">
                <Button
                  color="primary"
                  size="small"
                  variant="contained"
                  className={classes.button}
                  component={Link}
                  to="/login"
                  onClick={toggleDrawer}
                >
                  로그인
                </Button>
                <Button
                  color="secondary"
                  size="small"
                  variant="contained"
                  className={classes.button}
                  component={Link}
                  to="/signup"
                  onClick={toggleDrawer}
                >
                  회원가입
                </Button>
              </Box>
            )}
          </List>
        </SwipeableDrawer>
      </Box>
      <Zoom in={trigger}>
        <div onClick={toTop} role="presentation">
          <Fab
            color="secondary"
            size="small"
            aria-label="To TOP"
            className={classes.fab}
          >
            <KeyboardArrowUpRounded />
          </Fab>
        </div>
      </Zoom>
    </div>
  );
};

export default withStyles(styles)(Navbar);

import React from 'react';
import { Link } from 'react-router-dom';
// component
import Oneday1 from '../../images/oneday_1.png';
import Oneday2 from '../../images/oneday_2.png';
import Oneday3 from '../../images/oneday_3.png';
import Oneday4 from '../../images/oneday_4.png';
import Oneday5 from '../../images/oneday_5.png';
import Oneday6 from '../../images/oneday_6.png';
import Oneday7 from '../../images/oneday_7.png';
import Oneday8 from '../../images/oneday_8.png';
import Oneday9 from '../../images/oneday_9.png';
import LogoRounded from '../../images/logo_rounded.png';
import Slogan from '../../images/slogan.jpg';
// Redux stuff
import { useDispatch } from 'react-redux';
import { getEnrollProductGroup } from '../../redux/actions/enrollActions';
// mui
import { makeStyles, withStyles, useTheme } from '@material-ui/core/styles';
import { Box, Grid, Typography, Divider, Chip, useMediaQuery, Paper } from '@material-ui/core';



const useStyles = makeStyles((theme) => ({
    header: {
        backgroundColor: '#fff',
    },
    img: {
        height: 255,
        display: 'block',
        overflow: 'hidden',
        width: '90%',
        objectFit: 'cover'
    },
    whiteFixed: {
        display: 'block',
        backgroundColor: '#ffffff',
        paddingBottom: theme.spacing(1),
        paddingRight: theme.spacing(1),
        paddingLeft: theme.spacing(1),
        position: 'fixed',
        zIndex: 1000,

        [theme.breakpoints.up('sm')]: {
            right: 0,
            top: 118,
            width: 150,
            paddingTop: theme.spacing(3),
        },
        [theme.breakpoints.up('xl')]: {
            width: 200,
            right: 50,
            paddingTop: theme.spacing(3),
        },
        [theme.breakpoints.down('xs')]: {
            width: '100%',
            left: 0,
            bottom: 0,
            paddingTop: theme.spacing(1),
        }
    },
    grayBack: {
        backgroundColor: '#e7e7e7'
    },
    greenBack: {
        backgroundColor: '#e1f0e4'
    },
    halfBox: {
        backgroundColor: '#88479b',
        padding: theme.spacing(1),
        cursor: 'pointer'
    },
    halfBox2: {
        backgroundColor: '#a777b3',
        padding: theme.spacing(1),
        cursor: 'pointer'
    },
    greenRound: {
        backgroundColor: theme.palette.secondary.main,
        borderRadius: 20,
        width: 200
    },
    gogo4Img: {
        width: '90%',
    },
    peach: {
        backgroundColor: '#fae8dc'
    },
    borderleft: {
        border: 'solid 5px',
        borderColor: theme.palette.primary.main,
        borderRadius: 20,
        backgroundColor: '#fff',
        marginLeft: theme.spacing(5),

        [theme.breakpoints.down('xs')]: {
            marginLeft: theme.spacing(1),
            paddingTop: theme.spacing(3),
            height: 200
        }
    },
    borderright: {
        border: 'solid 5px',
        borderColor: theme.palette.primary.main,
        borderRadius: 20,
        backgroundColor: '#fff',
        marginRight: theme.spacing(5),

        [theme.breakpoints.down('xs')]: {
            marginRight: theme.spacing(1),
            paddingTop: theme.spacing(3),
            height: 200
        }
    },
    logorounded: {
        width: 150,
        height: 80,
        position: 'absolute',
        left: '50%',
        top: '50%',
        transform: 'translate(-50%, -60%)'
    },
    backPrimary: {
        backgroundColor: theme.palette.primary.main,
        marginRight: theme.spacing(3),
        marginLeft: theme.spacing(3),
        [theme.breakpoints.down('xs')]: {
            marginRight: theme.spacing(0),
            marginLeft: theme.spacing(0),
        }
    },
    greenBox: {
        backgroundColor: '#80d39f',
        width: 100,
        height: 28,

    },
    greenBox2: {
        backgroundColor: theme.palette.secondary.main,
        width: 100,
        height: 28
    },
    step1: {
        backgroundColor: '#52dbff',
        borderRadius: 50, width: 100, height: 100,
        [theme.breakpoints.down('xs')]: {
            borderRadius: 40, width: 80, height: 80,
        },
        [theme.breakpoints.up('md')]: {
            borderRadius: 75, width: 150, height: 150,
        },
    },
    step2: {
        backgroundColor: '#3ac5fe',
        borderRadius: 50, width: 100, height: 100,
        [theme.breakpoints.down('xs')]: {
            borderRadius: 40, width: 80, height: 80,
        },
        [theme.breakpoints.up('md')]: {
            borderRadius: 75, width: 150, height: 150,
        },
    },
    step3: {
        backgroundColor: '#1db3ff',
        borderRadius: 50, width: 100, height: 100,
        [theme.breakpoints.down('xs')]: {
            borderRadius: 40, width: 80, height: 80,
        },
        [theme.breakpoints.up('md')]: {
            borderRadius: 75, width: 150, height: 150,
        },
    },
    step4: {
        backgroundColor: '#01a1ff',
        borderRadius: 50, width: 100, height: 100,
        [theme.breakpoints.down('xs')]: {
            borderRadius: 40, width: 80, height: 80,
        },
        [theme.breakpoints.up('md')]: {
            borderRadius: 75, width: 150, height: 150,
        },
    },
    font: {
        fontWeight: 'bold',
        fontSize: 14,
        [theme.breakpoints.down('xs')]: {
            fontSize: 11
        },
        [theme.breakpoints.up('md')]: {
            marginTop: theme.spacing(1),
            fontSize: 18
        },
    }
}));
const PurpleTypo = withStyles({
    root: { color: '#88479b' }
})(Typography);

const SkyTypo = withStyles({
    root: { color: '#00a2fe' }
})(Typography);
const WhiteTypo = withStyles({
    root: { color: '#ffffff' }
})(Typography);

const EnrollOneday = (props) => {
    const classes = useStyles();
    const theme = useTheme();
    const matches = useMediaQuery(theme.breakpoints.down('xs'));
    const dispatch = useDispatch();

    const handleProduct = (name, productId, productGroupId, productGroupName, categoryName) => {
        localStorage.setItem('productName', name);
        localStorage.setItem('productId', productId);
        localStorage.setItem('productGroupId', productGroupId);
        localStorage.setItem('productGroupName', productGroupName);
        localStorage.setItem('categoryName', categoryName);
        dispatch(getEnrollProductGroup(productGroupId));
    };

    return (
        <Box>
            <Box p={3} m={1} className={classes.header} align='center'>
                <Typography variant='subtitle1'>처음 뮤직플레이를 만나는 아이들을 위한</Typography>
                <PurpleTypo display='inline' variant='subtitle1'>뮤직플레이 원데이 클래스</PurpleTypo>
                <Typography display='inline' variant='subtitle1'>입니다</Typography>

            </Box>
            {/* float */}
            <Paper className={classes.whiteFixed} align='center'>
                {
                    matches ?
                        <Box>
                            <Typography variant='subtitle2'>처음 뮤직플레이를 만나는 아이들을 위한</Typography>
                            <PurpleTypo variant='subtitle2'><b>뮤직플레이 원데이 클래스</b></PurpleTypo>
                            <Box height={5} align='center' />
                        </Box>
                        :
                        <Box>
                            <Typography variant='subtitle2'>처음 뮤직플레이를</Typography>
                            <Typography variant='subtitle2'>만나는 아이들을 위한</Typography>
                            <PurpleTypo variant='subtitle2'><b>뮤직플레이</b></PurpleTypo>
                            <PurpleTypo variant='subtitle2'><b>원데이 클래스</b></PurpleTypo>
                            <Box height={15} align='center' />
                        </Box>

                }
                <Typography variant='subtitle2' display='inline'> 20,000원</Typography>
                <Typography variant='subtitle2' display='inline'> (1회)</Typography>
                <Box height={5} />
                {
                    matches ?
                        <Grid container direction='row'>
                            <Grid item xs={6} component={Link} to='/enrollstep2'>
                                <Box
                                    p={1}
                                    align='center'
                                    style={{ backgroundColor: '#88479b', cursor: 'pointer', marginLeft: theme.spacing(2), marginRight: theme.spacing(2) }}
                                    onClick={() => handleProduct('뮤직플레이 원데이 4-5세', 'sOjJwaCrMxIMqst181l7', 'musicgogoOneDayClass', "뮤직플레이 원데이클래스", '4-5세')}
                                >
                                    <WhiteTypo variant='subtitle2'> 4-5세 수업신청</WhiteTypo>
                                </Box>

                            </Grid>
                            <Grid item xs={6} component={Link} to='/enrollstep2'>
                                <Box p={1}
                                    align='center'
                                    style={{ marginLeft: theme.spacing(2), marginRight: theme.spacing(2), marginBottom: theme.spacing(2) }}
                                    className={classes.halfBox2}
                                    onClick={() => handleProduct('뮤직플레이 원데이 6-7세', 'sOjJwaCrMxIMqst181l7', 'musicgogoOneDayClass', "뮤직플레이 원데이클래스", '6-7세')}
                                >
                                    <WhiteTypo variant='subtitle2'> 6-7세 수업신청</WhiteTypo>
                                </Box>

                            </Grid>
                        </Grid>
                        :
                        <Box>
                            <Box m={1} component={Link} to='/enrollstep2'>
                                <Box
                                    p={1}
                                    align='center'
                                    style={{ backgroundColor: '#88479b', cursor: 'pointer' }}
                                    onClick={() => handleProduct('뮤직플레이 원데이 4-5세', 'sOjJwaCrMxIMqst181l7', 'musicgogoOneDayClass', "뮤직플레이 원데이클래스", '4-5세')}
                                >
                                    <WhiteTypo variant='subtitle2'> 4-5세 수업신청</WhiteTypo>
                                </Box>
                            </Box>
                            <Box m={1} component={Link} to='/enrollstep2'>
                                <Box p={1}
                                    align='center'
                                    className={classes.halfBox2}
                                    onClick={() => handleProduct('뮤직플레이 원데이 6-7세', 'sOjJwaCrMxIMqst181l7', 'musicgogoOneDayClass', "뮤직플레이 원데이클래스", '6-7세')}
                                >
                                    <WhiteTypo variant='subtitle2'> 6-7세 수업신청</WhiteTypo>
                                </Box>
                            </Box>
                        </Box>
                }
            </Paper>
            {/* end of Float */}



            <Box p={1}>
                <Box height={30} />
                <Typography variant='h4'><b>수업 정보</b></Typography>
                <Divider light variant='middle' style={{ marginTop: 10, marginBotton: 10 }} />
                <Box height={10} />
                <Box p={1} m={1}>
                    <SkyTypo variant='subtitle1' gutterBottom>수업 정보</SkyTypo>
                    <Typography variant='subtitle1'>Ⅰ 새로운 친구,토끼와 거북을 만나요! (4-5세) </Typography>
                    <Typography variant='subtitle1'>Ⅱ 어린음악대가 될래요!(4-5세)</Typography>
                    <Typography variant='subtitle1'>Ⅰ 인디언마을로 놀러가요(6-7세)</Typography>
                    <Typography variant='subtitle1'>Ⅱ 인디언마을로 놀러가요(6-7세)</Typography>
                </Box>

                <Box p={1} m={1}>
                    <SkyTypo variant='subtitle1' gutterBottom>수업 시간</SkyTypo>
                    <Typography variant='subtitle1'>30~40분 </Typography>
                </Box>
                <Box p={1} m={1}>
                    <SkyTypo variant='subtitle1' gutterBottom>수강 가능일</SkyTypo>
                    <Typography variant='subtitle1'>수업 일시 예약(각 학원별 상이함)</Typography>
                </Box>
                <Box p={1} m={1}>
                    <SkyTypo variant='subtitle1' gutterBottom>학습 연령</SkyTypo>
                    <Typography variant='subtitle1'>4~5세 / 6~7세</Typography>
                </Box>
                <Box p={1} m={1}>
                    <SkyTypo variant='subtitle1' gutterBottom>환불 정책</SkyTypo>
                    <Typography variant='subtitle1'>원데이 클래스 패키지를 수령 후 개봉한 경우 환불 대상에서 제외됩니다.</Typography>
                    <Typography variant='subtitle1'>패키지 미개봉시에는 반품 확인 후 환불이 진행되며, 교구 패키지가 배송준비중에 있을 때 취소요청을 하신 경우, 배송업체 확인 후 취소절차가 진행됩니다.</Typography>
                    <Typography variant='subtitle1'>(배송이 이미 시작된 경우 왕복배송비를 부담하셔야 합니다.)</Typography>
                </Box>
                <Box height={30} />
                <Typography variant='h4'><b>교육 목표</b></Typography>
                <Divider light variant='middle' style={{ marginTop: 10, marginBotton: 10 }} />
                <Box height={10} />
                <Box p={1} m={1}>
                    <SkyTypo variant='subtitle1' gutterBottom>1.소리와 음에 대한 새로운 발견과 경험의 다양한 접근 방법을 제시합니다.</SkyTypo>
                    <Typography variant='subtitle1'>-다양한 악기를 제시하고, 아이들로 하여금 새로운 연주 방법들을 발견하도록 합니다. </Typography>
                    <Typography variant='subtitle1'>-주변에 있는 물건으로 악기를 만들어 보고, 새로운 소리를 경험하도록 합니다.</Typography>
                </Box>
                <Box p={1} m={1}>
                    <SkyTypo variant='subtitle1' gutterBottom>2.아이의 독창성을 인정하면서 주변의 목소리에 귀 기울이는 사회정서를 발달시킵니다.</SkyTypo>
                    <Typography variant='subtitle1'>-자기조절능력을 발휘하고, 모든 감정을 경험, 탐색하는 능력을 키워줍니다.</Typography>
                    <Typography variant='subtitle1'>-아이의 자존감과 공동체의식을 키워, 공동체와 소통하고 협동하는 긍정적인 또래 관계를 경험하게 합니다.</Typography>
                </Box>
                <Box p={1} m={1}>
                    <SkyTypo variant='subtitle1' gutterBottom>3.체험하는 놀이수업과 이론수업을 병행함으로써 이해력과 성취도를 높여 창의성을 키웁니다.</SkyTypo>
                    <Typography variant='subtitle1'>-음악교구를 활용한 자기주도적 학습으로 두뇌발달에 도움을 주고 융합적인 사고능력을 키웁니다.</Typography>
                    <Typography variant='subtitle1'>-오감을 자극하고 반응하며 추상적인 개념을 구체화하는 다감각적 학습을 경험하고, 신체활동을 통해 음악개념과 용어를 배웁니다.</Typography>
                </Box>
                <Box p={1} m={1}>
                    <SkyTypo variant='subtitle1' gutterBottom>4.시대별, 문화별, 영역별 감상 활동을 통해 음악적 공감대를 넓힙니다.</SkyTypo>
                    <Typography variant='subtitle1'>-시대와 문화, 장르를 모두 아우르는 폭넓은 음악 감상과 경험의 기회를 제공합니다.</Typography>
                </Box>

                <Box height={30} />
                <Typography variant='h4'><b>수업 특징</b></Typography>
                <Divider light variant='middle' style={{ marginTop: 10, marginBotton: 10 }} />
                <Box height={10} />
                <Box p={1} m={1}>
                    <SkyTypo variant='subtitle1' gutterBottom><b>4~5세</b></SkyTypo>
                    <Typography variant='subtitle1'>-음악에 첫발을 내딛는 어린음악가를 위한 창의력, 상상력, 오감체험의 음악창의 수업 </Typography>
                    <Typography variant='subtitle1'>-유아의 호기심을 음악적 경험으로 이끌어 내어 상상력을 자극함으로써 어휘력 및 자기표현 능력 발달 </Typography>
                </Box>
                <Box p={1} m={1}>
                    <SkyTypo variant='subtitle1' gutterBottom><b>6~7세</b></SkyTypo>
                    <Typography variant='subtitle1'>-음악의 여러 영역을 폭넓고 자유롭게 활동하는 열정적인 음악가를 위한 감성, 인성 성장의 음악표현수업 </Typography>
                    <Typography variant='subtitle1'>-음악용어와 기초적인 음악적 개념을 소개함으로써 음악교육의 기초를 형성 </Typography>
                </Box>
                <Box height={40} />
                <Box align='center'>
                    <Box align='center' className={classes.greenRound}>
                        <WhiteTypo variant='subtitle1'>뮤직플레이 교육 효과</WhiteTypo>
                    </Box>
                    <Box height={15} />
                    <Typography variant='h4' color='secondary' display='inline'>창의력</Typography>
                    <Typography variant='h4' display='inline'>을 갖춘 아이로</Typography><br />
                    <Typography variant='h4' display='inline'>말랑말랑한 </Typography>
                    <Typography variant='h4' display='inline' color='primary'>우뇌</Typography>
                    <Typography variant='h4' display='inline'>로,</Typography>
                    <Typography variant='h4'>미래형 인재를 만드는 최고의 교육</Typography>
                </Box>
                <Box>
                    <Grid container direction='row'>
                        <Grid item xs={6} sm={3}>
                            <Box align='center' p={2} m={1}>
                                <img src={Oneday1} alt='instru' className={classes.gogo4Img} />
                                <Chip color='secondary' label='악기연주' />
                            </Box>
                        </Grid>
                        <Grid item xs={6} sm={3}>
                            <Box align='center' p={2} m={1}>
                                <img src={Oneday2} alt='instru' className={classes.gogo4Img} />
                                <Chip color='primary' label='신체활동' />
                            </Box>
                        </Grid>
                        <Grid item xs={6} sm={3}>
                            <Box align='center' p={2} m={1}>
                                <img src={Oneday3} alt='instru' className={classes.gogo4Img} />
                                <Chip color='secondary' label='음악이론' />
                            </Box>
                        </Grid>
                        <Grid item xs={6} sm={3}>
                            <Box align='center' p={2} m={1}>
                                <img src={Oneday4} alt='instru' className={classes.gogo4Img} />
                                <Chip color='primary' label='감상&표현' />
                            </Box>
                        </Grid>
                    </Grid>
                </Box>
            </Box>
            <Box className={classes.peach} align='center'>
                <Box height={50} />
                <Typography variant='h4'><b>음악을 특별하게 만나는 시간!</b></Typography>
                <Typography display='inline' color='primary' variant='h4'><b>MUSIC </b></Typography>
                <Typography display='inline' color='secondary' variant='h4'><b> GOGO</b></Typography>
                <Typography display='inline' variant='h4'><b> 포인트</b></Typography>
                <Box height={20} />
                <Grid container direction='row' style={{ position: 'relative' }}>
                    <Grid item xs={6}>
                        <Box align='center' p={matches ? 1 : 2} m={1} className={classes.borderleft}>
                            <Typography color='primary' variant='subtitle1' display='inline'>놀이</Typography>
                            <Typography variant='subtitle1' display='inline'>로 만나는 음악</Typography>
                            <Box height={10} />
                            {
                                matches ?
                                    <Box>
                                        <Typography variant='body2'>재밌고! 신나고!</Typography>
                                        <Typography variant='body2'>즐겁고!</Typography>
                                        <Typography variant='body2'>특별한</Typography>
                                        <Typography variant='body2'>음률체험교실에서</Typography>
                                        <Typography variant='body2'>음악을 느껴보는</Typography>
                                        <Typography variant='body2'>시간</Typography>
                                    </Box> :
                                    <Box>
                                        <Typography variant='body2'>재밌고! 신나고! 즐겁고!</Typography>
                                        <Typography variant='body2'>특별한 음률체험교실에서</Typography>
                                        <Typography variant='body2'>음악을 느껴보는 시간</Typography>
                                    </Box>

                            }

                        </Box>
                    </Grid>
                    <Grid item xs={6}>
                        <Box align='center' p={matches ? 1 : 2} m={1} className={classes.borderright}>
                            <Typography color='primary' variant='subtitle1' display='inline'>깊게</Typography>
                            <Typography variant='subtitle1' display='inline'> 만나는 음악</Typography>
                            <Box height={10} />
                            {
                                matches ?
                                    <Box>
                                        <Typography variant='body2'>음악 전 영역</Typography>
                                        <Typography variant='body2'>누리과정과</Typography>
                                        <Typography variant='body2'>음악교과와 </Typography>
                                        <Typography variant='body2'>연계하여</Typography>
                                        <Typography variant='body2'>깊게 만나는 시간</Typography>
                                    </Box> :
                                    <Box>
                                        <Typography variant='body2'>음악 전 영역 누리과정과</Typography>
                                        <Typography variant='body2'>음악교과와 연계하여</Typography>
                                        <Typography variant='body2'>깊게 만나는 시간</Typography>
                                    </Box>
                            }

                        </Box>
                    </Grid>
                    <Grid item xs={6}>
                        <Box align='center' p={matches ? 1 : 2} m={1} className={classes.borderleft}>
                            <Typography color='primary' variant='subtitle1' display='inline'>경험</Typography>
                            <Typography variant='subtitle1' display='inline'>으로 만나는 음악</Typography>
                            <Box height={10} />

                            <Typography variant='body2'>4가지 영역으로</Typography>
                            <Typography variant='body2'>음악을 </Typography>
                            <Typography variant='body2'>다양하게</Typography>
                            <Typography variant='body2'>경험해보는 시간</Typography>

                        </Box>
                    </Grid>
                    <Grid item xs={6}>
                        <Box align='center' p={matches ? 1 : 2} m={1} className={classes.borderright}>
                            <Typography color='primary' variant='subtitle1' display='inline'>온+오프라인</Typography>
                            <Typography variant='subtitle1' display='inline'>으로 </Typography>
                            <Typography variant='subtitle1'>만나는 음악</Typography>
                            <Box height={10} />
                            {
                                matches ?
                                    <Box>
                                        <Typography variant='body2'>영상 속의 </Typography>
                                        <Typography variant='body2'>소리쌤을 만나며</Typography>
                                        <Typography variant='body2'>아이 스스로</Typography>
                                        <Typography variant='body2'>성장하는 시간</Typography>

                                    </Box> :
                                    <Box>
                                        <Typography variant='body2'>영상 속의 소리쌤을 만나며</Typography>
                                        <Typography variant='body2'>아이 스스로</Typography>
                                        <Typography variant='body2'>성장하는 시간</Typography>
                                    </Box>
                            }

                        </Box>
                    </Grid>
                    <img src={LogoRounded} alt='logo' className={classes.logorounded} />

                </Grid>
                <Box height={30} />
            </Box>
            <Box align='center'>
                <Box height={50} />
                {
                    matches ?
                        <Box>
                            <Typography variant='h3'>뮤직플레이 원데이클래스</Typography>
                            <Typography variant='h3'>"어떤 수업을 하나요?"</Typography>
                        </Box>
                        : <Typography variant='h3'>뮤직플레이 원데이클래스 "어떤 수업을 하나요?"</Typography>
                }

            </Box>
            <Box align='center' className={classes.peach} p={2} m={2}>
                <Box className={classes.backPrimary} p={1}>
                    <WhiteTypo variant='subtitle1'># 4-5세반 # 스토리텔링으로 만나는 음악 # 토끼와 거북이</WhiteTypo>
                </Box>
                <Box height={10} />
                {
                    matches ?
                        <Box align='left'>
                            <Typography variant='body1' display='inline'>- 토끼와 거북 머리띠 만들기를 통해 </Typography>
                            <PurpleTypo variant='body1' display='inline'><b> 역할놀이</b> 경험</PurpleTypo><br />
                            <Typography variant='body1' display='inline'>- 재미있는 토끼와 거북 음원과 <b>스토리텔링</b>으로 </Typography>
                            <PurpleTypo variant='body1' display='inline'><b> 상상력</b> 자극</PurpleTypo><br />
                            <Typography variant='body1' display='inline'>- 리듬스틱과 <b>수박쉐이커 만들기</b>를 통해 두드리고 흔드는 리듬악기 경험을 통해 </Typography>
                            <PurpleTypo variant='body1' display='inline'><b> 리듬감각 </b> 향상</PurpleTypo><br />
                            <Typography variant='body1' display='inline'>- 스크래치 종이를 긁으면서 자신만의 색채로 </Typography>
                            <PurpleTypo variant='body1' display='inline'> 생각 표현</PurpleTypo><br />
                            <Typography variant='body1' display='inline'>- 활동지를 통해 </Typography>
                            <PurpleTypo variant='body1' display='inline'> 수업내용 복습</PurpleTypo><br />
                        </Box>
                        :
                        <Box>
                            <Typography variant='body1' display='inline'>토끼와 거북 머리띠 만들기를 통해 </Typography>
                            <PurpleTypo variant='body1' display='inline'><b>역할놀이</b> 경험</PurpleTypo><br />
                            <Typography variant='body1' display='inline'>재미있는 토끼와 거북 음원과 <b>스토리텔링</b>으로 </Typography>
                            <PurpleTypo variant='body1' display='inline'><b>상상력</b> 자극</PurpleTypo><br />
                            <Typography variant='body1' display='inline'>리듬스틱과 <b>수박쉐이커 만들기</b>를 통해 두드리고 흔드는 리듬악기 경험을 통해 </Typography>
                            <PurpleTypo variant='body1' display='inline'><b>리듬감각 </b> 향상</PurpleTypo><br />
                            <Typography variant='body1' display='inline'>스크래치 종이를 긁으면서 자신만의 색채로 </Typography>
                            <PurpleTypo variant='body1' display='inline'>생각 표현</PurpleTypo><br />
                            <Typography variant='body1' display='inline'>활동지를 통해 </Typography>
                            <PurpleTypo variant='body1' display='inline'>수업내용 복습</PurpleTypo><br />
                        </Box>
                }

            </Box>
            <Box align='center' className={classes.peach} p={2} m={2}>
                <Box className={classes.backPrimary} p={1}>
                    <WhiteTypo variant='subtitle1'>＃6-7세반 ＃ 스토리텔링으로 만나는 음악 ＃인디언 마을로 놀러가요</WhiteTypo>
                </Box>
                <Box height={10} />
                {
                    matches ?
                        <Box align='left'>
                            <Typography variant='body1' display='inline'>- 인디언 모자 만들기를 통해 </Typography>
                            <PurpleTypo variant='body1' display='inline'> 타문화 경험</PurpleTypo><br />
                            <Typography variant='body1' display='inline'>- 스토리텔링과 융합된 클래식음악을 통해 </Typography>
                            <PurpleTypo variant='body1' display='inline'><b> 음악적 상상력</b> 자극</PurpleTypo><br />
                            <Typography variant='body1' display='inline'>- 발오선 활동을 통해 </Typography>
                            <PurpleTypo variant='body1' display='inline'><b> 음악적 기초</b> 경험</PurpleTypo><br />
                            <Typography variant='body1' display='inline'>- 퍼즐만들기를 통해 아이의 </Typography>
                            <PurpleTypo variant='body1' display='inline'> 생각 표현</PurpleTypo><br />
                            <Typography variant='body1' display='inline'>- 활동지를 통해 </Typography>
                            <PurpleTypo variant='body1' display='inline'> 수업내용 복습</PurpleTypo><br />
                        </Box>
                        :
                        <Box>
                            <Typography variant='body1' display='inline'>인디언 모자 만들기를 통해 </Typography>
                            <PurpleTypo variant='body1' display='inline'>타문화 경험</PurpleTypo><br />
                            <Typography variant='body1' display='inline'>스토리텔링과 융합된 클래식음악을 통해 음악적 </Typography>
                            <PurpleTypo variant='body1' display='inline'><b>상상력</b> 자극</PurpleTypo><br />
                            <Typography variant='body1' display='inline'>발오선 활동을 통해 </Typography>
                            <PurpleTypo variant='body1' display='inline'><b>음악적 기초</b> 경험</PurpleTypo><br />
                            <Typography variant='body1' display='inline'>퍼즐만들기를 통해 아이의 </Typography>
                            <PurpleTypo variant='body1' display='inline'>생각 표현</PurpleTypo><br />
                            <Typography variant='body1' display='inline'>활동지를 통해 </Typography>
                            <PurpleTypo variant='body1' display='inline'>수업내용 복습</PurpleTypo><br />
                        </Box>
                }

            </Box>
            <Box align='center' className={classes.grayBack}>
                <Box height={40} />
                <Typography variant='h4'>원데이 클래스 "뮤직플레이" 구성</Typography>
                <Box height={20} />
                <Grid container direction='row'>
                    <Grid item xs={12} sm={4}>
                        <Box p={2} m={1} align='center' style={{ backgroundColor: '#fff' }}>
                            <img src={Oneday5} alt='comp' className={classes.gogo4Img} />
                            <Box height={10} />
                            <Typography color='primary' variant='subtitle1' gutterBottom>온라인 강의</Typography>
                            <Typography variant='body2' gutterBottom>온라인 강의 + 오프라인 수업</Typography>
                        </Box>
                    </Grid>
                    <Grid item xs={12} sm={4}>
                        <Box p={2} m={1} align='center' style={{ backgroundColor: '#fff' }}>
                            <img src={Oneday6} alt='comp' className={classes.gogo4Img} />
                            <Box height={10} />
                            <Typography color='primary' variant='subtitle1' gutterBottom>DIY 악기만들기</Typography>
                            <Typography variant='body2' gutterBottom>악기를 직접 만들어 연주해보는 시간
</Typography>
                        </Box>
                    </Grid>
                    <Grid item xs={12} sm={4}>
                        <Box p={2} m={1} align='center' style={{ backgroundColor: '#fff' }}>
                            <img src={Oneday7} alt='comp' className={classes.gogo4Img} />
                            <Box height={10} />
                            <Typography color='primary' variant='subtitle1' gutterBottom>스토리텔링 음악</Typography>
                            <Typography variant='body2' gutterBottom>이야기로
                            만나는 즐거운
음악놀이시간</Typography>
                        </Box>
                    </Grid>
                </Grid>
                <Box height={30} />
            </Box>
            <Box align='center' className={classes.greenBack}>
                <Box height={40} />
                <Typography display='inline' variant='h3'>원데이 클래스</Typography>
                <Typography display='inline' color='secondary' variant='h3'>뮤직플레이 패키지</Typography>
                <Box height={20} />
                <Grid container direction='row'>
                    <Grid item xs={12} sm={6}>
                        <Box m={4} align='center' style={{ backgroundColor: '#fff' }}>
                            <Box className={classes.greenBox}>
                                <WhiteTypo>4-5세</WhiteTypo>
                            </Box>
                            <Box>
                                <img src={Oneday8} alt='comp' width='100%' />
                            </Box>

                            <Box height={10} />
                            <Box p={2}>
                                <Typography variant='body2' gutterBottom>리듬스틱,토끼와 거북 머리띠 만들기 재료,수박쉐이커 만들기 재료(도안,수박씨), 스크래치 종이와 펜,피아노 건반 마카북, 마카펜
</Typography></Box>
                        </Box>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <Box m={4} align='center' style={{ backgroundColor: '#fff' }}>
                            <Box className={classes.greenBox2}>
                                <WhiteTypo>6-7세</WhiteTypo>
                            </Box>
                            <Box>
                                <img src={Oneday9} alt='comp' width='100%' />
                            </Box>
                            <Box height={10} />
                            <Box p={2}>
                                <Typography variant='body2' gutterBottom>리듬스틱,인디언 모자 만들기 재료(모자도안,깃털,모자 연결끈,인디언장식,마카북 (높은 음자리표), 마카펜,그림퍼즐</Typography>
                            </Box>
                        </Box>
                    </Grid>

                </Grid>
                <Box height={30} />
            </Box>
            <Box align='center' style={{ backgroundColor: '#ffffea' }}>
                <Box p={3}>
                    <Typography variant='h3' display='inline'>뮤직플레이 </Typography>
                    <SkyTypo variant='h3' display='inline'>원데이 클래스</SkyTypo>
                    <Typography variant='h3' display='inline'> 신청방법</Typography>
                </Box>
                <Box height={10} />
                <Box p={matches ? 1 : 10}>
                    <Grid container direction='row'>
                        <Grid item xs={3}>
                            <Box m={1} className={classes.step1}>
                                <WhiteTypo variant='caption'>step.1</WhiteTypo>
                                <Box height={10} />
                                <WhiteTypo className={classes.font}>원데이클래스</WhiteTypo>
                                <WhiteTypo className={classes.font}>수업 선택</WhiteTypo>
                            </Box>
                        </Grid>
                        <Grid item xs={3}>
                            <Box m={1} className={classes.step2}>
                                <WhiteTypo variant='caption'>step.2</WhiteTypo>
                                <Box height={15} />
                                <WhiteTypo className={classes.font}>연령선택</WhiteTypo>

                            </Box>
                        </Grid>
                        <Grid item xs={3}>
                            <Box m={1} className={classes.step3} >
                                <WhiteTypo variant='caption'>step.3</WhiteTypo>
                                <Box height={15} />
                                <WhiteTypo className={classes.font}>주변 학원 검색</WhiteTypo>

                            </Box>
                        </Grid>
                        <Grid item xs={3}>
                            <Box m={1} className={classes.step4} >
                                <WhiteTypo variant='caption'>step.4</WhiteTypo>
                                <Box height={10} />
                                <WhiteTypo className={classes.font}>수강 가능</WhiteTypo>
                                <WhiteTypo className={classes.font}>날짜 선택</WhiteTypo>

                            </Box>
                        </Grid>
                    </Grid>
                </Box>

            </Box>
            <Box height={30} />
            <Box>
                <img src={Slogan} alt='slogan' style={{ width: '100%' }} />
            </Box>



        </Box>
    );
}

export default EnrollOneday;

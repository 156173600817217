import React from 'react';

// components
import Cancel1 from '../../images/cancel_1.png';
import Cancel2 from '../../images/cancel_2.png';
// MUI stuff
import withStyles from '@material-ui/core/styles/withStyles';
import { Grid, Divider, Typography, Box } from '@material-ui/core';


const styles = (theme) => ({
    ...theme.userPages,
    margin: {
        margin: theme.spacing(2)
    },
    root: {
        marginTop: 20,
        padding: theme.spacing(1)
    },
    box: {
        margin: theme.spacing(3)
    },
    img1: {
        margin: theme.spacing(1),
        width: '100%',
        maxWidth: 353
    },
    img2: {
        margin: theme.spacing(1),
        width: '100%',
        maxWidth: 351
    },


});

const InfoCancel = props => {
    const { classes } = props;

    return (
        <Box className={classes.root}>
            <Box className={classes.box}>
                <Typography variant='h4' color='primary' gutterBottom>수업시간 변경 안내</Typography>
                <Box height={30} />
                <Typography variant='body1' gutterBottom>뮤직플레이, 스마트피아노 수업은 악기와 교구패키지를
                사용하여 진행되는 수업으로 각 수업은 정원제로
진행되며, 결제시간 기준으로 '선착순 마감'됩니다.</Typography>
                <Typography variant='body1' display='inline' gutterBottom>선택한 수업 날짜를 변경하고자 할 때에는 해당일
                로부터 </Typography>
                <Typography color='primary' variant='body1' display='inline' gutterBottom>4일 전 까지(공휴일 제외) 해당 학원에 전화로
신청해야 합니다.</Typography>
                <Box height={30} />
                <Grid container>
                    <Grid item xs={12} sm={6}>
                        <img src={Cancel1} alt='안내1' className={classes.img1} />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <img src={Cancel2} alt='안내2' className={classes.img2} />
                    </Grid>
                </Grid>
            </Box>
            <Divider light variant='middle' style={{ marginTop: 20, marginBottom: 20 }} />


            <Box className={classes.box}>
                <Typography variant='h4' color='primary' gutterBottom>수업 취소 안내</Typography>
                <Box height={20} />
                <Typography variant='body1'>
                    · 수업 당일에 환불을 요청하는 경우는 수업진행
                    여부에 관계없이 수업을 진행한 것으로 간주하여
                    처리합니다.
                </Typography>
                <Typography variant='body1'>
                    · 수업일 이후에 환불을 요청하는 경우는 '평생교육법 시행령 제23조 학습비 반환 기준'에 의거하여 환불됩니다.
                </Typography>
                <Box height={15} />
                <Typography variant='subtitle1'>[평생교육법 시행령 제23조 학습비 반환 기준]</Typography>
                <Typography variant='body1' color='error' >- 환불요청일이 수강기간 시작 전인 경우</Typography>
                <Typography variant='body1' color='error' style={{ marginLeft: 15 }}> : 해당월 수강료 전액</Typography>
                <Typography variant='body1' >- 환불요청일이 ⅓경과 전인 경우</Typography>
                <Typography variant='body1' style={{ marginLeft: 15 }}> : 해당월 수강료의 ⅔ 환불</Typography>
                <Typography variant='body1'>- 환불요청일이 ½경과 전인 경우</Typography>
                <Typography variant='body1' style={{ marginLeft: 15 }}> : 해당월 수강료의 ½ 환불</Typography>
                <Typography variant='body1'>- 환불요청일이 ½경과 후인 경우 </Typography>
                <Typography variant='body1' style={{ marginLeft: 15 }}> : 없음</Typography>
                <Typography variant='body1' color='error' >- 수강기간 1개월(30일) 초과 수업의 환불 요청의 경우</Typography>
                <Typography variant='body1' color='error' style={{ marginLeft: 15 }}> : 환불 요청일이 속한 해당월의 반환 대상 결제대금(수강기간 1개월(30일)이내인 경우의 기준에 따라 산출한 금액)과 나머지 월의 반환 대상 결제 대금 전액 </Typography>
                <Box height={15} />
                <Typography variant='body1'>· 신청한 수업이 정원 미달인 경우에는 부득이하게
  폐강될 수 있으며, 수업료는 '전액 환불'됩니다.</Typography>
                <Typography variant='body1'>· 원데이 클래스는 배송받으신 교구 패키지를 개봉
  하였을 경우 환불이 불가능합니다.</Typography>
                <Typography variant='body1'>· 교구 패키지가 배송 준비 중에 있을 때 취소 요청
                을 하신 경우, 배송업체 확인 후 취소 절차가 진행
                됩니다.</Typography>
                <Typography variant='body1'> (배송이 이미 시작된 경우에는 고객이
  왕복배송비를 부담해야 합니다.)</Typography>
            </Box>
            <Divider light variant='middle' style={{ marginTop: 20, marginBottom: 20 }} />


            <Box className={classes.box}>
                <Typography variant='h4' color='primary' gutterBottom>교재 배송 안내</Typography>
                <Box height={20} />
                <Typography variant='body1'>· 배송업체 : cj대한통운</Typography>
                <Typography variant='body1'>
                    · 배송료는 판매가에 포함되어 있습니다.
                </Typography>
                <Typography variant='body1'>· 배송기간 : 당일 3시 전 입금시, 익일 배송</Typography>
                <Typography variant='body1' style={{marginLeft: 15}}> ※명절연휴나 택배사 물량증가시에는 이틀 정도
     더 소요될 수 있습니다.</Typography>
                <Typography variant='body1'>· 배송조회 : 마이페이지에 송장번호를 확인해
  주세요.</Typography>



            </Box>

        </Box>
    );
};


export default withStyles(styles)(InfoCancel);
import React from 'react';
import { Link } from 'react-router-dom';
// MUI stuff
import withStyles from '@material-ui/core/styles/withStyles';
import { Grid, Typography, Link as MuiLink } from '@material-ui/core';



const styles = (theme) => ({

    ...theme.userPages,
    footerText: {
        [theme.breakpoints.up('sm')]: {
            fontSize: 14
        },
        fontSize: 12
    }

});

const Footer = (props) => {
    const { classes } = props;
    
    //console.log('%c       ', 'font-size: 100px; background: url(http://musicgogo.co.kr/static/media/logo.520b73df.svg) no-repeat;');
    //console.log("::: 뮤직플레이 서비스를 이용해 주셔서 감사합니다. 개발 및 시스템에 문의 사항이 있거나, 채용에 관심이 있으신 분은 musicgogo@jueun-edu.com으로 연락주세요. ")
    // 
    return (

        <div className='footer-container'>
            <Grid container >
                <Grid item sm={1} />
                <Grid item xs sm={10}>
                    <Typography style={{ color: 'rgb(243,243,243)', marginBottom: 5 }} variant='body2'><MuiLink color='inherit' component={Link} to='/terms'>이용약관</MuiLink>  | <MuiLink color='inherit' component={Link} to='/privacy'><b>개인정보취급방침</b></MuiLink>  | <MuiLink color='inherit' component={Link} to='/privacy'>이메일무단수집거부</MuiLink></Typography>
                    <Typography className={classes.footerText} variant='body2'>법인명(상호) : 주은교육(주) | 대표자(성명) : 홍혜숙, 김덕주 </Typography>
                    <Typography className={classes.footerText} variant='body2' display='inline'>사업자등록번호: [503-81-80292] </Typography>
                    <Typography className={classes.footerText} variant='body2' display='inline'>통신판매업신고: 제2008-대구달서-0204호 </Typography><MuiLink className={classes.footerText} rel="noreferer" color='inherit' style={{ cursor: 'pointer' }} underline='none' target='_blank' onClick={() => window.open('https://www.ftc.go.kr/bizCommPop.do?wrkr_no=5038180292', '주은교육(주)', 'width=600px, height=600px')}> [사업자정보확인]</MuiLink>
                    <Typography className={classes.footerText} variant='body2'>개인정보보호 책임자 : 김덕주 | 관리자 : 원혜인 (musicgogo@jueun-edu.com)</Typography>
                    <Typography className={classes.footerText} variant='body2' display='inline'>주소 (서울본부) : 서울특별시 송파구 동남로 157 GB타워 4층 | </Typography>
                    <Typography className={classes.footerText} variant='body2' display='inline'>(대구본부) : 대구광역시 달서구 달구벌대로 1130, 401호 </Typography>
                    <Typography className={classes.footerText} variant='body2'>전화: 1688-7970 | 이메일: musicgogo@jueun-edu.com</Typography>
                    <Typography className={classes.footerText} variant='body2'>copyrightⓒ2008 JUEUNEDU co.,Ltd. All rights reserved.</Typography>
                </Grid>
                <Grid item sm={1} />
            </Grid>

            {/* <div style={{ width: 29, height: 29, backgroundColor: '#000', textAlign: 'center' }}><Typography variant='caption'>TOP</Typography></div> */}
        </div>

    )
}

export default withStyles(styles)(Footer);

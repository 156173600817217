import React, { useState, useEffect } from 'react';
import dayjs from 'dayjs';
// Redux stuff
import { useSelector, useDispatch } from 'react-redux';
import { getProducts, getCurriculumsByProduct, getCurriculum } from '../../redux/actions/dataActions';
// components
import DialogEditDataGrid from '../../components/layout/DialogEditDataGrid';
// MUI stuff
import withStyles from '@material-ui/core/styles/withStyles';
import { DataGrid } from '@material-ui/data-grid';

import {
    Typography,
    Box,
    Button,
    FormControl,
    FormControlLabel,
    Radio,
    RadioGroup,
    Dialog,
    DialogContent,
    DialogActions,
    DialogTitle,
    TextField,
    Select,
    MenuItem,
} from '@material-ui/core';

const styles = (theme) => ({
    ...theme.userPage,
    table: {
        minWidth: 650,
    },
    whiteback: {
        backgroundColor: '#fff',
    },
});
export const AdminProduct = (props) => {
    const { classes } = props;
    const [dialogOpen, setDialogOpen] = useState(false);
    // eslint-disable-next-line no-unused-vars
    const [editContent, setEditContent] = useState();
    const { products, curriculums, dataLoading } = useSelector((state) => state.data);
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(getProducts());
    }, [dispatch]);

    const [prod, setProd] = useState('상품 아이디(productId)를 선택하여 주십시오.');
    const [cate, setCate] = useState('플레이어');

    const handleChangeProd = (e) => {
        setProd(e.target.value);
        dispatch(getCurriculumsByProduct(e.target.value));
    };

    const curris = curriculums
        .filter((c) => (cate === 'all' ? true : c.category === cate))
        .map((c) => ({
            id: c.curriculumId,
            name: c.curriculumName,
            product: c.productId,
            yyyymm: c.yyyymm,
            order: c.yyyymm + '_' + c.curriculumName,
            status: c.status,
        }));
    const curriCol = [
        { field: 'id', headerName: 'ID', width: 150 },
        { field: 'name', headerName: 'name', width: 200 },
        { field: 'product', headerName: 'Product', width: 100 },
        { field: 'yyyymm', headerName: 'YYYYMM', width: 100 },
        { field: 'order', headerName: 'order', width: 200 },
        { field: 'status', headerName: 'status', width: 100 },
    ];
    const editCurri = (e) => {
        const thisId = e[0];
        dispatch(getCurriculum(thisId));
        setDialogOpen(true);
    };

    const columns = [
        { field: 'id', headerName: 'ID', width: 150 },
        { field: 'name', headerName: 'name', width: 200 },
        { field: 'price', headerName: 'price', width: 100 },
        { field: 'packages', headerName: '패키지', width: 100 },
        { field: 'status', headerName: 'status', width: 100 },
    ];
    const rows = products.map((prod) => ({
        id: prod.productId,
        name: prod.productName,
        price: prod.price,
        packages: prod.packages,
        status: prod.status,
    }));

    const initialInfo = {
        productId: '',
        productName: '',
        category: '',
        count: 0,
        createdAt: 0,
        createdBy: '',
        desc: {},
        startDate: 0,
        endDate: 0,
        imageUrl: [],
        isOpenAtMain: false,
        isRepeat: false,
        lessonType: '',
        link: '',
        order: 0,
        packages: '',
        price: 0,
        productAge: {},
        productGroupId: '',
        regularPayment: '',
        settleType: 'fixed',
        settlement: 0,
        status: 'open',
        targetRange: 'all',
        timespan: '1회',
    };

    const [pDialog, setPDialog] = useState(false);
    const [productInfo, setProductInfo] = useState(initialInfo);

    const editProduct = (event) => {
        setPDialog(true);
        setProductInfo(products.filter((prd) => prd.productId === event.data.id)[0]);
    };
    const onProductDialogClose = () => {
        setPDialog(false);
        setProductInfo(initialInfo);
    };
    const onChangeProductInfo = (e) => {
        setProductInfo({
            ...productInfo,
            [e.target.name]: e.target.value,
        });
    };
    const onChangeProductInfoDesc = (e) => {
        setProductInfo({
            ...productInfo,
            desc: {
                ...productInfo.desc,
                [e.target.name]: e.target.value,
            },
        });
    };
    const onChangeProductInfoAge = (e) => {
        setProductInfo({
            ...productInfo,
            productAge: {
                ...productInfo.productAge,
                [e.target.name]: e.target.value,
            },
        });
    };
    const onChangeProductInfoDate = (e) => {
        setProductInfo({
            ...productInfo,
            [e.target.name]: dayjs(e.target.value) / 1000,
        });
    };

    return (
        <Box p={1} m={1}>
            <h1>Product 관리</h1>
            <Typography variant="h5">1. Products 목록 조회</Typography>
            <Typography>- 수정/추가는 시스템관리자에게 요청하십시오.</Typography>
            <Box style={{ width: '100%', height: 380 }}>
                <DataGrid
                    rows={rows}
                    columns={columns}
                    pageSize={5}
                    checkboxSelection
                    onRowSelected={editProduct}
                    autoHeight
                    className={classes.whiteback}
                />
            </Box>
            <Typography variant="h5">2. 커리큘럼 관리 </Typography>
            <Typography>
                - 연간교육계획안은 Product : 'DzbNwY2Bz5uPXP5r2sX6'의 커리큘럼만 노출하여
                관리합니다.[뮤직플레이,뮤직플레이프리미엄] (*yyyymm은 2021년 연도만 관리합니다. ){' '}
            </Typography>
            <Typography>
                - 수강신청 시,마이클래스 등에 노출되는 '커리큘럼 미리보기' 기능도 역시 2021년도 기준으로만 노출됩니다.
                (매월갱신할 필요가 없음)
            </Typography>
            <FormControl variant="outlined" margin="dense">
                <Select value={prod} onChange={handleChangeProd} fullWidth>
                    <MenuItem value="상품 아이디(productId)를 선택하여 주십시오.">
                        <em>상품 아이디(productId)를 선택하여 주십시오.</em>
                    </MenuItem>
                    {products.map((prd, index) => (
                        <MenuItem key={index} value={prd.productId}>{`${prd.productName} (${prd.productId})`}</MenuItem>
                    ))}
                </Select>
            </FormControl>
            <br />
            <FormControl component="fieldset">
                <RadioGroup
                    aria-label="category"
                    name="category"
                    value={cate}
                    onChange={(e) => setCate(e.target.value)}
                    row
                >
                    <FormControlLabel
                        value={'all'}
                        control={<Radio size="small" />}
                        label={<Typography variant="body2">전체</Typography>}
                    />
                    <FormControlLabel
                        value={'플레이어'}
                        control={<Radio size="small" />}
                        label={<Typography variant="body2">플레이어</Typography>}
                    />
                    <FormControlLabel
                        value={'러너'}
                        control={<Radio size="small" />}
                        label={<Typography variant="body2">러너</Typography>}
                    />
                </RadioGroup>
            </FormControl>
            <Box style={{ width: '100%', height: 1200 }}>
                <DataGrid
                    rows={curris}
                    columns={curriCol}
                    pageSize={20}
                    // onRowSelected={editCurri}
                    autoHeight
                    loading={dataLoading}
                    hideFooter={false}
                    checkboxSelection={false}
                    className={classes.whiteback}
                    onSelectionModelChange = {editCurri}
                />
            </Box>
            <Dialog open={pDialog} fullWidth maxWidth={'sm'}>
                <DialogTitle>
                    <Typography variant="h5">{productInfo.productName}</Typography>
                </DialogTitle>
                <DialogContent>
                    <Typography>
                        ㆍproductId : <strong>{productInfo.productId}</strong>
                    </Typography>
                    <Typography>
                        ㆍ생성일자 : {dayjs(productInfo.createdAt * 1000).format('YYYY-MM-DD HH:mm:ss')}
                    </Typography>
                    <Typography>ㆍ생성자 : {productInfo.createdBy}</Typography>

                    <Box height={20} />
                    <Typography color="primary" variant="subtitle1">
                        기본 정보
                    </Typography>
                    <TextField
                        variant="outlined"
                        label="order (노출순서)"
                        size="small"
                        margin="dense"
                        color="secondary"
                        value={productInfo.order}
                        name="order"
                        onChange={onChangeProductInfo}
                        fullWidth
                        helperText="상품 노출 순서"
                    />
                    <TextField
                        variant="outlined"
                        label="productName"
                        size="small"
                        margin="dense"
                        color="secondary"
                        value={productInfo.productName}
                        name="productName"
                        onChange={onChangeProductInfo}
                        fullWidth
                    />
                    <TextField
                        variant="outlined"
                        label="category(분류)"
                        size="small"
                        margin="dense"
                        color="secondary"
                        value={productInfo.category}
                        name="category"
                        onChange={onChangeProductInfo}
                        fullWidth
                        helperText="MG || PG"
                    />
                    <TextField
                        variant="outlined"
                        label="lessonType(레슨타입)"
                        size="small"
                        margin="dense"
                        color="secondary"
                        value={productInfo.lessonType}
                        name="lessonType"
                        onChange={onChangeProductInfo}
                        fullWidth
                        helperText="regular || premium || trial || home"
                    />
                    <TextField
                        variant="outlined"
                        label="isRepeat(반복여부)"
                        size="small"
                        margin="dense"
                        color="secondary"
                        value={productInfo.isRepeat}
                        name="isRepeat"
                        onChange={onChangeProductInfo}
                        fullWidth
                        helperText="true || false (반복이냐 한번이냐)"
                    />
                    <TextField
                        variant="outlined"
                        label="productGroupId"
                        size="small"
                        margin="dense"
                        color="secondary"
                        value={productInfo.productGroupId}
                        name="productGroupId"
                        onChange={onChangeProductInfo}
                        fullWidth
                        helperText="ProductGroup의 아이디"
                    />
                    <TextField
                        variant="outlined"
                        label="link(링크)"
                        size="small"
                        margin="dense"
                        color="secondary"
                        value={productInfo.link}
                        name="link"
                        onChange={onChangeProductInfo}
                        fullWidth
                        helperText="상세페이지 링크 Url"
                    />
                    <TextField
                        variant="outlined"
                        label="shortName(약칭)"
                        size="small"
                        margin="dense"
                        color="secondary"
                        value={productInfo.shortName}
                        name="shortName"
                        onChange={onChangeProductInfo}
                        fullWidth
                        helperText="배송 메뉴 요약명칭"
                    />

                    <Box height={20} />
                    <Typography color="primary" variant="subtitle1">
                        가격 정보
                    </Typography>
                    <TextField
                        variant="outlined"
                        label="price(가격)"
                        size="small"
                        margin="dense"
                        color="secondary"
                        value={productInfo.price}
                        name="price"
                        onChange={onChangeProductInfo}
                        fullWidth
                    />
                    <TextField
                        variant="outlined"
                        label="settlement(정산금액)"
                        size="small"
                        margin="dense"
                        color="secondary"
                        value={productInfo.settlement}
                        name="settlement"
                        onChange={onChangeProductInfo}
                        fullWidth
                    />

                    <TextField
                        variant="outlined"
                        label="settleType(정산유형)"
                        size="small"
                        margin="dense"
                        color="secondary"
                        value={productInfo.settleType}
                        name="settleType"
                        onChange={onChangeProductInfo}
                        fullWidth
                        helperText="fixed || unfixed"
                    />
                    <TextField
                        variant="outlined"
                        label="regularPayment(정기결제)"
                        size="small"
                        margin="dense"
                        color="secondary"
                        value={productInfo.regularPayment}
                        name="regularPayment"
                        onChange={onChangeProductInfo}
                        fullWidth
                        helperText="6개월 정기결제 || 12개월 정기결제 || null"
                    />

                    <Box height={20} />
                    <Typography color="primary" variant="subtitle1">
                        상태
                    </Typography>
                    <TextField
                        variant="outlined"
                        label="status"
                        size="small"
                        margin="dense"
                        color="secondary"
                        value={productInfo.status}
                        name="status"
                        onChange={onChangeProductInfo}
                        fullWidth
                        helperText="open || closed"
                    />
                    <TextField
                        variant="outlined"
                        label="targetRange(범위 - 전체/학원전용/온라인전용)"
                        size="small"
                        margin="dense"
                        color="secondary"
                        value={productInfo.targetRange}
                        name="targetRange"
                        onChange={onChangeProductInfo}
                        fullWidth
                        helperText="all || hakwon || online"
                    />
                    <Box height={20} />
                    <Typography color="primary" variant="subtitle1">
                        수업구분
                    </Typography>
                    <TextField
                        variant="outlined"
                        label="timespan(월횟수)"
                        size="small"
                        margin="dense"
                        color="secondary"
                        value={productInfo.timespan}
                        name="timespan"
                        onChange={onChangeProductInfo}
                        fullWidth
                        helperText="월4회 || 1회"
                    />
                    <TextField
                        variant="outlined"
                        label="count(총수업횟수-중요)"
                        size="small"
                        margin="dense"
                        color="secondary"
                        value={productInfo.count}
                        name="count"
                        onChange={onChangeProductInfo}
                        fullWidth
                        helperText="1 || 4 || 12 || 24 || 48"
                    />
                    <TextField
                        variant="outlined"
                        label="플레이어 여부"
                        size="small"
                        margin="dense"
                        color="secondary"
                        value={productInfo.productAge.player}
                        name="player"
                        onChange={onChangeProductInfoAge}
                        fullWidth
                        helperText="true || false"
                    />
                    <TextField
                        variant="outlined"
                        label="러너 여부"
                        size="small"
                        margin="dense"
                        color="secondary"
                        value={productInfo.productAge.runner}
                        name="runner"
                        onChange={onChangeProductInfoAge}
                        fullWidth
                        helperText="true || false"
                    />
                    <Box height={20} />
                    <Typography color="primary" variant="subtitle1">
                        노출
                    </Typography>
                    <TextField
                        variant="outlined"
                        label="imageUrl(사용안함)"
                        size="small"
                        margin="dense"
                        color="secondary"
                        value={productInfo.imageUrl[0]}
                        name="imageUrl"
                        fullWidth
                    />
                    <TextField
                        variant="outlined"
                        label="isOpenAtMain(메인에 노출 여부)"
                        size="small"
                        margin="dense"
                        color="secondary"
                        value={productInfo.isOpenAtMain}
                        name="isOpenAtMain"
                        onChange={onChangeProductInfo}
                        fullWidth
                        helperText="true || false"
                    />
                    <Box height={20} />
                    <Typography color="primary" variant="subtitle1">
                        날짜
                    </Typography>
                    <TextField
                        variant="outlined"
                        label="startDate(시작일)"
                        size="small"
                        margin="dense"
                        color="secondary"
                        value={dayjs(productInfo.startDate * 1000).format('YYYY-MM-DD HH:mm:ss')}
                        name="startDate"
                        onChange={onChangeProductInfoDate}
                        fullWidth
                    />
                    <TextField
                        variant="outlined"
                        label="endDate(종료일)"
                        size="small"
                        margin="dense"
                        color="secondary"
                        value={dayjs(productInfo.endDate * 1000).format('YYYY-MM-DD HH:mm:ss')}
                        name="endDate"
                        onChange={onChangeProductInfoDate}
                        fullWidth
                    />
                    <Box height={20} />
                    <Typography color="primary" variant="subtitle1">
                        상세설명
                    </Typography>
                    <TextField
                        variant="outlined"
                        label="d1"
                        size="small"
                        margin="dense"
                        color="secondary"
                        value={productInfo.desc.d1}
                        name="d1"
                        onChange={onChangeProductInfoDesc}
                        fullWidth
                    />
                    <TextField
                        variant="outlined"
                        label="d2"
                        size="small"
                        margin="dense"
                        color="secondary"
                        value={productInfo.desc.d2}
                        name="d2"
                        onChange={onChangeProductInfoDesc}
                        fullWidth
                    />
                    <TextField
                        variant="outlined"
                        label="d3"
                        size="small"
                        margin="dense"
                        color="secondary"
                        value={productInfo.desc.d3}
                        name="d3"
                        onChange={onChangeProductInfoDesc}
                        fullWidth
                    />
                    <TextField
                        variant="outlined"
                        label="d4"
                        size="small"
                        margin="dense"
                        color="secondary"
                        value={productInfo.desc.d4}
                        name="d4"
                        onChange={onChangeProductInfoDesc}
                        fullWidth
                    />
                    <TextField
                        variant="outlined"
                        label="d5"
                        size="small"
                        margin="dense"
                        color="secondary"
                        value={productInfo.desc.d5}
                        name="d5"
                        onChange={onChangeProductInfoDesc}
                        fullWidth
                    />
                    <TextField
                        variant="outlined"
                        label="d6"
                        size="small"
                        margin="dense"
                        color="secondary"
                        value={productInfo.desc.d6}
                        name="d6"
                        onChange={onChangeProductInfoDesc}
                        fullWidth
                    />
                    <TextField
                        variant="outlined"
                        label="packages"
                        size="small"
                        margin="dense"
                        color="secondary"
                        value={productInfo.packages}
                        name="packages"
                        onChange={onChangeProductInfo}
                        fullWidth
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={onProductDialogClose}>닫기</Button>
                </DialogActions>
            </Dialog>
            <DialogEditDataGrid
                dialogOpen={dialogOpen}
                setDialogOpen={setDialogOpen}
                title={'커리큘럼'}
                textContent={
                    '필수 정보는 빠짐이 없이 작성되도록 유의바랍니다. 만일 내용이 없으면, 공백(스페이스바)이라도 입력해주세요.'
                }
                actionButton="변경사항저장"
                value={editContent}
            />
        </Box>
    );
};

export default withStyles(styles)(AdminProduct);

import React from "react";
import { Link } from "react-router-dom";
// components
import ImageBox1 from "../../images/imagebox_3.png";
import ImageBox2 from "../../images/imagebox_2.jpg";
import ImageBox3 from "../../images/imagebox_1.jpg";
import ImageBox4 from "../../images/imagebox_4.jpg";
//import LittleBandLogo from "../../images/littleband_logo.png";
import PianogogoLogo from "../../images/pianogogo_logo.png";
import MusicgogoLogo from "../../logo.svg";
// MUI stuff

import useMediaQuery from "@material-ui/core/useMediaQuery";
import { withStyles, useTheme } from "@material-ui/core/styles";

import { Grid, Box, Typography } from "@material-ui/core";

const styles = (theme) => ({
  ...theme.userPage,
  root: {
    margin: "0 auto 0 auto",
    maxWidth: 1300,
  },
  subroot: {},
  imageBox1: {
    width: "100%",
    height: 200,

    backgroundImage: `url(${ImageBox1})`,
    [theme.breakpoints.down("xs")]: {
      height: 150,
      backgroundPosition: "center center",
    },
  },
  imageBox2: {
    width: "100%",
    height: 200,
    backgroundImage: `url(${ImageBox2})`,
    [theme.breakpoints.down("xs")]: {
      height: 150,
      backgroundPosition: "center center",
    },
  },
  imageBox3: {
    width: "100%",
    height: 200,
    backgroundImage: `url(${ImageBox3})`,
    [theme.breakpoints.down("xs")]: {
      height: 150,
      backgroundPosition: "center center",
    },
  },
  imageBox4: {
    width: "100%",
    height: 200,
    backgroundImage: `url(${ImageBox4})`,
    [theme.breakpoints.down("xs")]: {
      height: 150,
      backgroundPosition: "center center",
    },
  },
  grad1: {
    width: "100%",
    height: 200,
    paddingTop: 75,
    backgroundImage: `linear-gradient(to bottom, rgba(76,76,76,0.8), rgba(18,18,18,0.9)), url(${ImageBox1})`,
    [theme.breakpoints.down("xs")]: {
      height: 150,
      paddingTop: 50,
    },
  },
  grad2: {
    width: "100%",
    height: 200,
    paddingTop: 75,
    backgroundImage: `linear-gradient(to bottom, rgba(172,66,180,0.8), rgba(172,66,180,0.9)), url(${ImageBox2})`,
    [theme.breakpoints.down("xs")]: {
      height: 150,
      paddingTop: 50,
    },
  },
  grad3: {
    width: "100%",
    height: 200,
    paddingTop: 70,
    backgroundImage: `linear-gradient(to bottom, rgba(241,86,39,0.8), rgba(241,86,39,0.9)), url(${ImageBox3})`,
    [theme.breakpoints.down("xs")]: {
      height: 150,
      paddingTop: 50,
    },
  },
  grad4: {
    width: "100%",
    height: 200,
    paddingTop: 75,
    backgroundImage: `linear-gradient(to bottom, rgba(54,175,220,0.8), rgba(54,175,220,0.9)), url(${ImageBox4})`,
    [theme.breakpoints.down("xs")]: {
      height: 150,
      paddingTop: 50,
    },
  },
});
const YellowTypo = withStyles({
  root: { color: "#ffcc00" },
})(Typography);
const WhiteTypo = withStyles({
  root: { color: "#ffffff" },
})(Typography);

export const Banner2 = (props) => {
  const { classes } = props;

  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down("xs"));

  return (
    <Grid container className={classes.root} spacing={matches ? 0 : 2}>
      <Grid
        container
        item
        direction={matches ? "row-reverse" : "row"}
        xs={12}
        sm={6}
      >
        <Grid item xs={6}>
          <Box className={classes.imageBox3}></Box>
        </Grid>
        <Grid item xs={6} component={Link} to="/enroll/regular">
          <Box className={classes.grad3} align="center">
            <WhiteTypo variant="subtitle1">뮤직플레이</WhiteTypo>
            <WhiteTypo variant="caption">musicPLAY</WhiteTypo>
            <br />
            <img src={MusicgogoLogo} alt="logo" width="70px" height="46px" />
            <br />
          </Box>
        </Grid>
      </Grid>
      <Grid container item direction="row" xs={12} sm={6}>
        <Grid item xs={6}>
          <Box className={classes.imageBox1}></Box>
        </Grid>
        <Grid item xs={6} component={Link} to="/enroll/premium">
          <Box className={classes.grad1} align="center">
            <YellowTypo variant="subtitle1">뮤직플레이 프리미엄</YellowTypo>
            <YellowTypo variant="caption">musicPLAY Premium</YellowTypo>
            <br />
            <img src={MusicgogoLogo} alt="logo" width="70px" height="46px" />
            <br />
          </Box>
        </Grid>
      </Grid>

      <Grid
        container
        item
        direction={matches ? "row-reverse" : "row"}
        xs={12}
        sm={6}
      >
        <Grid item xs={6}>
          <Box className={classes.imageBox2}></Box>
        </Grid>
        <Grid item xs={6} component={Link} to="/enroll/oneday">
          <Box className={classes.grad2} align="center">
            <WhiteTypo variant="subtitle1" noWrap>
              뮤직플레이 원데이클래스
            </WhiteTypo>
            <WhiteTypo variant="caption">musicPLAY One Day Class</WhiteTypo>
            <br />
            <img src={MusicgogoLogo} alt="logo" width="70px" height="46px" />
            <br />
          </Box>
        </Grid>
      </Grid>

      <Grid container item direction="row" xs={12} sm={6}>
        <Grid item xs={6}>
          <Box className={classes.imageBox4}></Box>
        </Grid>
        <Grid item xs={6} component={Link} to="/enroll/pianogogo">
          <Box className={classes.grad4} align="center">
            <WhiteTypo variant="subtitle1" noWrap>
              스마트피아노 원데이클래스
            </WhiteTypo>
            <WhiteTypo variant="caption">Smart Piano One Day Class</WhiteTypo>
            <br />
            <img src={PianogogoLogo} alt="logo" width="70px" height="46px" />
            <br />
          </Box>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default withStyles(styles)(Banner2);

import { Typography } from '@material-ui/core';
import React from 'react';
import MainGrid from '../../components/layout/MainGrid';
import SubGrid from '../../components/layout/SubGrid';
import Navbar from '../../components/layout/Navbar';
function Terms() {

    return (
        <>
            <Navbar />
            <MainGrid>
                <SubGrid style={{ padding: 5 }}>
                    <Typography variant='h4'>서비스 이용약관</Typography>
                    <Typography variant='body2'>
                        <br />
                        <br />  제 1 장 총 칙
<br />
                        <br />  제 1 조(목적)
<br />  본 약관은 주은교육(주)(이하 "회사"라 한다)이 "주은교육(주) 홈페이지 중 「스마트뮤직플레이」 (이하 "스마트뮤직플레이"이라 함)"에서 제공하는 교육정보서비스 및 전자상거래 서비스의 이용과 관련하여 "회사"와 이용자의 권리, 의무 및 책임사항을 규정함을 목적으로 합니다.
<br />  ※「PC통신, 무선 등을 이용하는 전자상거래에 대해서도 그 성질에 반하지 않는 한 이 약관을 준용합니다」
<br />
                        <br />제 2 조(용어의 정리)
<br />1. "주은교육(주) 홈페이지 중 「스마트뮤직플레이」(이하 "스마트뮤직플레이"이라 함)"란 스마트뮤직플레이(www.musicgogo.co.kr)을 말합니다.
<br />2. 「스마트뮤직플레이」란 회사가 운영하는 음악 교육 동영상 강의사이트와 교육에 부수된 교재 등 재화 또는 용역(이하 "재화 등"이라 함)을 이용자에게 제공하기 위하여 컴퓨터 등 정보통신설비를 이용하여 재화 등을 거래할 수 있도록 설정한 가상의 영업장을 말합니다.
<br />3. "이용자"란 "스마트뮤직플레이"에 접속하여 이 약관에 따라 "스마트뮤직플레이"가 제공하는 서비스를 받는 회원 및 비회원을 말합니다.
<br />4. "회원"이라 함은 "스마트뮤직플레이"에 개인정보를 제공하여 회원등록을 한 자로서, "스마트뮤직플레이"의 정보를 지속적으로 제공받으며, "스마트뮤직플레이"가 제공하는 서비스를 계속적으로 이용할 수 있는 자를 말합니다.
<br />‘회사’가 정하는 각 ‘회원’의 규정은 다음과 같습니다.
<br />-개인회원: 개인 명의로 회원가입 후 기본적으로 모든 회원에게 부여되는 자격을 가진 회원.
<br />-학원장회원: 어린음악대 학원장 명의로 회원가입 후 기본적으로 모든 회원에게 부여되는 자격을 가진 회원.
<br />5. "비회원"이라 함은 회원에 가입하지 않고 "스마트뮤직플레이"에서 제공하는 서비스를 이용하는 자를 말합니다.
<br />6. "콘텐츠"란 "스마트뮤직플레이"에서 제공하는 온라인 강좌 및 기타 관련정보 등뿐만 아니라, 정보통신망이용촉진 및 정보보호 등에 관한 법률 규정에 의한 정보통신망에서 사용되는 부호ㆍ문자ㆍ음성ㆍ음향ㆍ이미지 또는 영상 등으로 표현된 자료 또는 정보를 말합니다.
<br />7. “아이디(ID)”라 함은 회원의 식별과 서비스의 이용을 위하여 회원이 설정하고 회사가 승인하여 등록된 전자우편주소 또는 소셜 서비스 연동을 통해 수집된 전자우편주소를 말합니다.
<br />8. "비밀번호(PASSWORD)"라 함은 서비스 이용 시, 아이디와 일치되는 회원임을 확인하고, 회원 개인정보 보호를 위하여, 회원 자신이 정한 문자 또는 숫자 등의 조합을 말합니다.
<br />9. "운영자(관리자)"라 함은 서비스의 전반적인 관리와 원활한 운영을 위하여 "회사"에서 선정한 사람 또는 회사를 말합니다.
<br />10. "회원의 게시물"이라 함은 "회사"의 서비스가 제공되는 "스마트뮤직플레이"에 회원이 올린 글, 이미지, 각종 파일 및 링크, 각종 덧글 등의 정보를 의미합니다.
<br />11. "포인트"라 함은 "스마트뮤직플레이" 내에서 활동을 하고 얻은 포인트를 말합니다.
<br />12. 기타: 전항 각호에 해당하는 정의 이외의, 기타 용어의 정의에 대하여는 거래 관행 및 관계 법령에 따릅니다.
<br />
                        <br />제 3 조 (약관 등의 명시와 설명 및 개정)
<br />1. "스마트뮤직플레이"는 이 약관의 내용과 상호 및 대표자 성명, 영업소 소재지 주소(소비자의 불만을 처리할 수 있는 곳의 주소를 포함), 전화번호ㆍ전자우편주소, 사업자등록번호, 개인정보관리책임자등을 이용자가 쉽게 알 수 있도록 홈페이지 초기 서비스화면(전면)에 게시합니다. 다만, 약관의 내용은 이용자가 연결화면을 통하여 볼 수 있도록 할 수 있습니다.
<br />2. "스마트뮤직플레이"는 이용자가 약관에 동의하기에 앞서 약관에 정하여져 있는 내용 중 청약철회, 배송책임, 환불조건 등과 같은 중요한 내용을 이용자가 이해할 수 있게 설명 및 확인할 수 있도록 별도의 연결화면 등을 제공해야 합니다.
<br />3. "스마트뮤직플레이"는 전자상거래 등에서의 소비자보호에 관한 법률, 전자거래기본법, 전자서명법, 정보통신망 이용 촉진 등에 관한 법률, 소비자보호법 등 관련법을 위배하지 않는 범위에서 이 약관을 개정할 수 있습니다.
<br />4. "스마트뮤직플레이"가 약관을 개정할 경우에는 적용일자 및 개정사유를 명시하여 개정약관의 적용일자 14일(이용자에게 불리하거나 중대한 사항의 변경은 30일) 이전부터 적용일자 전일까지 공지하고 기존 이용자에게는 전자우편 등을 통해 발송합니다. 개정약관은 그 적용일자 이후에 체결되는 계약과 이미 체결된 계약 모두에 적용됩니다. 다만 이미 계약을 체결한 이용자가 개정약관 조항의 적용을 받기를 원하지 않는다는 뜻을 개정약관의 공지기간내에 "스마트뮤직플레이"에 송신하여 "회사"의 동의를 받은 경우에는 기존약관 조항이 적용됩니다.
<br />5. 이 약관에서 정하지 아니한 사항과 이 약관의 해석에 관하여는 전자상거래 등에서의 소비자 보호에 관한 법률, 공정거래위원회가 정하는 전자상거래 등에서의 소비자 보호 지침 및 관계법령 또는 상관례에 따릅니다.
<br />6. 기존 회원이 변경된 약관에 동의하지 않는 경우 서비스 이용을 중단하고 회원탈퇴를 할 수 있습니다.
<br />
                        <br />제 4 조 (약관의 효력)
<br />1. 본 약관은 이용자가 약관의 내용에 동의한다는 의사표시를 함으로써 회원가입을 신청하고, "회사"가 그 신청에 대하여 승낙함으로써 효력이 발생합니다.
<br />2. "회사"는 기존 회원이 본 약관 제3조 제4항에 따른 공지 또는 통지에도 불구하고, 동 기간 내에 이의를 제기하지 않는 경우에는 변경된 약관을 승인한 것으로 봅니다.
<br />3. 본 약관에 명시되지 않은 사항에 대해서는 "약관의 규제에 관한 법률", "정보통신망 이용촉진 및 정보보호 등에 관한 법률", "전자상거래 등에서의 소비자보호에 관한 법률" 및 기타 관련법령의 규정에 따릅니다.
<br />
                        <br />제 5 조(회원가입)
<br />1. 이용자는 "스마트뮤직플레이"가 정한 가입 양식에 따라 회원정보를 기입한 후 이 약관에 동의한다는 의사표시를 함으로서 회원가입을 신청합니다.
<br />2. "스마트뮤직플레이"는 제1항과 같이 회원으로 가입할 것을 신청한 이용자 중 다음 각호에 해당하지 않는 한 회원으로 등록합니다.
<br />① 가입신청자가 이전에 회원자격을 상실한 적이 있는 경우. 다만 회원자격 상실 후 3년이 경과한 자로서 "스마트뮤직플레이"의 회원 재가입 승낙을 얻은 경우에는 예외로 합니다.
<br />② 등록 내용에 허위, 기재누락, 오기가 있는 경우
<br />③ 기타 회원으로 등록하는 것이 "스마트뮤직플레이" 운영에 현저히 지장이 있다고 판단되는 경우
<br />3. 회원가입계약의 성립 시기는 "스마트뮤직플레이"의 승낙이 회원에게 도달한 시점으로 합니다.
<br />4. 회원은 등록사항에 변경이 있는 경우, 즉시 전자우편 또는 기타 방법으로 "스마트뮤직플레이"에 그 변경사항을 알려야 합니다.
<br />
                        <br />제 6 조 (만 14세 미만 이용자의 회원가입에 관한 특칙)
<br />1. 만14세 미만의 이용자는 "스마트뮤직플레이"에서 전자적 표시 형태로 제공되는 개인정보취급방침을 충분히 숙지한 후, 반드시 보호자[법정대리인]의 동의를 얻어 회원가입을 신청 하여야 하는 등, 본인의 개인정보를 제공함에 있어 신중을 기해야 합니다.
<br />2. "회사"는 만 14세 미만의 이용자가 회원가입을 신청하는 경우 보호자[법정대리인]의 동의 여부를 확인할 수 있습니다.
<br />3. 만14세 미만의 이용자는 "회사"가 법정대리인에게 전항의 동의사실 여부를 확인 할 수 있도록 회원가입 시, 보호자[법정대리인]의 성명 및 유ㆍ무선 연락처 등을 제공하여야 합니다.
<br />4. "회사"는 보호자[법정대리인] 동의에 대한 확인절차를 거치지 않은 만14세 미만 이용자에 대하여는 회원가입을 불허 또는 취소할 수 있습니다.
<br />5. 만 14세 미만 회원의 보호자[법정대리인]는 자녀에 대한 개인정보의 열람, 정정, 갱신을 요구하거나 회원가입에 대한 동의를 철회할 수 있으며, 이러한 경우에 "회사"는 지체 없이 필요한 조치를 취해야 합니다. 이때 "회사"는 보호자[법정대리인]임을 입증할 수 있는 문서 등의 확인서류 제출을 요청할 수 있습니다.
<br />
                        <br />제 7 조 (회원정보의 변경)
<br />1. 회원은 "스마트뮤직플레이"의 "정보수정" 페이지에서 언제든지 자신의 개인정보를 열람하고 수정할 수 있습니다. 단, 개인정보수정이 악용될 소지가 있다고 판단되는 부분에 대하여는 "회사"가 정보수정을 제한할 수 있습니다.
<br />2. 회원이 전항의 변경사항을 수정하지 않아 발생한 불이익에 대하여 "회사"는 책임지지 않습니다.
<br />
                        <br />제 8 조 ("회원"의 "아이디" 및 "비밀번호"의 관리에 대한 의무)
<br />1. 회원은 아이디와 비밀번호에 대한 관리책임이 있으며, 이를 타인에게 공개하여 제3자가 이용하도록 하여서는 아니 됩니다.
<br />2. 회원은 자신의 아이디 및 비밀번호가 유출되어 제3자에 의해 사용되고 있음을 인지한 경우, 즉시 "회사"에 알리고, "회사"의 안내가 있는 경우에는 그에 따라야 합니다.
<br />3. "회사"는 회원의 개인정보보호 및 기타 서비스 부정이용행위 방지 등을 위하여 회원에게 비밀번호의 변경 등 필요한 조치를 요구할 수 있으며, 회원은 "회사"의 요구가 있는 즉시 "회사"의 요청에 성실히 응해야 합니다.
<br />4. "회사"는 회원이 본조 제2항 및 제3항에 따른 의무를 성실히 이행하지 않아 발생한 불이익에 대하여 책임지지 않습니다.
<br />5. 회원이 고의로 또는 타인이 자신의 아이디를 사용하고 있음을 인지하였으나 이를 묵인하는 등 자신의 아이디 및 비밀번호를 제3자에게 이용하게 하거나 서비스를 이용하도록 방치한 경우 회원은 그에 따른 손해배상책임을 집니다.
<br />
                        <br />제 9 조(회원 탈퇴 및 자격 상실 등)
<br />1. 회원은 "스마트뮤직플레이"에 언제든지 탈퇴를 요청할 수 있으며 "스마트뮤직플레이"는 즉시 회원탈퇴를 처리합니다.
<br />2. 회원이 다음 각호의 사유에 해당하는 경우, "스마트뮤직플레이"는 회원자격을 제한 및 정지시킬 수 있습니다.
<br />① 가입 신청시에 허위 내용을 등록한 경우
<br />② "스마트뮤직플레이"를 이용하여 구입한 재화등의 대금, 기타 이용에 관련하여 회원이 부담하는 채무를 기일에 지급하지 않는 경우
<br />③ "스마트뮤직플레이" 이용을 방해하거나 그 정보를 도용하는 등 전자상거래 질서를 위협하는 경우
<br />④ "스마트뮤직플레이"를 이용하여 법령 또는 이 약관이 금지하거나 공서양속에 반하는 행위를 하는 경우
<br />⑤ "스마트뮤직플레이"에서 회원이 본 약관을 준수하지 않을 경우
<br />⑥ "스마트뮤직플레이" 회원이 아이디 등 회원정보를 "회사"의 동의없이 제 3자에게 공유, 판매, 양도하였거나 이를 목적으로 한 거래행위를 시도한 경우
<br />⑦ "스마트뮤직플레이" 회원 자격을 제한ㆍ정지 시킨 후, 동일한 행위가 2회 이상 반복되거나 3일이내에 그 사유가 시정되지 아니하는 경우
<br />⑧ "스마트뮤직플레이" 회원자격을 상실시키는 경우에는 회원등록을 말소합니다. 이 경우 회원에게 이를 통지하고, 회원등록 말소 전에 최소한 3일 이상의 기간을 정하여 소명할 기회를 부여합니다.
<br />⑨ "스마트뮤직플레이" 이외의 타 회사사이트 등의 운영을 방해한 적이 있거나 강제탈퇴 당한 적이 있는 경우 "회사"의 판단 하에 회원자격을 박탈하거나 회원가입을 거부할 수 있습니다.
<br />3. 약관을 준수하지 않아 발생하는 회원자격 박탈의 경우 유료서비스를 이용중이더라도 강제탈퇴가 가능하며 회원은 환불을 받을 수 없습니다.
<br />
                        <br />제 10 조 (거래조건에 대한 정보의 표시)
<br />1. "회사"는 다음 각호에 해당하는 사항을 해당 콘텐츠 또는 그 포장에 표시합니다.
<br />① 콘텐츠의 명칭, 내용, 가격, 이용기간
<br />② 콘텐츠 이용에 필요한 전자매체의 최소한의 기술사양
<br />③ 환불기준 등 서비스 이용계약의 해지방법 및 효과
<br />④ 이용약관 및 개인정보취급방침
<br />2. "회사"는 전항 각 호의 사항을 "스마트뮤직플레이", 본 이용약관, 개인정보취급방침 등에서 이미 고지하고 있는 경우, 별도로 표시하지 않을 수 있습니다.
<br />3. "회사"가 제공하는 서비스에 대하여 이용기간을 "스마트뮤직플레이", 이용약관 등에 명시하지 않은 경우 그 이용기간은 1년인 것으로 합니다.
<br />
                        <br />제 11 조 (서비스 이용계약의 성립 및 결제방법 등)
<br />1. 회원은 "회사"가 제공하는 다음 각호 또는 이와 유사한 절차에 의하여 콘텐츠 서비스 이용을 신청을 합니다. "회사"는 계약 체결 전, 다음 각 호의 사항에 관하여 회원이 정확하게 이해하고 실수 또는 착오 없이 거래할 수 있도록 정보를 제공합니다.
<br />2. "회사"는 회원이 유료서비스 이용을 신청할 경우, 결제가 완료된 시점에 계약 승낙의 의사표시를 한 것으로 봅니다. 따라서 동영상강좌를 결제한 경우에는 결제완료 시점과 동시에 수강을 시작하는 것으로 봅니다.
<br />3. "회사"는 다음 각호에 해당하면 계약을 승낙하였더라도 언제든지 취소할 수 있습니다.
<br />① 신청 내용에 허위, 기재누락, 오기가 있는 경우
<br />② 기타 구매신청을 승낙한 것이 "스마트뮤직플레이" 운영에 현저히 지장이 있다고 판단하는 경우
<br />4. "스마트뮤직플레이"의 승낙의 의사표시에는 이용자의 구매 신청에 대한 확인 및 판매가능 여부, 구매신청의 정정 취소등에 관한 정보 등을 포함하여야 합니다.
<br />
                        <br />제 12 조 (상품의 구입 및 배송 등)
<br />1. 교재, 악기, 준비물 등 모든 상품(이하 "상품"이라 함)은 "스마트뮤직플레이"에서 구매결제가 완료된 시점으로부터 7일 이내에 배송하는 것을 원칙으로 하고 있으며, 천재지변, 재고 부족 등으로 지연될 수 있습니다.
<br />2. 파손, 파본, 배송오류 등의 문제가 발생한 경우 수령일로부터 3일 이내에 "스마트뮤직플레이"를 통해 "회사"에 알려야 하며 "회사"는 추가 배송비용 없이 재 발송하거나 환불해 드립니다. 이때, 회원은 반드시 배송 받은 상품을 "회사"에 반송해야 합니다.
<br />3. 전항에 해당하지 않는 교환, 환불의 경우 반환에 필요한 비용은 회원이 부담합니다.
<br />4. 상품에 관한 기타사항은 고객센터를 통하여 안내 받을 수 있습니다.
<br />5. "회사"는 제휴를 통하여 제휴 온라인, 오프라인 매장 등을 통해 상품을 위탁하여 발송 및 판매할 수 있습니다.
<br />
                        <br />제 13 조(서비스의 제공 및 변경)
<br />1. "스마트뮤직플레이"는 다음과 같은 업무를 수행합니다.
<br />① 재화 또는 용역에 대한 정보 제공 및 구매계약의 체결
<br />② 구매계약이 체결된 재화 또는 용역의 공급
<br />③ 기타 "스마트뮤직플레이"가 정하는 업무
<br />2. "스마트뮤직플레이"는 재화 또는 용역의 품절 또는 기술적 사양의 변경 등의 경우에는 장차 체결되는 계약에 의해 제공할 재화 또는 용역의 내용을 변경할 수 있습니다. 이 경우에는 변경된 재화 또는 용역의 내용 및 제공일자를 명시하여 현재의 재화 또는 용역의 내용을 게시한 곳에 공지합니다.
<br />3. "스마트뮤직플레이"가 제공하기로 이용자와 계약을 체결한 서비스의 내용을 재화등의 품절 또는 기술적 사양의 변경 등의 사유로 변경할 경우에는 그 사유를 이용자에게 통지합니다.
<br />
                        <br />제 14 조(구매신청)
<br />"스마트뮤직플레이" 이용자는 "스마트뮤직플레이"상에서 다음 또는 이와 유사한 방법에 의하여 구매를 신청하며, "스마트뮤직플레이"는 이용자가 구매신청을 함에 있어서 다음의 각 내용을 알기 쉽게 제공하여야 합니다.
<br />① 재화 등의 검색 및 선택
<br />② 성명, 주소, 전화번호, 전자우편주소 등의 입력
<br />③ 청약철회권이 제한되는 서비스, 배송료 등의 비용부담과 관련한 내용에 대한 확인
<br />④ 재화 등의 구매신청 및 이에 관한 확인 또는 "스마트뮤직플레이"의 확인에 대한 동의
<br />⑤ 결제방법의 선택
<br />
                        <br />
                        <br />제 15 조(서비스의 중단)
<br />1. "스마트뮤직플레이"는 컴퓨터 등 정보통신설비의 보수점검ㆍ교체 및 고장, 통신의 두절 등의 사유가 발생한 경우에는 서비스의 제공을 일시적으로 중단할 수 있습니다.
<br />2. 사업종목의 전환, 사업의 포기, 업체 간의 통합 등의 이유로 서비스를 제공할 수 없게 되는 경우에는 "스마트뮤직플레이"는 이용자에게 통지하고 당초 "스마트뮤직플레이"에서 제시한 조건에 따라 소비자에게 보상합니다. 다만, "회사"에서 제공하는 포인트 등 마일리지 형태의 적립금과 사이버 머니는 지급 대상에서 제외합니다.
<br />3. "회사"의 책임 있는 사유로 서비스가 연속으로 48시간 이상 중단된 경우 중단된 시간의 1.2배에 해당하는 만큼의 시간을 피해보상으로 연장하여 줍니다.
<br />4. 콘텐츠의 정상적 이용이 가능하지만, 사소한 하자가 있는 경우에 있어서 이용자의 피해구제를 위해 사업자는 하자가 있는 온라인 콘텐츠를 인지한 즉시 완전한 온라인 콘텐츠를 가능한 빨리 다시 공급하기 위해 최선을 다합니다.
<br />
                        <br />제 16 조(관계법령상 환불정책)
<br />1. "스마트뮤직플레이"의 본 약관상 환불규정 등은 "약관의 규제에 관한 법률", "온라인 디지털 콘텐츠산업 발전법", "전자상거래 등에서의 소비자보호에 관한 법률" 등을 반영하였습니다.
<br />2. 전 항의 관계법령에 따른 소비자의 청약철회가 불가능한 경우로는,
<br />① 청약철회가 불가능한 콘텐츠에 대한 사실을 표시사항에 포함한 경우
<br />② 시용상품을 제공한 경우
<br />③ 한시적 또는 일부이용 등의 방법을 제공한 경우
<br />3. "스마트뮤직플레이"는 본 조 제2항 ②에 해당하는 사유인 "시용상품"을 제공하고 있으며 "강좌맛보기"등이 이에 해당합니다. 또한 ①의 청약철회가 불가능한 콘텐츠에 대한 사실을 표시사항에 포함하고 있으며, 강의노트를 다운받거나, 유료강좌를 일정수준 이상 수강하였을 때 팝업을 통하여 청약철회가 불가능함에 대한 동의를 받고 있습니다.
<br />
                        <br />제 17 조 (환불규정)
<br />1. "스마트뮤직플레이"는 회원의 편의를 위하여 전 조 제1항의 관계법령에도 불구하고 본 약관을 통하여 회원에게 좀 더 유리한 별도의 환불규정을 적용합니다.
<br />2. 회원은 환불 또는 변경을 유ㆍ무선의 방법을 통하여 "스마트뮤직플레이"에 그 의사를 표시하여야 하며, "스마트뮤직플레이"는 환불요청을 접수하고 회원의 요청과 환불규정을 확인 후, 3영업일 이내에 환불하여 드립니다.
<br />3. 회원은 다음 각 호의 사항에 따라 "스마트뮤직플레이"에 유료 콘텐츠 서비스의 환불 또는 변경을 요청할 수 있습니다.
<br />① "스마트뮤직플레이"의 모든 강좌는 수강시작일(결제일)로부터 7일 이내에 강좌맛보기를 제외한 유료강의를 전혀 수강하지 않은 경우에는 (패키지 미 개봉시에만) 100% 환불하여 드립니다.
<br />② 위 ①의 100% 환불이 가능한 경우를 제외하고 "회사"는 회원의 요구 또는 귀책사유로 인하여 계약이 해지되는 경우에는 수강시작일(결제일)부터 해지일까지의 일수에 해당하는 금액 또는 실제 수강 강의수(기기에 저장한 것은 수강한 것으로 본다)에 해당하는 금액 중 큰 금액과 수수료 10%(총결제금액의 10%)를 공제 후 환불하며, 자세한 환불규정은 아래 4항의 규정과 같습니다.
<br />③ 패키지강좌 또는 할인된 강좌의 환불은 서비스로 제공되었던 기간 및 할인금액이 제공되지 않은 것으로 보고 개별강좌의 "정가" 및 "이용기간"을 기준으로 환불금액을 산정합니다.
<br />④ 유료강좌의 환불이 가능한 경우라고 하더라도 모바일서비스 및 악보, MR 등 부가서비스를 결제한 경우 해당 비용은 환불이 불가능 합니다. 또한 교구 패키지의 패키지를 개봉하였을 경우 사용 한 것으로 간주하며 환불이 불가합니다.
<br />⑤ 회원의 요구로 "휴강" 등을 하는 경우 휴강기간은 "이용기간"에 포함되며, 휴강은 회사에서 서비스로 제공하는 기간에 불과하기 때문에 환불시에는 해당기간을 이용한 것으로 봅니다.
<br />⑥ 환불 요청 당일은 수업경과에 해당합니다.
<br />⑦ 수업신청 인원이 미달된 수업은 부득이 폐강될 수 있으며, 수업료는 전액 환불해 드립니다.
<br />⑧ 교구 패키지가 배송준비중에 있을 때 취소요청을 하신 경우, 배송업체 확인 후 취소 절차가 진행됩니다. 배송이 이미 시작되니 경우에는 왕복배송비를 부담하셔야 합니다.
<br />4. 회원의 환불에 관한 세부내역은 아래와 같습니다.
<br /><br />
                        <table border='1px'>
                            <tr>
                                <td colSpan='2'><u>구분</u></td>
                                <td><u>반환사유 발생일</u></td>
                                <td><u>반환금액</u></td>
                            </tr>
                            <tr>
                                <td colSpan='2'>1. 회사가 서비스를 제공할 수 없게 되는 경우</td>
                                <td>수업을 할 수 없거나, 수업 장소를 제공할 수 없게 된 날</td>
                                <td>이미 낸 학습비를 일할 계산한 금액</td>
                            </tr>
                            <tr>
                                <td rowSpan='6'>2. 학습자 본인의 의사로 학습을 포기한 경우</td>
                                <td rowSpan='4'>가. 학습비 징수기간이 1개월 이내인 경우</td>
                                <td>1) 수업시작 전</td>
                                <td>이미 낸 학습비 전액</td>
                            </tr>
                            <tr>
                                <td>2) 총수업시간의 1/3이 지나기 이전</td>
                                <td>이미 낸 학습비의 2/3에 해당하는 금액</td>
                            </tr>
                            <tr>
                                <td>3) 총수업시간의 1/3이 지난 후부터 1/2이 지나기 이전까지</td>
                                <td>이미 낸 학습비의 1/2에 해당하는 금액</td>
                            </tr>
                            <tr>
                                <td>4) 총수업시간의 1/2이 지난 후</td>
                                <td>반환하지 아니함</td>
                            </tr>
                            <tr>
                                <td rowSpan='2'>나. 학습비 징수기간이 1개월을 초과하는 경우</td>
                                <td>1) 수업시작 전</td>
                                <td>이미 낸 학습비 전액</td>
                            </tr>
                            <tr>
                                <td>2) 수업시작 이후</td>
                                <td>반환사유가 발생한 그 달의 반환 대상 학습비(가목에 따라 산출된 반환 대상 학습비를 말한다)와 나머지 달의 학습비 전액을 합산한 금액</td>
                            </tr>
                            <tr>
                                <td colSpan='2'>비고</td>
                                <td colSpan='2'>
                                    1. 총수업시간은 개별강좌의 "이용기간"을 말하며, 패키지강좌 및 할인된 강좌의 환불의 경우에는 서비스로 제공되었던 기간은 제외하고 개별강좌의 "이용기간"을 기준으로 환불금액을 산정합니다.
<br />2. "학습비 징수기간"이란 사이트에 명시되어 있는 "수강기간"(강의를 수강할 수 있는 총 기간)을 말하며 패키지 강좌 수강 시 추가로 제공되는 "서비스 기간"은 제외합니다.
<br />3. 회원의 요구로 "휴강" 등을 하는 경우 휴강기간은 서비스를 이용한 기간에 포함됩니다. 휴강은 회사가 회원의 편의를 위하여 서비스로 제공하는 기간에 불과하며 환불시에는 해당기간동안 서비스를 이용한 것으로 봅니다.
<br />4. 실제 수강 강의수(기기에 저장한 것은 수강한 것으로 본다)에 해당하는 금액이 위 1, 2에서 계산한 금액보다 큰 경우에는 실제 수강 강의수에 해당하는 금액을 공제하고 환불합니다. 이 경우 환불금액의 산정은 개별강좌의 "정가"를 기준으로 합니다.
<br />5. 위 1, 2에서 계산한 환불금액 및 비고3에서 계산한 환불금액과는 별도로 수수료 10%(총결제금액의 10%)를 추가로 공제한 후 환불합니다. 단, 18조 3항의①에 의거하여 100% 환불되는 경우에는 수수료10%를 공제하지 않습니다.
        </td>
                            </tr>
                        </table>

                        <br />5. "회사" 사정에 의한 서비스의 중단
<br />"스마트뮤직플레이"는 부득이한 사유로 서비스를 지속할 수 없을 경우에는 이를 회원에게 공지한 후 환불하여 드립니다.
<br />6. 강제탈퇴
<br />회원이 관계법령 및 본 약관의 규정을 위반하여 "스마트뮤직플레이"로부터 강제탈퇴 처리가 되는 경우에는 본 조의 환불규정이 적용되지 않습니다.
<br />7. 환불은 이용대금의 결제와 동일한 방법으로 합니다. 다만, 동일한 방법으로 환불이 불가능 할 때는 이를 사전에 고지하고, 본인명의의 계좌로 무통장입금 방식으로 환불합니다.
<br />
                        <br />제 18 조 (적립 포인트 등 에 관한 규정)
<br />1. 포인트의 사용가능기간
<br />포인트는 적립일로부터 2년간 유지되며 그 이후에는 소멸됩니다.
<br />2. 포인트는 현금으로 환전이 불가능합니다.
<br />3. "회사" 정책에 따라 포인트의 사용이 제한될 수 있습니다.
<br />4. 기타 "회사"에서 제공하는 쿠폰이나 할인권 등 이와 유사한 것의 사용가능기간은 별도의 사용기간이 정해지지 않은 경우 1년으로 합니다.
<br />
                        <br />제 19 조(청약철회 등)
<br />1. "스마트뮤직플레이"에서 재화 등의 구매에 관한 계약을 체결한 이용자는 수신확인의 통지를 받은 날로부터 7일 이내에 청약철회가 가능합니다. 다만, 정보의 열람 · 강좌의 수강 · 재화의 사용 등과 같이 본인이 실질적인 사용을 한 이후에는 청약철회를 하실 수 없습니다.
<br />2. 이용자는 재화 등을 배송 받은 경우 다음에 해당하는 경우에는 반품 및 교환을 할 수 없습니다
<br />① 이용자에게 책임 있는 사유로 재화 등이 멸실 또는 훼손된 경우
<br />② 이용자의 사용 또는 일부 소비에 의하여 재화 등의 가치가 현저히 감소한 경우
<br />③ 시간의 경과에 의하여 재판매가 곤란할 정도로 재화 등의 가치가 현저히 감소한 경우
<br />④ 같은 성능을 지닌 재화 등으로 복제가 가능한 경우 그 원본인 재화 등의 포장을 훼손한 경우
<br />3. 이용자는 콘텐츠의 내용이 표시ㆍ광고 내용과 다르거나 계약내용과 다르게 이행된 경우에는 당해 콘텐츠를 공급받은 날로부터 3월 이내, 그 사실을 안 날 또는 알 수 있었던 날로부터 30일 이내에 청약철회 등을 할 수 있습니다
<br />4. 이용자는 구두 또는 서면(전자문서를 포함한다)으로 청약철회를 할 수 있습니다.
<br />5. 이용자가 청약 철회 등을 서면으로 하는 경우 그 의사표시가 기재된 서면을 발송한 날에 그 효력이 발생합니다.
<br />
                        <br />제 20 조(청약철회 등의 효과)
<br />1. 청약철회 등을 한 경우 이용자는 공급받은 재화 또는 콘텐츠 등을 반환하여야 하며, 다운로드받은 온라인콘텐츠의 경우에는 이를 즉시 삭제하고 따로 저장하거나 보관하여서는 안됩니다.
<br />2. "스마트뮤직플레이"는 이용자로부터 재화 등을 반환 받은 경우 3영업일 이내에 이미 지급받은 재화 등의 대금을 환급합니다.
<br />3. "스마트뮤직플레이"는 위 대금을 환급함에 있어서 이용자가 신용카드 또는 전자화폐 등의 결제수단으로 재화 등의 대금을 지급한 때에는 지체없이 당해 결제수단을 제공한 사업자로 하여금 재화 등의 대금의 청구를 정지 또는 취소하도록 요청합니다.
<br />4. 청약철회 등의 경우 공급받은 재화 등의 반환에 필요한 비용은 이용자가 부담합니다. 다만 재화 등의 내용이 표시·광고 내용과 다르거나 계약내용과 다르게 이행되어 청약 철회 등을 하는 경우 재화 등의 반환에 필요한 비용은 "스마트뮤직플레이"가 부담합니다.
<br />5. 이용자가 재화 등을 제공 받을 때 발송비를 부담한 경우에 "스마트뮤직플레이"는 청약철회시 그 비용을 누가 부담하는지를 이용자가 알기 쉽도록 명확하게 표시합니다.
<br />
                        <br />제 21 조(과오금)
<br />1. "회사"는 과오금이 발생한 경우 이용대금의 결제와 동일한 방법으로 과오금 전액을 환급합니다. 다만, 동일한 방법으로 환급이 불가능 할 때는 이를 사전에 고지하고, 본인명의의 계좌로 무통장입금 방식으로 환급합니다.
<br />2. "회사"의 책임 있는 사유로 과오금이 발생한 경우 "회사"는 과오금 전액을 환급합니다. 다만, "이용자"의 책임 있는 사유로 과오금이 발생한 경우, 과오금의 환급에 소요되는 비용은 합리적인 범위 내에서 "이용자"가 부담합니다.
<br />
                        <br />제 22 조(개인정보보호)
<br />1. "스마트뮤직플레이"는 이용자의 정보 수집 시 구매계약 이행 및 부정이용방지 등에 필요한 최소한의 정보를 수집합니다. 다음 사항을 필수사항으로 하며 그 외 사항은 선택사항으로 합니다.
<br />① 성명
<br />② 성별
<br />③ 생년월일
<br />④ 주소
<br />⑤ 전화번호
<br />⑥ 아이디(회원의 경우)
<br />⑦ 비밀번호(회원의 경우)
<br />⑧ 전자우편주소
<br />⑨ 이동전화번호
<br />⑩ 법정대리인 정보(만14세 미만인 경우)
<br />⑪ IP주소
<br />⑫ 접속기기별 고유번호
<br />⑬ 결제정보
<br />⑭ 접속로그
<br />⑮ 서비스이용기록
<br />2. "스마트뮤직플레이"가 이용자의 개인식별이 가능한 개인정보를 수집하는 때에는 반드시 당해 이용자의 동의를 받습니다.
<br />3. 제공된 개인정보는 당해 이용자의 동의없이 목적 외의 이용이나 제3자에게 제공할 수 없습니다. 다만, 다음의 경우에는 예외로 합니다.
<br />① 배송업무상 배송업체에게 배송에 필요한 최소한의 이용자의 정보(성명, 주소, 전화번호)를 알려주는 경우
<br />② 통계작성, 학술연구 또는 시장조사를 위하여 필요한 경우로서 특정 개인을 식별할 수 없는 형태로 제공하는 경우
<br />③ 재화 등의 거래에 따른 대금정산을 위하여 필요한 경우
<br />④ 도용방지를 위하여 본인확인에 필요한 경우
<br />⑤ 법률의 규정 또는 법률에 의하여 필요한 불가피한 사유가 있는 경우
<br />⑥ 기타 "회사"가 필요하다고 인정하는 경우
<br />4. "스마트뮤직플레이"가 이용자의 동의를 받아야 하는 경우에는 개인정보관리 책임자의 신원(소속, 성명 및 전화번호, 기타 연락처), 정보의 수집목적 및 이용목적, 제3자에 대한 정보제공 관련사항(제공 받은 자, 제공목적 및 제공할 정보의 내용) 등 정보통신망이용촉진 등에 관한 법률이 규정한 사항을 미리 명시하거나 고지해야 합니다.
<br />5. 이용자는 언제든지 "스마트뮤직플레이"가 가지고 있는 자신의 개인정보에 대해 열람 및 오류정정을 요구할 수 있으며 "스마트뮤직플레이"는 이에 대해 필요한 조치를 취할 의무를 집니다. 이용자가 오류의 정정을 요구한 경우에 "스마트뮤직플레이"는 그 오류를 정정하여야 합니다. 단, 개인정보 수정이 악용될 소지가 있다고 판단되는 부분에 대하여는 "회사"가 정정을 제한할 수 있습니다.
<br />6. "스마트뮤직플레이"는 개인정보 보호를 위하여 관리자를 한정하여 그 수를 최소화하여야 합니다.
<br />7. "스마트뮤직플레이" 또는 그로부터 개인정보를 제공받은 제3자는 개인정보의 수집목적 또는 제공받은 목적을 달성한 때에는 당해 개인정보를 지체없이 파기합니다. 단, "회사"의 판단에 따라 필요하다고 여겨지는 경우 그러하지 아니할 수 있습니다.
<br />
                        <br />제 23 조 (부정이용 금지 및 차단)
<br />1. "회사"는 다음 각호에 해당하는 경우를 부정 이용행위로 봅니다.
<br />① 동일한 아이디로 2대 이상의 PC에서 동시접속이 발생하는 경우
<br />② 동일한 아이디로 다수의 PC 또는 IP에서 서비스를 이용하는 경우
<br />③ 자신의 아이디 및 서비스를 타인이 이용하도록 공유하는 경우
<br />④ 자신의 아이디 및 서비스를 타인에게 매매, 대여, 양도하는 행위 및 이를 광고하는 행위
<br />⑤ 서비스 이용 중, 복제프로그램을 실행하는 경우 또는 녹화를 하거나 시도하는 경우
<br />2. "회사"는 전항에 따른 부정 이용자가 발견 되었을 경우, 다음 각호에 따른 조치를 취할 수 있습니다.
<br />① [1차 발견 시] - 전자우편, 팝업창 등을 통하여 경고합니다. 단 복제 또는 녹화, 양도, 매매, 대여, 공유 등을 시도한 경우 바로 2차 발견으로 간주합니다.
<br />② [2차 발견 시] - 별도의 통지 없이 서비스 이용을 정지 시킵니다. 이때, "신분을 확인할 수 있는 공적인 문서" 및 "재발방지확약ㆍ보증서"를 "회사"에 송달하는 경우에는 서비스 이용정지를 해제 합니다.
<br />③ [3차 발견 시] - 별도의 통지 없이 서비스 이용을 영구 정지시킵니다.
<br />3. 회원은 제2항의 조치를 이유로, 서비스 이용기간의 연장 및 환불을 요구할 수 없습니다.
<br />4. 회원은 "회사"로 부터의 본조 제2항의 조치에 이의가 있는 경우, "회사" 고객지원팀에 해당 사실에 대하여 소명할 수 있으며, 그에 따라 이용에 관한 별도의 조치를 받을 수 있습니다.
<br />5. 부정이용 식별방법 및 차단
<br />① "회사"는 회원의 서비스 이용중에 수집ㆍ확인된 IP정보 등의 자료를 토대로, 서버를 통하여 부정이용 여부를 분류ㆍ확인합니다.
<br />② "회사"는 회원이 서비스 이용중에 복제프로그램을 실행시키거나 동일한 ID로 동시 접속을 하는 경우, 서비스 이용 접속을 강제로 종료 시킵니다.
<br />6. "회사"는 이용자의 고의·과실이 없음이 입증된 경우 계정정지 기간만큼 이용기간을 연장합니다. 단, 고의·과실이 없음은 이용자가 입증하여야 합니다.
<br />
                        <br />제 24 조 (정보의 제공 및 광고의 게재)
<br />1. "회사"는 회원이 서비스 이용 중 필요하다고 인정되는 다양한 정보를 공지사항이나 전자우편 또는 유선상 등의 방법으로 회원에게 제공할 수 있습니다. 다만, 회원은 언제든지 전자우편의 수신 거절을 할 수 있습니다.
<br />2. "회사"는 서비스 제공과 관련하여 해당 서비스 화면, "스마트뮤직플레이", 회원가입 시 회원이 직접 작성한 전자우편 등에 광고를 게재할 수 있습니다. 광고가 게재된 전자우편 등을 수신한 회원은 수신거절을 할 수 있습니다.
<br />
                        <br />제 25 조 (저작권의 귀속 및 이용제한)
<br />1. "스마트뮤직플레이"내에 제작·작성된 저작물에 대한 저작권 기타 지적재산권은 "회사" 에 귀속합니다.
<br />2. 이용자는 "스마트뮤직플레이"를 이용함으로써 얻은 정보 중 "스마트뮤직플레이"의 지적재산권이 귀속된 정보를 "회사"의 사전 승낙없이 복제, 송신, 출판, 배포, 방송 기타 방법에 의하여 영리 · 비영리 등 목적을 불문하고 이용하거나 제3자에게 이용하게 하여서는 안됩니다.
<br />3. 제 26조 2항을 위반하는 행위를 하거나 시도한 자는 이로 인한 유무형의 손해배상책임 등 모든 책임을 집니다.
<br />
                        <br />제 26 조(회원의 게시물)
<br />1. 회원이 "회사"의 사이트에 등록한 게시물에 대한 모든 권리 및 책임은 이를 게시한 회원에게 있습니다.
<br />2. "회사"가 약정에 따라 회원에게 귀속된 저작권을 사용하는 경우 당해 회원에게 통지하여야 합니다.
<br />3. "회사"는 회원이 공개한 게시물을 사용 복제, 수정, 출판, 배포할 수 있고, 회원이 게시하거나 등록한 게시물의 내용이 다음 각 호에 해당된다고 판단되는 경우에 사전 통지 없이 삭제할 수 있습니다.
<br />① 다른 회원 또는 제 3자를 비방하거나 중상모략으로 명예를 훼손시키는 내용인 경우
<br />② 공공질서 및 미풍양속에 위반되는 내용일 경우
<br />③ 범죄적 행위에 결부된다고 인정되는 내용일 경우
<br />④ 제 3자의 저작권 등 기타 권리를 침해하는 내용인 경우
<br />⑤ 게시물 등이 해당 게시판의 성격과 무관한 내용인 경우
<br />⑥ 기타 관계 법령이나 본 약관에 위반되는 경우
<br />⑦ 기타 허위, 과대, 광고성이 짙은 내용이라고 판단되는 경우
<br />⑧ 기타 관계 법령이나 본 약관에 위반되는 경우
<br />⑨ 기타 "회사"가 불필요한 내용이라고 판단하는 경우
<br />
                        <br />제 27 조 (약관 외 사항의 처리)
<br />1. 본 약관에 명시되지 않은 사항은 사회에서 인정되는 범위안에서 상호협의 조정토록 노력하며 불가시 관계법령에 따라 처리하고 이 약관이 관계법령과 배치되는 부분은 관계법령을 우선합니다.
<br />
                        <br />제 28 조 (면책조항)
<br />1. "회사"는 천재지변 또는 이에 준하는 불가항력과 화재, 도난, 정전 등 불가피한 사정으로 인하여 서비스를 제공할 수 없는 경우에는 서비스 제공에 관한 책임이 면제됩니다.
<br />2. "회사"는 회원의 귀책사유로 인하여 발생한 서비스 이용의 장애에 대하여는 책임을 지지 않습니다.
<br />3. "회사"는 회원이 서비스와 관련하여 게재한 정보, 자료, 사실의 신뢰도, 정확성 등의 내용에 관하여는 책임을 지지 않습니다.
<br />4. "회사"는 이용자 상호간 또는 이용자와 제3자 간에 콘텐츠를 매개로 하여 발생한 분쟁 등에 대하여 책임을 지지 않습니다.
<br />5. "회사"는 환불조항에 제시되지 아니한 사항에 대하여는 환불 책임을 지지 아니합니다.
<br />
                        <br />제 29 조 (분쟁해결)
<br />1. "회원" 사이에 대한민국 헌법과 법률, 규칙에 위배되는 사항이 발생하였을 경우, "회사"는 민, 형사상 책임을 지지 않습니다. 단, "회사"는 본 약관을 일방적으로 위배한 회원에 대하여 피해자가 고소, 고발 조치를 취하는데 도움을 제공할 수 있습니다.
<br />2. "스마트뮤직플레이"는 이용자가 제기하는 정당한 의견이나 불만을 반영합니다. 또한 "회사"의 귀책사유에 의한 피해가 있고 그 피해가 약관에 부합하는 경우 7일 이내에 보상 처리합니다.
<br />3. "스마트뮤직플레이"는 이용자로부터 제출되는 불만사항 및 의견은 우선적으로 그 사항을 처리합니다. 다만, 신속한 처리가 곤란한 경우에는 이용자에게 그 사유와 처리일정을 통보해 드립니다.
<br />4. "스마트뮤직플레이"와 이용자간에 발생한 분쟁과 관련하여 이용자의 피해구제신청이 있는 경우에는 공정거래위원회 또는 시·도지사가 의뢰하는 분쟁조정기관 또는 콘텐츠산업진흥법 제 29조에서 정하고 있는 콘텐츠분쟁조정위원회에 분쟁조정을 신청할 수 있습니다.
<br />
                        <br />제 30 조(재판권 및 준거법)
<br />1. "스마트뮤직플레이"는 이용자간에 발생한 전자상거래 분쟁에 관한 소송은 "회사"를 관할하는 지방법원의 전속관할로 합니다.
<br />2. "스마트뮤직플레이"를 이용하는 이용자 사이에 제기된 전자상거래 소송에는 한국법을 적용합니다.
<br />
                        <br />제 31 조("회사"등에 관한 정보제공)
<br />1. "회사"의 대표이사 : 홍혜숙, 김덕주 | "회사"의 상호 : 주은교육(주)
<br />2. "회사"의 주소 : 대구광멱시 달서구 달구벌대로 1130,401호 (우42709)/서울특별시 송파구 동남로 157 GB타워 4층 주은교육㈜ 서울사무소  3. "회사"의 전화번호 : 02-6925-1822
<br />4. "회사"의 FAX : 02-6925-1833
<br />5. "회사"의 E-mail : jueun20100@naver.com
<br />[부칙]
<br />본 약관은 2020년 11월 01일부터 14일간 공지한 후 2020년 11월 15일부터 시행됩니다.

</Typography>


                </SubGrid>
            </MainGrid>
        </>

    );
}

export default Terms;
import React, { useState, useEffect } from 'react';
// component
import DialogStudent from './DialogStudent';
// redux
import { useDispatch } from 'react-redux';
import { updateMember } from '../../redux/actions/managerActions';
// mui stuff
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
import CardActions from '@material-ui/core/CardActions';
import Collapse from '@material-ui/core/Collapse';
import Avatar from '@material-ui/core/Avatar';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import { red } from '@material-ui/core/colors';
import Tooltip from '@material-ui/core/Tooltip';
import { Divider } from '@material-ui/core';
import { ExpandMoreRounded, AccessibilityNewRounded, FaceRounded, PaletteRounded, StraightenRounded, SubjectRounded } from '@material-ui/icons';

const useStyles = makeStyles((theme) => ({
    root: {
        /*  maxWidth: 345, */
    },
    media: {
        height: 0,
        paddingTop: '56.25%', // 16:9
    },
    expand: {
        transform: 'rotate(0deg)',
        marginLeft: 'auto',
        transition: theme.transitions.create('transform', {
            duration: theme.transitions.duration.shortest,
        }),
    },
    expandOpen: {
        transform: 'rotate(180deg)',
    },
    avatar: {
        backgroundColor: red[500],
    },
    avatarNone: {
        backgroundColor: '#666'
    },
    left: {
        marginLeft: theme.spacing(2)
    }
}));
const MemberProfile = (props) => {
    const classes = useStyles();
    const { student} = props;
    // redux
    //const { parent } = useSelector(state => state.manager);
    const dispatch = useDispatch();
    // modal
    const [expanded, setExpanded] = useState(false);
    const [open, setOpen] = useState(false);
    // 원생정보
    const initialValue = {
        age: '',
        sex: '',
        memo: '',
        phone: '',
        siblings: [],
        classInfo: {
            musicClass: '',
            artClass: ''
        },
        isRegistered: false,
        isPaidGogo: false,
        isInvisibleForHakwon: false,
        studentNameForHakwon: ''
    }
    const [value, setValue] = useState(initialValue);

    const handleExpandClick = () => {
        setExpanded(!expanded);
    };
    const dialogOpen = () => {
        setOpen(true);

    }
    const dialogClose = () => {
        setOpen(false);
        setValue(initialValue);
    }
    const onChangeSex = e => {
        setValue({
            ...value,
            sex: e.target.value
        })
    }
    const onChangeValue = e => {
        setValue({
            ...value,
            [e.target.name]: e.target.value
        })
    }
    const onChangeSwitch = e => {
        setValue({
            ...value,
            [e.target.name]: e.target.checked
        });
        if (e.target.checked && e.target.name === 'isInvisibleForHakwon') {
            alert('삭제하시겠습니까? 저장 버튼을 누르면 삭제됩니다.');
        }
    }
    const onChangeClass = e => {
        setValue({
            ...value,
            classInfo: {
                ...value.classInfo,
                [e.target.name]: e.target.value
            }
        })
    }

    useEffect(() => {
        
        setValue({
            age: student.age,
            sex: student.sex,
            memo: student.memo,
            phone: student.phone,
            siblings: [],
            classInfo: student.classInfo,
            isRegistered: student.isRegistered,
            isPaidGogo: student.isPaidGogo,
            isInvisibleForHakwon: student.isInvisibleForHakwon ? student.isInvisibleForHakwon : false,
            studentNameForHakwon: student.studentNameForHakwon ? student.studentNameForHakwon : student.studentName
        });

    }, [student]);

    const handleSaveStudentInfo = () => {
        const newData = {
            hakwonId: student.hakwonId,
            isRegistered: value.isRegistered,
            isDeleted: student.isDeleted,
            isPaidGogo: value.isPaidGogo,
            isInvisibleForHakwon: value.isInvisibleForHakwon,
            studentName: student.studentName,
            studentNameForHakwon: value.studentNameForHakwon,
            age: value.age,
            sex: value.sex,
            classInfo: value.classInfo,
            memo: value.memo,
            phone: value.phone,
            siblings: value.siblings
        }
        console.log(newData);
        dispatch(updateMember(student.studentId, newData));
        setOpen(false);
        setValue(initialValue);
    }
    

    return (
        <Card className={classes.root}>
            <CardHeader
                avatar={
                    <Avatar aria-label="recipe" className={student.isRegistered === true ? classes.avatar : classes.avatarNone}>
                        <Typography variant='body2'>{student.studentName.substring(1, 3)}</Typography>
                    </Avatar>
                }
                action={
                    <IconButton aria-label="settings" onClick={dialogOpen}>
                        <SubjectRounded />
                    </IconButton>
                }
                title={<Typography variant='subtitle2'>{student.studentNameForHakwon}</Typography>}
                subheader={<Typography variant='body2'>{student.age}</Typography>}
            />

            <CardContent>
                <Typography variant="body2" color="textSecondary" component="p">
                    {student.memo}
                </Typography>
            </CardContent>
            <CardActions disableSpacing>
                <IconButton aria-label="add to favorites">
                    <Tooltip title='성별'><FaceRounded color={student.sex === '남' ? 'secondary' : student.sex === '여' ? 'primary' : 'inherit'} /></Tooltip>
                </IconButton>

                {student.classInfo?.musicClass && <IconButton aria-label="share"><Tooltip title='어린음악대'><StraightenRounded color='primary' /></Tooltip></IconButton>}

                {student.classInfo?.artClass && <IconButton aria-label="share"><Tooltip title='어린화가들'><PaletteRounded color='secondary' /></Tooltip></IconButton>}

                {student.isPaidGogo && <IconButton aria-label="share"><Tooltip title='뮤직플레이'><AccessibilityNewRounded color='primary' /></Tooltip></IconButton>}

                <IconButton
                    className={clsx(classes.expand, {
                        [classes.expandOpen]: expanded,
                    })}
                    onClick={handleExpandClick}
                    aria-expanded={expanded}
                    aria-label="show more"
                >
                    <ExpandMoreRounded />
                </IconButton>
            </CardActions>
            <Collapse in={expanded} timeout="auto" unmountOnExit>
                <CardContent>
                    <Divider light variant='middle' />
                    <Typography color='primary' variant='subtitle2'>서비스 이용내역</Typography>
                    <Typography paragraph className={classes.left}> {null}</Typography>
                    <Typography color='primary' variant='subtitle2'>어린음악대 내역</Typography>
                    <Typography variant='caption' paragraph className={classes.left}> 준비중..</Typography>
                    <Typography paragraph>{null}</Typography>
                    

                </CardContent>
            </Collapse>
            <DialogStudent
                open={open}
                dialogOpen={dialogOpen}
                dialogClose={dialogClose}
                student={student}
                value={value}
                onChangeValue={onChangeValue}
                onChangeClass={onChangeClass}
                onChangeSex={onChangeSex}
                onChangeSwitch={onChangeSwitch}
                handleSaveStudentInfo={handleSaveStudentInfo}
            />

        </Card>
    );
}
/* function phoneFormat(string) {
    if (string ==='undefined'){
        return string;
    } else if (string.startsWith("+")) {
        string = "0" + string.substring(3);
    } else if (string.startsWith("82")) {
        string = "0" + string.substring(2);
    }
    const str = string.replace(/(^02.{0}|^01.{1}|[0-9]{3})([0-9]+)([0-9]{4})/, "$1-$2-$3");
    return String(str);
} */
export default MemberProfile;

import React from 'react';
import {Link} from 'react-router-dom';
import { useParams } from 'react-router-dom';
// components
import Navbar from '../components/layout/Navbar';
import Footer from '../components/layout/Footer';
import DummyDivider from '../components/layout/DummyDivider';
import VideoClip from '../components/home/VideoClip';
// MUI stuff
import withStyles from '@material-ui/core/styles/withStyles';

import { Grid, Container, Typography, Box, Chip, Button } from '@material-ui/core';

const styles = (theme) => ({
    ...theme.userPage,
});
export const AloneVideo = (props) => {
    //const { classes } = props;
    const { vimeoId } = useParams();


    return (
        <>
            <Navbar />
            <div className='container'>
                <Container>
                    <DummyDivider bottom={30} />
                    <Typography variant='h3'>뮤직플레이 원데이클래스 <Chip color='secondary' label='미리보기'></Chip></Typography>
                    <DummyDivider top={50} />
                </Container>
                <Box p={1} m={1}>
                    <Grid container item xs={12} direction='row'>
                        <Grid item xs={12}>

                            <Box height={20} />
                            <VideoClip url={`https://vimeo.com/${vimeoId}`}
                                category="[뮤직플레이 원데이클래스]"
                                videoName='Ⅱ 어린음악대가 될래요 !'
                            />
                        </Grid>
                    </Grid>
                    <DummyDivider bottom={20} />
                    <Box p={1} m={1} align='center'>
                        <Button color='primary' variant='contained' component={Link} to='/lesson'>수업신청 바로가기</Button>
                    </Box>
                </Box>
                
                <DummyDivider bottom={30} />


                <Footer />
            </div >
        </>
    );
};



export default withStyles(styles)(AloneVideo);


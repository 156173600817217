import React from 'react';
// components
import DummyDivider from '../../components/layout/DummyDivider';
import QnaAccordian from './QnaAccordian';
// MUI stuff
import withStyles from '@material-ui/core/styles/withStyles';
import { Box, Typography } from '@material-ui/core';

const styles = (theme) => ({
    ...theme.userPage,
   
    margin: {
        marginLeft: theme.spacing(3),
        marginTop: theme.spacing(5)
    }
});


const data1 = [
    {
        que: '자녀 강좌를 신청하려고 하는데, 회원가입은 자녀가 하는건가요?',
        ans: '뮤직플레이는 가족단위로 회원관리를 하고 있습니다. 보호자께서 회원가입을 해주시고, 자녀들의 정보는 마이페이지에서 등록하실 수 있습니다.'
    },
    {
        que: '원래 어린음악대 원생이었는데, 이사를 가서 그 지역의 어린음악대 뮤직플레이 수업신청을 하려고 합니다. 별도의 회원가입 절차가 필요한가요?',
        ans: '마이페이지에서 주소 수정을 해주시고, 새로 등록한 학원에 인증을 요청해주세요.'
    }, {
        que: '뮤직플레이 수업을 듣고 싶은데, 주변에 어린음악대 학원이 없을 경우는 어떻게 하나요?',
        ans: '매주 진행되는 뮤직플레이 수업은 어린음악대 학원에서만 진행되는 수업으로, 원데이 클래스에 한하여 온라인으로 수강하실수 있습니다. '
    }, {
        que: '회원 탈퇴는 어떻게 되나요?',
        ans: '[마이 페이지] 우측 상단에 [회원탈퇴] 메뉴에서 탈퇴하실 수 있습니다.'
    }, {
        que: '자녀도 가입이 가능한가요?',
        ans: '네. 14세 이하 사용자는 보호자 인증 후 가입 가능합니다.'
    }, {
        que: '회원 정보는 어떻게 수정하나요?',
        ans: '[마이페이지] - [수정하기]에서 정보변경이 가능합니다.'
    }
];
const data2 = [
    {
        que: '신청한 수업의 수강생 입력을 잘못했습니다. 수강생 변경은 어떻게 하나요?',
        ans: '[마이페이지] - [나의 수업]에서 변경하신 후, 수업이 진행되는 원에 꼭 전화로 알려주세요.'
    }, {
        que: '원래 어린음악대 원생이었는데, 이사를 가서 그 지역의 어린음악대 뮤직플레이 수업신청을 하려고 합니다. 별도의 회원가입 절차가 필요한가요?',
        ans: '마이페이지에서 주소 수정을 해주시고, 새로 등록한 원에 인증을 요청해주세요.'
    }, {
        que: '신청한 수업의 결제 카드를 변경하려면 어떻게 하나요?',
        ans: '수업 개강 전일까지 인터넷으로 결제 취소 후, 재신청으로 변경 가능합니다. 단, 재신청은 마감되지 않은 수업의 경우에만 가능합니다.'
    }, {
        que: '수업료에 재료비나 교재비가 포함되어 있나요?',
        ans: '모든 수업은 수업에 따라 필요한 교구나 워크북이 포함 된 가격입니다. 수업 시작 후 별도로 납부할 금액은 없습니다.'
    }, {
        que: '수업 기간 중간에 취소나 변경이 가능한가요?',
        ans: '수업 취소는 환불 규정에 따라 부분환불이 가능하며, 수업 변경은 불가합니다. 수업 변경은 기존의 수강중인 수업을 취소하고, 다른 수업으로 다시 신청하게 되는 과정이므로, 수강하는 수업은 환불 규정에 따라 부분환불이 가능하며, 수강하시고자 하는 수업은 신규 수강 신청하셔야 합니다.'
    },{
        que: '수업일정 변경은 어디서 하나요?',
        ans: '수업을 신청하신 원에 전화로 변경하시면 됩니다. 단,지정하신 수업일 4일 전까지만 변경가능합니다.'
    }
]
const data3 = [{
    que: '수업을 빠졌을 때 보강은 어떻게 되나요?',
    ans: '수업을 진행하는 원장님의 개인사정 및 기타 사유로 인한 휴강은 마지막 주 또는 원장님과 수강생이 협의된 지정일에 보강을 진행합니다. 단, 법정공휴일에 의한 휴강은 보강이 없으므로 강의일정을 꼭 확인해 주시고, 수강생 개인의 사정으로 인한 결석 등의 사유로 강의를 듣지 못한 부분 또한 별도의 보강이 이루어지지 않습니다.'
}, {
    que: '수업시 필요한 교구는 어디서 받나요?',
    ans: '수업이 진행되는 날, 수업을 진행하는 원에서 받아볼 수 있습니다. 홈스쿨링의 경우는 집으로 배송됩니다.'
}];
export const Qna = (props) => {
    const { classes } = props;
    return (
        <Box p={1} m={1}>
            <Box>
                <Typography variant='h4' color='primary' gutterBottom>자주하는 질문</Typography>
                <Box height={20} />
                <Typography variant='subtitle1' gutterBottom className={classes.margin}>회원가입 / 혜택</Typography>
            </Box>
            
            <QnaAccordian
                data={data1}
            />
            
            <Box>
                <Typography variant='subtitle1' gutterBottom className={classes.margin}>수업신청 / 취소</Typography>
            </Box>
            <QnaAccordian
                data={data2}
            />

            <Box>
                <Typography variant='subtitle1' gutterBottom className={classes.margin}>수업관련</Typography>
            </Box>
            <QnaAccordian
                data={data3}
            />
            <DummyDivider top={60} />

        </Box>


    );
};

export default withStyles(styles)(Qna);


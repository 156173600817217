import React, { useState } from "react";
import dayjs from "dayjs";
import { useDispatch, useSelector } from "react-redux";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  Box,
  Typography,
  Divider,
  Paper,
} from "@material-ui/core";
import { SaveRounded, SearchRounded } from "@material-ui/icons";
import {
  getUsersInfoByPhoneNumber,
  postAfterSchool,
} from "../../../redux/actions/adminActions";

export default function AdminAddAfterSchool(props) {
  const { open, setOpen } = props;
  const { searchUsers } = useSelector((state) => state.admin);
  const dispatch = useDispatch();

  const initial = {
    afterSchoolId: "",
    title: "",
    name: "",
    userId: "",
    userLevel: 9,
    phone: "",
    startDay: "",
    endDay: "",
    memo: "",
  };
  const [info, setInfo] = useState(initial);
  const [search, setSearch] = useState("");
  const [searchOpen, setSearchOpen] = useState(false);
  const handleClose = () => {
    setOpen(false);
  };
  const handleSearch = () => {
    if (search.trim() === "") {
      alert("검색할 휴대전화번호를 입력하세요");
    } else {
      setSearchOpen(true);
      dispatch(getUsersInfoByPhoneNumber({ phone: search }));
    }
  };
  const onChange = (e) => {
    setInfo({ ...info, [e.target.name]: e.target.value });
  };
  const onChangeDate = (e) => {
    setInfo({
      ...info,
      [e.target.name]: dayjs(e.target.value).format("YYYY-MM-DD"),
    });
  };
  const handleSelect = (user) => {
    setSearchOpen(false);
    setInfo({
      ...info,
      userId: user.userId,
      userLevel: user.level,
      name: user.name,
      phone: user.phone,
    });
  };

  const handleAddAfterSchool = async () => {
    const { afterSchoolId, ...rest } = info;
    if (
      info.name === "" ||
      info.userId === "" ||
      info.startDay === "" ||
      info.startDay === "Invalid Date" ||
      info.endDay === "" ||
      info.endDay === "Invalid Date" ||
      info.phone === ""
    ) {
      alert("필수정보를 입력하시기 바랍니다.");
    } else if (dayjs(info.startDay) >= dayjs(info.endDay)) {
      alert("종료일은 시작일보다 이후 날짜여야 합니다.");
    } else {
      dispatch(postAfterSchool(rest));
      setOpen(false);
    }
  };

  // console.log(searchUsers);

  return (
    <>
      <Dialog open={open} onClose={handleClose} fullWidth maxWidth="sm">
        <Box m={2} p={2}>
          <Typography variant="h4">방과후교사 추가</Typography>
        </Box>

        <DialogContent>
          <TextFieldWithLabel
            labelWidth="150px"
            label="휴대전화로 검색"
            value={search}
            onChange={(e) => {
              let p = e.target.value.replace(/\D/g, "");
              const match = p.match(/^(\d{3})(\d{3,4})(\d{4})$/);
              if (match) {
                p = `${match[1]}-${match[2]}-${match[3]}`;
              }
              setSearch(p);
            }}
          >
            <Button
              variant="contained"
              onClick={handleSearch}
              startIcon={<SearchRounded />}
              style={{ marginLeft: 5 }}
              color="secondary"
            >
              회원검색
            </Button>
          </TextFieldWithLabel>
          <Divider light style={{ marginTop: 5, marginBottom: 4 }} />

          <TextFieldWithLabel
            labelWidth="150px"
            label="회원ID"
            name="userId"
            value={info.userId}
            onChange={onChange}
            disabled
          />
          <TextFieldWithLabel
            labelWidth="150px"
            label="회원Level"
            type="number"
            name="userLevel"
            disabled
            value={info.userLevel}
            onChange={(e) => {
              setInfo({ ...info, userLevel: Number(e.target.value) });
            }}
          />
          <TextFieldWithLabel
            labelWidth="150px"
            label="휴대전화"
            subLabel="( - 포함)"
            name="phone"
            disabled
            value={info.phone}
            onChange={onChange}
          />
          <TextFieldWithLabel
            labelWidth="150px"
            label="교사명"
            subLabel="카톡아이디 => 본명"
            name="name"
            value={info.name}
            onChange={onChange}
          />
          {/* 기타 주요정보 */}
          <Divider light style={{ marginTop: 5, marginBottom: 4 }} />

          <TextFieldWithLabel
            labelWidth="150px"
            label="교실(클래스)명"
            name="title"
            value={info.title}
            onChange={onChange}
          />
          <TextFieldWithLabel
            labelWidth="150px"
            label="시작일"
            name="startDay"
            value={info.startDay}
            onChange={onChangeDate}
            type="date"
          />
          <TextFieldWithLabel
            labelWidth="150px"
            label="종료일"
            name="endDay"
            value={info.endDay}
            onChange={onChangeDate}
            type="date"
          />
          <TextFieldWithLabel
            labelWidth="150px"
            label="메모"
            name="memo"
            value={info.memo}
            onChange={onChange}
            multiline
            maxRows={5}
            minRows={2}
          />
        </DialogContent>
        <DialogActions>
          <Button
            onClick={handleAddAfterSchool}
            variant="contained"
            color="secondary"
            startIcon={<SaveRounded />}
          >
            추가
          </Button>
          <Button onClick={handleClose} variant="contained">
            닫기
          </Button>
        </DialogActions>
        {/* 검색 dialog */}
        <SearchDialog
          {...{ searchOpen, setSearchOpen, searchUsers, handleSelect }}
        />
      </Dialog>
    </>
  );
}

function TextFieldWithLabel({
  labelWidth,
  label,
  subLabel,
  type,
  value,
  name,
  onChange,
  disabled,
  multiline,
  maxRows,
  minRows,
  children, // such as button
}) {
  return (
    <Box display="flex" flexDirection="row" alignItems="center">
      <Box
        display="flex"
        flexDirection="column"
        style={{ flexBasis: labelWidth }}
      >
        <Typography
          display="flex"
          style={{ fontSize: "16px", fontWeight: 500 }}
        >
          {label}
        </Typography>
        {subLabel && <Typography variant="caption">{subLabel}</Typography>}
      </Box>

      <TextField
        variant="outlined" //
        color="secondary"
        fullWidth
        style={{ flex: 1 }}
        size="small"
        margin="dense"
        name={name}
        value={value}
        onChange={onChange}
        disabled={disabled}
        type={type ? type : "text"}
        multiline={multiline}
        maxRows={maxRows}
        minRows={minRows}
      />
      {children}
    </Box>
  );
}

function SearchDialog({
  searchOpen,
  setSearchOpen,
  searchUsers,
  handleSelect,
}) {
  const handleClose = () => {
    setSearchOpen(false);
  };
  return (
    <Dialog
      open={searchOpen}
      onClose={handleClose}
      fullWidth
      maxWidth="sm"
      style={{ zIndex: 3000 }}
    >
      <DialogTitle>
        <Typography variant="h4">회원선택</Typography>
      </DialogTitle>
      <DialogContent>
        {searchUsers.length > 0 ? (
          <UserInfo searchUsers={searchUsers} handleSelect={handleSelect} />
        ) : (
          <NoUser />
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} variant="contained">
          닫기
        </Button>
      </DialogActions>
    </Dialog>
  );
}

function UserInfo({ searchUsers, handleSelect }) {
  return (
    <Box m={1} p={1}>
      {searchUsers.map((user) => (
        <Paper
          key={user.userId}
          style={{
            border: "1px solid grey",
            borderRadius: 5,
            padding: "10px",
            margin: 3,
          }}
        >
          <Box>
            <Typography>id: {user.userId}</Typography>
            <Typography>phone: {user.phone}</Typography>
            <Typography>name: {user.name}</Typography>
            <Typography>email: {user.email}</Typography>
            <Typography>
              가입일: {dayjs(user.joinedAt * 1000).format("YYYY-MM-DD")}
            </Typography>
            <Typography>level: {user.level}</Typography>
          </Box>
          <Box display="flex" flexDirection="row" justifyContent="flex-end">
            <Button
              variant="contained"
              color="secondary"
              size="small"
              onClick={() => handleSelect(user)}
            >
              선택
            </Button>
          </Box>
        </Paper>
      ))}
    </Box>
  );
}

function NoUser() {
  return (
    <Box m={2} p={2}>
      <Typography>
        해당하는 회원이 없습니다. 먼저 카카오톡으로 회원가입을 요청하세요. 5초면
        충분합니다.
      </Typography>
    </Box>
  );
}

import React from 'react';
import { Link } from 'react-router-dom';
// component
import Pigo1 from '../../images/pigo_1.png';
//import Pigo2 from '../../images/pigo_2.png';
import Pigo3 from '../../images/pigo_3.png';
import Pigo4 from '../../images/pigo_4.png';
import Pigo5 from '../../images/pigo_5.png';
import Pigo6 from '../../images/pigo_6.png';
import Online from '../../images/oneday_5.png';
import Slogan from '../../images/slogan.jpg';
import Temp from '../../images/main_small_3.png';
// Redux stuff
import { useDispatch } from 'react-redux';
import { getEnrollProductGroup } from '../../redux/actions/enrollActions';
// mui
import { makeStyles, withStyles, useTheme } from '@material-ui/core/styles';
import { Box, Grid, Typography, Divider, useMediaQuery, Paper } from '@material-ui/core';


const useStyles = makeStyles((theme) => ({
    header: {
        backgroundColor: '#fff',
    },
    img: {
        height: 255,
        display: 'block',
        overflow: 'hidden',
        width: '90%',
        objectFit: 'cover'
    },
    image: {
        width: '100%',
        maxWidth: 867
    },
    whiteFixed: {
        display: 'block',
        backgroundColor: '#ffffff',
        paddingBottom: theme.spacing(1),
        paddingRight: theme.spacing(1),
        paddingLeft: theme.spacing(1),
        position: 'fixed',
        zIndex: 1000,

        [theme.breakpoints.up('sm')]: {
            right: 0,
            top: 118,
            width: 150,
            paddingTop: theme.spacing(3),
        },
        [theme.breakpoints.up('xl')]: {
            width: 200,
            right: 50,
            paddingTop: theme.spacing(3),
        },
        [theme.breakpoints.down('xs')]: {
            width: '100%',
            left: 0,
            bottom: 0,
            paddingTop: theme.spacing(1),
        }
    },
    grayBack: {
        backgroundColor: '#e7e7e7'
    },
    greenBack: {
        backgroundColor: '#e1f0e4'
    },
    halfBox: {
        backgroundColor: '#009eff',
        padding: theme.spacing(1),
        cursor: 'pointer'
    },
    halfBox2: {
        backgroundColor: '#69e2ff',
        padding: theme.spacing(1),
        cursor: 'pointer'
    },
    greenRound: {
        backgroundColor: theme.palette.secondary.main,
        borderRadius: 20,
        width: 200
    },
    gogo4Img: {
        width: '100%',
        maxHeight: 100,
        objectFit: 'scale-down',
        [theme.breakpoints.only('xs')]: {
            maxHeight: 150
        }
    },
    peach: {
        backgroundColor: '#e0f7ff'
    },
    borderleft: {
        border: 'solid 5px',
        borderColor: theme.palette.primary.main,
        borderRadius: 20,
        backgroundColor: '#fff',
        marginLeft: theme.spacing(5),
        [theme.breakpoints.down('xs')]: {
            marginLeft: theme.spacing(1),
            height: 200
        }
    },
    borderright: {
        border: 'solid 5px',
        borderColor: theme.palette.primary.main,
        borderRadius: 20,
        backgroundColor: '#fff',
        marginRight: theme.spacing(5),
        [theme.breakpoints.down('xs')]: {
            marginRight: theme.spacing(1),
            height: 200
        }
    },
    logorounded: {
        width: 150,
        height: 80,
        position: 'absolute',
        left: '50%',
        top: '50%',
        transform: 'translate(-50%, -60%)'
    },
    backPrimary: {
        backgroundColor: theme.palette.primary.main,
        marginRight: theme.spacing(3),
        marginLeft: theme.spacing(3),
        [theme.breakpoints.down('xs')]: {
            marginRight: theme.spacing(0),
            marginLeft: theme.spacing(0),
        }
    },
    greenBox: {
        backgroundColor: '#80d39f',
        width: 100,
        height: 28,

    },
    greenBox2: {
        backgroundColor: theme.palette.secondary.main,
        width: 100,
        height: 28
    },
    step1: {
        backgroundColor: '#52dbff',
        borderRadius: 50, width: 100, height: 100,
        [theme.breakpoints.down('xs')]: {
            borderRadius: 40, width: 80, height: 80,
        },
        [theme.breakpoints.up('md')]: {
            borderRadius: 75, width: 150, height: 150,
        },
    },
    step2: {
        backgroundColor: '#3ac5fe',
        borderRadius: 50, width: 100, height: 100,
        [theme.breakpoints.down('xs')]: {
            borderRadius: 40, width: 80, height: 80,
        },
        [theme.breakpoints.up('md')]: {
            borderRadius: 75, width: 150, height: 150,
        },
    },
    step3: {
        backgroundColor: '#1db3ff',
        borderRadius: 50, width: 100, height: 100,
        [theme.breakpoints.down('xs')]: {
            borderRadius: 40, width: 80, height: 80,
        },
        [theme.breakpoints.up('md')]: {
            borderRadius: 75, width: 150, height: 150,
        },
    },
    step4: {
        backgroundColor: '#01a1ff',
        borderRadius: 50, width: 100, height: 100,
        [theme.breakpoints.down('xs')]: {
            borderRadius: 40, width: 80, height: 80,
        },
        [theme.breakpoints.up('md')]: {
            borderRadius: 75, width: 150, height: 150,
        },
    },
    font: {
        fontWeight: 'bold',
        fontSize: 14,
        [theme.breakpoints.down('xs')]: {
            fontSize: 11
        },
        [theme.breakpoints.up('md')]: {
            marginTop: theme.spacing(1),
            fontSize: 18
        },
    },
    white: {
        backgroundColor: '#fff',
        borderRadius: 20
    },
    imgGift: {
        width: '100%',
        maxHeight: 600,
        objectFit: 'scale-down'
    },
    back: {
        backgroundColor: '#fff',
        height: 500,
        backgroundImage: `url(${Temp})`,
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center bottom',
        backgroundSize: '414px 400px',
        [theme.breakpoints.down('xs')]: {
            height: 400,
            backgroundRepeat: 'no-repeat',
            backgroundPosition: 'center bottom',
            backgroundSize: '414px 400px',

        }
    }
}));
// const PurpleTypo = withStyles({
//     root: { color: '#88479b' }
// })(Typography);

const SkyTypo = withStyles({
    root: { color: '#00a2fe' }
})(Typography);
const WhiteTypo = withStyles({
    root: { color: '#ffffff' }
})(Typography);

const EnrollPianogogo = (props) => {
    const classes = useStyles();
    const theme = useTheme();
    const matches = useMediaQuery(theme.breakpoints.down('xs'));
    const dispatch = useDispatch();

    const handleProduct = (name, productId, productGroupId, productGroupName, categoryName) => {
        localStorage.setItem('productName', name);
        localStorage.setItem('productId', productId);
        localStorage.setItem('productGroupId', productGroupId);
        localStorage.setItem('productGroupName', productGroupName);
        localStorage.setItem('categoryName', categoryName);
        dispatch(getEnrollProductGroup(productGroupId));
    };
    
    return (
        <Box>
            <Box p={3} m={1} className={classes.header} align='center'>
                <Typography variant='subtitle1'>처음 피아노를 만나는 아이들을 위한</Typography>
                <SkyTypo display='inline' variant='subtitle1'>스마트피아노 원데이 클래스</SkyTypo>
                <Typography display='inline' variant='subtitle1'>입니다</Typography>

            </Box>

            {/* float */}
            <Paper className={classes.whiteFixed} align='center'>
                {
                    matches ?
                        <Box>
                            <Typography variant='subtitle2'>처음 피아노를 만나는 아이들을 위한</Typography>
                            <SkyTypo variant='subtitle2'><b>스마트피아노 원데이 클래스</b></SkyTypo>
                            <Box height={5} align='center' />
                        </Box>
                        :
                        <Box>
                            <Typography variant='subtitle2'>처음 피아노를</Typography>
                            <Typography variant='subtitle2'>만나는 아이들을 위한</Typography>
                            <SkyTypo variant='subtitle2'><b>스마트피아노</b></SkyTypo>
                            <SkyTypo variant='subtitle2'><b>원데이 클래스</b></SkyTypo>
                            <Box height={15} align='center' />
                        </Box>

                }
                <Typography variant='subtitle2' display='inline'> 20,000원</Typography>
                <Typography variant='subtitle2' display='inline'> (1회)</Typography>
                <Box height={5} />
                {
                    matches ?
                        <Grid container direction='row'>
                            <Grid item xs={6} component={Link} to='/enrollstep2'>
                                <Box
                                    p={1}
                                    align='center'
                                    style={{ backgroundColor: '#009eff', cursor: 'pointer', marginLeft: theme.spacing(2), marginRight: theme.spacing(2) }}
                                    onClick={() => handleProduct('스마트피아노 원데이', 'C3i47lsVsh90QFUBuSDL', "pianogogoOneDayClass", "스마트피아노 원데이클래스", '6-7세')}
                                >
                                    <WhiteTypo variant='subtitle2'> 6-7세 수업신청</WhiteTypo>
                                </Box>

                            </Grid>
                            <Grid item xs={6} component={Link} to='/enrollstep2'>
                                <Box p={1}
                                    align='center'
                                    style={{ marginLeft: theme.spacing(2), marginRight: theme.spacing(2), marginBottom: theme.spacing(2) }}
                                    className={classes.halfBox2}
                                    onClick={() => handleProduct('스마트피아노 원데이', 'C3i47lsVsh90QFUBuSDL', "pianogogoOneDayClass", "스마트피아노 원데이클래스", '8-9세')}
                                >
                                    <WhiteTypo variant='subtitle2'> 8-9세 수업신청</WhiteTypo>
                                </Box>

                            </Grid>
                        </Grid>
                        :
                        <Box>
                            <Box m={1} component={Link} to='/enrollstep2'>
                                <Box
                                    p={1}
                                    align='center'
                                    style={{ backgroundColor: '#009eff', cursor: 'pointer' }}
                                    onClick={() => handleProduct('스마트피아노 원데이', 'C3i47lsVsh90QFUBuSDL', "pianogogoOneDayClass", "스마트피아노 원데이클래스", '6-7세')}
                                >
                                    <WhiteTypo variant='subtitle2'> 6-7세 수업신청</WhiteTypo>
                                </Box>
                            </Box>
                            <Box m={1} component={Link} to='/enrollstep2'>
                                <Box p={1}
                                    align='center'
                                    className={classes.halfBox2}
                                    onClick={() => handleProduct('스마트피아노 원데이', 'C3i47lsVsh90QFUBuSDL', "pianogogoOneDayClass", "스마트피아노 원데이클래스", '8-9세')}
                                >
                                    <WhiteTypo variant='subtitle2'> 8-9세 수업신청</WhiteTypo>
                                </Box>
                            </Box>
                        </Box>
                }
            </Paper>
            {/* end of Float */}



            <Box p={1}>
                <Box height={30} />
                <Typography variant='h4'><b>수업 정보</b></Typography>
                <Divider light variant='middle' style={{ marginTop: 10, marginBotton: 10 }} />
                <Box height={10} />
                <Box p={1} m={1}>
                    <SkyTypo variant='subtitle1' gutterBottom>수업 정보</SkyTypo>
                    <Typography variant='subtitle1'>피아노 안녕,만나서 반가워 (6-7세)/  </Typography>
                    <Typography variant='subtitle1'>피아노 고! 고! (8-9세)</Typography>
                </Box>

                <Box p={1} m={1}>
                    <SkyTypo variant='subtitle1' gutterBottom>수업 시간</SkyTypo>
                    <Typography variant='subtitle1'>30~40분 </Typography>
                </Box>
                <Box p={1} m={1}>
                    <SkyTypo variant='subtitle1' gutterBottom>수강 가능일</SkyTypo>
                    <Typography variant='subtitle1'>수업 일시 예약(각 학원별 상이함)</Typography>
                </Box>
                <Box p={1} m={1}>
                    <SkyTypo variant='subtitle1' gutterBottom>학습 연령</SkyTypo>
                    <Typography variant='subtitle1'>유치(6-7세) / 초등(8~9세)</Typography>
                </Box>
                <Box p={1} m={1}>
                    <SkyTypo variant='subtitle1' gutterBottom>환불 정책</SkyTypo>
                    <Typography variant='subtitle1'>원데이 클래스 패키지를 수령 후 개봉한 경우 환불 대상에서 제외됩니다.</Typography>
                    <Typography variant='subtitle1'>패키지 미개봉시에는 반품 확인 후 환불이 진행되며, 교구 패키지가 배송준비중에 있을 때 취소요청을 하신 경우, 배송업체 확인 후 취소절차가 진행됩니다.</Typography>
                    <Typography variant='subtitle1'>(배송이 이미 시작된 경우 왕복배송비를 부담하셔야 합니다.)</Typography>
                </Box>

                <Box height={30} />
                <Typography variant='h4'><b>교육 목표</b></Typography>
                <Divider light variant='middle' style={{ marginTop: 10, marginBotton: 10 }} />
                <Box height={10} />
                <Box m={1} p={1}>
                    <Box>
                        <SkyTypo variant='subtitle1' gutterBottom>- 전 영역의 음악적 활동을 통해 감성발달과 음악개념 형성
</SkyTypo>
                    </Box>
                    <Box>
                        <SkyTypo variant='subtitle1' gutterBottom>- 차별화된 교수 방법을 통해 음악 교육의 새로운 방향 제시
</SkyTypo>
                    </Box>
                    <Box>
                        <SkyTypo variant='subtitle1' gutterBottom>- 담임제 관리를 통해 체계적, 효과적으로 음악 전 영역 경험
</SkyTypo>
                    </Box>
                    <Box>
                        <SkyTypo variant='subtitle1' gutterBottom>- 신개념 피아노 연습과 레슨, 평가를 통한 실력 상승
</SkyTypo>

                    </Box>
                </Box>

                <Box height={30} />
                <Typography variant='h4'><b>수업 특징</b></Typography>
                <Divider light variant='middle' style={{ marginTop: 10, marginBotton: 10 }} />
                <Box height={10} />
                <Box p={1} m={1}>

                    <Typography variant='subtitle1'>- 전문캐릭터와 다양한 교구 활용</Typography>
                    <Typography variant='subtitle1'>- 어린음악대만의 독점 교재 사용</Typography>
                    <Typography variant='subtitle1'>- 리듬악기를 통한 음악적 흥미유발로 자신감 및 성취도 상승</Typography>
                    <Typography variant='subtitle1'>- 음악교구 최초 특허받은 음악전문 교구 사용으로 인지발달 및 지적 능력 향상</Typography>
                    <Typography variant='subtitle1'>- 피아노레슨의 신개념 패러다임 스마트e-피아노를 통해 연습 및 레슨의 흥미를 유발시켜 집중력 향상</Typography>

                </Box>
                <Box height={30} />
                <Typography variant='h4'><b>수업 내용</b></Typography>
                <Divider light variant='middle' style={{ marginTop: 10, marginBotton: 10 }} />
                <Box height={10} />
                <Box p={1} m={1}>

                    <Typography variant='subtitle1'>- 리듬스틱, 피아노만들기, 텅드럼만들기를 통해 악기와 친밀해짐</Typography>
                    <Typography variant='subtitle1'>- 마카북을 통해 썼다 지웠다를 반복하며 흥미롭게 공부용</Typography>
                    <Typography variant='subtitle1'>- 음표플래시카드와 스티커 붙이기 활동을 통해 복잡한 음악이론을 재미있게 공부</Typography>
                    <Typography variant='subtitle1'>- 체험클래스교재에 있는 악보와 와우이론으로 뮤직플로우 피아노 교육 경험</Typography>

                </Box>

                <Box height={40} />



                {/* <Box align='center'>
<Box align='center' className={classes.greenRound}>
    <WhiteTypo variant='subtitle1'>뮤직플레이 교육 효과</WhiteTypo>
</Box>
<Box height={15} />
<Typography variant='h4' color='secondary' display='inline'>창의력</Typography>
<Typography variant='h4' display='inline'>을 갖춘 아이로</Typography><br />
<Typography variant='h4' display='inline'>말랑말랑한 </Typography>
<Typography variant='h4' display='inline' color='primary'>우뇌</Typography>
<Typography variant='h4' display='inline'>로,</Typography>
<Typography variant='h4'>미래형 인재를 만드는 최고의 교육</Typography>
</Box>
<Box>
<Grid container direction='row'>
    <Grid item xs={6} sm={3}>
        <Box align='center' p={2} m={1}>
            <img src={null} alt='instru' className={classes.gogo4Img} />
            <Chip color='secondary' label='악기연주' />
        </Box>
    </Grid>
    <Grid item xs={6} sm={3}>
        <Box align='center' p={2} m={1}>
            <img src={Oneday2} alt='instru' className={classes.gogo4Img} />
            <Chip color='primary' label='신체활동' />
        </Box>
    </Grid>
    <Grid item xs={6} sm={3}>
        <Box align='center' p={2} m={1}>
            <img src={Oneday3} alt='instru' className={classes.gogo4Img} />
            <Chip color='secondary' label='음악이론' />
        </Box>
    </Grid>
    <Grid item xs={6} sm={3}>
        <Box align='center' p={2} m={1}>
            <img src={Oneday4} alt='instru' className={classes.gogo4Img} />
            <Chip color='primary' label='감상&표현' />
        </Box>
    </Grid>
</Grid>
</Box> */}
            </Box>
            <Box align='center' style={{ backgroundColor: '#fff' }}>
                <Box height={40} />
                <Typography color='secondary' variant='h4'>독점교재/특허교구를 활용한 </Typography>
                <Typography variant='h4'>뮤직 플로우 피아노 교육 프로그램</Typography>
                <Box height={20} />
                <Box p={2}>
                    <img src={Pigo1} alt='pigo' className={classes.image} />
                </Box>
                <Box height={30} />
            </Box>

            <Box className={classes.peach} align='center'>
                <Box height={50} />
                <Typography variant='h4'><b>원데이로 만나는</b></Typography>
                <SkyTypo display='inline' variant='h4'><b>PIANO </b></SkyTypo>
                <Typography display='inline' color='secondary' variant='h4'><b> 체험 수업</b></Typography>
                <Typography display='inline' variant='h4'><b> 포인트</b></Typography>
                <Box height={20} />
                <Grid container direction='row'>
                    <Grid item xs={12} sm={4}>
                        <Box align='center' p={2} m={2} className={classes.white}>
                            <Typography color='primary' variant='subtitle1' display='inline'>피아노와의 첫만남</Typography>
                            <Box height={10} />
                            <Typography variant='body2'>피아노를 직접 만들고,</Typography>
                            <Typography variant='body2'>특허받은 음악전문 교구와</Typography>
                            <Typography variant='body2'>독점교재들을 통해 </Typography>
                            <Typography variant='body2'>체계적으로  </Typography>
                            <Typography variant='body2'>피아노를 이해하는 수업</Typography>
                        </Box>
                    </Grid>
                    <Grid item xs={12} sm={4}>
                        <Box align='center' p={2} m={2} className={classes.white}>
                            <Typography color='primary' variant='subtitle1' display='inline'>뮤직 플로우</Typography>

                            <Box height={10} />
                            <Typography variant='body2'>자기주도적 몰입(FLOW)과정을</Typography>
                            <Typography variant='body2'>체험하며,</Typography>
                            <Typography variant='body2'>전 영역의 음악적 활동을  </Typography>
                            <Typography variant='body2'>통해 감성발달과 음악개념을  </Typography>
                            <Typography variant='body2'>형성하는 시간</Typography>
                        </Box>
                    </Grid>
                    <Grid item xs={12} sm={4}>
                        <Box align='center' p={2} m={2} className={classes.white}>
                            <Typography color='primary' variant='subtitle1' display='inline'>두뇌 밸런스</Typography>
                            <Box height={10} />
                            <Typography variant='body2'>아이들의 올바른 성장을 위해</Typography>
                            <Typography variant='body2'>발달단계에 맞추어</Typography>
                            <Typography variant='body2'>좌뇌와 우뇌가 균형적으로</Typography>
                            <Typography variant='body2'>발달할 수 있도록 개발 된 </Typography>
                            <Typography variant='body2'>두뇌 밸런스 수업</Typography>
                        </Box>
                    </Grid>
                </Grid>
                <Box height={30} />
            </Box>
            <Box align='center'>
                <Box height={50} />
                {
                    matches ?
                        <Box>
                            <Typography variant='h3'>스마트피아노 원데이클래스</Typography>
                            <Typography variant='h3'>"어떤 수업을 하나요?"</Typography>
                        </Box>
                        : <Typography variant='h3'>뮤직플레이 원데이클래스 "어떤 수업을 하나요?"</Typography>
                }

            </Box>
            <Box align='center' className={classes.peach} p={2} m={2}>
                <Box style={{ backgroundColor: '#289cff' }} p={1}>
                    <WhiteTypo variant='subtitle1'>＃유아부  ＃놀이로 만나는 피아노  ＃피아노 안녕,만나서 반가워</WhiteTypo>
                </Box>
                <Box height={10} />
                {
                    matches ?
                        <Box align='left'>
                            <SkyTypo variant='body1' gutterBottom><b>피아노와의 첫만남</b></SkyTypo>
                            <Typography variant='body1' gutterBottom>흰건반, 검은건반 놀이, 손가락 번호 찍찍이 놀이로 피아노를 만나요. </Typography>
                            <SkyTypo variant='body1' gutterBottom><b>DIY 악기 만들기</b></SkyTypo>
                            <Typography variant='body1' gutterBottom>업라이트 피아노 만들기, 텅드럼 만들기 악기를 만들어 연주해보아요. </Typography>
                            <SkyTypo variant='body1' gutterBottom><b>피아노 연주</b></SkyTypo>
                            <Typography variant='body1' gutterBottom>전문가한테 배워야 하는 중요한 이유 ! 올바른 연주자세를 배우면서 연주해보아요.</Typography>
                        </Box>
                        :
                        <Box>
                            <SkyTypo variant='body1' gutterBottom><b>피아노와의 첫만남</b></SkyTypo>
                            <Typography variant='body1' gutterBottom>흰건반, 검은건반 놀이, 손가락 번호 찍찍이 놀이로 피아노를 만나요. </Typography>
                            <SkyTypo variant='body1' gutterBottom><b>DIY 악기 만들기</b></SkyTypo>
                            <Typography variant='body1' gutterBottom>업라이트 피아노 만들기, 텅드럼 만들기 악기를 만들어 연주해보아요. </Typography>
                            <SkyTypo variant='body1' gutterBottom><b>피아노 연주</b></SkyTypo>
                            <Typography variant='body1' gutterBottom>전문가한테 배워야 하는 중요한 이유 ! 올바른 연주자세를 배우면서 연주해보아요.</Typography>
                        </Box>
                }

            </Box>
            <Box align='center' className={classes.peach} p={2} m={2}>
                <Box style={{ backgroundColor: '#289cff' }} p={1}>
                    <WhiteTypo variant='subtitle1'>＃초등부  ＃STEAM 예술교육  ＃피아노 고! 고!</WhiteTypo>
                </Box>
                <Box height={10} />
                {
                    matches ?
                        <Box align='left'>
                            <SkyTypo variant='body1' gutterBottom><b>놀이로 배우는 음악이론</b></SkyTypo>
                            <Typography variant='body1' gutterBottom>음표와 쉼표를 재미있게 만나요 </Typography>
                            <SkyTypo variant='body1' gutterBottom><b>악기는 어떻게 소리가 날까?</b></SkyTypo>
                            <Typography variant='body1' gutterBottom>그랜드 피아노 만들기,텅드럼 만들기 활동을 통해 악기가 소리나는 원리를 이해해보아요 . </Typography>
                            <SkyTypo variant='body1' gutterBottom><b>열 손가락 , 88 피아노건반</b></SkyTypo>
                            <Typography variant='body1' gutterBottom>손가락 번호 교구, 계이름 상행 찍찍이 교구, 피아노 건반 계이름 찍찍이 교구 등을 통해
음악을 수학적으로 접근해 보아요</Typography>
                        </Box>
                        :
                        <Box>
                            <SkyTypo variant='body1' gutterBottom><b>놀이로 배우는 음악이론</b></SkyTypo>
                            <Typography variant='body1' gutterBottom>음표와 쉼표를 재미있게 만나요 </Typography>
                            <SkyTypo variant='body1' gutterBottom><b>악기는 어떻게 소리가 날까?</b></SkyTypo>
                            <Typography variant='body1' gutterBottom>그랜드 피아노 만들기,텅드럼 만들기 활동을 통해 악기가 소리나는 원리를 이해해보아요 . </Typography>
                            <SkyTypo variant='body1' gutterBottom><b>열 손가락 , 88 피아노건반</b></SkyTypo>
                            <Typography variant='body1' gutterBottom>손가락 번호 교구, 계이름 상행 찍찍이 교구, 피아노 건반 계이름 찍찍이 교구 등을 통해
음악을 수학적으로 접근해 보아요</Typography>
                        </Box>
                }

            </Box>
            <Box align='center' className={classes.grayBack}>
                <Box height={40} />
                <Typography variant='h4'>원데이 클래스 "스마트피아노" 구성</Typography>
                <Box height={20} />
                <Grid container direction='row'>
                    <Grid item xs={12} sm={3}>
                        <Box p={2} m={1} align='center' style={{ backgroundColor: '#fff', height: 280 }}>
                            <img src={Online} alt='comp' className={classes.gogo4Img} />
                            <Box height={10} />
                            <Typography color='primary' variant='subtitle1' gutterBottom>온라인 강의</Typography>
                            <Typography variant='body2' gutterBottom>온라인 강의 + 오프라인 수업</Typography>
                        </Box>
                    </Grid>
                    <Grid item xs={12} sm={3}>
                        <Box p={2} m={1} align='center' style={{ backgroundColor: '#fff', height: 280 }}>
                            <img src={Pigo5} alt='comp' className={classes.gogo4Img} />
                            <Box height={10} />
                            <Typography color='primary' variant='subtitle1' gutterBottom>음악 이론</Typography>
                            <Typography variant='body2' gutterBottom>스마트피아노 워크북으로
                            기초음악이론을
                            재미있게 만나는 시간
                            (악보포함)
</Typography>
                        </Box>
                    </Grid>
                    <Grid item xs={12} sm={3}>
                        <Box p={2} m={1} align='center' style={{ backgroundColor: '#fff', height: 280 }}>
                            <img src={Pigo3} alt='comp' className={classes.gogo4Img} />
                            <Box height={10} />
                            <Typography color='primary' variant='subtitle1' gutterBottom>악기만들기</Typography>
                            <Typography variant='body2' gutterBottom>악기를(피아노,텅드럼)
                            직접 만들어
                            연주해보는 시간
</Typography>
                        </Box>
                    </Grid>
                    <Grid item xs={12} sm={3}>
                        <Box p={2} m={1} align='center' style={{ backgroundColor: '#fff', height: 280 }}>
                            <img src={Pigo4} alt='comp' className={classes.gogo4Img} />
                            <Box height={10} />
                            <Typography color='primary' variant='subtitle1' gutterBottom>STEAM 예술교육</Typography>
                            <Typography variant='body2' gutterBottom>음표찾기 플래시카드,
                            건반 마카북,
                            손가락번호 찍찍이 등
                            음악을 보고 만지며 배우는 STEAM교육
</Typography>
                        </Box>
                    </Grid>
                </Grid>
                <Box height={30} />
            </Box>
            <Box align='center' className={classes.greenBack}>
                <Box height={40} />
                <Typography display='inline' variant='h3'>원데이 클래스 </Typography>
                <Typography display='inline' color='secondary' variant='h3'> 스마트피아노 패키지</Typography>
                <Box height={20} />
                <Grid container direction='row'>
                    <Grid item xs={12} sm={6}>
                        <Box m={4} align='center' style={{ backgroundColor: '#fff' }}>
                            <Box className={classes.greenBox}>
                                <WhiteTypo>6-7세</WhiteTypo>
                            </Box>
                            <Box>
                                <img src={Pigo5} alt='comp' className={classes.imgGift} />
                            </Box>

                            <Box height={10} />
                            <Box p={2}>
                                <Typography variant='caption' gutterBottom>찍찍이 스티커 교구 세트(피아노 건반, 손가락 번호, 계이름상행 기본판& 스티커),</Typography>
                                <Typography variant='caption' gutterBottom>손가락번호 스티커, 마카북 피아노 건반, 마카펜, 부직포 백, 스마트피아노 교재, 음악놀이 스티커, 찍찍이 1인 교구 음표&쉼표, 찍찍이 미니 플래시카드, 텅드럼 만들기 교구
</Typography>
                            </Box>
                        </Box>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <Box m={4} align='center' style={{ backgroundColor: '#fff'}}>
                            <Box className={classes.greenBox2}>
                                <WhiteTypo>8-9세</WhiteTypo>
                            </Box>
                            <Box>
                                <img src={Pigo6} alt='comp' className={classes.imgGift} />
                            </Box>
                            <Box height={10} />
                            <Box p={2}>
                                <Typography variant='caption' gutterBottom>찍찍이 스티커 교구 세트(피아노 건반, 손가락 번호, 계이름상행 기본판& 스티커), </Typography>
                                <Typography variant='caption' gutterBottom>손가락번호 스티커, 마카북 피아노 건반, 마카펜, 마카북 높은음자리, 어린음악대 OPP비닐, 부직포 백, 스마트피아노 교재, 리듬스틱, 그랜드피아노만들기교구</Typography>
                            </Box>
                        </Box>
                    </Grid>

                </Grid>
                <Box height={30} />
            </Box>
            <Box align='center' style={{ backgroundColor: '#ffffea' }}>
                <Box p={3}>
                    <Typography variant='h3' display='inline'>스마트피아노 </Typography>
                    <SkyTypo variant='h3' display='inline'>원데이 클래스</SkyTypo>
                    <Typography variant='h3' display='inline'> 신청방법</Typography>
                </Box>
                <Box height={10} />
                <Box p={matches ? 1 : 10}>
                    <Grid container direction='row'>
                        <Grid item xs={3}>
                            <Box m={1} className={classes.step1}>
                                <WhiteTypo variant='caption'>step.1</WhiteTypo>
                                <Box height={10} />
                                <WhiteTypo className={classes.font}>원데이클래스</WhiteTypo>
                                <WhiteTypo className={classes.font}>수업 선택</WhiteTypo>
                            </Box>
                        </Grid>
                        <Grid item xs={3}>
                            <Box m={1} className={classes.step2}>
                                <WhiteTypo variant='caption'>step.2</WhiteTypo>
                                <Box height={15} />
                                <WhiteTypo className={classes.font}>연령선택</WhiteTypo>

                            </Box>
                        </Grid>
                        <Grid item xs={3}>
                            <Box m={1} className={classes.step3} >
                                <WhiteTypo variant='caption'>step.3</WhiteTypo>
                                <Box height={15} />
                                <WhiteTypo className={classes.font}>주변 학원 검색</WhiteTypo>

                            </Box>
                        </Grid>
                        <Grid item xs={3}>
                            <Box m={1} className={classes.step4} >
                                <WhiteTypo variant='caption'>step.4</WhiteTypo>
                                <Box height={10} />
                                <WhiteTypo className={classes.font}>수강 가능</WhiteTypo>
                                <WhiteTypo className={classes.font}>날짜 선택</WhiteTypo>

                            </Box>
                        </Grid>
                    </Grid>
                </Box>

            </Box>
            <Box height={30} />
            <Box>
                <img src={Slogan} alt='slogan' style={{ width: '100%' }} />
            </Box>



        </Box>
    );

}

export default EnrollPianogogo;


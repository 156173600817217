import { Typography } from '@material-ui/core';
import React from 'react';
import Navbar from '../../components/layout/Navbar';
import MainGrid from '../../components/layout/MainGrid';
import SubGrid from '../../components/layout/SubGrid';
function Privacy() {

    return (
        <>
            <Navbar />
            <MainGrid>
                <SubGrid style={{ padding: 5 }}>
                    <Typography variant='h4'>개인정보 취급 방침</Typography>
                    <Typography variant='body2'>
                        <br />
                        <br />주은교육(주)(이하 "회사"라 함)은 개인정보보호법, 통신비밀보호법, 전기통신사업법, 정보통신망 이용촉진 및 정보보호 등에 관한 법률 등을 준수하고 있습니다.
<br />
                        <br />회사는 개인정보처리방침을 통하여 이용자가 제공하는 개인정보가 어떠한 용도와 방식으로 이용되고 있으며, 개인정보보호를 위해 어떠한 조치가 취해지고 있는지 알려드립니다.
<br />
                        <br />주은교육(주) 홈페이지 중 「스마트 뮤직플레이」 (이하 "사이트"라 함)에서의 개인정보처리방침의 주요내용은 다음과 같습니다.
<br />
                        <br />1. 개인정보의 처리 목적
<br />2. 처리하는 개인정보의 항목
<br />3. 개인정보의 처리 및 보유기간
<br />4. 개인정보의 제3자 제공
<br />5. 이용자와 법정대리인의 권리 및 그 행사방법
<br />6. 개인정보의 파기
<br />7. 개인정보 자동수집 장치의 설치·운영 및 그 거부 에 관한 사항
<br />8. 개인정보보호책임자
<br />9. 개인정보에 관한 민원서비스
<br />
                        <br />ο 본 방침은 2020년 11월 1일부터 시행됩니다.
<br />
                        <br />1. 개인정보의 처리 목적
<br />회사는 다음의 목적을 위해 이용자의 개인정보를 처리합니다.
<br />
                        <br />ο 서비스 제공에 관한 계약 이행 및 서비스 제공에 따른 요금정산 콘텐츠 제공, 구매 및 요금 결제, 본인인증, 물품배송 또는 청구지 등 발송
<br />
                        <br />ο 회원 관리
<br />회원제 서비스 이용에 따른 본인확인, 개인 식별, 불량회원의 부정 이용 방지와 비인가 사용 방지, 가입 의사 확인, 연령확인, 불만처리 등 민원처리, 고지사항전달
<br />
                        <br />ο 마케팅 및 광고에 활용
<br />신규 서비스(제품) 개발 및 특화, 이벤트 등 광고성 정보 전달, 이벤트 등 광고성 정보 전달, 인구통계학적 특성에 따른 서비스 제공 및 광고 게재, 접속 빈도 파악 또는 회원의 서비스 이용에 대한 통계
<br />
                        <br />ο 가맹원 안내
<br />학습상담 신청, 고객문의 상담,가맹원 배정
<br />
                        <br />ο이메일, 채팅 등 고객상담
<br />사이트 이용 및 학습서비스 불편사항 등 상담
<br />
                        <br />ο 기타
<br />가입횟수 제한, 접속IP 및 접속기기별고유번호 분석을 통한 부정이용 차단, 분쟁조정을 위한 기록보존, 회원의 기타 통계자료 산출
<br />
                        <br />
                        <br />2. 처리하는 개인정보의 항목
<br />
                        <br />- 필수항목 :카카오계정, 성명, 성별, 생년월일, 아이디, 비밀번호, 주소, 전화번호, 이메일 주소, CI(연계정보), 휴대폰 번호, 만 14세 미만인 경우 법정대리인 정보, 결제정보, 접속IP주소, 접속기기별고유번호, 접속로그, 서비스이용기록
<br />
                        <br />ο 개인정보 수집방법 : 홈페이지, 가맹센터, 서면(e-mail, 팩스 등)양식 ,전화통화, 배송요청, 협력회사로부터의 제공, 생성정보 수집 툴을 통한 수집 등
<br />※ 전화를 통한 개인정보 수집 시에는 통화내용이 녹취가 되며, 녹취 사실을 이용자에게 알려드립니다.
<br />
                        <br />- 정보주체의 수집이용 거부권 및 불이익 고지
<br />
                        <br />ο 정보주체는 회사가 수집하는 개인정보의 항목 및 수집 방법에 동의를 거부할 수 있으며, 거부 시 회원가입이 불가능 하거나 회원에게 제공되는 서비스 이용의 일부가 제한 될 수 있습니다.
<br />
                        <br />3. 개인정보의 처리 및 보유기간
<br />개인정보의 처리 및 보유기간은 다음과 같습니다.
<br />
                        <br />ο 보유항목: 처리항목과 같습니다.
<br />ο 보유근거: 이용약관 및 개인정보처리방침
<br />ο 보유기간: 이용계약에 따른 회원자격이 유지되는 기간동안
<br />
                        <br />그러나 회원탈퇴 후에도 관계법령의 규정에 의하여 보존할 필요가 있는 경우 회사는 아래와 같이 일정 기간동안 회원정보를 보관합니다.
<br />
                        <br />표시/광고에 관한 기록 : 6개월 (전자상거래등에서의 소비자보호에 관한 법률)
<br />계약 또는 청약철회 등에 관한 기록 : 5년 (전자상거래 등에서의 소비자보호에 관한 법률)
<br />대금결제 및 재화 등의 공급에 관한 기록 : 5년 (전자상거래등에서의 소비자보호에 관한 법률)
<br />소비자의 불만 또는 분쟁처리에 관한 기록 : 3년 (전자상거래등에서의 소비자보호에 관한 법률)
<br />신용정보의 수집/처리 및 이용 등에 관한 기록 : 3년 (신용정보의 이용 및 보호에 관한 법률)
<br />통신비밀보호법에 따른 통신사실확인자료 3개월
<br />
                        <br />4. 개인정보의 제3자 제공
<br />
                        <br />가.회사는 이용자의 개인정보를 원칙적으로 외부에 제공하지 않습니다. 다만, 아래의 경우에는 예외로 합니다.
<br />
                        <br />- 이용자들이 사전에 동의한 경우
<br />- 법령의 규정에 의거하거나, 수사 목적으로 법령에 정해진 절차와 방법에 따라 수사기관의 요구가 있는 경우
<br />나.수집한 개인정보의 위탁
<br />회사는 서비스 이행을 위해 아래와 같이 외부 전문업체에 위탁하여 운영하고 있습니다
<br />ο위탁 대상자: CJ 대한통운
<br />ο위탁업무 내용: 상품 배송업무
<br />ο제공하는 정보: 배송정보:주문자명,배송지 주소,전화번호,휴대폰 번호
<br />
                        <br />ο위탁 대상자: ㈜케이지모빌리언스
<br />ο위탁업무 내용: 휴대폰 결제 시스템
<br />ο제공하는 정보: 결제정보: 이용자성명,휴대폰번호,가입통신사,주민번호 앞7자리,전자메일주소
<br />
                        <br />o 위탁 대상자 : 어린음악대 가맹점
<br />o 위탁업무 내용 : 수업신청 정보: 수업 받는 가맹원,수업시간 및 과목, 이름,주소,휴대폰번호,결제정보
<br />
                        <br />5. 이용자 및 법정대리인의 권리와 그 행사방법
<br />
                        <br />① 정보주체는 회사에 대해 언제든지 다음 각 호의 개인정보 보호 관련 권리를 행사할 수 있습니다.
<br />1) 개인정보 열람 요구
<br />2) 오류 등이 있을 경우 정정 요구
<br />3) 삭제 요구
<br />4) 처리정지 요구
<br />② 제1항에 따른 권리 행사는 회사에 대해 서면, 전화, 전자우편,모사전송(FAX) 등을 통하여 하실 수 있으며 회사는 이에 대해 지체 없이 조치하겠습니다.
<br />③ 정보주체가 개인정보의 오류 등에 대한 정정 또는 삭제를 요구한 경우에는 회사는 정정 또는 삭제를 완료할 때까지 당해 개인정보를 이용하거나 제공하지 않습니다.
<br />④ 제1항에 따른 권리 행사는 정보주체의 법정대리인이나 위임을 받은 자 등 대리인을 통하여 하실 수 있습니다. 이 경우 개인정보 보호법 시행규칙 별지 제11호 서식에 따른 위임장을 제출하셔야 합니다.
<br />⑤ 정보주체는 개인정보보호법 등 관계법령을 위반하여 회사가 처리하고 있는 정보주체 본인이나 타인의 개인정보 및 사생활을 침해하여서는 아니 됩니다.
<br />
                        <br />
                        <br />6. 개인정보의 파기
<br />
                        <br />회사는 회원의 개인정보 보유기간이 경과된 경우에는 보유기간의 종료일로부터 5일 이내, 개인정보의 처리 목적 달성, 해당 서비스의 폐지, 사업의 종료 등 그 개인정보가 불필요하게 되었을 때에는 그 불필요하게 된 날로부터 5일 이내 개인정보를 파기합니다. 단, 관계법령에 의하여 일정기간 보존하여야 할 개인정보는 일정기간 보존 후에 지체없이 파기됩니다.
<br />
                        <br />7. 개인정보 자동수집 장치의 설치·운영 및 그 거부에 관한 사항
<br />
                        <br />회사는 이용자의 정보를 수시로 저장하고 찾아내는 ‘쿠키(cookie)’ 등을 운용합니다. 쿠키란 사이트를 운영하는데 이용되는 서버가 귀하의 브라우저에 보내는 아주 작은 텍스트 파일로서 이용자의 컴퓨터 하드디스크에 저장됩니다. 회사는 다음과 같은 목적을 위해 쿠키를 사용합니다.
<br />
                        <br />▶ 쿠키 등 사용 목적
<br />
                        <br />이용자의 접속 빈도나 방문 시간 등을 분석, 이용자의 취향과 관심분야를 파악 및 자취 추적, 각종 이벤트 참여 정도 및 방문 횟수 파악 등을 통한 타겟 마케팅 및 개인 맞춤 서비스 제공. 이용자는 쿠키 설치에 대한 선택권을 가지고 있습니다. 따라서, 이용자는 웹브라우저에서 옵션을 설정함으로써 모든 쿠키를 허용하거나, 쿠키가 저장될 때마다 확인을 거치거나, 아니면 모든 쿠키의 저장을 거부할 수도 있습니다.
<br />
                        <br />
                        <br />▶ 쿠키 설정 거부 방법
<br />
                        <br />예: 쿠키 설정을 거부하는 방법으로는 이용자가 사용하는 웹 브라우저의 옵션을 선택함으로써 모든 쿠키를 허용하거나 쿠키를 저장할 때마다 확인을 거치거나, 모든 쿠키의 저장을 거부할 수 있습니다.
<br />
                        <br />설정방법 예(인터넷 익스플로어의 경우) : 웹 브라우저 상단의 도구 - 인터넷 옵션 - 개인정보
<br />
                        <br />단, 이용자가 쿠키 설치를 거부하였을 경우 서비스 제공에 어려움이 있을 수 있습니다.
<br />
                        <br />8. 개인정보보호책임자
<br />
                        <br />회사는 회원의 개인정보를 보호하고 개인정보와 관련한 불만을 처리하기 위하여 아래와 같이 개인정보보호책임자를 지정하고 있습니다.
<br />
                        <br />고객서비스담당부서 : CS팀
<br />전화번호 : 1688-7970
<br />
                        <br />개인정보보호책임자 : 김덕주
<br />전화번호 : 02-6925-1822
<br />이메일 : musicgogo@jueun-edu.com
<br />
                        <br />이용자는 회사의 서비스를 이용하며 발생하는 모든 개인정보보호 관련 민원을 개인정보보호책임자 혹은 담당부서로 신고할 수 있습니다. 회사는 이용자들의 신고사항에 대해 신속하게 충분한 답변을 드릴 것입니다.
<br />
                        <br />9. 개인정보에 관한 민원서비스
<br />기타 개인정보침해에 대한 신고나 상담이 필요하신 경우에는 아래 기관에 문의하시기 바랍니다.
<br />
                        <br />1. 개인분쟁조정위원회 (www.1336.or.kr/1336)
<br />2. 정보보호마크인증위원회 (www.eprivacy.or.kr/02-580-0533~4)
<br />3. 대검찰청 첨단범죄수사센터(http://i-minwon.spo.go.kr/02-3480-2582)
<br />4. 경찰청 사이버테러대응센터 (www.ctrc.go.kr/02-392-0330)
<br />
                        <br />본 개인정보처리방침에서 규정되지 않은 사항은 관계법령의 범위 내에서 당사 이용약관을 우선적으로 적용합니다.
<br />
                        <br />- 개인정보처리방침 공고일자 : 2020년 11월1일
<br />
                        <br />- 개인정보처리방침 시행일자 : 2020년 11월1일
<br />
                        <br />
                        <br />
                        <br />
                    </Typography>
                    <hr />
                    <br />
                    <Typography variant='h4'>이메일 무단 수집 거부</Typography>
                    <Typography variant='body2'>
                        <br />
                        <br />본 웹사이트에 게시된 이메일 주소가 전자우편 수집 프로그램이나 그 밖의 기술적 장치를 이용하여 무단으로 수집되는 것을 거부하며 이를 위반 시 정보통신망법에 의해 형사처벌됨을 유념하시기 바랍니다.
<br />
                        <br />[게시일 2020년 11월 1일]
<br />
                    </Typography>


                </SubGrid>
            </MainGrid>
        </>

    )
}

export default Privacy;
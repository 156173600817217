import React from "react";
import dayjs from "dayjs";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  Box,
  Typography,
  Divider,
} from "@material-ui/core";
import { DeleteRounded, SaveRounded } from "@material-ui/icons";
import { useDispatch } from "react-redux";
import {
  deleteAfterSchool,
  updateAfterSchool,
} from "../../../redux/actions/adminActions";

export default function AdminEditAfterSchool({
  editOpen,
  setEditOpen,
  info,
  setInfo,
  initial,
}) {
  const dispatch = useDispatch();

  const handleClose = () => {
    setEditOpen(false);
    setInfo(initial);
  };
  const onChange = (e) => {
    setInfo({ ...info, [e.target.name]: e.target.value });
  };
  const onChangeDate = (e) => {
    setInfo({
      ...info,
      [e.target.name]: dayjs(e.target.value).format("YYYY-MM-DD"),
    });
  };
  const handleSaveInfo = () => {
    console.log("endDay=>", info.endDay);
    // save
    if (
      info.name === "" ||
      info.userId === "" ||
      info.startDay === "" ||
      info.startDay === "Invalid Date" ||
      info.endDay === "" ||
      info.endDay === "Invalid Date" ||
      info.phone === ""
    ) {
      alert("필수정보를 입력하시기 바랍니다.");
    } else if (dayjs(info.startDay) >= dayjs(info.endDay)) {
      alert("종료일은 시작일보다 이후 날짜여야 합니다.");
    } else {
      const conf = window.confirm("수정하시겠습니까?");
      if (conf) {
        dispatch(updateAfterSchool(info));
        setEditOpen(false);
      }
    }
  };
  const handleDeleteInfo = () => {
    const conf = window.confirm(
      "삭제하시겠습니까? 회원Level은 8에서 일반고객(9)으로 전환되며, 해당 리스트에서는 다시는 볼 수 없습니다."
    );
    if (conf) {
      dispatch(deleteAfterSchool(info.afterSchoolId));
      setEditOpen(false);
    }
  };
  return (
    <Dialog open={editOpen} onClose={handleClose} fullWidth maxWidth="sm">
      <DialogTitle>
        <Typography variant="h4">방과후교사 정보 수정</Typography>
      </DialogTitle>
      <DialogContent>
        <TextFieldWithLabel
          labelWidth="150px"
          label="회원ID"
          name="userId"
          value={info?.userId}
          onChange={onChange}
          disabled
        />
        <TextFieldWithLabel
          labelWidth="150px"
          label="회원Level"
          type="number"
          name="userLevel"
          disabled
          value={info.userLevel}
          onChange={(e) => {
            setInfo({ ...info, userLevel: Number(e.target.value) });
          }}
        />
        <TextFieldWithLabel
          labelWidth="150px"
          label="휴대전화"
          subLabel="( - 포함)"
          name="phone"
          disabled
          value={info.phone}
          onChange={onChange}
        />
        <TextFieldWithLabel
          labelWidth="150px"
          label="교사명"
          subLabel="카톡아이디 => 본명"
          name="name"
          value={info.name}
          onChange={onChange}
        />
        {/* 기타 주요정보 */}
        <Divider light style={{ marginTop: 5, marginBottom: 4 }} />

        <TextFieldWithLabel
          labelWidth="150px"
          label="교실(클래스)명"
          name="title"
          value={info?.title}
          onChange={onChange}
        />
        <TextFieldWithLabel
          labelWidth="150px"
          label="시작일"
          name="startDay"
          value={info.startDay}
          onChange={onChangeDate}
          type="date"
        />
        <TextFieldWithLabel
          labelWidth="150px"
          label="종료일"
          name="endDay"
          value={info.endDay}
          onChange={onChangeDate}
          type="date"
        />
        <TextFieldWithLabel
          labelWidth="150px"
          label="메모"
          name="memo"
          value={info.memo}
          onChange={onChange}
          multiline
          maxRows={5}
          minRows={2}
        />
      </DialogContent>
      <DialogActions>
        <Button
          onClick={handleSaveInfo}
          variant="contained"
          color="primary"
          startIcon={<SaveRounded />}
        >
          수정
        </Button>
        <Button
          onClick={handleDeleteInfo}
          variant="contained"
          color="default"
          startIcon={<DeleteRounded />}
        >
          삭제
        </Button>
        <Button onClick={handleClose} variant="contained">
          닫기
        </Button>
      </DialogActions>
    </Dialog>
  );
}

function TextFieldWithLabel({
  labelWidth,
  label,
  subLabel,
  type,
  value,
  name,
  onChange,
  disabled,
  multiline,
  maxRows,
  minRows,
  children, // such as button
}) {
  return (
    <Box display="flex" flexDirection="row" alignItems="center">
      <Box
        display="flex"
        flexDirection="column"
        style={{ flexBasis: labelWidth }}
      >
        <Typography
          display="flex"
          style={{ fontSize: "16px", fontWeight: 500 }}
        >
          {label}
        </Typography>
        {subLabel && <Typography variant="caption">{subLabel}</Typography>}
      </Box>

      <TextField
        variant="outlined" //
        color="primary"
        fullWidth
        style={{ flex: 1 }}
        size="small"
        margin="dense"
        name={name}
        value={value}
        onChange={onChange}
        disabled={disabled}
        type={type ? type : "text"}
        multiline={multiline}
        maxRows={maxRows}
        minRows={minRows}
      />
      {children}
    </Box>
  );
}
